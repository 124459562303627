import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Language from '../../constants/language';
import Button from '../button.component';
import TextInput from '../textInput.component';
import TextArea from '../textarea.component';
import SelectBox from '../selectbox.component';
import { FadeIn } from '../styled/fadeIn.component';
import * as Data from '../../backend/data';
import _ from 'lodash';
import ErrorBoundary from '../error/error.component';
import FallbackUI from '../error/standardFallback.component';
import PagePushNotificationMessagesListContainer from '../../containers/managementInterface/page/pushNotificationMessagesList.container';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentLandingPage } from '../../reducers/slices/currentLandingPage.slice';
import { usePrevious } from '../../utils/utils';
import { getErrorMessage } from '../../utils/error';

const MarginTopBottom1rem = styled.div`
    margin-bottom:1rem;
    margin-top:1rem;
`

const MarginBottom1rem = styled.div`
    margin-bottom:1rem;
`

const MarginTop1rem = styled.div`
    margin-top:1rem;
`

function SendPushNotification(props) {

    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);
    const csrfToken = useSelector((state) => state.csrfToken.value);
    const currentLandingPage = useSelector((state) => state.currentLandingPage.value);
    const currentEvent = useSelector((state) => state.currentEvent.value);
    const currentLandingPageTemplate = useSelector((state) => state.currentLandingPageTemplate.value);

    const [messageTitle, setMessageTitle] = useState(null);
    const [messageText, setMessageText] = useState(null);
    const [landingPageId, setLandingPageId] = useState(null);
    const [guestId, setGuestId] = useState(0);
    const [recipients, setRecipients] = useState([]);
    const [landingPages, setLandingPages] = useState([]);
    const [name, setName] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const previousCurrentEvent = usePrevious(currentEvent);

    useEffect(() => {
        updateStateData();
    },[]);

    useEffect(() => {
        if(currentEvent !== previousCurrentEvent) {
            updateStateData();
        }
    },[currentEvent]);

    function updateStateData() {
        let recipients = [];
        let landingPageId, name, landingPages;
        if(currentEvent !== null || (currentLandingPage !== null && currentLandingPage.landing_page_template.is_public !== 1)) {
            if(currentEvent !== null) {
                recipients = currentEvent.landing_pages?.filter((landingPage) => landingPage.id === landingPageId)[0]?.guests.filter((guest) => guest.has_push_subscription === true); 
                recipients = recipients == undefined ? [] : recipients;
                landingPageId = currentEvent.landing_pages[0]?.id;
                name = currentEvent.name;
                landingPages = currentEvent.landing_pages;
            }
            else if(currentLandingPage !== null) {
                if(currentLandingPage.landing_page_template.is_public !== 1) {
                    recipients = currentLandingPage.guests?.filter((guest) => guest.has_push_subscription === true); 
                }
                landingPageId = currentLandingPage.id;
                name = currentLandingPage.name;
                landingPages = [currentLandingPage];
            }
            recipients.push({ 'id': 0, 'last_name': '', 'first_name': '- ' + Language[language].message.all + ' -'});
            for(let a = 0; a < recipients.length; a++) {
                if(recipients[a].id === 0) {
                    recipients[a].displayField = recipients[a].first_name;
                }
                else {
                    recipients[a].displayField = recipients[a].last_name + ', ' + recipients[a].first_name;
                }
            }
        }
        else if (currentLandingPage !== null && currentLandingPage.landing_page_template.is_public === 1) {
            landingPageId = currentLandingPage.id;
            name = currentLandingPage.name;
            landingPages = [currentLandingPage];
        }
        setLandingPageId(landingPageId);
        setRecipients(recipients);
        setName(name);
        setLandingPages(landingPages);
    }

    function onMessageTextBlur(event) {
        setMessageText(event.target.value);
    }

    function onSendPushMessageButtonClick(event) {
        if( messageTitle !== null 
            && messageTitle.length > 0 
            && messageText !== null 
            && messageText.length > 0 
            && landingPageId !== undefined
        ) {
            let formData = [
                {
                    name: 'csrfToken',
                    value: csrfToken
                },
                {
                    name: 'guestId',
                    value: guestId
                },
                {
                    name: 'landingPageId',
                    value: landingPageId
                },
                {
                    name: 'title',
                    value: messageTitle
                },
                {
                    name: 'message',
                    value: messageText
                }
            ];
            Data.transfer(window.baseUrl + '/builder/user/sendMessage','POST',formData,undefined,undefined,csrfToken)
            .then((data) => {
                if(data.success) {
                    setMessageTitle('');
                    setMessageText('');
                    setErrorMessage(null);
                    setSuccessMessage('Message sent');
                    if(currentLandingPage !== null) {
                        dispatch(setCurrentLandingPage(data.landingPage));
                    }
                }
            })
            .catch((error) => {
                setErrorMessage(getErrorMessage(error));
                setSuccessMessage(null);
            });
        }
        else {
            onRequestDataFailure("Error - No data");
        }
    }

    function onLandingPageSelectBoxChanged(event) {
        setLandingPageId(event.target.value);
    }

    function onUserSelectBoxChanged(event) {
        setGuestId(event.target.value);
    }

    function onTitleInputChange(event) {
        setMessageTitle(event.target.value);
    }

    function onTitleInputBlur(event) {
        setMessageTitle(event.target.value);
    }

    return (
        <FadeIn>
            <div className="container">
                <MarginTop1rem className="row">
                    <div className="col">
                        { Language[language].message.sendNewPushMessage }
                    </div>
                </MarginTop1rem>
                <MarginTopBottom1rem className="row">
                    <div className="col">
                        {
                            name
                        }
                    </div>
                </MarginTopBottom1rem>
                <MarginTopBottom1rem className="row">
                    <div className="col">
                        <MarginTopBottom1rem className="row">
                            <div className="col">
                                {
                                    currentLandingPage !== null && currentLandingPage.landing_page_template.is_public === 1 &&
                                    <PagePushNotificationMessagesListContainer
                                        key={"pushNotificationMessagesPageContainer"}
                                    />
                                }
                            </div>
                        </MarginTopBottom1rem>
                    </div>
                    <div className="col">
                        <MarginTopBottom1rem className="row">
                            <div className="container-fluid">
                                <div className="row mb-3">
                                    <div className="col text-center">
                                        {
                                            Language[language].message.newMessage
                                        }
                                    </div>
                                </div>
                            </div>
                        </MarginTopBottom1rem>
                        <MarginTopBottom1rem className="row">
                            <div className="col">
                                <TextInput
                                    key={"newPushMessageTitle"}
                                    type={"text"}
                                    label={Language[language].message.title}
                                    name={"newPushMessageTitle"}
                                    placeholder={Language[language].message.title}
                                    onBlur={onTitleInputBlur}
                                    onChange={onTitleInputChange}
                                    submitOnEnter={false}
                                    required={false}
                                    disabled={false}
                                    value={messageTitle}
                                />
                            </div>
                        </MarginTopBottom1rem>
                        <MarginTopBottom1rem className="row">
                            <div className="col">
                                <TextArea
                                    key={"newPushMessageText"}
                                    rows={"4"}
                                    disabled={false}
                                    required={false}
                                    onBlur={onMessageTextBlur}
                                    name={"messageText"}
                                    label={Language[language].message.message}
                                    value={messageText}
                                />
                            </div>
                        </MarginTopBottom1rem>
                    </div>
                    {
                        (currentLandingPage === null || (currentLandingPage !== null && currentLandingPage.landing_page_template.is_public !== 1)) &&
                        <div className="col">
                            <MarginTopBottom1rem className="row">
                                <div className="col">
                                    <SelectBox
                                        key={"newPushMessageLandingPageSelectBoxComponent"}
                                        disabled={false}
                                        required={true}
                                        onChange={onLandingPageSelectBoxChanged}
                                        name={"newPushMessageLandingPageSelectBox"}
                                        label={Language[language].texts.events.landingPage}
                                        displayField={"name"}
                                        valueField={"id"}
                                        options={landingPages}
                                        disabledOperatorIds={[]}
                                        value={landingPageId}
                                        />
                                </div>
                            </MarginTopBottom1rem>
                            <MarginBottom1rem className="row">
                                <div className="col">
                                    <SelectBox
                                        key={"newPushMessageUserSelectBoxComponent"}
                                        disabled={false}
                                        required={true}
                                        onChange={onUserSelectBoxChanged}
                                        name={"newPushMessageUserSelectBox"}
                                        label={Language[language].texts.events.guest}
                                        displayField={"displayField"}
                                        displayFieldsSeparator={', '}
                                        valueField={"id"}
                                        options={_.orderBy(recipients,['last_name'],['asc'])}
                                        disabledOperatorIds={[]}
                                        value={guestId}
                                        />
                                </div>
                            </MarginBottom1rem>
                        </div>
                    }
                </MarginTopBottom1rem>
                <MarginBottom1rem className="row">
                    <div className="col">
                        <Button
                            key={"sendNewPushMessage"}
                            buttonText={Language[language].general.submit}
                            additionalClasses={""}
                            onClicked={onSendPushMessageButtonClick}
                        />
                    </div>
                </MarginBottom1rem>
                {
                    errorMessage !== null && 
                    <MarginBottom1rem className="row">
                        <div className="col alert alert-danger">
                            { errorMessage }
                        </div>
                    </MarginBottom1rem>
                }
                {
                    successMessage !== null && 
                    <MarginBottom1rem className="row">
                        <div className="col alert alert-success">
                            { successMessage }
                        </div>
                    </MarginBottom1rem>
                }
            </div>
        </FadeIn>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"sendPushNotificationMessage.component.js"}
            fallbackUI={<FallbackUI language={language} component={"sendPushNotificationMessage"} />}
        >
            <SendPushNotification
                key={"sendPushNotificationMessageComponent"}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;