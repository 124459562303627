import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import Language from '../../../constants/language';
import * as Data from '../../../backend/data';
import AppFeatures from "../../../components/app/appFeatures.component";
import Status from "../../../components/app/status.component";
import AppMessages from "../../../containers/app/appMessages.container";
import AppText from "../../../components/app/appText.component";
import BrowserIncompatabilityWarning from "../../../components/app/browserIncompatibilityWarning.component";
import ErrorBoundary from '../../../components/error/error.component';
import FallbackUI from '../../../components/error/standardFallback.component';
import CookieAndPrivacyConsent from '../../../components/cookieAndPrivacyConsent.component';
import Footer from '../../../components/footer.component';
import ImprintView from '../../../views/imprint.view'; 
import PrivacyView from '../../../views/privacy.view';
import LandingPageStartImage from '../../../components/app/landingPageStartImage.component';
import LogVisit from '../../../components/log/logVisit.component';
import { isNullOrUndefined } from "../../../utils/utils";
import { PageBackgroundImage } from "../../../components/app/pageBackgroundImage.component";
import Row from '../../../components/app/row.component';
import { useSelector, useDispatch } from 'react-redux';
import { setNavigation } from "../../../reducers/slices/navigation.slice";

const Separator = styled.hr`
    width: 25%;
    border-color: #b7b7b7;
    margin-top:3rem;
    margin-bottom:3rem;
`

const MarginTop2rem = styled.div`
    margin-top:2rem;
`

const Modal = styled.div`
    position:absolute;
    top:3%;
    width: 90%;
    left:50%;
    margin-left:-45%;
    background-color:#fff;
    color:#000;
    border-radius:0.25rem;
    z-index:9999;
    padding: 1rem;
    height:90vh;
    overflow-y:auto;
`

const ContainerWithMobileView = styled.div`
    @media (max-width: 576px) {
        padding-left:0px;
        padding-right:0px;
    }
`

function StandardView(props) {

    const dispatch = useDispatch();
    const navigation = useSelector((state) => state.navigation.value);

    const [landingPageLanguage, setLandingPageLanguage] = useState(props.language);
    const [showNewContentAvailable, setShowNewContentAvailable] = useState(false);
    const [view, setView] = useState(undefined);

    const additionalButtonStyles = {backgroundColor:'#021030',color:'#fff',height:'auto',fontFamily: 'Parisienne,cursiv',fontSize: '1.7rem'};
    const buttonIconColor = '#021030';

    useEffect(() => {
        setLandingPageLanguage(getLandingPageLanguage());

        if(window.location.hash === '#newMessage') {
            window.setTimeout(() => {
                document.getElementById("appMessagesEnd").scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
            },1500);
        }

        if('serviceWorker' in navigator) {
            //check new content available
            //see SO: https://stackoverflow.com/questions/37573482/to-check-if-serviceworker-is-in-waiting-state
            navigator.serviceWorker.ready
            .then((registration) => {
                if(registration.waiting && registration.active) {
                    setShowNewContentAvailable(true);
                } 
                else {
                    // updatefound is also fired for the very first install. ¯\_(ツ)_/¯
                    registration.addEventListener('updatefound', () => {
                        registration?.installing?.addEventListener('statechange', () => {
                            if (event.target.state === 'installed') {
                                if (registration.active) {
                                    // If there's already an active SW, and skipWaiting() is not
                                    // called in the SW, then the user needs to close all their
                                    // tabs before they'll get updates.
                                    setShowNewContentAvailable(true);
                                }
                            }
                        });
                    });
                }
            });
        }

        let view;
        if(navigation === 'ImprintView') {
            view = <ImprintView key={"appImprintViewComponent"} location={"app"} language={landingPageLanguage} />;
        }
        if(navigation === 'PrivacyView') {
            view = <PrivacyView key={"appPrivacyViewComponent"} location={"app"} language={landingPageLanguage} />;
        }
        setView(view);

    },[]);

    function getLandingPageLanguage() {
        let language = props.language;
        if(props.guestLandingPage != null) {
            if(props.guestLandingPage.landing_page.app_info.language_bcp47_code !== null) {
                if(props.guestLandingPage.landing_page.app_info.language_bcp47_code.indexOf('-') > -1) {
                    let languageSplitted = props.guestLandingPage.landing_page.app_info.language_bcp47_code.split('-');
                    language = languageSplitted[0];
                }
                else {
                    language = props.guestLandingPage.landing_page.app_info.language_bcp47_code;
                }
            }
        } 
        return language;
    }

    function onModalCloseButtonClick() {
        dispatch(setNavigation(null));
    }

    /*
    let additionalButtonStyles = {backgroundColor:'#021030',color:'#fff',height:'auto',fontFamily: 'Parisienne,cursiv',fontSize: '1.7rem'};
    let buttonIconColor = '#021030';

    let View;
    if(this.props.navigation === 'ImprintView') {
        View = <ImprintView key={"appImprintViewComponent"} location={"app"} language={this.state.landingPageLanguage} />;
    }
    if(this.props.navigation === 'PrivacyView') {
        View = <PrivacyView key={"appPrivacyViewComponent"} location={"app"} language={this.state.landingPageLanguage} />;
    }
    */
    return (
        <>
            <PageBackgroundImage 
                key={"backgroundImageKey"} 
                images={props.guestLandingPage.landing_page.app_images}
                isEditImageVisible={false}
            />
            <LandingPageStartImage
                key={"landingPageStartImage"}
                guestLandingPage={props.guestLandingPage}
                language={landingPageLanguage}
            />
            <div className="container-fluid p-0">
                {
                    _.orderBy(props.guestLandingPage?.landing_page.rows,['row_no'])?.map((row, index) => {
                        return (
                            <Row 
                                key={`row${index}`} 
                                row={row}
                                landingPage={props.guestLandingPage?.landing_page}
                                isEditModeActive={false}
                            />
                        )
                    })
                }
                <MarginTop2rem className="row">
                    <div className="col d-flex justify-content-center">
                        <Separator 
                            key={"separatorAppMessages"}
                        />
                    </div>
                </MarginTop2rem>
                <MarginTop2rem className="row">
                    <div className="col text-center">
                        <div className="container-lg">
                            <div className="row">
                                <div className="col text-center">
                                    <AppMessages    
                                        key={"appMessages"}
                                        messages={props.guestLandingPage.messages}
                                        textStyle={additionalButtonStyles}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </MarginTop2rem>
                <div className="row">
                    <div className="col">
                        <br />
                        <br />
                    </div>
                </div>
            </div>
            {/*</Background>*/}
            <AppFeatures
                key={"appFeatuesComponentKey"}
                language={landingPageLanguage}
                additionalButtonStyles={additionalButtonStyles}
                guestLandingPage={props.guestLandingPage}
                buttonIconColor={buttonIconColor}
            />
            <Status 
                key={"statusComponentKey"} 
                language={landingPageLanguage}
            />
            <BrowserIncompatabilityWarning 
                key={"browserIncompatibalityWarning"}
                language={landingPageLanguage}
            />
            <CookieAndPrivacyConsent 
                key={"cookieAndPrivacyConsentComponent"}
                location={"app"}
            />
            <LogVisit 
                key={"logVisitComponent"}
                landingPage={props.guestLandingPage?.landing_page}
                language={landingPageLanguage}
                csrfToken={props.csrfToken}
            />
            <Footer
                key={"footerComponent"}
                location={"app"} 
                {...props}
            />
            {
                (navigation === 'PrivacyView' || navigation === 'ImprintView') &&
                <Modal>
                    <ContainerWithMobileView className="container-fluid">
                        <div className="row d-flex align-items-center text-center">
                            <div className="col text-end">
                                <button type="button" className="btn-close" aria-label="Close" onClick={onModalCloseButtonClick}></button>
                            </div>
                        </div>
                        <div className="row d-flex align-items-center text-center">
                            <div className="col">
                                { view }
                            </div>
                        </div>
                    </ContainerWithMobileView>
                </Modal>
            }
        </>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"app"}
            component={"page/standard.view.js"}
            fallbackUI={<FallbackUI language={language} component={"standardView"} />}
        >
            <StandardView
                key={"standardView"}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;