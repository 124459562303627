import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import ErrorBoundary from '../../../error/error.component';
import FallbackUI from '../../../error/standardFallback.component';
import Selectbox from '../../../selectbox.component';
import * as ProductStatus from '../../../../constants/productStatus';
import Language from '../../../../constants/language';
import ProductSetup from './productSetupWizard.component';
import Button from '../../../button.component';
import { Icon } from '../../../styled/icon.component';
import { isNullOrUndefined } from '../../../../utils/utils';
import { useSelector, useDispatch } from 'react-redux';
import { createEditor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import { useTextElements } from '../../../textAreaWithEditingOptions.component';
import { v4 as uuidv4 } from 'uuid';

import infinityIcon from '../../../../assets/images/infinity.svg';
import editIcon from '../../../../assets/images/edit.svg';
import deleteIcon from '../../../../assets/images/trash.svg';

function ProductListRow(props) {
    const language = useSelector((state) => state.language.value);
    const user = useSelector((state) => state.user.value);

    const editor = useMemo(() => withReact(createEditor()), []);
    const [editorKey,setEditorKey] = useState(uuidv4());

    const { renderElement, renderLeaf } = useTextElements(); 

    function onProductStatusChange() {

    }

    return (
        <tr>
            <td>
                <Icon 
                    image={editIcon} 
                    title=""
                    hoverBackgroundColor={'darkgray'}
                    cursor={'pointer'}
                    onClick={(event) => props.onEditProduct(props.product)}
                /> 
            </td>
            <td>
                <Icon 
                    image={deleteIcon} 
                    title=""
                    hoverBackgroundColor={'darkgray'}
                    cursor={'pointer'}
                    onClick={(event) => props.onProductDeleted(props.product)}
                />
            </td>
            <td>
                {
                    <Selectbox
                        key={`productStatus${props.product.id}`}
                        name={`productStatus${props.product.id}`}
                        label={Language[language].shop.wizard.product.status}
                        value={props.product.product_status_id}
                        valueField={"id"}
                        displayField={"name"}
                        options={[
                            {
                                id: ProductStatus.PRODUCT_STATUS_ACTIVE,
                                name: Language[language].shop.wizard.product.productStatusActive
                            },
                            {
                                id: ProductStatus.PRODUCT_STATUS_PAUSED,
                                name: Language[language].shop.wizard.product.productStatusPaused
                            }
                        ]}
                        onChange={onProductStatusChange}
                        onBlur={onProductStatusChange}
                        width={'fit-content'}
                    />
                }
            </td>
            <td>{props.product.product_no}</td>
            <td>{props.product.name}</td>
            <td>                
                <Slate
                    key={editorKey}
                    editor={editor}
                    value={JSON.parse(props.product.description)}
                    onChange={(event) => event}
                >
                    <Editable 
                        readOnly={true}
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                    />
                </Slate>
            </td>
            <td>{`${props.product.price_net} ${user.business_data.country.currency.symbol}`}</td>
            <td>
                {
                    props.product.shipping_costs_domestic !== '0.00' ?
                        `${props.product.shipping_costs_domestic} ${user.business_data.country.currency.symbol}`
                        : ``
                }
            </td>
            <td>
                {
                    props.product.shipping_costs_european_foreign_countries !== '0.00' ?
                        `${props.product.shipping_costs_european_foreign_countries} ${user.business_data.country.currency.symbol}`
                        : ``
                }
            </td>
            <td>
                {
                    props.product.shipping_costs_other_countries !== '0.00' ?
                        `${props.product.shipping_costs_other_countries} ${user.business_data.country.currency.symbol}`
                        : ``
                }
            </td>
            <td>{props.product.maximum_number_of_products_in_shipping_price}</td>
            <td>
                {
                    props.product.is_default_sales_tax_of_country ? 
                        `${user.business_data.country.sales_tax_amount_in_percent}%` 
                        : `${parseInt(props.product.sales_tax_amount_in_percent)}%`
                }
            </td>
            <td>
                {
                    props.product.unlimited_number_available ? 
                        <Icon 
                            image={infinityIcon} 
                            title=""
                            hoverBackgroundColor={'darkgray'}
                            cursor={'pointer'}
                        /> 
                        : props.product.number_available_goods
                }
            </td>
        </tr>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"productListRow.component.js"}
            fallbackUI={<FallbackUI language={language} component={"productListRow"} />}
        >
            <ProductListRow
                key={`productListRowComponent`}
                {...props}
            />
       </ErrorBoundary>
   )
}
 
export default ComponentWithErrorBoundary;