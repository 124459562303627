import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isNullOrUndefined } from '../../utils/utils';
import Language from '../../constants/language';
/*
import { connect } from 'react-redux';
import { updateNavigation, updateIsCookieAndPrivacyOptOutOpen } from '../../actions/index';
*/
import ErrorBoundary from '../error/error.component';
import FallbackUI from '../error/standardFallback.component';
import { useSelector, useDispatch } from 'react-redux';
import { setIsCookieAndPrivacyOptOutOpen } from '../../reducers/slices/isCookieAndPrivacyOptOutOpen.slice';

export const Link = styled.a`
    color:inherit;
    cursor:pointer;

    &:hover {
        color:inherit;
    }
`

const HiddenSpan = styled.span`
    display:none;
`

export const StyledSpan = styled.span`
    font-weight:${props => props.styles.bold ? 'bold' : 'normal'};
    font-style:${props => props.styles.italic ? 'italic' : 'normal'};
    text-decoration:${props => props.styles.underline ? 'underline' + (props.styles.strikethrough ? ' line-through' : '') : props.styles.strikethrough ? 'line-through' : 'none'};
    color:${props => props.styles.colorSelection ? props.styles.colorSelection : 'inherit'};
`
export const UnstyledSpan = styled.span`
`

export function getUrlsInText(text) {
    const regex = /(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?/g;
    return text.match(regex);
}

export function isUrlText(text) {
    const regex = /(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?/g;
    return regex.test(text);
}

export function getEmailAddressesInText(text) {
    const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
    return text.match(regex);
}

export function isEmailAddressText(text) {
    const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
    return regex.test(text);
}

export function getPhoneNumbersInText(text) {
    const regex = /(([+][0-9]{1,3})[ ]([0-9]{2,5}))[ ][0-9]{1,9}([-]?[0-9]{1,4})/g;
    return text.match(regex);
}

export function getNewLineOccurrencesInText(text) {
    const regex = /\\n/g;
    return text.match(regex);
}

function getPrivacyUsageOptionOccurencesInText(text) {
    return extractStringFromText(text,'$$$changePrivacyUsageTextBegin$$$','$$$changePrivacyUsageTextEnd$$$');
}

function getCookieUsageOptionOccurencesInText(text) {
    return extractStringFromText(text,'$$$changeCookieUsageTextBegin$$$','$$$changeCookieUsageTextEnd$$$');
}

function getUnorderListOccurencesInText(text) {
    return extractStringFromText(text,'$$$unorderedListBegin$$$','$$$unorderedListEnd$$$');
}

function extractStringFromText(text,extractBeginText,extractEndText,filterPlaceholders = false) {
    let occurences = [];
    let start = text.indexOf(extractBeginText);
    let end = text.indexOf(extractEndText);
    while(start > -1 && end > -1) {
        let match = text.substring(start,end + extractEndText.length);
        if(filterPlaceholders) {
            occurences.push(match.replace(extractBeginText,'').replace(extractEndText,''));
        }
        else {
            occurences.push(match);
        }
        text = text.replace(match,'');
        start = text.indexOf(extractBeginText);
        end = text.indexOf(extractEndText);
    }
    return occurences;
}

function TextReplacement(props) {

    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);

    function getAllReplacementPositions(text) {
        let allReplacementPositions = [];
        const emailAddresses = getEmailAddressesInText(text);
        if(emailAddresses && emailAddresses.length > 0) {
            emailAddresses.forEach((entry) => {
                allReplacementPositions.push(
                    {
                        text: entry,
                        type: 'email'
                    }
                );
            
            })
        }
        const urls = getUrlsInText(text);
        if(urls && urls.length > 0) {
            urls.forEach((entry) => {
                allReplacementPositions.push(
                    {
                        text: entry,
                        type: 'url'
                    }
                );
            })
        }
        const phoneNumbers = getPhoneNumbersInText(text);
        if(phoneNumbers && phoneNumbers.length > 0) {
            phoneNumbers.forEach((entry) => {
                allReplacementPositions.push(
                    {
                        text: entry,
                        type: 'phone'
                    }
                );
            })
        }
        const newLines = getNewLineOccurrencesInText(text);
        if(newLines && newLines.length > 0) {
            allReplacementPositions.push(
                {
                    text: newLines[0],
                    type: 'newLine'
                }
            );
        }
        const privacyOptions = getPrivacyUsageOptionOccurencesInText(text);
        if(privacyOptions.length > 0) {
            privacyOptions.forEach((entry) => {
                allReplacementPositions.push(
                    {
                        text: entry,
                        type: 'privacyOption'
                    }
                );
            });
        }
        const cookieOptions = getCookieUsageOptionOccurencesInText(text);
        if(cookieOptions.length > 0) {
            cookieOptions.forEach((entry) => {
                allReplacementPositions.push(
                    {
                        text: entry,
                        type: 'cookieOption'
                    }
                );
            });
        }
        const unorderedListOccurences = getUnorderListOccurencesInText(text);
        if(unorderedListOccurences.length > 0) {
            unorderedListOccurences.forEach((entry) => {
                allReplacementPositions.push(
                    {
                        text: entry,
                        type: 'unorderedList'
                    }
                );
            });
        }
        return allReplacementPositions;
    }

    function getLowestComponentPositionText(componentTexts, text) {
        let componentTextFoundAtPosition = null;
        let returnObject;
        componentTexts
            .filter((componentText) => componentText.foundAtTextPosition === undefined)
            .forEach((componentText, index) => {
                let indexPosition = getIndexPosition(text, componentTexts, index);
                if (
                    text.indexOf(componentText.text, indexPosition) < componentTextFoundAtPosition ||
                    componentTextFoundAtPosition === null
                ) {
                    componentText.foundAtTextPosition = text.indexOf(componentText.text,indexPosition);
                    componentText.textEndsAtPosition = text.indexOf(componentText.text,indexPosition) + componentText.text.length;
                    componentText.indexPosition = indexPosition;
                    returnObject = componentText;
                }
            });
        return returnObject;
    }

    function getIndexPosition(text, componentTexts, index) {
        let indexPosition = 0;
        componentTexts.forEach((componentText, loopIndex) => {
            if (componentText.text === componentTexts[index].text && loopIndex <= index) {
                //occurencesFound++;
                indexPosition = text.indexOf(componentText.text, indexPosition + loopIndex);
            }
        });
        return indexPosition;
    }
      
    function getComponentsTextWithPositionInTextSorted(componentTexts, text) {
        while (componentTexts.find((componentText) => componentText.foundAtTextPosition === undefined) !== undefined) {
            let updatedComponentText = getLowestComponentPositionText(componentTexts, text);
            componentTexts.forEach((componentText) => {
                if (componentText.text === updatedComponentText.text) {
                    componentText = updatedComponentText;
                }
            });
        }
        return _.sortBy(componentTexts, ["foundAtTextPosition"], ["asc"]);
    }

    return (
        <>
            {
                props.text.split('\\n').map((text, textIndex) => {
                    let allReplacements = getAllReplacementPositions(text);
                    if(!isNullOrUndefined(allReplacements) && allReplacements.length > 0) {
                        allReplacements = getComponentsTextWithPositionInTextSorted(allReplacements,text);
                        return allReplacements.map((replacement, index) => {
                            let textBegin = 0;
                            if (index > 0) {
                              textBegin = allReplacements[index - 1].textEndsAtPosition;
                            }
                            let textBefore = text.substring(textBegin,replacement.foundAtTextPosition);
                            let textAfter = "";
                            if (index === allReplacements.length - 1) {
                                textAfter = text.substring(replacement.textEndsAtPosition);
                            }
                            if(replacement.type === 'url' && textBefore.substring(textBefore.length-1) === '@') {
                                return;
                            }
                            if(textBegin > replacement.foundAtTextPosition) {
                                return <span key={"text" + textIndex + '.' + index}>{textAfter}</span>
                            }
                            return (
                                <UnstyledSpan 
                                    styles={props.text}
                                    key={"text" + textIndex + '.' + index}
                                >
                                    {
                                        textBefore.length > 0 &&
                                        <span>{textBefore}</span>
                                    }
                                    {
                                        replacement.type === 'email' &&
                                        <EmailAddress 
                                            key={"email" + index} 
                                            email={replacement.text}
                                        />
                                    }
                                    {
                                        replacement.type === 'url' &&
                                        <UrlLink
                                            key={"url" + index} 
                                            url={replacement.text}
                                        />
                                    }
                                    {
                                        replacement.type === 'phone' &&
                                        <PhoneNumberLink
                                            key={"phone" + index} 
                                            number={replacement.text}
                                        />
                                    }
                                    {
                                        (replacement.type === 'privacyOption' || replacement.type === 'cookieOption') &&
                                        <OpenCookieAndPrivacyLinkWithRedux
                                            key={"cookieAndPrivacyLink" + index}
                                            language={language}
                                            type={replacement.type}
                                        />
                                    }
                                    {                                        
                                        replacement.type === 'unorderedList' &&
                                        <UnorderedList
                                            key={"unorderedList" + index} 
                                            text={replacement.text}
                                        />
                                    }
                                    {
                                        textAfter.length > 0 &&
                                        <span>{textAfter}</span>
                                    }
                                </UnstyledSpan>
                            );
                        })
                    }
                    else {
                        return (
                            <UnstyledSpan 
                                styles={props.text}
                                key={"text" + textIndex}
                            >
                                {
                                    text
                                }
                            </UnstyledSpan>
                        )
                    }
                })
            }
        </>
    )
}



export function UrlLink(props) {
    const [hrefLink,setHrefLink] = useState(props.url);
    useEffect(() => {
        setHrefLink('//' + props.url);
        if(props.url.substring(0,7) === 'http://' || props.url.substring(0,8) === 'https://') {
            setHrefLink(props.url);
        }
    },[]);

    return (
        <Link href={hrefLink} target="_blank" rel="noreferrer">{props.url}</Link>
    )
}

export function PhoneNumberLink(props) {
    return (
        <Link key={"number" + props.number} href={"tel:" + props.number.replace(' ','')} target="_blank" rel="noreferrer">{props.number}</Link>
    )
}

export function EmailAddress(props) {
    function onEmailAddressClick() {
        document.location.href = "mailto:" + props.email;
    }

    if(props.email.indexOf('@') > -1) {
        let emailUntilAtSign = props.email?.substring(0,props.email.indexOf('@'))?.replaceAll('.','(dot)');
        let emailFromAtSignTillEnd = props.email?.substring(props.email.indexOf('@')+1,props.email.length)?.replaceAll('.','(dot)');
        let randomString = (Math.random() + 1).toString(36).substring(2,9);
        return (
            <>
                <Link key={"email" + props.email} onClick={onEmailAddressClick}>
                    {
                        emailUntilAtSign
                    }
                    <span>(at)</span>
                    <HiddenSpan>
                        {
                            randomString
                        }
                    </HiddenSpan>
                    {
                        emailFromAtSignTillEnd
                    }
                </Link>
            </>
        )
    }
}

function OpenCookieAndPrivacyLinkWithRedux(props) {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);
    
    function onLinkClick(event) {
        dispatch(setIsCookieAndPrivacyOptOutOpen(true));
        //props.updateIsCookieAndPrivacyOptOutOpen(true);
    }

    return (
        <Link
            key={"changeCookieAndPrivacySettings"}
            onClick={onLinkClick}
        >
            {
                props.type === 'privacyOption' &&
                Language[language].privacy.changeTrackingOption
            }
            {
                props.type === 'cookieOption' &&
                Language[language].privacy.changeCookieUsageOption
            }
        </Link>
    )
}

function  UnorderedList(props) {
    const [unorderedListEntries, setUnorderedListEntries] = useState([]);

    useEffect(() => {
        if(!isNullOrUndefined(props.text)) {
            let entries = extractStringFromText(props.text,'$$$unorderedListEntryBegin$$$','$$$unorderedListEntryEnd$$$',true);
            if(entries.length > 0) {
                setUnorderedListEntries(entries);
            }
        }
    },[props]);

    return (
        <>
            {
                unorderedListEntries.map((entry, index) => {
                    return (
                        <UnorderedListEntry
                            key={"unorderedListEntry" + index}
                            text={entry}
                        />
                    )
                })
            }
        </>
    )
}

function UnorderedListEntry(props) {
    return (
        <li>
            {props.text}
        </li>
    )
}

/*
function mapStateToProps({ user, navigation, language, csrfToken, isCookieAndPrivacyOptOutOpen }) {
    return {
        user,
        navigation,
        language,
        csrfToken,
        isCookieAndPrivacyOptOutOpen
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateNavigation: navigation => dispatch(updateNavigation(navigation)),
        updateIsCookieAndPrivacyOptOutOpen: isCookieAndPrivacyOptOutOpen => dispatch(updateIsCookieAndPrivacyOptOutOpen(isCookieAndPrivacyOptOutOpen)),
    };
}
*/

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"textReplacement.component.js"}
            fallbackUI={<FallbackUI language={language} component={"textReplacement"} />}
        >
            <TextReplacement
                key={"textReplacementComponent"}
                {...props}
            />
       </ErrorBoundary>
   )
}
 
/*
const Form = connect(
    mapStateToProps,
    mapDispatchToProps
)(ComponentWithErrorBoundary);
*/

/*
const OpenCookieAndPrivacyLinkWithRedux = connect(
    mapStateToProps,
    mapDispatchToProps
)(OpenCookieAndPrivacyLink);
*/

export default ComponentWithErrorBoundary;