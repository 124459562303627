import React, { Component } from 'react';
import Language from '../../constants/language';
import * as Gender from '../../constants/gender';
import * as GuestType from '../../constants/guesttypes';

class Salutation extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        let salutation;
        if(GuestType.GUEST_TYPE_FAMILY === this.props.guestLandingPage.guest_type_id) {
            salutation = Language[this.props.language].texts.salutation.dearFamily + ' ' + this.props.guestLandingPage.guest.last_name;
        }
        else {
            switch(this.props.guestLandingPage.guest.gender_id) {
                case Gender.GENDER_MALE:
                    salutation = Language[this.props.language].texts.salutation.dearMale + ' ' + this.props.guestLandingPage.guest.first_name;
                    break;
                case Gender.GENDER_FEMALE:
                    salutation = Language[this.props.language].texts.salutation.dearFemale + ' ' + this.props.guestLandingPage.guest.first_name;
                    break;
                case Gender.GENDER_DIVERSE:
                    salutation = Language[this.props.language].texts.salutation.dearDiverse + ' ' + this.props.guestLandingPage.guest.first_name;
                    break;
                default:
                    salutation = Language[this.props.language].texts.salutation.dearDiverse + ' ' + this.props.guestLandingPage.guest.first_name;
                    break;
            }
        }
        salutation += ','
        return (
            <div>
                {
                    salutation
                }
            </div>
        );
	}
}

export default Salutation;
