import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: null,
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setNavigation: (state, action) => {
      state.value = action.payload
    },
  },
});

export const { setNavigation } = navigationSlice.actions;

export default navigationSlice.reducer;