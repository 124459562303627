import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    ${props => props.font?.is_default_web_font !== undefined && props.font?.is_default_web_font === 0 && 
    `@font-face {
        font-family: ` + props.font?.font_family + `;
        src: url('` + props.fontEotFile + `');
        src: url('` + props.fontEotFile + `?#iefix') format('embedded-opentype'), url('` + props.fontWoff2File + `') format('woff2'), url('` + props.fontWoffFile + `') format('woff'), url('` + props.fontTtfFile + `') format('truetype'), url('` + props.fontSvgFile + '#' + props.appText?.font?.font_family + `') format('svg');
        font-weight: ` + props.appText?.font?.font_weight + `; 
        font-style: ` + props.appText?.font?.font_style + `;
    }`}
`;

/*
Fonts Converter
https://www.fontsquirrel.com/
*/