import React, { Component } from 'react';
import styled from 'styled-components';
import SelectboxOption from './selectboxOption';
import { v4 as uuidv4 } from 'uuid';

const Select = styled.select` 
    height: calc(1.5em + 1.5rem + 2px) !important;
    width: ${props => props.width ? props.width : '100%' };
`

const Label = styled.label`
    padding: 0px; 
    position: absolute; 
    top: 8px; 
    left: 12px; 
    color: rgb(108, 115, 120); 
    font-size: 15px; 
    line-height: 20px; 
    font-weight: normal; 
    transition: all 0.25s ease-in-out 0s; 
    transform: translate(1px, -6px) scale(0.75); 
    transform-origin: left top;
`

const DivRelative = styled.div`
    position:relative;
`

class Selectbox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            key: uuidv4()
        }
        this.selectBox = React.createRef();
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if(prevProps.value !== this.props.value) {
            this.setState({
                inputTextValue: this.props.value
            });
            this.selectBox.current.value = this.props.value;
        } 
    }

    onChangedHandler(event) {
        if(this.props.onChange) {
            event.preventDefault()
            this.props.onChange(event);
        }
    }

    render() {
        return (
            <DivRelative>
                <Select 
                    key={this.state.key}
                    className={"form-control " + ((this.props.additionalClasses) ? this.props.additionalClasses : "")}
                    defaultValue={ this.props.value }
                    disabled={(this.props.disabled) ? true : undefined }
                    required={(this.props.disabled) ? true : undefined }
                    onChange={this.onChangedHandler.bind(this)}
                    ref={this.selectBox}
                    name={(this.props.name) ? this.props.name : undefined }
                    autoFocus={(this.props.autoFocus) ? true : undefined }
                    width={this.props.width}
                >
                    {
                        this.props.options.map(option => {
                            return (
                                <SelectboxOption  
                                    key={option[this.props.displayField] + "Key"} 
                                    option={option} 
                                    valueField={this.props.valueField} 
                                    displayField={this.props.displayField} 
                                    disabled={((this.props.disabledOperatorIds) ? this.props.disabledOperatorIds.filter(d => d === option.id).length > 0 : undefined)} 
                                />
                            )
                        })
                    }
                </Select>
                {
                    this.props.label && this.props.name && 
                    <Label htmlFor={this.props.name}>{this.props.label}</Label>
                }
            </DivRelative>
	    );
	}
}

export default Selectbox;