import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import ManageImages from '../../components/managementInterface/manageLandingPageImages.component';
import ManageSubdomain from '../../components/managementInterface/manageSubdomain.component';
import UserInteractionFeedbackMessage from '../../components/managementInterface/userInteractionFeedbackMessage.component';
import Button from '../../components/button.component';
import Language from '../../constants/language';
import * as Data from '../../backend/data';
import * as PageTypes from '../../constants/pagetypes';
import * as MessageTypes from '../../constants/messagetypes';
import { Icon } from "../../components/styled/icon.component";
import QrCode from '../../components/managementInterface/qrCode.component';
import Shop from '../../containers/managementInterface/shop.container';
import SendPushNotificationMessage from '../../components/managementInterface/sendPushNotificationMessage.component';
import ErrorBoundary from '../../components/error/error.component';
import FallbackUI from '../../components/error/standardFallback.component';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentLandingPage } from '../../reducers/slices/currentLandingPage.slice';
import { setCurrentLandingPageTemplate } from '../../reducers/slices/currentLandingPageTemplate.slice';
import { setFonts } from "../../reducers/slices/fonts.slice";
import { setMessage } from "../../reducers/slices/message.slice";
import { setUser } from '../../reducers/slices/user.slice';
import { setRowTypes } from '../../reducers/slices/rowTypes.slice';
import { setProductTypes } from '../../reducers/slices/productTypes.slice';
import { setCountries } from "../../reducers/slices/countries.slice";
import { getErrorMessage } from "../../utils/error";
import LoadingSpinner from '../../components/loadingSpinner.component';

import alertIcon from '../../assets/images/exclamation-lg.svg';
import previewIcon from '../../assets/images/eye.svg';
import backToMenuIcon from '../../assets/images/skip-backward.svg';
import imagesIcon from '../../assets/images/images.svg';
import urlIcon from '../../assets/images/globe.svg';
import editIcon from '../../assets/images/edit.svg';
import paymentsIcon from '../../assets/images/currency-euro.svg';
import qrCodeIcon from '../../assets/images/qr-code.svg';
import pushNotificationIcon from '../../assets/images/message-circle.svg';

import InvitationTemplate from './templates/event/invitation/standardTemplate.view';
import StandardPageTemplate from './templates/page/standardPageTemplate.view'; 

const EditorOptions = styled.div`
    position:fixed;
    top:30%;
    left:0px;
    border-radius:0.5rem;
    z-index:10001;
`

const EditOption = styled.div`
    border:1px solid #aaa;
    border-radius:0.25rem;
    cursor:pointer;
    background-color: rgb(33, 37, 41);
    color: #eaeaea;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: center;
`

const EditOptionContent = styled.div`
    display:${props => props.isVisible ? 'inline-block' : 'none'};
    position: fixed;
    left: 75px;
    top: 5%;
    height: 90%;
    width: 85%;
    background-color: rgb(33,37,41);
    color: #eaeaea;
    border-radius: 0.5rem;
    z-index:10000;
    border:1px solid #aaa;
    max-height:90%;
    overflow-y:auto;
    overflow-x:hidden;
`

const OptionText = styled.div`
    display:none;

    ${EditOption}:hover & {
        display:block;
    }
`

const EditorOptionsDiv = styled.div`
    position:fixed;
    left:1rem;
    top:1rem;
    background-color:#fefefe;
    border-right:1px solid #eee;
    padding:0.5rem;
    border-radius:0.5rem;
    /*width:25%;*/
    min-width:200px;
    z-index:1001;
    box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px 0px, rgb(0 0 0 / 19%) 0px 6px 20px 0px;
    width:fit-content;
    max-height:80vh;
    overflow-y:auto;
`

const Bold = styled.span`
    font-weight:bold;
`

const MarginTop = styled.div`
    margin-top:${props => props.marginTop ? props.marginTop : 'inherit'}
`

const ModalCloseButton = styled.div`
    margin:0.5rem;
    position: absolute;
    right: 5px;
    top: 5px;
` 

const Modal = styled.div`
    position: fixed;
    top:0px;
    left:0px;
    width: 100%;
    height: 100%;
    background-color:#0e0e0e;
    opacity:0.6;
    z-index:9999;
` 

const LoadingContainer = styled.div`
    width:100vw;
    height:100vh;
    background-color:#fff;
`

function LandingPageEditorView(props) {

    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);
    const csrfToken = useSelector((state) => state.csrfToken.value);

    const [isOptionContentVisible, setIsOptionContentVisible] = useState(false);
    const [activeOptionType, setActiveOptionType] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        dispatch(setCurrentLandingPage(props.landingPage));
        dispatch(setCurrentLandingPageTemplate(props.landingPageTemplate));
        Promise.all([
            getFonts(),
            getRowTypes(),
            getUser(),
            getProductTypes(),
            getCountries()
        ])
        .then((values) => {
            setIsLoading(false);
        });
        /*
        getFonts();
        getRowTypes();
        getUser(); 
        getProductTypes();
        getCountries();
        */
    },[]);

    function setError(error) {
        dispatch(setMessage({
            message: getErrorMessage(error),
            messageType: MessageTypes.MESSAGE_TYPE_ERROR
        }));
    }

    function getFonts() {
        return Data.transfer(window.baseUrl + '/builder/user/landingPage/fonts/get','POST',[],null,null,csrfToken)
        .then((data) => {
            if(data.success && data.fonts) {
                dispatch(setFonts(data.fonts));
            }
        })
        .catch((error) => {
            setError(error);
        });
    }

    function getUser() {
        return Data.transfer(window.baseUrl + '/builder/user/get','POST',[],null,null,csrfToken)
        .then((data) => {
            if(data.user) {
                dispatch(setUser(data.user));
            }
        })
        .catch((error) => {
            setError(error);
        });
    }

    function getProductTypes() {
        return Data.transfer(window.baseUrl + '/builder/productTypes/get','POST',[],null,null,csrfToken)
        .then((data) => {
            if(data.success && data.productTypes) {
                dispatch(setProductTypes(data.productTypes));
            }
        })
        .catch((error) => {
            setError(error);
        });
    }

    function getRowTypes() {
        return Data.transfer(window.baseUrl + '/builder/user/landingPage/rows/type/get','POST',[],null,null,csrfToken)
        .then((data) => {
            if(data.success && data.rowTypes) {
                dispatch(setRowTypes(data.rowTypes));
            }
        })
        .catch((error) => {
            setError(error);
        });
    }

    function getCountries() {
        return Data.transfer(window.baseUrl + '/builder/countries/get','POST',[],null,null,csrfToken)
        .then((data) => {
            if(data.success && data.countries) {
                let pleaseSelect = {
                    id: 0,
                    name: Language[language].shop.selectCountry
                };
                let countries = [pleaseSelect, ...data.countries];
                dispatch(setCountries(countries));
            }
        })
        .catch((error) => {
            setError(error);
        });
    
    }

    function onOpenPreviewClick(event) {
        let urlPart = (props.landingPageTemplate?.page_type_id === PageTypes.PAGE_TYPE_EVENT) ? '/event/' : '/page/';
        window.open(urlPart + props.landingPage?.name?.replace(/[^a-zA-Z0-9-_]/g, '') + '/' + props.previewGuest?.pivot?.identifier_token, '_blank');
    }

    function onLandingPageUpdate(updatedLandingPage) {
        dispatch(setCurrentLandingPage(updatedLandingPage));
    }

    function onBackToMenuClick() {
        window.open('/builder/', '_self');
    }

    function handleOptionClick(optionType) {
        setIsOptionContentVisible(true);
        setActiveOptionType(optionType);
    }

    function closeModal() {
        setIsOptionContentVisible(false);
    }

    /*
    let View;
    switch(this.props.landingPageTemplate.view) {
        case './event/invitation/standard.view':
            View = require('./templates/event/invitation/standardTemplate.view').default;
            break;
        case './page/standard.view':
            View = require('./templates/page/standardPageTemplate.view').default; 
            break;
        case './event/wedding/invitation.view.js':
            View = require('./templates/event/invitation/standardTemplate.view').default;
        default:
            break;
    } 
    */
    return (
        <>
            {
                isLoading &&
                <LoadingContainer className="container">
                    <div className="row">
                        <div className="col text-center">
                            <LoadingSpinner 
                                key="loadingSpinnerEvents" 
                                //color={'#fff'}
                            />
                        </div>
                    </div>
                </LoadingContainer>
            }
            {
                !isLoading &&
                <>
                    {
                        props.landingPageTemplate.view === './event/invitation/standard.view' &&
                        <InvitationTemplate 
                            key={"template"}
                            onLandingPageUpdate={onLandingPageUpdate}
                        />
                    }
                    {
                        props.landingPageTemplate.view === './page/standard.view' &&
                        <StandardPageTemplate 
                            key={"template"}
                            onLandingPageUpdate={onLandingPageUpdate}
                        />
                    }
                    {
                        props.landingPageTemplate.view === './event/wedding/invitation.view.js' &&
                        <InvitationTemplate 
                            key={"template"}
                            onLandingPageUpdate={onLandingPageUpdate}
                        />
                    }
                    {/*
                    <View 
                        key={"template"}
                        onLandingPageUpdate={onLandingPageUpdate}
                    />
                    */
                    }
                    <EditorOptions>
                        <div className="container">
                            <div className="row">
                                <EditOption 
                                    className=""
                                    onClick={onBackToMenuClick}
                                >
                                    <Icon 
                                        image={backToMenuIcon} 
                                        hoverBackgroundColor={'darkgray'}
                                        minSize={"30px"}
                                        maskColor={'#eaeaea'}
                                        cursor={'pointer'}
                                    />
                                    <OptionText>
                                        {
                                            Language[language].editor.backToMenu
                                        }
                                    </OptionText>
                                </EditOption>
                            </div>
                            {/*
                            <div className="row">
                                <EditOption 
                                    className=""
                                    onClick={this.handleOptionClick.bind(this)}
                                >
                                    <Icon 
                                        image={editIcon} 
                                        hoverBackgroundColor={'darkgray'}
                                        minSize={"30px"}
                                        maskColor={'#eaeaea'}
                                        cursor={'pointer'}
                                    />
                                </EditOption>
                            </div>
                            */}
                            <div className="row">
                                <EditOption
                                    className=""
                                    onClick={onOpenPreviewClick}
                                >
                                    <Icon 
                                        image={previewIcon} 
                                        hoverBackgroundColor={'darkgray'}
                                        minSize={"30px"}
                                        maskColor={'#eaeaea'}
                                        cursor={'pointer'}
                                    />
                                    <OptionText>
                                        {
                                            Language[language].editor.preview
                                        }
                                    </OptionText>
                                </EditOption>
                            </div>
                            <div className="row">
                                <EditOption 
                                    className=""
                                    onClick={(event) => handleOptionClick('pushNotifications')}
                                >
                                    <Icon 
                                        image={pushNotificationIcon} 
                                        hoverBackgroundColor={'darkgray'}
                                        minSize={"30px"}
                                        maskColor={'#eaeaea'}
                                        cursor={'pointer'}
                                    />
                                    <OptionText>
                                        {
                                            Language[language].editor.pushNotifications
                                        }
                                    </OptionText>
                                </EditOption>
                            </div>
                            <div className="row">
                                <EditOption 
                                    className=""
                                    onClick={(event) => handleOptionClick('manageImages')}
                                >
                                    <Icon 
                                        image={imagesIcon} 
                                        hoverBackgroundColor={'darkgray'}
                                        minSize={"30px"}
                                        maskColor={'#eaeaea'}
                                        cursor={'pointer'}
                                    />
                                    <OptionText>
                                        {
                                            Language[language].images.manageImages
                                        }
                                    </OptionText>
                                </EditOption>
                            </div>
                            <div className="row">
                                <EditOption
                                    className=""
                                    onClick={(event) => handleOptionClick('shop')}
                                >
                                    <Icon 
                                        image={paymentsIcon} 
                                        hoverBackgroundColor={'darkgray'}
                                        minSize={"30px"}
                                        maskColor={'#eaeaea'}
                                        cursor={'pointer'}
                                    />
                                    <OptionText>
                                        {
                                            Language[language].editor.shop
                                        }
                                    </OptionText>
                                </EditOption>
                            </div>
                            <div className="row">
                                <EditOption
                                    className=""
                                    onClick={(event) => handleOptionClick('manageSubdomain')}
                                >
                                    <Icon 
                                        image={urlIcon} 
                                        hoverBackgroundColor={'darkgray'}
                                        minSize={"30px"}
                                        maskColor={'#eaeaea'}
                                        cursor={'pointer'}
                                    />
                                    <OptionText>
                                        {
                                            Language[language].subdomain.manageSubdomain
                                        }
                                    </OptionText>
                                </EditOption>
                            </div>
                            <div className="row">
                                <EditOption
                                    className=""
                                    onClick={(event) => handleOptionClick('qrCode')}
                                >
                                    <Icon 
                                        image={qrCodeIcon} 
                                        hoverBackgroundColor={'darkgray'}
                                        minSize={"30px"}
                                        maskColor={'#eaeaea'}
                                        cursor={'pointer'}
                                    />
                                    <OptionText>
                                        {
                                            Language[language].editor.qrCode
                                        }
                                    </OptionText>
                                </EditOption>
                            </div>
                        </div>
                    </EditorOptions>
                    {
                        isOptionContentVisible &&
                        <>
                            <EditOptionContent
                                isVisible={isOptionContentVisible}
                            >
                                <div className="container">
                                    <div className="row">
                                        <div className="col text-end">
                                            <ModalCloseButton type="button" className="btn-close btn-close-white" aria-label="Close" onClick={closeModal} />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    {
                                        activeOptionType === 'manageImages' &&
                                        <ManageImages
                                            key={"manageImages"}
                                        />
                                    }
                                    {
                                        activeOptionType === 'manageSubdomain' &&
                                        <ManageSubdomain
                                            key={"manageSubdomain"}
                                        />
                                    }
                                    {
                                        activeOptionType === 'qrCode' &&
                                        <QrCode
                                            key={"qrCode"}
                                        />
                                    }
                                    {
                                        activeOptionType === 'shop' &&
                                        <Shop
                                            key={"shop"}
                                        />
                                    }
                                    {
                                        activeOptionType === 'pushNotifications' &&
                                        <SendPushNotificationMessage
                                            key={"pushNotifications"}
                                        />
                                    }
                                </div>
                            </EditOptionContent>
                        </>
                    }
                    <UserInteractionFeedbackMessage
                        key={"managementInterfaceMessageComponent"}
                    />
                </>
            }
        </>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"landingPageEditor.view.js"}
            fallbackUI={<FallbackUI language={language} component={"landingPageEditor"} />}
        >
            <LandingPageEditorView
                key={"landingPageEditorViewComponent"}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;