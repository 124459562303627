/*
<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-96BD7E0731"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-96BD7E0731');
</script>
*/

export function activate() {
    var u = "https://www.googletagmanager.com/gtag/js?id=G-96BD7E0731";
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; 
    g.src=u; 
    s.parentNode.insertBefore(g,s);
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-96BD7E0731');
}

export function deactivate() {
    return new Promise((resolve, reject) => {
        try {
            if(document.querySelector("script[src='https://www.googletagmanager.com/gtag/js?id=G-96BD7E0731']") !== null) {
                document.querySelector("script[src='https://www.googletagmanager.com/gtag/js?id=G-96BD7E0731']").remove();
                resolve(true);
            }
            else {
                resolve(false);
            }
        }
        catch(exception) {
            reject(exception);
        }
    });
}