import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import Button from '../button.component';
import TextInput from '../textInput.component';
import TextAreaWithEditingOptions from '../textAreaWithEditingOptions.component';
import SelectboxFonts from '../selectBoxFonts.component';
import Language from '../../constants/language';
import AppText from '../app/appText.component';
import ErrorBoundary from '../error/error.component';
import FallbackUI from '../error/standardFallback.component';
import * as MessageTypes from '../../constants/messagetypes';
import * as Data from '../../backend/data';
import * as TextPositions from '../../constants/textPositions';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentLandingPage } from '../../reducers/slices/currentLandingPage.slice';
import { setMessage } from '../../reducers/slices/message.slice';
import { usePrevious } from '../../utils/utils';
import { isNullOrUndefined } from '../../utils/utils';
import { getErrorMessage } from '../../utils/error';

import plusImage from '../../assets/images/plus-circle.svg';
import saveIcon from '../../assets/images/check2-square.svg';
import cancelIcon from '../../assets/images/x-square.svg';


const ShowTextFieldDiv = styled.div`
`

const Text = styled.div`
`

const EditTextFieldDiv = styled.div`
    border:1px solid #eaeaea;
    background-color:#fff;
    padding:1rem;
    border-radius:0.25rem;
    /*font-family: 'Lato', sans-serif !important;*/
    font-family:'latolight' !important;
    font-size: 20px;
    margin:2rem;
    max-width: 90vw;
    overflow-x: hidden;
    z-index:10;
    position:relative;
`

const EditTextFieldBackground = styled.div`
    position:absolute;
    background-color:#fff;
    opacity:0.5;
    width:100%;
    height:100%;
    border-radius:0.5rem;
` 

const RowMargin = styled.div`
    margin:1rem;
`

const RelDiv = styled.div`
    position:relative;

    &:hover {
        border:1px dashed #000;
        border-radius:0.25rem;
    }
`

const Row = styled.div`
`

const Col = styled.div`
`

const AddNewImage = styled.div`
    min-width: 40px;
    min-height: 40px;
    background-image: url(${props => props.image ? props.image : ''});
    background-size: contain;
    background-repeat:  no-repeat;
    background-position: center center;
    margin-top:1rem;
    display:none;
    position:absolute;
    top:calc(100% - 35px);
    left:50%;
    margin-left:-20px;
    background-color:#fff;
    border-radius:50%;
    cursor:pointer;

    ${RelDiv}:hover & {
        display:block;
    }
`

const NewItemRow = styled.div`
    margin:1rem;
`

const NewItemCol = styled.div`
    max-width:100px;
    height: 80px;
    background-color:white;
    border: 1px #aaa solid;
    cursor:pointer;
    border-radius:0.2rem;

    &:hover {
        border: 1px #aaa dashed;
        background-color:#f0d3d3;
    }
`

function EditText(props) {

    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);
    const csrfToken = useSelector((state) => state.csrfToken.value);
    const currentLandingPage = useSelector((state) => state.currentLandingPage.value);
    const fonts = useSelector((state) => state.fonts.value);
    const currentLandingPageTemplate = useSelector((state) => state.currentLandingPageTemplate.value);
    const rowTypes = useSelector((state) => state.rowTypes.value);

    const [appText, setAppText] = useState(null);
    const [isTextEditActive, setIsTextEditActive] = useState(false);
    const [color, setColor] = useState('#000');
    const [isAddNewItemOptionActive, setIsAddNewItemOptionActive] = useState(false);

    const previousCurrentLandingPage = usePrevious(currentLandingPage);
    const previousFonts = usePrevious(fonts);
    const previousAppTextTemplate = usePrevious(appTextTemplate);

    /*
    constructor(props) {
        super(props);
        this.state = {
            appText: null,
            isTextEditActive: false,
            color:'#000',
            isAddNewItemOptionActive: false,
        }

        this.setAppTextState = this.setAppTextState.bind(this);
        this.onSaveButtonClicked = this.onSaveButtonClicked.bind(this);
        this.onSaveTextSuccess = this.onSaveTextSuccess.bind(this);
        this.onRequestDataFailure = this.onRequestDataFailure.bind(this);
        this.onTextEdit = this.onTextEdit.bind(this);
        this.onAddNewItemClick = this.onAddNewItemClick.bind(this);
        this.onAddNewText = this.onAddNewText.bind(this);
    }

    componentDidMount() {
    }
    */

    useEffect(() => {
        if(currentLandingPage !== previousCurrentLandingPage || props.appTextTemplate !== previousAppTextTemplate || fonts !== previousFonts) {
            setAppTextState();
        }
    },[]);

    /*
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(currentLandingPage !== prevProps.currentLandingPage || props.appTextTemplate !== prevProps.appTextTemplate || fonts !== prevProps.fonts) {
            setAppTextState();
        }
    }
    */

    function setAppTextState() {
        let appText = currentLandingPage?.app_texts?.find((appText) => appText.landing_page_template_text_id === props.appTextTemplate.id);
        if(appText === undefined) {
            appText = createAppTextTemp();
        }
        setAppText(appText);
        /*
        this.setState({
            appText: appText
        })
        */
    }

    function createAppTextTemp() {
        return {
            id: -1,
            text: [{type: 'paragraph',children: [{ text: props.appTextTemplate?.placeholder }],},],
            type: props.appTextTemplate?.key,
            landing_page_id: currentLandingPage?.id,
            landing_page_template_text_id: props.appTextTemplate?.id,
            font_size: props.appTextTemplate?.pivot.start_font_size,
            font_id: props.appTextTemplate?.pivot.start_font_id,
            color: props.appTextTemplate?.pivot.start_color,
            justify_content: props.appTextTemplate?.pivot.start_justify_content,
            font: fonts?.find((font) => font.id === props.appTextTemplate?.pivot.start_font_id),
            plain_text: props.appTextTemplate?.placeholder,
        }
    }

    function onHandleTextEditClick(event) {
        setIsTextEditActive(true);
        /*
        this.setState({
            isTextEditActive: true
        });
        */
    }

    function onCloseClick(event) {
        setIsTextEditActive(false);
        /*
        this.setState({
            isTextEditActive: false
        });
        */
    }

    function onSaveButtonClicked(event) {
        let formData = [
            {
                name: 'csrfToken',
                value: csrfToken
            },
            {
                name: 'landingPageId',
                value: currentLandingPage.id
            },
            {
                name: 'textType',
                value: props.textType
            },
            {
                name: 'textValue',
                value: (appText.text === "") ? null : JSON.stringify(appText.text)
            },
            {
                name: 'plainText',
                value: appText.plain_text
            },
            {
                name: 'fontSize',
                value: appText.font_size
            },
            {
                name: 'fontId',
                value: appText.font_id
            },
            {
                name: 'color',
                value: appText.color
            },
            {
                name: 'justifyContent',
                value: appText.justify_content
            },
        ];
        Data.transfer(window.baseUrl + '/builder/user/landingPage/texts/save','POST',formData,null,null,csrfToken)
        .then((data) => {
            if(data.success && data.landingPage) {
                onCloseClick(event);
                dispatch(setCurrentLandingPage(data.landingPage));
                dispatch(setMessage({
                    message: Language[language].editor.savedTextSuccessfully,
                    messageType: MessageTypes.MESSAGE_TYPE_SUCCESS
                }));
                /*
                this.props.updateCurrentLandingPage(data.landingPage);
                this.props.updateMessage({
                    message: Language[this.props.language].editor.savedTextSuccessfully,
                    messageType: MessageTypes.MESSAGE_TYPE_SUCCESS
                });
                */
            }
        })
        .catch((error) => {
            dispatch(setMessage({
                message: getErrorMessage(error),
                messageType: MessageTypes.MESSAGE_TYPE_ERROR
            }));
        });
    }

    function onCancelButtonClicked(event) {
        onCloseClick(event);
    }

    function onTextEdit(text = null, plainText = null, color = null,textPosition = null, fontSize = null, fontFamily = null) {
        return new Promise((resolve,reject) => {
            let appTextClone = _.cloneDeep(appText);
            if(appTextClone !== null) {
                if(text !== null) {
                    appTextClone.text = text;
                }
                if(plainText !== null) {
                    appTextClone.plain_text = plainText;
                }
                if(color !== null) {
                    appTextClone.color = color;
                }
                if(textPosition !== null) {
                    appTextClone.justify_content = textPosition;
                }
                if(fontSize !== null) {
                    appTextClone.font_size = fontSize;
                }
                if(fontFamily !== null) {
                    appTextClone.font_id = fontFamily;
                    appTextClone.font = fonts.find((font) => font.id === parseInt(fontFamily));
                }
                setAppText(appTextClone);
                resolve(appTextClone);
                /*
                this.setState({
                    appText: appTextClone,
                },() => {
                    resolve(this.state.appText);
                });
                */
            }
            else {
                reject("editText.component.js - property \"appText\" not available")
            }
        });
    }

    function onTextEditChange(newValue, plainText) {
        onTextEdit(newValue, plainText, null, null, null, null);
    }

    function onColorPickerBlur(event) {
        this.onTextEdit(null, null, event.target.value, null, null, null);
        setColor(event.target.value);
        /*
        this.setState({
            color: event.target.value
        })
        */
    }

    function onTextPositionChange(event) {
        if(TextPositions.getTextPositions(language).find((position) => position.value === event.target.value) !== undefined) {
            onTextEdit(null, null, null, event.target.value, null, null);
        }
        else {
            throw("editText.component.js - value \"textPosition\" is not valid");
        }
    }

    function onFontSizeBlur(event) {
        if(event.target.value >= 0.5 && event.target.value <= 6) {
            onTextEdit(null, null, null, null, event.target.value, null);
        }
    }

    function onFontSizeChanged(event) {
        if(event.target.value >= 0.5 && event.target.value <= 6) {
            onTextEdit(null, null, null, null, event.target.value, null);
        }
    }

    function onTextFontFamilyChange(event) {
        if(fonts.find((font) => font.id === parseInt(event.target.value)) !== undefined) {
            onTextEdit(null, null, null, null, null, event.target.value);
        }
        else {
            throw("editText.component.js - value \"font family\" is not valid");
        }
    }

    function onTextAlignmentChange(newTextAlignmentValue) {
        if(TextPositions.getTextPositions(language).find((position) => position.value === newTextAlignmentValue) !== undefined) {
            onTextEdit(null, null, null, newTextAlignmentValue, null, null);
        }
        else {
            throw("editText.component.js - value \"textPosition\" is not valid");
        }
    }

    function onAddNewItemClick() {
        setIsAddNewItemOptionActive(!isAddNewItemOptionActive);
        /*
        this.setState({
            isAddNewItemOptionActive: !this.state.isAddNewItemOptionActive,
        });
        */
    }

    function onAddNewText(rowTypeId) {
        
    }


    return (
        <RelDiv>
            <Row className="row">
                <Col className="col-12">            
                {
                    !isTextEditActive &&
                    <ShowTextFieldDiv className="container-fluid" onClick={onHandleTextEditClick} id={props.textType} title={Language[language].editor.editText}>
                        {
                            <Text>
                                { 
                                    <AppText 
                                        key={"appText" + props.textType}
                                        appText={appText}
                                        language={language}
                                        isEditMode={true}
                                        emptyEntryRowHeight={props.emptyEntryRowHeight}
                                        margin={props.margin}
                                    />
                                } 
                            </Text>
                        }
                    </ShowTextFieldDiv>
                }
                {
                    isTextEditActive &&
                    <>
                        <EditTextFieldBackground />
                        <EditTextFieldDiv>
                            <div className="row">
                                <div className="col text-end">
                                    <button 
                                        type="button" 
                                        className="btn-close" 
                                        aria-label="Close" 
                                        onClick={onCloseClick}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-start">
                                    { Language[language].editor.preview }:
                                </div>
                            </div>
                            <RowMargin className="row">
                                <div className="col text-start">
                                    <AppText 
                                        key={"appText" + props.textType}
                                        appText={appText}
                                        language={language}
                                        isEditMode={false}
                                    />
                                </div>
                            </RowMargin>
                            <RowMargin className="row">
                                <div className="col text-start alert alert-info">
                                    {
                                        props.appTextTemplate.help_text
                                    }
                                </div>
                            </RowMargin>
                            <RowMargin className="row">
                                <div className="col">
                                    {
                                        (currentLandingPageTemplate?.landing_page_template_texts?.find((text) => text.key === props.textType)?.text_type === 'text') &&
                                        <TextAreaWithEditingOptions
                                            id={"textarea" + props.textType} 
                                            value={appText.text}
                                            language={language}
                                            onChange={onTextEditChange}
                                            onTextAlignmentChange={onTextAlignmentChange}
                                        />
                                    }
                                    {
                                        currentLandingPageTemplate?.landing_page_template_texts?.find((text) => text.key === props.textType)?.text_type === 'textarea' && 
                                        <TextAreaWithEditingOptions
                                            id={"textarea" + props.textType} 
                                            value={appText.text}
                                            language={language}
                                            onChange={onTextEditChange}
                                            onTextAlignmentChange={onTextAlignmentChange}
                                        />
                                    }
                                </div>                   
                            </RowMargin>
                            <RowMargin className="row">
                                <div className="col">  
                                    <SelectboxFonts
                                        key={"editTextFontFamiliy" + props.textType}
                                        name={"editTextFontFamiliy" + props.textType}
                                        label={Language[language].editor.fontFamily}
                                        value={appText?.font_id}
                                        valueField={"id"}
                                        displayField={"name"}
                                        options={fonts}
                                        onChange={onTextFontFamilyChange}
                                        onBlur={onTextFontFamilyChange}
                                    />
                                </div>
                            </RowMargin>
                            {/*
                            <ColorPickerRow className="row">
                                <div className="col-6">
                                    <TextInput
                                        key={"editTextColorPicker" + this.props.textType}
                                        type={"color"}
                                        label={""}
                                        name={"editTextColorPicker" + this.props.textType}
                                        placeholder={undefined}
                                        onBlur={this.onColorPickerBlur.bind(this)}
                                        submitOnEnter={false}
                                        required={true}
                                        disabled={false}
                                        value={(this.state.appText?.color) ? this.state.appText.color : '#000'}
                                        autoFocus={false}
                                        additionalStyles={{display:'inline-block',width:'50px',padding:'initial'}}
                                    />
                                    <TextColor color={(this.state.appText?.color) ? this.state.appText.color : '#000'}>
                                        { Language[this.props.language].editor.textColor }
                                    </TextColor>
                                </div>
                            </ColorPickerRow>
                            */}
                            <RowMargin className="row">
                                <div className="col">
                                    <TextInput
                                        key={"textEditFontSize" + props.textType}
                                        type={"number"}
                                        label={""}
                                        min={0.5}
                                        max={6}
                                        step={0.25}
                                        name={props.textType}
                                        placeholder={Language[language].editor.fontSize}
                                        onBlur={onFontSizeBlur}
                                        onChange={onFontSizeChanged}
                                        submitOnEnter={false}
                                        required={true}
                                        disabled={false}
                                        value={appText?.font_size}
                                        autoFocus={true}
                                    />
                                </div>
                            </RowMargin>
                            {/*
                            <RowMargin className="row">
                                <div className="col">
                                    
                                    <Selectbox
                                        key={"editTextJustifyContent" + this.props.textType}
                                        name={"editTextJustifyContent" + this.props.textType}
                                        label={Language[this.props.language].editor.textPosition}
                                        value={this.state.appText?.justify_content}
                                        valueField={"value"}
                                        displayField={"name"}
                                        options={TextPositions.getTextPositions(this.props.language)}
                                        onChange={this.onTextPositionChange.bind(this)}
                                        onBlur={this.onTextPositionChange.bind(this)}
                                    />
                                    
                                </div>
                            </RowMargin>
                            */}
                            <RowMargin className="row">
                                <div className="col">
                                    <Button
                                        key={"saveButton" + props.textType}
                                        buttonText={Language[language].general.save}
                                        additionalClasses={""}
                                        onClicked={onSaveButtonClicked}
                                        icon={saveIcon} 
                                        iconSize={"20px"}
                                        iconColor={"#000"}
                                    />
                                </div>
                                <div className="col">
                                    <Button
                                        key={"cancelButton" + props.textType}
                                        buttonText={Language[language].general.cancel}
                                        additionalClasses={""}
                                        onClicked={onCancelButtonClicked}
                                        icon={cancelIcon} 
                                        iconSize={"20px"}
                                        iconColor={"#000"}
                                    />
                                </div>
                            </RowMargin>
                        </EditTextFieldDiv>
                    </>
                }
                <AddNewImage image={plusImage} onClick={onAddNewItemClick}></AddNewImage>
                {
                    isAddNewItemOptionActive && !isTextEditActive &&
                    <NewItemRow className="row d-flex justify-content-center">
                        {
                            !isNullOrUndefined(rowTypes) && rowTypes.map((rowType) => {
                                let image = isNullOrUndefined(rowType.row_type_image) ? null : require(`../../assets/images/${rowType.row_type_image}`).default;
                                return (
                                    <NewItemCol className="col text-center" onClick={(e) => onAddNewText(rowType.id)}>
                                        {
                                            !isNullOrUndefined(image) &&
                                            <>
                                                <img src={image} alt={rowType.option_text} />
                                                <br />
                                            </>
                                        }
                                        <small>{rowType.option_text}</small>
                                    </NewItemCol>
                                )
                            })
                        }
                    </NewItemRow>
                }
            </Col>
        </Row>
    </RelDiv>
    );
}
/*
function mapStateToProps({ user, navigation, language, isSignedIn, csrfToken, currentEvent, currentLandingPage, currentLandingPageTemplate, message, fonts, rowTypes }) {
    return {
        user,
        navigation,
        language,
        isSignedIn,
        csrfToken,
        currentEvent,
        currentLandingPage,
        currentLandingPageTemplate,
        message,
        fonts,
        rowTypes
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateUser: user => dispatch(updateUser(user)),
        updateNavigation: navigation => dispatch(updateNavigation(navigation)),
        updateLanguage: language => dispatch(updateLanguage(language)),
        updateIsSignedIn: isSignedIn => dispatch(updateIsSignedIn(isSignedIn)),
        updateCurrentLandingPage: currentLandingPage => dispatch(updateCurrentLandingPage(currentLandingPage)),
        updateMessage: message => dispatch(updateMessage(message)),
    };
}
*/
const ComponentWithErrorBoundary = (props) =>  {
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"editText.component.js"}
            fallbackUI={<FallbackUI language={props.language} component={"editText"} />}
        >
            <EditText
                key={"editTextComponent" + props.textType}
                {...props}
            />
       </ErrorBoundary>
   )
}
/*
const Form = connect(
    mapStateToProps,
    mapDispatchToProps
)(ComponentWithErrorBoundary);
*/
export default ComponentWithErrorBoundary;