import React, { useState, useEffect, useReducer, useRef } from 'react';
import ErrorBoundary from '../../../error/error.component';
import FallbackUI from '../../../error/standardFallback.component';
import Button from '../../../button.component';
import Language from '../../../../constants/language';
import * as Data from '../../../../backend/data';
import Introduction from './businessSetupWizard/introduction.component';
import PrivateOrCompany from './businessSetupWizard/legalStatus.component';
import Address from './businessSetupWizard/address.component';
import SmallBusinessRegulation from './businessSetupWizard/smallBusinessRegulation.component';
import VatNo from './businessSetupWizard/vatNo.component';
import PayPalEmailAddress from './businessSetupWizard/paypalEmailAddress.component';
import Summary from './businessSetupWizard/summary.component';
import { LEGAL_STATUS_TYPE_PRIVATE, LEGAL_STATUS_TYPE_COMPANY } from '../../../../constants/legalStatusTypes';
import { isEmailAddressValid } from "../../../../utils/email";
import SuccessIcon from '../../../successIcon.component';
import { isNullOrUndefined, usePrevious } from '../../../../utils/utils';
import { useSelector, useDispatch } from 'react-redux';
import { setBusinessData } from '../../../../reducers/slices/user.slice';
import { setMessage } from '../../../../reducers/slices/message.slice';
import { getErrorMessage } from '../../../../utils/error';
import * as MessageTypes from '../../../../constants/messagetypes';
import ProgressBar from '../../../progressBar.component';

import backIcon from '../../../../assets/images/skip-backward.svg';
import nextIcon from '../../../../assets/images/skip-forward.svg';
import saveIcon from '../../../../assets/images/check2-square.svg';

const initialState = {step: 1};
const lastStep = 7;

/*
function usePrevious(value) {
    const ref = useRef();
    
    useEffect(() => {
        ref.current = value; //assign the value of ref to the argument
    },[value]); //this code will run when the value of 'value' changes
    
    return ref.current; //in the end, return the current ref value.
}
*/

function reducer(state, action) {
  switch (action.type) {
    case '+':
      return {step: state.step + 1};
    case '-':
      return {step: state.step - 1};
    case 'set':
        return {step: action.step};
    default:
      throw new Error();
  }
}

function BusinessSetupWizard(props) {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);
    const csrfToken = useSelector((state) => state.csrfToken.value);
    const user = useSelector((state) => state.user.value);
    const countries = useSelector((state) => state.countries.value);
    
    //const [countries, setCountries] = useState([]);
    const [minStep, setMinStep] = useState(1);
    const [state, dispatchState] = useReducer(reducer, initialState);
    const [isBackButtonDisabled, setIsBackButtonDisabled] = useState(false);
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
    const [isLiableToPaySalesTax, setIsLiableToPaySalesTax] = useState(false);
    const [legalStatus, setLegalStatus] = useState(null);
    const [addressData, setAddressData] = useState(null);
    const [vatNo, setVatNo] = useState(null);
    const [paypalEmailAddress, setPayPalEmailAddress] = useState(null);
    const [accountData, setAccountData] = useState(null);
    const [savedSuccessfully, setSavedSuccessfully] = useState(false);

    const previousStep = usePrevious(state.step);

    function onNextClick() {
        increaseDecreaseStep('+');
    }

    function onBackClick() {
        increaseDecreaseStep('-');
    }

    function onSaveClick() {
        let formData = [
            {
                name: 'accountData',
                value: JSON.stringify(accountData)
            },
            {
                name: 'csrfToken',
                value: props.csrfToken
            },
        ];
        Data.transfer(window.baseUrl + '/builder/user/businessData','POST',formData,null,null,csrfToken)
        .then((data) => {
            if(data.success) {
                setSavedSuccessfully(true);
                dispatch(setBusinessData(data.businessData));
                //props.updateBusinessData(data.businessData);
                props.afterSavingBusinessData(data.businessData);
            }
        })
        .catch((error) => {
            dispatch(setMessage({
                message: getErrorMessage(error),
                messageType: MessageTypes.MESSAGE_TYPE_ERROR
            }));
        });
    }

    function increaseDecreaseStep(direction, withDelay = false) {
        if(direction === '+') {
            let timeout = 0;
            if(withDelay) {
                timeout = 250;
            }
            window.setTimeout(() => {
                dispatchState({type: '+'});
            },timeout);
        }
        else if(direction === '-') {
            dispatchState({type: '-'})
        }
    }

    function checkIsNextButtonDisabled() {
        switch(state.step) {
            case 1:
                return false;
            case 2:
                return (legalStatus === null);
            case 3:
                return false;
            case 4:
                return (isLiableToPaySalesTax === null);
            case 5:
                return (vatNo === null);
            case 6:
                return (isEmailAddressValid(paypalEmailAddress) === false);
        }
    }

    function showComponent() {
        switch(state.step) {
            case 1:
                return (
                    <Introduction 
                        key={"businessSetupIntroduction"}
                    />
                )
            case 2:
                return (
                    <PrivateOrCompany 
                        key={"businessSetupPrivateOrCompany"}
                        legalStatus={legalStatus}
                        onReturnData={(legalStatus) => {
                            setLegalStatus(legalStatus);
                            increaseDecreaseStep('+');
                        }}
                    />
                )
            case 3:
                return (
                    <Address 
                        key={"businessSetupAddress"} 
                        legalStatus={legalStatus}
                        countries={countries}
                        addressData={addressData}
                        onReturnData={(addressData, isDataComplete) => {
                            setIsNextButtonDisabled(!isDataComplete);
                            setAddressData(addressData);
                        }}
                    />
                )
            case 4:
                return (
                    <SmallBusinessRegulation 
                        key={"businessSetupSmallBusinessRegulation"} 
                        isLiableToPaySalesTax={isLiableToPaySalesTax}
                        onReturnData={(isLiableToPaySalesTax) => {
                            setIsLiableToPaySalesTax(isLiableToPaySalesTax);
                            increaseDecreaseStep('+', true);
                        }}
                    />
                )
            case 5:
                return (
                    <VatNo 
                        key={"businessSetupVatNo"} 
                        vatNo={vatNo}
                        onReturnData={(vatNo,moveToNextStep) => {
                            setVatNo(vatNo);
                            if(moveToNextStep) {
                                increaseDecreaseStep('+', true);
                            }
                        }}
                    />
                )
            case 6:
                return (
                    <PayPalEmailAddress 
                        key={"businessSetupPayPalEmailAddress"} 
                        paypalEmailAddress={paypalEmailAddress}
                        onReturnData={(paypalEmailAddress, isEmailAddressValid, moveToNextStep) => {
                            setPayPalEmailAddress(paypalEmailAddress);
                            if(isEmailAddressValid && moveToNextStep) {
                                increaseDecreaseStep('+');
                            }
                        }}
                    />
                )
            case 7:
                return (
                    <Summary
                        key={"businessSetupSummary"} 
                        accountData={accountData}
                    />
                )
        }
    }

    function checkNextStep() {
        // forwards: 5 - 4 = 1
        // backwards: 4 - 5 = -1
        const direction = (state.step - previousStep) > 0 ? '+' : '-';
        switch(state.step) {
            case 4:
                if(legalStatus === LEGAL_STATUS_TYPE_PRIVATE) {
                    increaseDecreaseStep(direction);
                }
                break;
            case 5:
                if(legalStatus === LEGAL_STATUS_TYPE_PRIVATE || (legalStatus === LEGAL_STATUS_TYPE_COMPANY && isLiableToPaySalesTax === false)) {
                    increaseDecreaseStep(direction);
                }
                break;
        }
    }

    function createAccountObject() {
        return {
            legalStatus: legalStatus,
            addressData: addressData,
            isLiableToPaySalesTax: legalStatus === LEGAL_STATUS_TYPE_COMPANY ? isLiableToPaySalesTax : undefined,
            vatNo: (legalStatus === LEGAL_STATUS_TYPE_COMPANY && isLiableToPaySalesTax) ? vatNo : undefined,
            paypalEmailAddress: paypalEmailAddress,
        }
    }

    useEffect(() => {
        checkNextStep();
        setIsNextButtonDisabled(checkIsNextButtonDisabled());
    },[state.step,vatNo,paypalEmailAddress])


    useEffect(() => {
        setAccountData(createAccountObject());
    },[isLiableToPaySalesTax, legalStatus, addressData, vatNo, paypalEmailAddress])

    useEffect(() => {
        if(!isNullOrUndefined(user?.business_data)) {
            dispatchState({type: 'set', step: 2});
            setMinStep(2);
        }
    },[]);

    useEffect(() => {
        if(!isNullOrUndefined(user?.business_data)) {
            if(!isNullOrUndefined(user.business_data.is_liable_to_pay_sales_tax)) {
                setIsLiableToPaySalesTax((user.business_data.is_liable_to_pay_sales_tax === 1) ? true : false);
            }
            if(!isNullOrUndefined(user.business_data.legal_status_type_id)) {
                setLegalStatus(user.business_data.legal_status_type_id);
            }
            if(!isNullOrUndefined(user?.business_data?.vat_no)) {
                setVatNo(user.business_data.vat_no);
            }
            if(!isNullOrUndefined(user?.business_data?.paypal_email_address)) {
                setPayPalEmailAddress(user.business_data.paypal_email_address);
            }
            let addressObject = {
                legalStatus: user.business_data.legal_status_type_id,
                companyName: user.business_data.legal_status_type_id === LEGAL_STATUS_TYPE_COMPANY ? user.business_data.company_name : undefined,
                firstName: user.business_data.legal_status_type_id === LEGAL_STATUS_TYPE_PRIVATE ? user.business_data.first_name : undefined,
                lastName: user.business_data.legal_status_type_id === LEGAL_STATUS_TYPE_PRIVATE ? user.business_data.last_name : undefined,
                street: user.business_data.street,
                postalCode: user.business_data.postal_code,
                city: user.business_data.city,
                country: user.business_data.country_id
            };
            setAddressData(addressObject);
        }
    },[countries])

    return (
        <div className="container h-100">
            <div className="row mt-2">
                <div className="col text-center fw-bold">
                    {
                        Language[language].shop.wizard.business.headerText
                    }
                </div>
            </div>
            {
                savedSuccessfully === false &&
                <div className="row mt-2">
                    <div className="col text-center">
                        <ProgressBar
                            currentValue={state.step}
                            maxValue={lastStep}
                        />
                    </div>
                </div>
            }
            <div className="row mt-3 h-100 text-center d-flex justify-content-center align-items-center">
                {
                    savedSuccessfully === true &&
                    <div className="col d-flex justify-content-center">
                        <SuccessIcon />
                    </div>
                }
                {
                    savedSuccessfully === false &&
                    showComponent()
                }
            </div>
            <div className="row mt-3">´
                {
                    savedSuccessfully === false && state.step !== minStep &&
                    <div className="col d-flex justify-content-center">
                        <Button
                            key={"businessSetupBackButton"}
                            buttonText={Language[language].general.back}
                            additionalClasses={"w-50"}
                            onClicked={onBackClick}
                            disabled={isBackButtonDisabled}
                            icon={backIcon} 
                            iconColor={"#000"}
                        />
                    </div>
                }
                {
                    savedSuccessfully === false && state.step !== lastStep &&
                    <div className="col d-flex justify-content-center">
                        <Button
                            key={"businessSetupNextButton"}
                            buttonText={Language[language].general.next}
                            additionalClasses={"w-50"}
                            onClicked={onNextClick}
                            disabled={isNextButtonDisabled}
                            icon={nextIcon} 
                            iconColor={"#000"}
                        />
                    </div>
                }
                {
                    savedSuccessfully === false && state.step === lastStep &&
                    <div className="col d-flex justify-content-center">
                        <Button
                            key={"businessSetupSaveButton"}
                            buttonText={Language[language].general.save}
                            additionalClasses={"w-50"}
                            onClicked={onSaveClick}
                            //disabled={false}
                            icon={saveIcon} 
                            iconColor={"#000"}
                        />
                    </div>
                }
            </div>
        </div>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"businessSetupWizard.component.js"}
            fallbackUI={<FallbackUI language={language} component={"businessSetupWizard"} />}
        >
            <BusinessSetupWizard
                key={`businessSetupWizardComponent`}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;