import React, { useState, useEffect } from "react";
import * as Data from '../../backend/data';
import { isNullOrUndefined } from "../../utils/utils";
import { v4 as uuidv4 } from 'uuid';
import platform from 'platform';
import ErrorBoundary from '../error/error.component';
import FallbackUI from '../error/standardFallback.component';
var jwt = require('jsonwebtoken');

function LogVisit(props) {
    const [uniqueVisitorId,setUniqueVisitorId] = useState(window.localStorage.getItem('id'))

    useEffect(() => {
        if(isNullOrUndefined(uniqueVisitorId)) {
            const newId = uuidv4();
            setUniqueVisitorId(newId);
            window.localStorage.setItem('id',newId);
        }
        const landingPageId = props.landingPage.id;
        const browserName = platform.name;
        const browserVersion = platform.version;
        const osName = platform.os.family;
        const osVersion = platform.os.version;
        const ua = platform.ua;
        let dataEncoded = jwt.sign({ 
            uniqueVisitorId, 
            landingPageId, 
            browserName, 
            browserVersion,  
            osName,
            osVersion,
            ua
        }, process.env.MIX_JWT_PRIVATE_KEY, { algorithm: 'HS512' });

        let formData = [
            {
                name:'csrfToken',
                value: props.csrfToken
            },
            {
                name:'data',
                value: dataEncoded
            }
        ];
        Data.transfer(window.location.origin + '/log/visit','POST',formData,undefined,undefined,props.csrfToken)
        .then((data) => {
            if(data.success) {

            }
        });
    },[]);

    return null;
}

const ComponentWithErrorBoundary = (props) =>  {
    return ( 
        <ErrorBoundary
            location={"app"}
            component={"logVisit.component.js"}
            fallbackUI={<FallbackUI language={props.language} component={"logVisit"} />}
        >
            <LogVisit
                key={"logVisitComponent"}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;