import React, { Component, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Switch from "react-switch";
import Language from '../constants/language';
import ErrorBoundary from './error/error.component';
import FallbackUI from './error/standardFallback.component';
import * as Cookies from '../utils/cookies';
import * as GoogleAnalytics from '../utils/googleanalytics';
import { useSelector, useDispatch } from 'react-redux';
import { setIsCookieAndPrivacyOptOutOpen } from '../reducers/slices/isCookieAndPrivacyOptOutOpen.slice';
import { usePrevious } from '../utils/utils';
import { setNavigation } from '../reducers/slices/navigation.slice';

const caretUpIcon = require('../assets/images/caret-up.svg').default;
const caretDownIcon = require('../assets/images/caret-down.svg').default;

const Icon = styled.div`
    margin:0.5rem;
    min-width:${props => props.minSize ? props.minSize : '50px'};
    min-height:${props => props.minSize ? props.minSize : '50px'};
    color:#fff;
    background-color:${props => props.maskColor ? props.maskColor : '#fff'};
    -webkit-mask-image: url(${props => props.image});
    mask-image: url(${props => props.image});
    -webkit-mask-size: cover;
    mask-size:cover;
    background-size: cover;
    background-position: center;
    display:inline-block;
` 

const OptOutDiv = styled.div`
    position:${props => (props.location && props.location === "app") ? 'static' : 'fixed'};
    bottom:0px;
    left:0px;
    width:100%;
    background-color: #343a40;
    color:#eaeaea;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    z-index:10001;
    padding: 1rem;

    &:empty {
        
    }
` 

const AcceptButton = styled.button`
    background-color:#1e80a0;
    color:#fff;

    &:hover {
        text-decoration:underline;
        color:#fff;
    }
`

const ChangePrivacyOptions = styled.button`
    &:hover {
        text-decoration:underline;
    }
`

const OptOutHeader = styled.div`
    font-weight: bold;
`

const MarginTop = styled.div`
    margin-top:${props => props.marginTop ? props.marginTop : 'auto'};
`

const PaddingLeftRight2rem = styled.div`
    padding-left:2rem;
    padding-right:2rem;
`

const SwitchDiv = styled.div`
    padding-left:2rem;
    padding-right:2rem;
    display: flex;
    align-items: center;
`

const PaddingLeftSpan05rem = styled.span `
    padding-left:0.5rem;
`

const BoldText = styled.div`
    font-weight:bold;
`

const CloseButton = styled.button`
    font-size: small;
    color: #868585;
    position: absolute;
    right: 5px;
    top: 5px;
`

const Link = styled.button`
    color:#eaeaea;
    background-color:transparent;
    border:0px;

    &:hover{
        text-decoration:underline;
    }
`

const OptionsRow = styled.div`
    align-items: flex-end;
    margin-top:0.5rem;
`

const MoreInfoColumn = styled.div`
    align-items: center;
    display: flex;
`

const CookieAndPrivacyConsentDiv = styled.div`
    position:relative;
`

function CookieAndPrivacyConsent(props) {
    const dispatch = useDispatch();
    const isCookieAndPrivacyOptOutOpen = useSelector((state) => state.isCookieAndPrivacyOptOutOpen.value);
    const language = useSelector((state) => state.language.value);
    const [showOptOut, setShowOptOut] = useState(isCookieAndPrivacyOptOutOpen);
    const [allowTracking, setAllowTracking] = useState((window.localStorage.getItem('allowTracking') === "true") ? true : false);
    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const outputReference = useRef();
    const previousIsCookieAndPrivacyOptOutOpen = usePrevious(isCookieAndPrivacyOptOutOpen)

    useEffect(() => {
        if(window.localStorage.getItem('showOptOutWindow') !== "false") {
            setShowOptOutValue(true);
        }
        if(window.localStorage.getItem('allowTracking') === "true") {
            activateTracking();
        }
        else {
            deactivateTracking();
        }
    }, []);

    useEffect(() => {
        if(isCookieAndPrivacyOptOutOpen !== previousIsCookieAndPrivacyOptOutOpen) {
            setShowOptOutValue(isCookieAndPrivacyOptOutOpen);
        }
    }, [isCookieAndPrivacyOptOutOpen]);
    /*
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.isCookieAndPrivacyOptOutOpen !== this.props.isCookieAndPrivacyOptOutOpen) {
            this.setShowOptOut(this.props.isCookieAndPrivacyOptOutOpen);
        }
    }
    */

    function onSaveChangesClick(event) {
        event.preventDefault();
        setLocalStorageSettings(allowTracking);
        setShowOptOutValue(false);
        window.localStorage.setItem('showOptOutWindow',false);
    }

    function onAcceptButtonClick() {
        setLocalStorageSettings(true);
        activateTracking();
        window.localStorage.setItem('showOptOutWindow',false);
        setShowOptOutValue(false);
    }

    
    function onDenyButtonClick() {
        setLocalStorageSettings(false);
        activateTracking();
        window.localStorage.setItem('showOptOutWindow',false);
        setShowOptOutValue(false);
    }

    function activateTracking() {
        GoogleAnalytics.activate();
    }

    function deactivateTracking() {
        GoogleAnalytics.deactivate();
        Cookies.deleteAllCookies();
    }

    function setLocalStorageSettings(allowTracking) {
        window.localStorage.setItem('allowTracking',allowTracking);
        setAllowTracking(allowTracking);
    }

    function onAllowTrackingCheckboxChanged(newValue) {
        if(newValue) {
            activateTracking(); 
        }
        else {
            deactivateTracking();
        }
        setLocalStorageSettings(newValue);
    }

    function onShowPrivacyStatementClick() {
        dispatch(setNavigation('PrivacyView'));
    }

    function doNothing() {}

    function showMoreInfos() {
        setShowMoreInfo(!showMoreInfo);
    }

    function setShowOptOutValue(newValue) {
        setShowOptOut(newValue);
        if(isCookieAndPrivacyOptOutOpen !== newValue) {
            dispatch(setIsCookieAndPrivacyOptOutOpen(newValue));
        }
    }

    return (
        <CookieAndPrivacyConsentDiv>           
            {            
                showOptOut &&
                <div>
                    <OptOutDiv location={props.location} key={"statusMessageDiv"} id={"statusMessageDiv"} className="statusMessageDiv" ref={outputReference}>
                        <div className="row d-flex align-items-center">
                            <div className="col">
                                <CloseButton type="button" className="btn-close btn-close-white" aria-label="Close" onClick={onDenyButtonClick}></CloseButton>
                                <div>
                                    <div className="row">
                                        <BoldText className="col text-center">
                                            {
                                                Language[language].privacy.dataPrivacyInformation
                                            }
                                        </BoldText>
                                    </div>
                                    <div className="row">
                                        <div className="col text-center">
                                            {
                                                Language[language].privacy.weUseCookiesNote + ' '
                                            }
                                            <Link onClick={onShowPrivacyStatementClick}>{ Language[language].privacy.linkToPrivacyStatement }</Link>
                                        </div>
                                    </div>
                                    <OptionsRow className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="row">
                                                <SwitchDiv className="col">
                                                    <Switch 
                                                        onChange={doNothing} 
                                                        onColor={'#888888'}
                                                        disabled={true}
                                                        checked={true} 
                                                    />
                                                    <PaddingLeftSpan05rem>{Language[language].privacy.essentialCookies}</PaddingLeftSpan05rem>
                                                </SwitchDiv>
                                                <SwitchDiv className="col">
                                                    <Switch 
                                                        onChange={onAllowTrackingCheckboxChanged} 
                                                        onColor={'#888888'}
                                                        disabled={false}
                                                        checked={allowTracking} 
                                                    />
                                                    <PaddingLeftSpan05rem>{Language[language].privacy.allowTracking}</PaddingLeftSpan05rem>
                                                </SwitchDiv>
                                                <MoreInfoColumn className="col">
                                                    {
                                                        !showMoreInfo &&
                                                        <Icon onClick={showMoreInfos} image={caretDownIcon} minSize={"25px"} />
                                                    }
                                                    {
                                                        showMoreInfo &&
                                                        <Icon onClick={showMoreInfos} image={caretUpIcon} minSize={"25px"} />
                                                    }
                                                    <Link onClick={showMoreInfos}>
                                                        {
                                                            !showMoreInfo &&
                                                            Language[language].general.more 
                                                        }
                                                        {
                                                            showMoreInfo &&
                                                            Language[language].general.less 
                                                        }
                                                    </Link>
                                                </MoreInfoColumn>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="row">
                                                <div className="col text-center">
                                                    <ChangePrivacyOptions className="btn btn-light" onClick={onSaveChangesClick}>{Language[language].privacy.saveSelection}</ChangePrivacyOptions>
                                                </div>
                                                <div className="col text-center">
                                                    <AcceptButton className="btn" onClick={onAcceptButtonClick}>{Language[language].privacy.acceptAll}</AcceptButton>
                                                </div>
                                            </div>
                                        </div>
                                    </OptionsRow>
                                </div>
                                {
                                    showMoreInfo === true &&
                                    <MarginTop marginTop={"1rem"}>
                                        <div className="row">
                                            <div className="col">
                                                <OptOutHeader>
                                                    { Language[language].privacy.essentialCookies }
                                                </OptOutHeader>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <PaddingLeftRight2rem className="col">
                                                    { Language[language].privacy.essentialCookiesExplanation }
                                            </PaddingLeftRight2rem>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <OptOutHeader>
                                                    { Language[language].privacy.tracking }
                                                </OptOutHeader>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <PaddingLeftRight2rem className="col">
                                                    { Language[language].privacy.cookieAndTrackingExplanation }
                                            </PaddingLeftRight2rem>
                                        </div>
                                    </MarginTop>
                                }
                            </div>
                        </div>
                    </OptOutDiv>
                </div>
            }
        </CookieAndPrivacyConsentDiv>
    );
}


const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"cookieAndPrivacyConsent.component.js"}
            fallbackUI={<FallbackUI language={language} component={"cookieAndPrivacyConsent"} />}
        >
            <CookieAndPrivacyConsent
                key={"cookieAndPrivacyConsentComponent"}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;