import React from 'react';
import styled from 'styled-components';
import { isNullOrUndefined } from "../../utils/utils";
import { IMAGE_TYPE_TEXT_BACKGROUND } from '../../constants/imageTypes';

const BackgroundImage = (props) => {
    if(!isNullOrUndefined(props.images) && props.images.length > 0 && props.images.find((image) => image.landing_page_template_image_id === IMAGE_TYPE_TEXT_BACKGROUND) !== undefined) {
        return props.images.find((image) => image.landing_page_template_image_id === IMAGE_TYPE_TEXT_BACKGROUND);        
    }
    else {
        return null;
    }
}

export const PageBackgroundImage = styled.div`
    background-color:#fff;
    background-repeat:repeat;
    background-image:url(${props => (BackgroundImage(props)?.app_image_sizes.find((image) => image.size === '640') !== undefined) ? "/event/images/show/" + BackgroundImage(props)?.app_image_sizes.find((image) => image.size === '640')?.identifier_token : ''});
    background-size:cover;
    background-position:center;
    width:100vw;
    height:100%;
    position:fixed;
    border-radius: ${props => !isNullOrUndefined(BackgroundImage(props)) ? BackgroundImage(props).border_radius : '0' }%;
    opacity: ${props => !isNullOrUndefined(BackgroundImage(props)) ? BackgroundImage(props).opacity : 1 };
    z-index:0;
    border:${props => props.isEditImageVisible && !isNullOrUndefined(BackgroundImage(props)) ? '1px dashed #a0a0a0' : '0px' };
    /*border-radius: ${props => props.isEditImageVisible ? '0.25rem' : '0rem'};*/

    @media (max-width: 768px) and (min-width: 641px) {
        background-image:url(${props => (BackgroundImage(props)?.app_image_sizes.find((image) => image.size === '768') !== undefined) ? "/event/images/show/" + BackgroundImage(props)?.app_image_sizes.find((image) => image.size === '768')?.identifier_token : ''});
    }

    @media (max-width: 1024px) and (min-width: 769px) {
        background-image:url(${props => (BackgroundImage(props)?.app_image_sizes.find((image) => image.size === '1024') !== undefined) ? "/event/images/show/" + BackgroundImage(props)?.app_image_sizes.find((image) => image.size === '1024')?.identifier_token : ''});
    }

    @media (max-width: 1366px) and (min-width: 1025px) {
        background-image:url(${props => (BackgroundImage(props)?.app_image_sizes.find((image) => image.size === '1366') !== undefined) ? "/event/images/show/" + BackgroundImage(props)?.app_image_sizes.find((image) => image.size === '1366')?.identifier_token : ''});
    }

    @media (max-width: 1600px) and (min-width: 1367px) {
        background-image:url(${props => (BackgroundImage(props)?.app_image_sizes.find((image) => image.size === '1600') !== undefined) ? "/event/images/show/" + BackgroundImage(props)?.app_image_sizes.find((image) => image.size === '1600')?.identifier_token : ''});
    }
    
    @media (min-width: 1601px) {
        background-image:url(${props => (BackgroundImage(props)?.app_image_sizes.find((image) => image.size === '1920') !== undefined) ? "/event/images/show/" + BackgroundImage(props)?.app_image_sizes.find((image) => image.size === '1920')?.identifier_token : ''});
    }
` 
