import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: false,
};

export const isSignedInSlice = createSlice({
  name: 'isSignedIn',
  initialState,
  reducers: {
    setIsSignedIn: (state, action) => {
      state.value = action.payload
    },
  },
});

export const { setIsSignedIn } = isSignedInSlice.actions;

export default isSignedInSlice.reducer;