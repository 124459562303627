import React, { useState } from 'react';
import styled from 'styled-components';
import ErrorBoundary from '../error/error.component';
import FallbackUI from '../error/standardFallback.component';
import Language from '../../constants/language';
import Button from '../button.component';
import * as Data from '../../backend/data';
import * as MessageTypes from '../../constants/messagetypes';
import { EditOptionsBackground } from '../styled/editOptionsBackground.component';
import LoadingSpinner from '../../components/loadingSpinner.component';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentLandingPage } from '../../reducers/slices/currentLandingPage.slice';
import { setMessage } from '../../reducers/slices/message.slice';

import confirmIcon from '../../assets/images/check2-square.svg';
import denyIcon from '../../assets/images/x-square.svg';

const HeaderRow = styled.div`
    margin:1rem;
`

function RowDelete(props) {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);
    const csrfToken = useSelector((state) => state.csrfToken.value);
    const currentLandingPage = useSelector((state) => state.currentLandingPage.value);

    const [isLoading,setIsLoading] = useState(false);

    function onRowDeleteClick() {
        setIsLoading(true);
        let formData = [
            {
                name: 'csrfToken',
                value: csrfToken
            },
            {
                name: 'landingPageId',
                value: currentLandingPage.id
            },
            {
                name: 'rowId',
                value: props.row.id
            }
        ];
        Data.transfer(window.baseUrl + '/builder/user/landingPage/rows/delete','POST',formData,null,null,csrfToken)
        .then((data) => {
            if(data.success && data.landingPage) {
                dispatch(setCurrentLandingPage(data.landingPage));
            }
        })
        .catch((error) => {
            dispatch(setMessage({
                message: error.errors?.join(' '),
                messageType: MessageTypes.MESSAGE_TYPE_ERROR
            }));
            setIsLoading(false);
        });
    }

    return (
        <>
            <HeaderRow className="row d-flex justify-content-center">
                <EditOptionsBackground>
                    {
                        isLoading &&
                        <LoadingSpinner 
                            key="loadingSpinnerEvents" 
                            color={'#fff'}
                        />
                    }
                    {
                        !isLoading &&
                        <>
                        <div className="row m-1 d-flex justify-content-center">
                            {
                                Language[language].editor.deleteRow
                            }
                        </div>
                        <div className="col m-1 d-flex justify-content-center">
                            {
                                <>
                                    <Button 
                                        key={`deleteRowConfirm${props.row.id}`}
                                        buttonText={``}
                                        additionalStyles={{backgroundColor:'rgb(59 63 66)',marginRight:'0.25rem'}}
                                        onClicked={onRowDeleteClick}
                                        icon={confirmIcon} 
                                        iconColor={"#fff"}
                                    />
                                    <Button 
                                        key={`deleteRowDeny${props.row.id}`}
                                        buttonText={``}
                                        additionalStyles={{backgroundColor:'rgb(59 63 66)'}}
                                        onClicked={(e) => props.onRowDeleteCancel()}
                                        icon={denyIcon} 
                                        iconColor={"#fff"}
                                    />
                                </>
                            }
                        </div>
                        </>
                    }
                </EditOptionsBackground>
            </HeaderRow>
        </>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"rowDelete.component.js"}
            fallbackUI={<FallbackUI language={props.language} component={"rowDelete"} />}
        >
            <RowDelete
                key={`rowDeleteComponent${props.row.id}`}
                {...props}
            />
       </ErrorBoundary>
   )
}
 
export default ComponentWithErrorBoundary;