import React, { Component, useState, useEffect } from 'react';
import Language from '../constants/language';
import styled from 'styled-components';
import ErrorBoundary from './error/error.component';
import FallbackUI from './error/standardFallback.component';
import { useSelector, useDispatch } from 'react-redux'
import { setNavigation } from '../reducers/slices/navigation.slice';
import Logo from './styled/logo.component';

const Footer = styled.footer`
    bottom:0px;
    /*height:5vh;*/
    margin-bottom:0px;
    /*opacity: 0.88;*/
    border-radius:0 !important;
    border:0px !important;
` 

const Link = styled.button`
    border:0px;
    background-color:transparent;
    color:#eaeaea;
    cursor:pointer;

    &:hover {
        text-decoration:underline;
    }
`

const Text = styled.span`
    color:#eaeaea;
`

function FooterComponent(props) {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);

    function onLinkClick(event,target) {
        event.preventDefault();
        window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
        });
        //this.props.updateNavigation(target);
        dispatch(setNavigation(target));
    }
    
    return (
        <Footer className="bg bg-dark alert alert-dark container-fluid">
                <div className="row">
                    <div className="col-4 text-start">
                        {
                            props.location !== 'app' &&
                            <Text>
                                <Logo 
                                    fontSizeInPx={15} 
                                    fontColor={'#fff'}
                                />
                                &copy; 
                                { " " + new Date().getFullYear() }
                            </Text>
                        }

                    </div>
                    <div className="col-8 text-end">
                        <div className="row">
                            <div className="col text-end">
                                <Link onClick={(e) => onLinkClick(e,'ImprintView')} location={props.location ? props.location : undefined}>{Language[language].footer.imprint}</Link>
                                <Link onClick={(e) => onLinkClick(e,'PrivacyView')} location={props.location ? props.location : undefined}>{Language[language].footer.privacy}</Link>
                                {
                                    props.location !== 'app' &&
                                    <>
                                        <Link onClick={(e) => onLinkClick(e,'ContactView')}>{Language[language].footer.contact}</Link>
                                        <Link onClick={(e) => onLinkClick(e,'TermsAndConditionsView')}>{Language[language].footer.termsAndConditions}</Link>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
        </Footer>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"footer.component.js"}
            fallbackUI={<FallbackUI language={language} component={"footer"} />}
        >
            <FooterComponent
                key={"footerComponent"}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;