import React, { useState, useEffect } from 'react';
import ErrorBoundary from '../../../../error/error.component';
import FallbackUI from '../../../../error/standardFallback.component';
import { FadeIn } from '../../../../styled/fadeIn.component';
import Language from '../../../../../constants/language';
import Checkbox from '../../../../checkbox.component';
import { useSelector } from 'react-redux';

function SmallBusinessRegulation(props) {
    const language = useSelector((state) => state.language.value);

    const [isLiableToPaySalesTax, setIsLiableToPaySalesTax] = useState(props.isLiableToPaySalesTax);

    return (
        <FadeIn>
            <Checkbox 
                key={"iAmLiableToPaySalesTax"}
                label={Language[language].shop.iAmLiableToPaySalesTax}
                onChange={(newValue) => {
                    setIsLiableToPaySalesTax(newValue)
                    props.onReturnData(newValue);
                }}
                checked={isLiableToPaySalesTax}
            />
        </FadeIn>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"smallBusinessRegulation.component.js"}
            fallbackUI={<FallbackUI language={language} component={"smallBusinessRegulation"} />}
        >
            <SmallBusinessRegulation
                key={`smallBusinessRegulationComponent`}
                {...props}
            />
       </ErrorBoundary>
   )
}
 
export default ComponentWithErrorBoundary;