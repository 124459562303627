import React, { useState, useEffect } from 'react';
import AppMessage from '../../components/app/appMessage.component';
import styled from "styled-components";

const MarginBottom3rem = styled.div`
    margin-bottom:3rem;
`

function AppMessages(props) {
    return (
        <div>
            {
                props.messages.map((message) => {
                    return (
                        <MarginBottom3rem key={"marginMessageId" + message.id}>
                            <AppMessage
                                key={"messageId" + message.id}
                                message={message}
                                textStyle={props.textStyle}
                            />
                        </MarginBottom3rem>
                    )
                })
            }
            <div id="appMessagesEnd"></div>
        </div>
    );
	
}

export default AppMessages;