import React, { Component, useState, useEffect, useMemo, useCallback } from 'react';
import styled, { keyframes, createGlobalStyle }  from "styled-components";
import Language from '../../constants/language';
import Fonts from '../fonts.component';
import ErrorBoundary from '../error/error.component';
import FallbackUI from '../error/standardFallback.component';
import { useTextElements } from '../textAreaWithEditingOptions.component';
import { createEditor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import { isNullOrUndefined, isJsonString } from "../../utils/utils";
import { v4 as uuidv4 } from 'uuid';

import editIcon from '../../assets/images/edit.svg';

export const borderBlendIn = keyframes`
    0% {
        border: 0px;
    }
    100% {
        border: 1px solid #141619;
        border-radius:0.25rem;
        /*padding:1rem;*/
        background-color: rgba(255,255,255,0.4);
    }
`;

export const blendOut = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
`;

export const textEffect = keyframes`
    0% {
        opacity: 1;
        border: 0px;
    }
    100% {
        opacity: 0.2;
        border: 1px solid #141619;
        border-radius:0.25rem;
        /*padding:1rem;*/
    }
`;

export const blendIn = keyframes`
    0% {
        opacity: 0;
        width:100px;
    }
    100% {
        opacity: 1;
        width:200px;
    }
`;

export const slideIn = keyframes`
    0% {
        width:100px;
    }
    100% {
        width:200px;
    }
`;

const Row = styled.div`
    position:relative;
    pointer-events:none;
`

const Col = styled.div`
    display:flex;
    align-items:${props => props.appText?.justify_content ? props.appText.justify_content : 'inherit'};
    margin:${props => props.margin ? props.margin : '1rem'};
    flex-wrap: nowrap;
    flex-direction:column;
` 

const Text = styled.div`
    min-height:${props => props.emptyEntryRowHeight ? props.emptyEntryRowHeight : "1em"};
    color:${props => props.appText?.color ? props.appText.color : 'inherit'};
    font-size:${props => props.appText?.font_size ? props.appText.font_size + 'rem' : 'inherit'};
    font-family:${props => props.appText?.font?.font_family ? props.appText?.font?.font_family : 'inherit'};
    text-align:${props => (props.appText?.justify_content && props.appText?.justify_content === 'center') ? 'center' : (props.appText?.justify_content === 'flex-end') ? 'end' : 'start'};
    text-overflow: ellipsis;
    max-width: 90vw;
    overflow: hidden;
    cursor: ${props => props.isEditMode ? 'pointer' : 'auto'};
    /*flex: 0 0 100%;*/
`

const TextWrapper = styled.div`
    position:relative;
    pointer-events:auto;

    &:hover {
        ${Text} {
            animation-name: ${props => props.isEditMode ? blendOut : 'auto'};
            animation-fill-mode: ${props => props.isEditMode ? 'forwards' : 'inherit'};
            animation-duration: ${props => props.isEditMode ? '1s' : 'inherit'};
        };

        animation-name: ${props => props.isEditMode ? borderBlendIn : 'auto'};
        animation-fill-mode: ${props => props.isEditMode ? 'forwards' : 'inherit'};
        animation-duration: ${props => props.isEditMode ? '1s' : 'inherit'};
    }
`

const HoverContent = styled.div`
`

const HoverDiv = styled.div`
    display: none;
    position:absolute;
    left:50%;
    top:50%;
    font-size:1rem;
    font-weight:normal;
    width:200px;
    margin-left:-100px;
    height:65px;
    margin-top:-32.5px;
    font-family:'latolight' !important;
    font-size: 20px;

    &:hover {
        cursor:pointer;
    }

    ${TextWrapper}:hover & {
        display: inline-block;

        animation-name: ${blendIn};
        animation-duration: 1s;
    }
`

const HoverText = styled.div`
`

/*
const Font = styled.div`
    ${props => props.appText?.font?.is_default_web_font !== undefined && props.appText?.font?.is_default_web_font === 0 && 
    `@font-face {
        font-family: ` + props.appText?.font?.font_family + `;
        src: url('` + props.fontEotFile + `');
        src: url('` + props.fontEotFile + `?#iefix') format('embedded-opentype'), url('` + props.fontWoff2File + `') format('woff2'), url('` + props.fontWoffFile + `') format('woff'), url('` + props.fontTtfFile + `') format('truetype'), url('` + props.fontSvgFile + '#' + props.appText?.font?.font_family + `') format('svg');
        font-weight: ` + props.appText?.font?.font_weight + `; 
        font-style: ` + props.appText?.font?.font_style + `;
    }`}
`
*/


function AppText(props) {
    const [isDefaultWebFont,setIsDefaultWebFont] = useState(null);
    const [woffFile,setWoffFile] = useState(null);
    const [woff2File,setWoff2File] = useState(null);
    const [eotFile,setEotFile] = useState(null);
    const [ttfFile,setTtfFile] = useState(null);
    const [svgFile,setSvgFile] = useState(null);
    const [isTextModificationEnabled,setIsTextModificationEnabled] = useState(false);
    const editor = useMemo(() => withReact(createEditor()), []);
    const [value, setValue] = useState(getText());
    const [editorKey,setEditorKey] = useState(uuidv4());

    const { renderElement, renderLeaf } = useTextElements(); 
    /*
        isJsonString(props.appText?.text) ? JSON.parse(props.appText?.text) :
        (Array.isArray(props.appText?.text)) ? 
            props.appText?.text :
            [
                {
                    type: 'paragraph',
                    children: [{ text: isNullOrUndefined(props.appText?.text) ? '' : props.appText?.text }],
                },
            ]
    */
        /*
        (Array.isArray(props.appText?.text)) ? 
            props.appText?.text : 
            [
                {
                    type: 'paragraph',
                    children: [{ text: isNullOrUndefined(props.appText?.text) ? '' : props.appText?.text }],
                },
            ]
        */
    //);

    function getText() {
        let updatedTextValue;
        if(isJsonString(props.appText?.text)) {
            updatedTextValue = JSON.parse(props.appText?.text);
        }
        else {
            if((Array.isArray(props.appText?.text))) {
                updatedTextValue = props.appText?.text;
            }
            else {
                updatedTextValue = [
                    {
                        type: 'paragraph',
                        children: [{ text: isNullOrUndefined(props.appText?.text) ? '' : props.appText?.text }],
                    },
                ];
            }
        }
        return updatedTextValue;
    }

    function doNothing() {
        //onChange method is mandatory for slatejs, so in case of read-only: do nothing
    }
   
    /*
    const renderElement = useCallback(props => {
        switch (props.element.type) {
            case 'link':
                return <UrlLink {...props} />
            case 'email':
                return <EmailAddress {...props} />
            case 'span':
                return <Span {...props} />
            default:
                return <DefaultElement {...props} />
        }
    }, []);

    const renderLeaf = useCallback(props => {
        return <StandardLeaf {...props} />
    }, []);
    */

    /*
    useEffect(() => {
        if(props.appText?.font?.is_default_web_font === 0) {
            this.setState({
                isDefaultWebFont: false,
                woffFile: require('../../assets/fonts/' + props.appText?.font?.woff).default,
                woff2File: require('../../assets/fonts/' + props.appText?.font?.woff2).default,
                eotFile: require('../../assets/fonts/' + props.appText?.font?.eot).default,
                ttfFile: require('../../assets/fonts/' + props.appText?.font?.ttf).default,
                svgFile: require('../../assets/fonts/' + props.appText?.font?.svg).default,
            });
        }
        else {
            setIsDefaultWebFont(true);
        }
    },[props.appText?.font]);
    */

    useEffect(() => {
        if(props.appText?.font?.is_default_web_font !== undefined && props.appText?.font?.is_default_web_font === 0) {
            let woffFile = require('../../assets/fonts/' + props.appText?.font?.woff).default;
            let woff2File = require('../../assets/fonts/' + props.appText?.font?.woff2).default;
            let eotFile = require('../../assets/fonts/' + props.appText?.font?.eot).default;
            let ttfFile = require('../../assets/fonts/' + props.appText?.font?.ttf).default;
            let svgFile = require('../../assets/fonts/' + props.appText?.font?.svg).default;
            setIsDefaultWebFont(false);
            setWoffFile(woffFile);
            setWoff2File(woff2File);
            setEotFile(eotFile);
            setTtfFile(ttfFile);
            setSvgFile(svgFile);
        }
        else {
            setIsDefaultWebFont(true);
        }
        setValue(getText());
        setEditorKey(uuidv4());
    },[props.appText])

    return (
        <Row 
            isEditMode={props.isEditMode} 
            appText={props.appText} 
            isBackgroundImageEditVisible={props.isBackgroundImageEditVisible}
            className="row"
        >
            <Col 
                appText={props.appText} 
                margin={props.margin} 
                className="col"
            >
                <Fonts 
                    font={props.appText?.font} 
                    fontWoffFile={woffFile} 
                    fontWoff2File={woff2File} 
                    fontEotFile={eotFile} 
                    fontTtfFile={ttfFile} 
                    fontSvgFile={svgFile}
                />
                {
                    <TextWrapper
                        isEditMode={props.isEditMode}
                    >
                        <Text 
                            key={"text" + props.appText?.id} 
                            appText={props.appText} 
                            isEditMode={props.isEditMode} 
                            emptyEntryRowHeight={props.emptyEntryRowHeight}
                        >
                        {
                            <Slate
                                key={editorKey}
                                editor={editor}
                                value={value}
                                onChange={doNothing}
                            >
                                <Editable 
                                    readOnly={!isTextModificationEnabled}
                                    renderElement={renderElement}
                                    renderLeaf={renderLeaf}
                                />
                            </Slate>

                        }
                        </Text>
                        {
                            props.isEditMode && 
                            <HoverContent>
                                <HoverDiv>
                                    <div className="row">
                                        <div className="col text-center">
                                            <img src={editIcon} alt="Edit text"></img>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <HoverText className="col text-center">
                                            {Language[props.language].editor.editText}
                                        </HoverText>
                                    </div>
                                </HoverDiv>
                            </HoverContent>
                        }
                    </TextWrapper>
                }
                
            </Col>
        </Row>
    );
}


const ComponentWithErrorBoundary = (props) =>  {
    return ( 
        <ErrorBoundary
            location={props.isEditMode ? "builder" : "app"}
            component={"appText.component.js"}
            fallbackUI={<FallbackUI language={props.language} component={"appText"} />}
        >
            <AppText
                key={"appTextComponent" + props.appText?.id}
                {...props}
            />
       </ErrorBoundary>
    )
}

export default ComponentWithErrorBoundary;