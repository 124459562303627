export function getErrorMessage(error) {
    //let returnValue = '';
    //TODO: Translate "The given data was invalid." error message / Hard coded in 
    //if(error?.message) {
    //    returnValue = `${error.message} `;
    //}
    /*
    if(error?.errors) {
        returnValue += Object.values(error.errors).join(' ');
    }
    */
    return Object.values(error).map((singleError) => Object.values(singleError).map((singleErrorInner) => singleErrorInner)).join(' ');
    //return returnValue;
}