import React from 'react';
import _ from 'lodash';
import Language from '../../constants/language';
import ErrorBoundary from '../error/error.component';
import FallbackUI from '../error/standardFallback.component';
import ImageUpload from './imageUpload.component';
import LandingPageImagesContainer from '../../containers/managementInterface/landingPageImages.container';
import { FadeIn } from '../styled/fadeIn.component';
import { useSelector } from 'react-redux';

function ManageLandingPageImages(props) {
    const language = useSelector((state) => state.language.value);

    return (
        <FadeIn className="container">
            <div className="row">
                <div className="col fw-bold">
                    {Language[language].images.manageImages}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {Language[language].images.manageImagesIntroText}
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <ImageUpload 
                        key={"imageUpload"}
                    />
                </div>
            </div>
            <LandingPageImagesContainer 
                key={"landingPageImagesContainer"}
            />
        </FadeIn>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"manageLandingPageImages.component.js"}
            fallbackUI={<FallbackUI language={language} component={"manageLandingPageImages"} />}
        >
            <ManageLandingPageImages
                key={"ManageLandingPageImagesComponent"}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;