import { forEach } from 'lodash';
import React, { useState, useEffect } from 'react';
import styled, { keyframes }  from "styled-components";
import Language from '../../constants/language';
import * as MessageTypes from '../../constants/messagetypes';
import { useSelector, useDispatch } from 'react-redux';
import { setMessage } from '../../reducers/slices/message.slice';
import { usePrevious } from '../../utils/utils';

const showStatusDiv = keyframes`
    100% {
        opacity: 1;
    }
`;

const hideStatusDiv = keyframes`
    100% {
        opacity: 0;
    }
`;

const fade = keyframes`
    0%,100% { opacity: 0 }
    50% { opacity: 1 }
`

const StatusDiv = styled.div`
    position: fixed;
    z-index: 10000;
    top: 0rem;
    left: 50%;
    height: 2.5rem;
    width: 50%;
    margin-left: -25%;
    background-color: #e9e9e9;
    pointer-events: none;
    /*animation: ${fade} 3.5s ease-in-out forwards;*/
    /*display: ${props => (props.message?.length > 0 ? 'inline-block' : 'none')};*/

    /*transition: opacity ${props => (props.message?.length > 0 ? '0.5s' : '3.5s')};*/
    
    -webkit-transition: opacity ${props => (props.messageType === MessageTypes.MESSAGE_TYPE_NO_MESSAGE ? '0.5s' : '0.5s')} ease-in-out;
    -moz-transition: opacity ${props => (props.messageType === MessageTypes.MESSAGE_TYPE_NO_MESSAGE ? '0.5s' : '0.5s')} ease-in-out;
    -ms-transition: opacity ${props => (props.messageType === MessageTypes.MESSAGE_TYPE_NO_MESSAGE ? '0.5s' : '0.5s')} ease-in-out;
    -o-transition: opacity ${props => (props.messageType === MessageTypes.MESSAGE_TYPE_NO_MESSAGE ? '0.5s' : '0.5s')} ease-in-out;
    transition: opacity ${props => (props.messageType === MessageTypes.MESSAGE_TYPE_NO_MESSAGE ? '0.5s' : '0.5s')} ease-in-out;

    opacity: ${props => (props.message?.length > 0 ? 1 : 0)};
    

    @media (max-width: 576px) {
        left: 0rem;
        width:100%;
        margin-left:0rem;
    }
`

const StatusBackgroundColor = styled.div`
    background-color: ${props => ((props.messageType === MessageTypes.MESSAGE_TYPE_SUCCESS || props.messageType === MessageTypes.MESSAGE_TYPE_NO_MESSAGE) ?  '#dafdd1': (props.messageType === MessageTypes.MESSAGE_TYPE_ERROR) ? '#f5c2c7' : '#fffed3')} !important;
    color: ${props => (props.messageType === MessageTypes.MESSAGE_TYPE_SUCCESS ?   '#29422f' : (props.messageType === MessageTypes.MESSAGE_TYPE_ERROR) ? '#842029' : '#31311d')} !important;
    border-color: ${props => ((props.messageType === MessageTypes.MESSAGE_TYPE_SUCCESS) ? '#a9cab0' : (props.messageType === MessageTypes.MESSAGE_TYPE_ERROR) ? '#f5c2c7' : '' )};
`

function UserInteractionFeedbackMessage(props) {

    const dispatch = useDispatch();
    const message = useSelector((state) => state.message.value);

    const previousMessage = usePrevious(message);

    useEffect(() => {
        if(message?.message !== previousMessage?.message?.message && message !== null) {
            window.setTimeout(() => {
                dispatch(setMessage({
                    message: '',
                    messageType: MessageTypes.MESSAGE_TYPE_NO_MESSAGE
                }));
            }, 5000);
        }
    },[message]);

    return (
        <StatusDiv message={message?.message} messageType={message?.messageType} className="messageDiv">
            <div className="container">
                <div className="row">
                    <StatusBackgroundColor messageType={message?.messageType} className="col text-center alert alert-light">
                        {
                            message?.message
                        }
                    </StatusBackgroundColor>
                </div>
            </div>
        </StatusDiv>
    
    );
}
 
export default UserInteractionFeedbackMessage;