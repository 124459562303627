import styled from 'styled-components';

export const ModalOverlayBackground = styled.div`
    position: fixed;
    top:0px;
    left:0px;
    width: 100%;
    height: 100%;
    background-color:#0e0e0e;
    opacity:0.7;
    z-index:1000;
` 

export const ModalContent = styled.div`
    position: fixed;
    top: 40%;
    left: 50%;
    margin-left: -40%;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : '#fff'};
    color: ${props => props.color ? props.color : '#000'};
    z-index: 1001;
    border: 1px solid rgba(0,0,0,0.125);
    border-radius: 0.25rem;
    width: 80%;
    max-height: 80vh;
    height:fit-content;
    overflow-y: auto;
`

export const ModalCloseButton = styled.div`
    margin:0.5rem;
    position: absolute;
    right: 5px;
    top: 5px;
` 
