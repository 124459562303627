import React, { useState,useEffect } from 'react';
import ErrorBoundary from '../../../components/error/error.component';
import FallbackUI from '../../../components/error/standardFallback.component';
import * as Data from '../../../backend/data';
import PushNotificationMessagesListItem from '../../../components/managementInterface/pushNotificationMessagesListItem.component';
import Language from '../../../constants/language';
import LoadingSpinner from '../../../components/loadingSpinner.component';
import { useSelector, useDispatch } from 'react-redux';

function PagePushNotificationMessagesListContainer(props) {

    const language = useSelector((state) => state.language.value);
    const csrfToken = useSelector((state) => state.csrfToken.value);
    const currentLandingPage = useSelector((state) => state.currentLandingPage.value);

    const [messages,setMessages] = useState([]);
    const [isLoading,setIsLoading] = useState(true);

    function getMessages() {
        const formData = [
            {
                name: 'landingPageId',
                value: currentLandingPage.id
            }
        ];
        return Data.transfer(window.baseUrl + '/builder/user/landingPage/messages/get','POST',formData,undefined,undefined,csrfToken)
        .then((data) => {
            if(data.success && data.messages) {
                setMessages(data.messages);
            } 
            setIsLoading(false);
        })
        .catch((error) => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getMessages();
    },[currentLandingPage]);

    return (
        <div className="container-fluid">
            <div className="row mb-3">
                <div className="col text-center">
                    {
                        Language[language].message.sentMessages
                    }
                </div>
            </div>
            {
                isLoading &&
                <div className="row">
                    <div className="col text-center">
                        <LoadingSpinner key="loadingSpinnerSentMessages" color={"#fff"}></LoadingSpinner>
                    </div>
                </div>
            }
            {
                messages.length === 0 && isLoading === false &&
                <div>
                    {
                        Language[language].message.noMessagesSentYet
                    }
                </div>
            }
            {
                messages.map((message,index) => {
                    return (
                        <PushNotificationMessagesListItem
                            key={"pushNotificationMessage" + index} 
                            message={message.message}
                            guest={message.guest}
                        />
                    )
                })
            }
        </div>
    )
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"pushNotificationMessagesList.container.js"}
            fallbackUI={<FallbackUI language={language} component={"pagePushNotificationMessagesListContainer"} />}
        >
            <PagePushNotificationMessagesListContainer
                key={"pagePushNotificationMessagesListContainer"}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;