import React, { useState, useEffect, useMemo } from 'react';
import TextInput from '../../../../../textInput.component';
import ErrorBoundary from '../../../../../error/error.component';
import FallbackUI from '../../../../../error/standardFallback.component';
import { FadeIn } from '../../../../../styled/fadeIn.component';
import { Bold } from '../../../../../styled/bold.component';
import { Icon } from '../../../../../styled/icon.component';
import Language from '../../../../../../constants/language';
import { InputError } from '../../../../../styled/inputError.component';
import { isNullOrUndefined } from '../../../../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { setProductSetupWizard } from '../../../../../../reducers/slices/productSetupWizard.slice';
import { useProductTypeSetupWizard } from '../../../../../../factories/productSetup.factory';
import LandingPageImagesContainer from '../../../../../../containers/managementInterface/landingPageImages.container';
import { v4 as uuidv4 } from 'uuid';
import { createEditor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import { useTextElements } from '../../../../../textAreaWithEditingOptions.component';

function ProductSummary(props) {
    const language = useSelector((state) => state.language.value);
    const productSetupWizard = useSelector((state) => state.productSetupWizard.value);
    const user = useSelector((state) => state.user.value);

    const editor = useMemo(() => withReact(createEditor()), []);
    const [editorKey,setEditorKey] = useState(uuidv4());

    const { renderElement, renderLeaf } = useTextElements(); 

    return (
        <FadeIn>
            <div className="row">
                <div className="col">
                    <Bold>
                        {
                            Language[language].shop.summary
                        }
                    </Bold>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    {`${Language[language].shop.wizard.product.productName}: ${productSetupWizard.product.name}`}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {
                        `${Language[language].shop.wizard.product.productDescription}: ` //${productSetupWizard.product.description}`
                    }
                    <Slate
                        key={editorKey}
                        editor={editor}
                        value={JSON.parse(productSetupWizard.product.description)}
                        onChange={(event) => event}
                    >
                        <Editable 
                            readOnly={true}
                            renderElement={renderElement}
                            renderLeaf={renderLeaf}
                        />
                    </Slate>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {`${Language[language].shop.wizard.product.productNumber}: ${productSetupWizard.product.product_no}`}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {`${Language[language].shop.wizard.product.netPrice}: ${productSetupWizard.product.price_net} ${user.business_data.country.currency.symbol}`}
                </div>
            </div>
            {
                !isNullOrUndefined(productSetupWizard.product.shipping_costs_domestic) && 
                productSetupWizard.product.shipping_costs_domestic.toString().trim() !== '' && 
                //productSetupWizard.product.shipping_costs_domestic !== 0 &&
                parseInt(productSetupWizard.product.shipping_costs_domestic) !== NaN &&
                parseInt(Math.ceil(productSetupWizard.product.shipping_costs_domestic)) !== 0 &&
                <div className="row">
                    <div className="col">
                        {`${Language[language].shop.wizard.product.shippingCostsDomestic}: ${productSetupWizard.product.shipping_costs_domestic} ${user.business_data.country.currency.symbol}`}
                    </div>
                </div>
            }
            {
                !isNullOrUndefined(productSetupWizard.product.shipping_costs_european_foreign_countries) && 
                productSetupWizard.product.shipping_costs_european_foreign_countries.toString().trim() !== '' && 
                //productSetupWizard.product.shipping_costs_european_foreign_countries !== 0 &&
                parseInt(productSetupWizard.product.shipping_costs_european_foreign_countries) !== NaN &&
                parseInt(Math.ceil(productSetupWizard.product.shipping_costs_european_foreign_countries)) !== 0 &&
                <div className="row">
                    <div className="col">
                        {`${Language[language].shop.wizard.product.shippingCostsEuropeanForeignCountries}: ${productSetupWizard.product.shipping_costs_european_foreign_countries} ${user.business_data.country.currency.symbol}`}
                    </div>
                </div>
            }
            {
                !isNullOrUndefined(productSetupWizard.product.shipping_costs_other_countries) && 
                productSetupWizard.product.shipping_costs_other_countries.toString().trim() !== '' && 
                //productSetupWizard.product.shipping_costs_other_countries !== 0 &&
                parseInt(productSetupWizard.product.shipping_costs_other_countries) !== NaN &&
                parseInt(Math.ceil(productSetupWizard.product.shipping_costs_other_countries)) !== 0 &&
                <div className="row">
                    <div className="col">
                        {`${Language[language].shop.wizard.product.shippingCostsOtherCountries}: ${productSetupWizard.product.shipping_costs_other_countries} ${user.business_data.country.currency.symbol}`}
                    </div>
                </div>
            } 
            <div className="row">
                <div className="col">
                    {`${Language[language].shop.wizard.product.maxNumberOfProductsInOneShippingPackage}: ${productSetupWizard.product.maximum_number_of_products_in_shipping_price}`}
                </div>
            </div>
            {
                user.business_data.is_liable_to_pay_sales_tax === 1 &&
                <div className="row">
                    <div className="col">
                        {`${Language[language].shop.wizard.product.salesTax}: ${Boolean(productSetupWizard.product.is_default_sales_tax_of_country) === true ? user.business_data.country.sales_tax_amount_in_percent : productSetupWizard.product.sales_tax_amount_in_percent} %`}
                    </div>
                </div>
            }
            <div className="row">
                <div className="col">
                    {`${Language[language].shop.wizard.product.numberPiecesQuantityOfProduct}: ${Boolean(productSetupWizard.product.unlimited_number_available) === true ? Language[language].shop.wizard.product.unlimited : productSetupWizard.product.number_available_goods}`}
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <LandingPageImagesContainer
                        product={productSetupWizard.product}
                        maxImageWidth={"200px"}
                        headerText={Language[language].shop.wizard.product.productImages}
                    />
                </div>
            </div>
        </FadeIn>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"productSummary.component.js"}
            fallbackUI={<FallbackUI language={language} component={"productSummary"} />}
        >
            <ProductSummary
                key={`productSummaryComponent`}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;