import React, { useState, useEffect } from 'react';
import ErrorBoundary from '../../../../../error/error.component';
import FallbackUI from '../../../../../error/standardFallback.component';
import { FadeIn } from '../../../../../styled/fadeIn.component';
import { Icon } from '../../../../../styled/icon.component';
import Language from '../../../../../../constants/language';
import TextInput from '../../../../../textInput.component';
import { InputError } from '../../../../../styled/inputError.component';
import { isNullOrUndefined } from '../../../../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { handleDecimalsOnPrice, isPriceValid } from '../../../../../../utils/price';
import { setProductSetupWizard } from '../../../../../../reducers/slices/productSetupWizard.slice';
import { useProductTypeSetupWizard } from '../../../../../../factories/productSetup.factory';
import { v4 as uuidv4 } from 'uuid';

function ShippingCosts(props) {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);
    const user = useSelector((state) => state.user.value);
    const productSetupWizard = useSelector((state) => state.productSetupWizard.value);

    const [costsDomestic, setCostsDomestic] = useState(getShippingCostsValueFromProduct('domestic')); //useState(isNullOrUndefined(productSetupWizard.product?.shipping_costs_domestic) ? '' : productSetupWizard.product.shipping_costs_domestic);
    const [costsEuropeanForeignCountries, setCostsEuropeanForeignCountries] = useState(getShippingCostsValueFromProduct('europeanForeignCountries')); //useState(isNullOrUndefined(productSetupWizard.product?.shipping_costs_european_foreign_countries) ? '' : productSetupWizard.product.shipping_costs_european_foreign_countries);
    const [costsOtherCountries, setCostsOtherCountries] = useState(getShippingCostsValueFromProduct('otherCountries')); //useState(isNullOrUndefined(productSetupWizard.product?.shipping_costs_other_countries) ? '' : productSetupWizard.product.shipping_costs_other_countries);
    const [activeInput, setActiveInput] = useState('domestic');

    const { updateProduct } = useProductTypeSetupWizard();

    function getShippingCostsValueFromProduct(costsType) {
        let value = 0;
        switch(costsType) {
            case 'domestic':
                value = productSetupWizard.product?.shipping_costs_domestic;
                break;
            case 'europeanForeignCountries':
                value = productSetupWizard.product.shipping_costs_european_foreign_countries;
                break;
            case 'otherCountries':
                value = productSetupWizard.product.shipping_costs_other_countries;
                break;
            default:
                break;
        }
        if(
            isNullOrUndefined(value) ||
            parseInt(Math.ceil(value)) === 0
        ) {
            return '';
        }
        return value;
    }

    function checkCosts(event, costsType, action = 'submit') {
        setActiveInput(costsType);
        let newCosts = event.target.value;
        let costsSummary = {
            shipping_costs_domestic: costsDomestic,
            shipping_costs_european_foreign_countries: costsEuropeanForeignCountries,
            shipping_costs_other_countries: costsOtherCountries
        };
        let isValid = false;
        let validPrice = handleDecimalsOnPrice(newCosts);
        if(isPriceValid(validPrice)) {
            isValid = true;
            switch(costsType) {
                case 'domestic':
                    setCostsDomestic(validPrice);
                    costsSummary = {
                        shipping_costs_domestic: validPrice,
                        shipping_costs_european_foreign_countries: costsEuropeanForeignCountries,
                        shipping_costs_other_countries: costsOtherCountries
                    };
                    break;
                case 'europeanForeignCountries':
                    setCostsEuropeanForeignCountries(validPrice);    
                    costsSummary = {
                        shipping_costs_domestic: costsDomestic,
                        shipping_costs_european_foreign_countries: validPrice,
                        shipping_costs_other_countries: costsOtherCountries
                    };
                break;
                case 'otherCountries':
                    setCostsOtherCountries(validPrice);
                    costsSummary = {
                        shipping_costs_domestic: costsDomestic,
                        shipping_costs_european_foreign_countries: costsEuropeanForeignCountries,
                        shipping_costs_other_countries: validPrice
                    };
                    break;
                default:
                    break;
            }
            let product = _.cloneDeep(productSetupWizard.product);
            for (const [key, value] of Object.entries(costsSummary)) {
                product = updateProduct(product, { type: key, value: value });
            }
            let updatedWizard = { step: productSetupWizard.step, product: product};
            if(isValid && action === 'submit') {
                updatedWizard.step++;
            }
            dispatch(setProductSetupWizard(updatedWizard));
        }
    }

    return (
        <FadeIn>
            <div className="row">
                <div className="col d-flex justify-content-center align-items-center">
                    { `${Language[language].shop.wizard.product.enterShippingCosts} (${Language[language].general.in} ${user.business_data.country.currency.name})` }
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <TextInput
                        key={`costsDomestic${uuidv4()}`}
                        type={"text"}
                        //step={"0.01"}
                        label={""}
                        value={costsDomestic}
                        name={"costsDomestic"}
                        placeholder={`${Language[language].shop.wizard.product.shippingCostsDomestic} ${Language[language].general.in} ${user.business_data.country.currency.iso_4217_code}`}
                        onChange={(event) => checkCosts(event, 'domestic', 'change')}
                        submitOnEnter={true}
                        submitEvent={(event) => checkCosts(event, 'domestic')}
                        required={true}
                        autoFocus={activeInput === 'domestic'}
                    />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <TextInput
                        key={`costsEuropeanForeignCountries${uuidv4()}`}
                        type={"text"}
                        //step={"0.01"}
                        label={""}
                        value={costsEuropeanForeignCountries}
                        name={"costsEuropeanForeignCountries"}
                        placeholder={`${Language[language].shop.wizard.product.shippingCostsEuropeanForeignCountries} ${Language[language].general.in} ${user.business_data.country.currency.iso_4217_code}`}
                        onChange={(event) => checkCosts(event, 'europeanForeignCountries', 'change')}
                        submitOnEnter={true}
                        submitEvent={(event) => checkCosts(event, 'europeanForeignCountries')}
                        required={true}
                        autoFocus={activeInput === 'europeanForeignCountries'}
                    />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <TextInput
                        key={`costsOtherCountries${uuidv4()}`}
                        type={"text"}
                        //step={"0.01"}
                        label={""}
                        value={costsOtherCountries}
                        name={"costsOtherCountries"}
                        placeholder={`${Language[language].shop.wizard.product.shippingCostsOtherCountries} ${Language[language].general.in} ${user.business_data.country.currency.iso_4217_code}`}
                        onChange={(event) => checkCosts(event, 'otherCountries', 'change')}
                        submitOnEnter={true}
                        submitEvent={(event) => checkCosts(event, 'otherCountries')}
                        required={true}
                        autoFocus={activeInput === 'otherCountries'}
                    />
                </div>
            </div>
        </FadeIn>
    );
}

const ComponentWithErrorBoundary = (props) => {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"shippingCosts.component.js"}
            fallbackUI={<FallbackUI language={language} component={"shippingCosts"} />}
        >
            <ShippingCosts
                key={`shippingCostsComponent`}
                {...props}
            />
       </ErrorBoundary>
   )
}
 
export default ComponentWithErrorBoundary;