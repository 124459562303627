import React, { Component } from 'react';
import styled from 'styled-components';
import eyeIcon from '../assets/images/eye.svg';
import eyeSlashIcon from '../assets/images/eye-slash.svg';
import { isNullOrUndefined } from '../utils/utils';

/*
const TypeEmail = styled.input`

&:valid {
    border: 2px solid #00640d;
}

&:invalid {
    border: 2px solid #660000;
}
`
*/

const Input = styled.input`
    height: ${props => (props.name) ? 'calc(1.5em + 1.8rem) !important' : '35px'};
    padding-top: ${props => (props.name) ? '12px' : 'initial'};
    color: ${props => (props.labelColor) ? props.labelColor : '#212529'} !important;

    &:focus + label {
        padding: 0px; 
        position: absolute; 
        top: 8px; 
        left: 12px; 
        /*color: rgb(108, 115, 120);*/ 
        font-size: 15px; 
        line-height: 20px; 
        font-weight: normal; 
        transition: all 0.25s ease-in-out 0s; 
        transform: translate(1px, -6px) scale(0.75); 
        transform-origin: left top;
    }

    
    &:disabled + label {
        padding: 0px; 
        position: absolute; 
        top: 8px; 
        left: 12px; 
        /*color: rgb(108, 115, 120);*/
        font-size: 15px; 
        line-height: 20px; 
        font-weight: normal; 
        transition: all 0.25s ease-in-out 0s; 
        transform: translate(1px, -6px) scale(0.75); 
        transform-origin: left top;
    }

    &:not([value=""]) + label {
        padding: 0px; 
        position: absolute; 
        top: 8px; 
        left: 12px; 
        /*color: rgb(108, 115, 120);*/ 
        font-size: 15px; 
        line-height: 20px; 
        font-weight: normal; 
        transition: all 0.25s ease-in-out 0s; 
        transform: translate(1px, -6px) scale(0.75); 
        transform-origin: left top;
    }

    &[type="date"] + label {
        padding: 0px; 
        position: absolute; 
        top: 8px; 
        left: 12px; 
        /*color: rgb(108, 115, 120);*/
        font-size: 15px; 
        line-height: 20px; 
        font-weight: normal; 
        transition: all 0.25s ease-in-out 0s; 
        transform: translate(1px, -6px) scale(0.75); 
        transform-origin: left top;
    }

    
    &[type="datetime-local"] + label {
        padding: 0px; 
        position: absolute; 
        top: 8px; 
        left: 12px; 
        /*color: rgb(108, 115, 120);*/ 
        font-size: 15px; 
        line-height: 20px; 
        font-weight: normal; 
        transition: all 0.25s ease-in-out 0s; 
        transform: translate(1px, -6px) scale(0.75); 
        transform-origin: left top;
    }
`

const Label = styled.label`
    padding: 0px; 
    position: absolute; 
    top: ${props => (!isNullOrUndefined(props.text) && props.text.length > 0) ? '8px' : '16px'}; 
    left: ${props => (!isNullOrUndefined(props.text) && props.text.length > 0) ? '12px' : '22px'}; 
    color: ${props => (props.labelColor) ? props.labelColor : 'rgb(108, 115, 120)'}; 
    font-size: 15px; 
    line-height: 20px;
    font-weight: normal; 
    transition: all 0.25s ease-in-out 0s; 
    transform: ${props => (!isNullOrUndefined(props.text) && props.text.length > 0) ? 'translate(1px, -6px) scale(0.75)' : 'translate3d(0px, 0px, 0px)'}; 
    transform-origin: left top;
    z-index:10;
`

const InputGroupSpan = styled.span`
    background-color: transparent;
    border-left: 0px;
    border-top: 1px solid #ced4da;
    border-radius: 0;
    border-bottom: 1px solid #ced4da;
    border-right: 0px;
    cursor: pointer;
    position: relative;
    left: -30px;
    padding: 0px;
    z-index:10;
`

const DivRelative = styled.div`
    position:relative;
`

class TextInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //inputTextValue: (this.props.value) ? this.props.value : '',
            type: props.type,
        }
        this.textInput = React.createRef();
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        /*
        let newValue = this.state.inputTextValue;
        if(prevProps.value !== this.props.value) {
            newValue = this.props.value;
        }
        else if(prevState.inputTextValue !== this.state.inputTextValue) {
            newValue = this.state.inputTextValue;
        }
        if(prevProps.value !== this.props.value || this.state.inputTextValue !== this.props.value) {
            this.setState({
                inputTextValue: newValue //this.props.value
            });
            this.textInput.current.value = newValue; //this.props.value;
        } 
        */
    }

    onChangedHandler(event) {
        /*
        this.setState({
            inputTextValue: this.textInput.current.value
        });
        */
        if(this.props.onChange) {
            event.preventDefault()
            this.props.onChange(event);
        }
    }

    onKeyDownHandler(event) {
        if(event.key === "Enter" && !event.shiftKey && this.props.submitEvent) {
            this.props.submitEvent(event);
        }
    }

    onBlurHandler() {
        if(this.props.onBlur) {
            this.props.onBlur(event);
        }
    }    

    focusInput() {
        this.textInput.current.focus();
    }

    onShowHidePasswordClick() {
        if(this.state.type === "password") {
            this.setState({
                type: "text"
            });
        }
        else {
            this.setState({
                type: "password"
            });
        }
    }

    render() {
        return (
            <>
            {
                this.props.type === "password" &&
                <div className="input-group">
                    <Input 
                        className={"form-control " + ((this.props.additionalClasses) ? this.props.additionalClasses : "")}
                        type={this.state.type}
                        defaultValue={ /*this.state.inputTextValue*/this.props.value }
                        disabled={(this.props.disabled) ? true : undefined }
                        required={(this.props.disabled) ? true : undefined }
                        onChange={this.onChangedHandler.bind(this)}
                        onKeyDown={this.onKeyDownHandler.bind(this)}
                        onBlur={this.onBlurHandler.bind(this)}
                        ref={this.textInput}
                        autoComplete="off"
                        name={(this.props.name) ? this.props.name : undefined }
                        autoFocus={(this.props.autoFocus) ? true : undefined }
                        min={(this.props.min) ? this.props.min : undefined }
                        max={(this.props.max) ? this.props.max : undefined }
                        step={(this.props.step) ? this.props.step : undefined }
                        list={(this.props.list) ? this.props.list : undefined }
                        style={(this.props.additionalStyles) ? this.props.additionalStyles : undefined }
                        pattern={(this.props.pattern) ? this.props.pattern : undefined}
                        //placeholder={this.props.placeholder}
                        labelColor={this.props.labelColor}
                    />
                    <InputGroupSpan className="input-group-text" onClick={this.onShowHidePasswordClick.bind(this)}><img src={this.state.type === "password" ? eyeIcon : eyeSlashIcon} alt="Show/Hide password" /></InputGroupSpan>
                    {
                        this.props.placeholder && this.props.name && 
                        <Label onClick={this.focusInput.bind(this)} text={/*this.state.inputTextValue*/this.props.value} htmlFor={this.props.name} labelColor={this.props.labelColor}>{this.props.placeholder}</Label>
                    }
                </div>
            }
            {
                this.props.type !== "password" &&
                <DivRelative>
                    <Input 
                        className={"form-control " + ((this.props.additionalClasses) ? this.props.additionalClasses : "")}
                        type={this.props.type}
                        defaultValue={ /*this.state.inputTextValue*/this.props.value }
                        disabled={(this.props.disabled) ? true : undefined }
                        required={(this.props.disabled) ? true : undefined }
                        onChange={this.onChangedHandler.bind(this)}
                        onKeyDown={this.onKeyDownHandler.bind(this)}
                        onBlur={this.onBlurHandler.bind(this)}
                        ref={this.textInput}
                        autoComplete="off"
                        name={(this.props.name) ? this.props.name : undefined }
                        autoFocus={(this.props.autoFocus) ? true : undefined }
                        min={(this.props.min) ? this.props.min : undefined }
                        max={(this.props.max) ? this.props.max : undefined }
                        step={(this.props.step) ? this.props.step : undefined }
                        list={(this.props.list) ? this.props.list : undefined }
                        style={(this.props.additionalStyles) ? this.props.additionalStyles : undefined }
                        //placeholder={this.props.placeholder}
                        labelColor={this.props.labelColor}
                        maxLength={this.props.maxLength ? this.props.maxLength : undefined}
                    />
                    {
                        this.props.inputGroupAfterContent &&
                        <InputGroupSpan className="input-group-text">
                            {
                                this.props.inputGroupAfterContent
                            }
                        </InputGroupSpan>
                    }
                    {
                        this.props.placeholder && this.props.name && 
                        <Label 
                            onClick={this.focusInput.bind(this)} 
                            text={/*this.state.inputTextValue*/this.props.value} 
                            htmlFor={this.props.name} 
                            labelColor={this.props.labelColor}
                        >
                            {
                                this.props.placeholder
                            }
                        </Label>
                    }
                </DivRelative>
            }
            </>
	    );
	}
}

export default TextInput;