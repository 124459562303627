import React, { useState, useEffect } from 'react';
import ErrorBoundary from '../../../../error/error.component';
import FallbackUI from '../../../../error/standardFallback.component';
import { FadeIn } from '../../../../styled/fadeIn.component';
import { Bold } from '../../../../styled/bold.component';
import Language from '../../../../../constants/language';
import { LEGAL_STATUS_TYPE_PRIVATE, LEGAL_STATUS_TYPE_COMPANY } from '../../../../../constants/legalStatusTypes';
import { useSelector } from 'react-redux';

function Summary(props) {
    const language = useSelector((state) => state.language.value);

    return (
        <FadeIn>
            <div className="row">
                <div className="col">
                    <Bold>
                        {
                            Language[language].shop.summary
                        }
                    </Bold>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    {
                        `${Language[language].shop.legalStatus}: `
                    }
                    {
                        props.accountData.legalStatus === LEGAL_STATUS_TYPE_COMPANY &&
                        Language[language].shop.company
                    }
                    {
                        props.accountData.legalStatus === LEGAL_STATUS_TYPE_PRIVATE &&
                        Language[language].shop.private
                    }
                </div>
            </div>
            <div className="row mt-1">
                <div className="col">
                    <address>
                        {
                            props.accountData.legalStatus === LEGAL_STATUS_TYPE_COMPANY &&
                            props.accountData.addressData.companyName
                        }
                        {
                            props.accountData.legalStatus === LEGAL_STATUS_TYPE_PRIVATE &&
                            `${props.accountData.addressData.firstName} ${props.accountData.addressData.lastName}`
                        }
                        <br />
                        {`${props.accountData.addressData.street}`}
                        <br />
                        {`${props.accountData.addressData.postalCode} ${props.accountData.addressData.city}`}
                    </address>
                </div>
            </div>
            {
                props.accountData.isLiableToPaySalesTax &&
                <div className="row mt-1">
                    <div className="col">
                        {`${Language[language].shop.vatNoAbbrevation}: ${props.accountData.vatNo}`}
                    </div>
                </div>
            }
            <div className="row mt-1">
                <div className="col">
                    {`${Language[language].shop.paypalEmailAddress}: ${props.accountData.paypalEmailAddress}`}
                </div>
            </div>
        </FadeIn>
    )
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"summary.component.js"}
            fallbackUI={<FallbackUI language={language} component={"summary"} />}
        >
            <Summary
                key={`businessSetupSummaryComponent`}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;