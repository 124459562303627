import React, { Component } from 'react';
import Language from '../../constants/language';
import { format } from 'date-fns';
import parseISO from 'date-fns/parseISO'
import styled from 'styled-components';

const CalendarIcon = styled.div`
    border: 1px solid #021030;
    border-radius:1.5rem;
    text-decoration:none;
    font-size:1.5rem;
    color:#021030;
    border-top: 1.8rem solid #021030;
    max-width:125px;
    min-width:105px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

    :hover {
        text-decoration:none !important; 
    }
` 

const CalendarIconTop = styled.div`
    font-size: x-large;
    display: flex;
    text-align: center;
    justify-content: center;
    background-color: #021030;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    color: #fff;
    min-width: 90px;
    max-width: 120px;

    @media (max-width: 768px) {
        font-size: x-large;
        max-width: 90px;
    }
` 

const CalendarIconBottom = styled.div`
    font-size: xx-large;
    display: flex;
    text-align: center;
    justify-content: center;
    background-color: #fff;
    border-bottom-left-radius: 0.7rem;
    border-bottom-right-radius: 0.7rem;
    border: 1px solid;
    color: #021030;
    padding-bottom: 0.5rem;
    font-weight: bold;
    min-width: 90px;
    max-width: 120px;

    @media (max-width: 768px) {
        font-size: x-large;
        max-width: 90px;
    }
` 

class CalendarDay extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    onClickHandler(event) {
        event.preventDefault();
        this.props.onClicked(event);
    }    

    getMonthByDate(date) {
        let monthObj;
        let monthArray = Language[this.props.language].time.months.filter((langMonth) => {
            if(String(langMonth.number) === format(new Date(date), 'M')) {
                return langMonth;
            }
        });
        if(monthArray != undefined && monthArray.length > 0) {
            monthObj = monthArray[0];
        }
        return monthObj;
    }

    getDayByDate(date, showDateEnding) {
        let day = format(new Date(date), 'd');
        if(showDateEnding) {
            if(this.props.language === 'en') {
                if(day === "1") {
                    day += 'st';
                }
                else if(day === "2") {
                    day += 'nd';
                }
                else if(day === "3") {
                    day += 'rd';
                }
                else {
                    day += 'th';
                }
            }
            else {
                day += '.';
            }
        }
        return day;
    }

    render() {
        //let date = format(new Date(parseISO(this.props.date)), 'dd.MM.yyyy');
        if(this.props.date !== undefined) {
            let day = this.getDayByDate(parseISO(this.props.date), true);
            let dayWithoutEnding = this.getDayByDate(parseISO(this.props.date), false);
            let month = this.getMonthByDate(parseISO(this.props.date));
            let year = format(new Date(parseISO(this.props.date)), 'yyyy');
            if(this.props.showAsCalendarIcon) {
                return (
                    <>
                        <CalendarIconTop>{ month.shortName }</CalendarIconTop>
                        <CalendarIconBottom>{ dayWithoutEnding }</CalendarIconBottom>
                    </>
                );
            }
            else {
                return (
                    <span>
                        {
                            day + ' ' + month.name + ' ' + year
                        }
                    </span>
                );
            }
        }
        else {
            return null;
        }
	}
}

export default CalendarDay;
