import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: [],
};

export const rowTypesSlice = createSlice({
  name: 'rowTypes',
  initialState,
  reducers: {
    setRowTypes: (state, action) => {
      state.value = action.payload
    },
  },
});

export const { setRowTypes } = rowTypesSlice.actions;

export default rowTypesSlice.reducer;