import React, { useState } from 'react';
import styled from 'styled-components';
import ErrorBoundary from '../error/error.component';
import FallbackUI from '../error/standardFallback.component';
import Language from '../../constants/language';
import Button from '../button.component';
import * as Data from '../../backend/data';
import * as MessageTypes from '../../constants/messagetypes';
import { EditOptionsBackground } from '../styled/editOptionsBackground.component';
import { Icon } from '../styled/icon.component';
import LoadingSpinner from '../../components/loadingSpinner.component';
import { isNullOrUndefined } from '../../utils/utils';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentLandingPage } from '../../reducers/slices/currentLandingPage.slice';
import { setMessage } from '../../reducers/slices/message.slice';

const HeaderRow = styled.div`
    margin:1rem;
`

const AddNewRowColItem = styled.div`
    padding-left:0.5rem;
    paddding-right:0.5rem;
    margin-bottom:0.2rem;
    border-radius:0.5rem;
    cursor:pointer;

    &:hover {
        background-color:rgb(59 63 66);
    }
`

function RowAdd(props) {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);
    const csrfToken = useSelector((state) => state.csrfToken.value);
    const currentLandingPage = useSelector((state) => state.currentLandingPage.value);
    const rowTypes = useSelector((state) => state.rowTypes.value);

    const [isLoading,setIsLoading] = useState(false);

    function onAddNewRowTypeClick(newRowTypeId) {
        setIsLoading(true);
        let formData = [
            {
                name: 'csrfToken',
                value: csrfToken
            },
            {
                name: 'landingPageId',
                value: currentLandingPage.id
            },
            {
                name: 'newRowTypeId',
                value: newRowTypeId
            },
            {
                name: 'newRowNo',
                value: (props.row.row_no + 1)
            }
        ];
        Data.transfer(window.baseUrl + '/builder/user/landingPage/rows/add/new','POST',formData,null,null,csrfToken)
        .then((data) => {
            if(data.success && data.landingPage) {
                dispatch(setCurrentLandingPage(data.landingPage));
                props.onRowAdded();
            }
        })
        .catch((error) => {
            dispatch(setMessage({
                message: error.errors?.join(' '),
                messageType: MessageTypes.MESSAGE_TYPE_ERROR
            }));
            setIsLoading(false);
        });
    }

    return (
        <>
            <HeaderRow className="row d-flex justify-content-center">
                <EditOptionsBackground>
                    {
                        isLoading &&
                        <LoadingSpinner 
                            key="loadingSpinnerEvents" 
                            color={'#fff'}
                        />
                    }
                    {
                        !isLoading &&
                        <>
                            <div className="row d-flex justify-content-center">
                                {
                                    Language[language].editor.createNewRow
                                }
                            </div>
                            <div className="col d-flex justify-content-center">
                            {
                                !isNullOrUndefined(rowTypes) && rowTypes.map((rowType) => {
                                    let image = isNullOrUndefined(rowType.row_type_image) ? null : require(`../../assets/images/${rowType.row_type_image}`).default;
                                    return (
                                        <AddNewRowColItem 
                                            className="text-center"
                                            key={`rowType${rowType.id}`}
                                            onClick={(e) => onAddNewRowTypeClick(rowType.id)}
                                        >
                                            {
                                                !isNullOrUndefined(image) &&
                                                <Icon 
                                                    image={image} 
                                                    hoverBackgroundColor={'#fff'}
                                                    cursor={'pointer'}
                                                />
                                            }
                                            <small>{rowType.option_text}</small>
                                        </AddNewRowColItem>
                                    )
                                })
                            }
                            </div>
                        </>
                    }
                </EditOptionsBackground>
            </HeaderRow>
        </>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"rowAdd.component.js"}
            fallbackUI={<FallbackUI language={language} 
            component={"rowAdd"} />}
        >
            <RowAdd
                key={`rowAddComponent${props.row.id}`}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;