import React, { Component } from 'react';
import styled from 'styled-components';

const FlexButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content !important;
    transition: all .2s;
    color: ${props => props.color ? props.color : '#000'} !important;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : '#f8f9fa'} !important;

    &:before {
        content:'';
        position:absolute;
        bottom:0;
        left:0;
        width:100%;
        height:0%;
        /*background-color: ${props => props.hoverBackgroundColor ? props.hoverBackgroundColor : '#cbced1'} !important;*/
        background: linear-gradient(${props => props.hoverBackgroundColor ? props.hoverBackgroundColor : '#ffc8c8'}, #f8f9fa);
        transition:all .2s;
        z-index:-1;
        border-radius: 0.375rem;
    }

    &:hover {
        color: ${props => props.hoverColor ? props.hoverColor : '#000'} !important;
        border:1px solid #8d8d8d;
        filter: grayscale(20%);
        &:before {
            height: 100%;
        }
    }
`

const Icon = styled.div`
    margin-right:0.25rem;
    padding-right:0.5rem;
    min-width:${props => props.minSize ? props.minSize : '50px'};
    min-height:${props => props.minSize ? props.minSize : '50px'};
    color:#fff;
    background-color:${props => props.maskColor ? props.maskColor : '#fff'};
    -webkit-mask-image: url(${props => props.image});
    mask-image: url(${props => props.image});
    -webkit-mask-size: cover;
    mask-size:cover;
    background-size: cover;
    background-position: center;
` 

class Button extends Component {

    constructor(props) {
        super(props);

        this.textInput = React.createRef();
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    onClickHandler(event) {
        if(this.props.onClicked) {
            event.preventDefault();
            this.props.onClicked(event);
        }
    }    

    render() {
        const additionalTagsArray = Object.entries(this.props).filter((prop) => { if(prop[0].startsWith('data') || prop[0].startsWith('aria')) { return prop; } });
        const additionalTags = Object.fromEntries(additionalTagsArray);
        return (
            <FlexButton 
                className={"form-control btn btn-light " + ((this.props.additionalClasses) ? this.props.additionalClasses : "")}
                onClick={this.onClickHandler.bind(this)}
                disabled={(this.props.disabled) ? true : undefined }
                style={(this.props.additionalStyles) ? this.props.additionalStyles : undefined }
                backgroundColor={this.props.backgroundColor}
                color={this.props.color}
                hoverBackgroundColor={this.props.hoverBackgroundColor}
                hoverColor={this.props.hoverColor}
                {...additionalTags}
            >
                {
                    ((this.props.icon && !this.props.showIconOnValue) || (this.props.icon && this.props.showIconOnValue === this.props.iconValue)) && 
                    <Icon image={this.props.icon} maskColor={this.props.iconColor} minSize={this.props.iconSize ? this.props.iconSize : "24px"} />
                    /*<ButtonIcon image={this.props.icon} src={this.props.icon} alt={"icon"} />*/
                }
                { 
                    this.props.buttonText 
                }
            </FlexButton>
	    );
	}
}

export default Button;