import React, { useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import Selectbox from '../../../../selectbox.component';
import ErrorBoundary from '../../../../error/error.component';
import FallbackUI from '../../../../error/standardFallback.component';
import { FadeIn } from '../../../../styled/fadeIn.component';
import Language from '../../../../../constants/language';
import * as ProductTypes from '../../../../../constants/productTypes';
import { isNullOrUndefined } from '../../../../../utils/utils';
import { useSelector, useDispatch } from 'react-redux';
import { setProductSetupWizard } from '../../../../../reducers/slices/productSetupWizard.slice';
import { useProductTypeSetupWizard } from '../../../../../factories/productSetup.factory';

function SelectProductType(props) {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);
    const productSetupWizard = useSelector((state) => state.productSetupWizard.value);

    const [productType, setProductType] = useState(props.productType);

    const { updateProduct } = useProductTypeSetupWizard();

    function checkProductTypeValue(event) {
        /*
        0: "digitalProduct"
        1: 
            generatesLinkAfterPurchase: true
            hasColor: false 
            hasDate: false
            id: 3
            isActive: true
        */
        let selectedProductTypeValue = parseInt(event.target.value);
        let productType = Object.entries(ProductTypes.ProductTypes).find((productType) => productType[1].id === selectedProductTypeValue);
        if(productType !== undefined) {
            let product = _.cloneDeep(productSetupWizard.product);
            product = updateProduct(product, { type: 'product_type_id', value: selectedProductTypeValue });
            let updatedWizard = { step: productSetupWizard.step+1, product: product};
            dispatch(setProductSetupWizard(updatedWizard));
            props.onReturnData(event.target.value);
        }
    }

    return (
        <FadeIn>
            <div className="row">
                <div className="col">
                    {
                        Language[language].shop.wizard.product.selectProductTypeText
                    }
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <Selectbox
                        key={"productType"}
                        name={"productType"}
                        label={Language[language].shop.wizard.product.productType}
                        value={productType}
                        valueField={"id"}
                        displayField={"name"}
                        options={_.orderBy(props.productTypes,['name'])}
                        onChange={checkProductTypeValue}
                        onBlur={checkProductTypeValue}
                    />
                </div>
            </div>
        </FadeIn>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"selectProductType.component.js"}
            fallbackUI={<FallbackUI language={language} component={"selectProductType"} />}
        >
            <SelectProductType
                key={`selectProductTypeComponent`}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;