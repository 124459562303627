import React, { Component } from "react";
import ReactDOM from "react-dom";
import ErrorBoundary from '../../components/error/error.component';
import FallbackUI from '../../components/error/standardFallback.component';

class LandingPageView extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
    render() {
        let View;
        switch(this.props.guestLandingPage.landing_page.landing_page_template.view) {
            case './event/wedding/classic.view':
                View = require('./event/wedding/classic.view').default; //require('./test.view').default; 
                break;
            case './event/invitation/standard.view':
                View = require('./event/invitation/standard.view').default;
                break;
            case './page/standard.view':
                View = require('./page/standard.view').default; 
                break;
            case './event/wedding/invitation.view.js':
                View = require('./event/wedding/invitation.view.js').default; 
                break;
            default:
                break;
        } 
        return (
            <>
                {
                    View !== undefined &&
                    <View {...this.props}></View>
                }
            </>
        );
    }
}


const ComponentWithErrorBoundary = (props) =>  {
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"landingPage.view.js"}
            fallbackUI={<FallbackUI language={props.language} component={"landingPageView"} />}
        >
            <LandingPageView
                key={"landingPageViewComponent"}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary; //LandingPageView;