import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: [],
};

export const columnTypesSlice = createSlice({
  name: 'columnTypes',
  initialState,
  reducers: {
    set: (state, action) => {
      state.value = action.payload
    },
  },
});

export const { set } = columnTypesSlice.actions;

export default columnTypesSlice.reducer;