import React, { useState, useEffect, useReducer } from 'react';
import ErrorBoundary from '../../../error/error.component';
import FallbackUI from '../../../error/standardFallback.component';
import Button from '../../../button.component';
import Language from '../../../../constants/language';
import * as Data from '../../../../backend/data';
import SuccessIcon from '../../../successIcon.component';
import { isNullOrUndefined, usePrevious } from '../../../../utils/utils';
import SelectProductType from './productSetupWizard/selectProductType.component';
import { useProductTypeSetupWizard } from '../../../../factories/productSetup.factory';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { setProductSetupWizard, resetState } from '../../../../reducers/slices/productSetupWizard.slice';
import { setMessage } from '../../../../reducers/slices/message.slice';
import { increaseStep, decreaseStep } from '../../../../reducers/slices/productSetupWizard.slice';
import { addOrUpdateProduct } from '../../../../reducers/slices/currentLandingPage.slice';
import { getErrorMessage } from '../../../../utils/error';
import * as MessageTypes from '../../../../constants/messagetypes';
import ProgressBar from '../../../progressBar.component';

import backIcon from '../../../../assets/images/skip-backward.svg';
import nextIcon from '../../../../assets/images/skip-forward.svg';
import saveIcon from '../../../../assets/images/check2-square.svg';
import cancelIcon from '../../../../assets/images/x-square.svg';

const initialState = {step: 1};

function ProductSetupWizard(props) {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);
    const csrfToken = useSelector((state) => state.csrfToken.value);
    const productSetupWizard = useSelector((state) => state.productSetupWizard.value);
    const productTypes = useSelector((state) => state.productTypes.value);
    const currentLandingPage = useSelector((state) => state.currentLandingPage.value);
    const user = useSelector((state) => state.user.value);

    const [minStep, setMinStep] = useState(1);
    const [isBackButtonDisabled, setIsBackButtonDisabled] = useState(false);
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
    const [savedSuccessfully, setSavedSuccessfully] = useState(false);
    const [lastStep, setLastStep] = useState(999);

    const [productTypeId, setProductTypeId] = useState(props.product ? props.product.product_type_id : null);
    const [productSetupController, setProductSetupController] = useState(null);

    const previousStep = usePrevious(productSetupWizard.step);

    const { getController } = useProductTypeSetupWizard();

    function onNextClick() {
        increaseDecreaseStep('+');
    }

    function onBackClick() {
        increaseDecreaseStep('-');
    }

    function onCancelClick() {
        props.navigateBackToProductList();
    }

    function onSaveClick() {
        let formData = [
            {
                name: 'landingPageId',
                value: currentLandingPage.id
            },
            {
                name: 'product',
                value: JSON.stringify(productSetupWizard.product)
            }
        ];
        Data.transfer(window.baseUrl + '/builder/user/product/save','POST',formData,null,null,csrfToken)
        .then((data) => {
            if(data.success) {
                setSavedSuccessfully(true);
                dispatch(addOrUpdateProduct({product:data.product}));
                dispatch(resetState());
            }
        })
        .catch((error) => {
            dispatch(setMessage({
                message: getErrorMessage(error),
                messageType: MessageTypes.MESSAGE_TYPE_ERROR
            }));
        });
    }

    function increaseDecreaseStep(direction, withDelay = false) {
        if(direction === '+') {
            let timeout = 0;
            if(withDelay) {
                timeout = 250;
            }
            window.setTimeout(() => {
                dispatch(increaseStep());
            },timeout);
        }
        else if(direction === '-') {
            dispatch(decreaseStep());
        }
    }

    function checkIsNextButtonDisabled() {
        switch(productSetupWizard.step) {
            case 1:
                return false;
            default:
                if(!isNullOrUndefined(productSetupController)) {
                    return productSetupController.isNextButtonDisabled(productSetupWizard);
                }
        }
    }

    function showComponent() {
        switch(productSetupWizard.step) {
            case 1:
                return (
                    <SelectProductType 
                        key={"productSetupWizardSelectProductType"}
                        productTypes={productTypes}
                        productType={productTypeId}
                        onReturnData={(productTypeId) => {
                            setProductTypeId(productTypeId);
                        }}
                    />
                )
            default:
                if(!isNullOrUndefined(productSetupController)) {
                    return productSetupController.getComponent(productSetupWizard);
                }
                return <></>
        }
    }

    function needsNextStepToBeSkipped() {
        // forwards: 5 - 4 = 1
        // backwards: 4 - 5 = -1
        const direction = (productSetupWizard.step - previousStep) >= 0 ? '+' : '-';
        if(!isNullOrUndefined(productSetupController)) {
            if(productSetupController.needsNextStepToBeSkipped(productSetupWizard, user)) {
                increaseDecreaseStep(direction);
            }
        }
    }

    useEffect(() => {
        needsNextStepToBeSkipped();
        setIsNextButtonDisabled(checkIsNextButtonDisabled());
    },[productSetupWizard.step, productSetupController]);

    useEffect(() => {
        setIsNextButtonDisabled(checkIsNextButtonDisabled());
    },[productSetupWizard]);

    useEffect(() => {
        setProductSetupController(getController(productTypeId));
    },[productTypeId]);

    useEffect(() => {
        if(!isNullOrUndefined(productSetupController)) {
            setLastStep(productSetupController.getMaxStep());
        }
    },[productSetupController]);

    useEffect(() => {
        if(props.product) {
            dispatch(setProductSetupWizard({step: 1, product: props.product}));
        }
    },[])

    return (
        <>
            <div className="container h-100">
                <div className="row mt-2">
                    <div className="col text-center fw-bold">
                        {
                            Language[language].shop.wizard.product.headerText
                        }
                    </div>
                </div>
                {
                    savedSuccessfully === false &&
                    <div className="row mt-2">
                        <div className="col text-center">
                            <ProgressBar
                                currentValue={productSetupWizard.step}
                                maxValue={lastStep}
                            />
                        </div>
                    </div>
                }
                <div className="row mt-3 h-100 text-center d-flex justify-content-center align-items-center">
                    {
                        savedSuccessfully === true &&
                        <div className="col d-flex justify-content-center">
                            <SuccessIcon />
                        </div>
                    }
                    {
                        savedSuccessfully === false &&
                        showComponent()
                    }
                </div>
                
            </div>
            <div className="container">
                <div className="row mt-3 mb-2">
                    <div className="col d-flex justify-content-center">
                        <Button
                            key={"productSetupCancelButton"}
                            buttonText={savedSuccessfully === true ? Language[language].shop.wizard.product.backToProductList : Language[language].general.cancel}
                            additionalClasses={"w-50"}
                            onClicked={onCancelClick}
                            icon={savedSuccessfully === true ? backIcon : cancelIcon} 
                            iconColor={"#000"}
                        />
                    </div>
                    {
                        savedSuccessfully === false && productSetupWizard.step !== minStep &&
                        <div className="col d-flex justify-content-center">
                            <Button
                                key={"productSetupBackButton"}
                                buttonText={Language[language].general.back}
                                additionalClasses={"w-50"}
                                onClicked={onBackClick}
                                disabled={isBackButtonDisabled}
                                icon={backIcon} 
                                iconColor={"#000"}
                            />
                        </div>
                    }
                    {
                        savedSuccessfully === false && productSetupWizard.step !== lastStep &&
                        <div className="col d-flex justify-content-center">
                            <Button
                                key={"productSetupNextButton"}
                                buttonText={Language[language].general.next}
                                additionalClasses={"w-50"}
                                onClicked={onNextClick}
                                disabled={isNextButtonDisabled}
                                icon={nextIcon} 
                                iconColor={"#000"}
                            />
                        </div>
                    }
                    {
                        savedSuccessfully === false && productSetupWizard.step === lastStep &&
                        <div className="col d-flex justify-content-center">
                            <Button
                                key={"productSetupSaveButton"}
                                buttonText={Language[language].general.save}
                                additionalClasses={"w-50"}
                                onClicked={onSaveClick}
                                //disabled={false}
                                icon={saveIcon} 
                                iconColor={"#000"}
                            />
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"productSetupWizard.component.js"}
            fallbackUI={<FallbackUI language={language} component={"productSetupWizard"} />}
        >
            <ProductSetupWizard
                key={`productSetupWizardComponent`}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;