import React, { useMemo, useState, useEffect, useCallback, useRef } from "react";
import styled from "styled-components";
import { createEditor, Editor, Transforms, Text, Node, Element, Descendant, Range } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import ErrorBoundary from './error/error.component';
import FallbackUI from './error/standardFallback.component';
import { isNullOrUndefined, isJsonString } from "../utils/utils";
import Button from './button.component';
import TextInput from "./textInput.component";
import { isUrlText, isEmailAddressText } from './app/textReplacement.component';
import { StyledSpan, Link } from './app/textReplacement.component';
import Language from "../constants/language";

import boldIcon from '../assets/images/type-bold.svg';
import italicIcon from '../assets/images/type-italic.svg';
import underlineIcon from '../assets/images/type-underline.svg';
import strikethroughIcon from '../assets/images/type-strikethrough.svg';
import mailIcon from '../assets/images/envelope.svg';
import urlIcon from '../assets/images/globe.svg';
import textLeftIcon from '../assets/images/text-left.svg';
import textCenterIcon from '../assets/images/text-center.svg';
import textRightIcon from '../assets/images/text-right.svg';
import deleteImageIcon from '../assets/images/trash.svg';

const MODAL_STATE_CLOSED = 0;
const MODAL_STATE_OPEN_LINK = 1;
const MODAL_STATE_OPEN_EMAIL = 2;

const FontSize0 = styled.span`
    font-size: 0;
`

const TextEditor = styled.div`
    background-color:#fff;
    border-radius: 0.25rem;
    border: 1px solid #ddd;
    cursor: text;
    padding:0.5rem;
`;


const OptionsModalBackground = styled.div`
    position: fixed;
    top:0px;
    left:0px;
    width: 100%;
    height: 100%;
    background-color:#6c757d;
    opacity:0.6;
    z-index:1000;
` 

const OptionsModalDivCloseButton = styled.div`
    margin:0.5rem;
    position:absolute;
    right:5px;
    top:5px;
` 

const OptionsModalDiv = styled.div`
    position:fixed;
    top:50%;
    left:50%;
    width:50%;
    margin-left:-25%;
    height:fit-content;
    margin-top:-100px;
    background-color:#fff;
    z-index:1001;
    border-radius:0.25rem;
` 

const HiddenSpan = styled.span`
    display:none;
`

const InvalidErrorDiv = styled.div`
    display:${props => props.isValid ? 'none' : 'block'};
`

const DefaultElementParagraph = styled.p`
    margin:0;
`

const withInlines = editor => {
    const { insertData, insertText, isInline } = editor;
  
    editor.isInline = element => ['link','email','span'].includes(element.type) || isInline(element);
  
    // Event is being called when text is entered by keyboard
    editor.insertText = text => {
        if(text && isEmailAddressText(text)) {
            wrapElement(editor, text, text, 'email')
        }
        else if (text && isUrlText(text)) {
            wrapElement(editor, text, text, 'link');
        } 
        else {
            insertText(text);
        }
    }
  
    //Event is being called when text is copied in
    editor.insertData = data => {
        const text = data.getData('text/plain')
        if(text && isEmailAddressText(text)) {
            wrapElement(editor, text, text, 'email')
        } 
        else if (text && isUrlText(text)) {
            wrapElement(editor, text, text,'link');
        } 
        else {
            insertData(data)
        }
    }
  
    return editor
}

const serialize = nodes => {
    return nodes.map(node => Node.string(node)).join(' ')
}

const insertElement = (editor, url, text, type) => {
    //if (editor.selection) {
    wrapElement(editor, url, text, type);
    //}
}
  
const isLinkActive = editor => {
    const [link] = Editor.nodes(editor, {
      match: n =>
        !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link',
    })
    return !!link
}

const isEmailActive = editor => {
    const [email] = Editor.nodes(editor, {
      match: n =>
        !Editor.isEditor(n) && Element.isElement(n) && n.type === 'email',
    })
    return !!email
}

function unwrapElement(editor, type) {
    Transforms.unwrapNodes(editor, 
        {
            match: n =>
            !Editor.isEditor(n) && Element.isElement(n) && n.type === type,
        }
    );
}

const wrapElement= (editor, value, text, type) => {
    if (isLinkActive(editor)) {
        unwrapElement(editor, 'link');
    }
    if (isEmailActive(editor)) {
        unwrapElement(editor, 'email');
    }
  
    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    let newElement;
    if(type === 'link') {
        newElement = {
            type: type,
            url: value,
            //children: isCollapsed ? [{ text: text }] : [],
            children: [{ text: text }],
        }
    }
    else if(type === 'email') {
        newElement = {
            type: type,
            email: value,
            //children: isCollapsed ? [{ text: text }] : [],
            children: [{ text: text }],
        }
        /*
        let emailUntilAtSign = value.substring(0,value.indexOf('@'))?.replaceAll('.','(dot)');
        let emailFromAtSignTillEnd = value.substring(value.indexOf('@')+1,value.length)?.replaceAll('.','(dot)');
        
        let spanElement = {
            type: 'span',
            children: [{text: (Math.random() + 1).toString(36).substring(2,9) }],
        }

        newElement = {
            type: type,
            email: value,
            //children: isCollapsed ? [{ text: text }] : [],
            //children: [{text: `${emailUntilAtSign}(at)${emailFromAtSignTillEnd} ` }],
            children: [{text: `${emailUntilAtSign}(at)`},spanElement,{text: `${emailFromAtSignTillEnd}`}],
        }
        */
    }
  
    if(editor.selection !== null) {
        Transforms.delete(editor, { at: editor.selection });
    }
    Transforms.insertNodes(editor, newElement);

    /*
    if (isCollapsed) {
        Transforms.insertNodes(editor, newElement)
    } 
    else {
        Transforms.wrapNodes(editor, newElement, { split: true });
        Transforms.collapse(editor, { edge: 'end' });
    }
    */
}

export const useTextElements = () => {
    const renderElement = useCallback(props => {
        switch (props.element.type) {
            case 'link':
                return <UrlLink {...props} />
            case 'email':
                return <EmailAddress {...props} />
            case 'span':
                return <Span {...props} />
            default:
                return <DefaultElement {...props} />
        }
    }, []);

    const renderLeaf = useCallback(props => {
        return <StandardLeaf {...props} />
    }, []);

    return {
        renderElement,
        renderLeaf
    };
}


function TextAreaWithEditingOptions(props) {
    const editor = useMemo(() => withInlines(withReact(createEditor())), []);
    const [textValue, setTextValue] = useState(
        isJsonString(props.value) ? 
            JSON.parse(props.value) :
            (Array.isArray(props.value)) ? 
                props.value :
                [
                    {
                        type: 'paragraph',
                        children: [{ text: props.value }],
                    },
                ]
    );
    const [newLinkText,setNewLinkText] = useState("");
    const [newLinkUrl,setNewLinkUrl] = useState("");
    const [isNewLinkUrlValid,setIsNewLinkUrlValid] = useState(true);
    const [newEmailAddress, setNewEmailAddress] = useState("");
    const [newEmailText, setNewEmailText] = useState("");
    const [isNewEmailAddressValid,setIsNewEmailAddressValid] = useState(true);
    const [modalState, setModalState] = useState(MODAL_STATE_CLOSED);

    const { renderElement, renderLeaf } = useTextElements();

    function onTextEditChanged(newTextValue, event) {
        /*
        const [link] = Editor.nodes(editor, {
            match: n =>
              !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link',
        });
        if(link !== undefined) {
            let currentLinkObject = link.find(l => l.type === 'link');
            if(currentLinkObject !== undefined) {
                if(currentLinkObject.children.map(child => child.text).join('') === "") {
                    Transforms.unwrapNodes(editor, 
                        {
                            match: n =>
                            !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link',
                        }
                    );
                }
            }
        }
        */
        if(isTextElementEmpty(editor,'link')) {
            Transforms.unwrapNodes(editor, 
                {
                    match: n =>
                    !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link',
                }
            );
        }
        if(isTextElementEmpty(editor,'email')) {
            Transforms.unwrapNodes(editor, 
                {
                    match: n =>
                    !Editor.isEditor(n) && Element.isElement(n) && n.type === 'email',
                }
            );
        }
        setTextValue(newTextValue);
        props.onChange(newTextValue, serialize(newTextValue));
    }

    function isTextElementEmpty(editor,type) {
        let returnValue = false;
        const [element] = Editor.nodes(editor, {
            match: n =>
              !Editor.isEditor(n) && Element.isElement(n) && n.type === type,
        });
        if(element !== undefined) {
            let currentLinkObject = element.find(l => l.type === type);
            if(currentLinkObject !== undefined) {
                if(currentLinkObject.children.map(child => child.text).join('') === "") {
                    returnValue = true;
                }
            }
        }
        return returnValue;
    }

    /*
    const renderElement = useCallback(props => {
        switch (props.element.type) {
            case 'link':
                return <UrlLink {...props} />
            case 'email':
                return <EmailAddress {...props} />
            case 'span':
                return <Span {...props} />
            default:
                return <DefaultElement {...props} />
        }
    }, []);

    const renderLeaf = useCallback(props => {
        return <StandardLeaf {...props} />
    }, []);
    */

    function onFormatTextBoldButtonClick(editor) {
        const isActive = isBoldMarkActive(editor)
        Transforms.setNodes(
            editor,
            { 
                bold: isActive ? null : true 
            },
            { 
                match: n => Text.isText(n), split: true 
            }
        )
    }

    function onFormatTextItalicButtonClick(editor) {
        const isActive = isItalicMarkActive(editor)
        Transforms.setNodes(
            editor,
            { 
                italic: isActive ? null : true 
            },
            { 
                match: n => Text.isText(n), split: true 
            }
        )
    }

    function onFormatTextUnderlineButtonClick(editor) {
        const isActive = isUnderlineMarkActive(editor)
        Transforms.setNodes(
            editor,
            { 
                underline: isActive ? null : true 
            },
            { 
                match: n => Text.isText(n), split: true 
            }
        )
    }

    function onFormatTextStrikethroughButtonClick(editor) {
        const isActive = isStrikeThroughMarkActive(editor)
        Transforms.setNodes(
            editor,
            { 
                strikethrough: isActive ? null : true 
            },
            { 
                match: n => Text.isText(n), split: true 
            }
        );
    }

    function onFormatTextEmailButtonClick(editor) {
        if(isEmailActive(editor)) {
            const [email] = Editor.nodes(editor, {
                match: n =>
                  !Editor.isEditor(n) && Element.isElement(n) && n.type === 'email',
            });
            let currentLinkObject = email.find(l => l.type === 'email');
            if(currentLinkObject !== undefined) {
                let currentEmail = currentLinkObject.email;
                let currentEmailText = currentLinkObject.children.map(child => child.text).join('');
                setNewEmailText(currentEmailText);
                setNewEmailAddress(currentEmail);
                setIsNewEmailAddressValid(currentEmail > 0 ? isEmailAddressText(currentEmail) : true);
            }
            setModalState(MODAL_STATE_OPEN_EMAIL);
        }
        else {
            setNewEmailText(getSelectedText(editor));
            setModalState(MODAL_STATE_OPEN_EMAIL);
        }
    }

    function onFormatTextUrlButtonClick(editor) {
        if(isLinkActive(editor)) {
            const [link] = Editor.nodes(editor, {
                match: n =>
                  !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link',
            });
            let currentLinkObject = link.find(l => l.type === 'link');
            if(currentLinkObject !== undefined) {
                let currentUrl = currentLinkObject.url;
                let currentLinkText = currentLinkObject.children.map(child => child.text).join('');
                setNewLinkText(currentLinkText);
                setNewLinkUrl(currentUrl);
                setIsNewLinkUrlValid(currentUrl > 0 ? isUrlText(currentUrl) : true);
            }
            setModalState(MODAL_STATE_OPEN_LINK);
        }
        else {
            setNewLinkText(getSelectedText(editor));
            setModalState(MODAL_STATE_OPEN_LINK);
        }
    }

    function onSubmitHyperlink(editor) {
        const [link] = Editor.nodes(editor, {
            match: n =>
              !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link',
        });
        if(link !== undefined) {
            Transforms.removeNodes(editor, {
                match: n =>
                !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link',
            });
        }
        insertElement(editor, newLinkUrl, newLinkText,'link');
        setModalState(MODAL_STATE_CLOSED);
    }

    function onSubmitEmail(editor) {
        const [email] = Editor.nodes(editor, {
            match: n =>
              !Editor.isEditor(n) && Element.isElement(n) && n.type === 'email',
        });
        if(email !== undefined) {
            Transforms.removeNodes(editor, {
                match: n =>
                !Editor.isEditor(n) && Element.isElement(n) && n.type === 'email',
            });
        }
        insertElement(editor, newEmailAddress, newEmailText,'email');
        setModalState(MODAL_STATE_CLOSED);   
    }

    function onFormatTextColorButtonChange(editor,event) {
        Transforms.setNodes(
            editor,
            { 
                color: true, 
                colorSelection: event.target.value 
            },
            { 
                match: n => Text.isText(n), 
                split: true 
            }
        );
    }

    function isTypeActive(editor, type) {
        const [match] = Editor.nodes(editor, {
            match: n => n.type === type,
            universal: true,
        })
        return !!match
    }

    function onTextAlignmentButtonClick(newTextAlignment) {
        if(props.onTextAlignmentChange) {
            props.onTextAlignmentChange(newTextAlignment);
        }
    }

    function isBoldMarkActive(editor) {
        const [match] = Editor.nodes(editor, {
            match: n => n.bold === true,
            universal: true,
        })
        return !!match
    }

    function isItalicMarkActive(editor) {
        const [match] = Editor.nodes(editor, {
            match: n => n.italic === true,
            universal: true,
        })
        return !!match
    }

    function isUnderlineMarkActive(editor) {
        const [match] = Editor.nodes(editor, {
            match: n => n.underline === true,
            universal: true,
        })
        return !!match
    }

    function isStrikeThroughMarkActive(editor) {
        const [match] = Editor.nodes(editor, {
            match: n => n.strikethrough === true,
            universal: true,
        })
        return !!match
    }

    function getSelectedText(editor) {
        if(editor.selection !== null) {
            return Editor.string(editor, editor.selection);
        }
        return '';
    }

    function closeModal() {
        setModalState(MODAL_STATE_CLOSED);
    }

    function changeUrl(event) {
        setNewLinkUrl(event.target.value);
        setIsNewLinkUrlValid(event.target.value.length > 0 ? isUrlText(event.target.value) : true);
    }

    function changeLinkText(event) {
        setNewLinkText(event.target.value);
    }

    function changeEmailText(event) {
        setNewEmailText(event.target.value);
    }

    function changeEmailAddress(event) {
        setNewEmailAddress(event.target.value);
        setIsNewEmailAddressValid(event.target.value.length > 0 ? isEmailAddressText(event.target.value) : true);
    }

    function removeLink(editor) {
        unwrapElement(editor,'link');
        setModalState(MODAL_STATE_CLOSED);
    }

    function removeEmail(editor) {
        unwrapElement(editor,'email');
        setModalState(MODAL_STATE_CLOSED);   
    }
    
    return (
        <>
            <div className="row mb-1 d-flex flex-row">
                {/*<div className="col">*/}
                    <Button
                        key={"boldText"}
                        buttonText={""}
                        additionalClasses={""}
                        onClicked={event => { onFormatTextBoldButtonClick(editor) }}
                        icon={boldIcon} 
                        iconSize={"20px"}
                        iconColor={"#000"}
                        additionalStyles={{paddingLeft:'6px',paddingRight:'6px',paddingTop:'1px',paddingBottom:'1px'}}
                    />
                {/*</div>*/}
                {/*<div className="col">*/}
                    <Button
                        key={"italicText"}
                        buttonText={""}
                        additionalClasses={""}
                        onClicked={event => { onFormatTextItalicButtonClick(editor) }}
                        icon={italicIcon} 
                        iconSize={"20px"}
                        iconColor={"#000"}
                        additionalStyles={{marginLeft:'3px',paddingLeft:'6px',paddingRight:'6px',paddingTop:'1px',paddingBottom:'1px'}}
                    />
                {/*</div>*/}
                {/*<div className="col">*/}
                    <Button
                        key={"underlineText"}
                        buttonText={""}
                        additionalClasses={""}
                        onClicked={event => { onFormatTextUnderlineButtonClick(editor) }}
                        icon={underlineIcon} 
                        iconSize={"20px"}
                        iconColor={"#000"}
                        additionalStyles={{marginLeft:'3px',paddingLeft:'6px',paddingRight:'6px',paddingTop:'1px',paddingBottom:'1px'}}
                    />
                {/*</div>*/}
                {/*<div className="col">*/}
                    <Button
                        key={"strikethroughText"}
                        buttonText={""}
                        additionalClasses={""}
                        onClicked={event => { onFormatTextStrikethroughButtonClick(editor) }}
                        icon={strikethroughIcon} 
                        iconSize={"20px"}
                        iconColor={"#000"}
                        additionalStyles={{marginLeft:'3px',paddingLeft:'6px',paddingRight:'6px',paddingTop:'1px',paddingBottom:'1px'}}
                    />
                {/*</div>*/}
                {/*<div className="col">*/}
                {/*
                    <TextInput
                        key={"colorPicker"}
                        type={"color"}
                        label={""}
                        name={"colorPicker"}
                        placeholder={undefined}
                        onBlur={event => { onFormatTextColorButtonChange(editor,event) }}
                        onChange={event => { onFormatTextColorButtonChange(editor,event) }}
                        submitOnEnter={false}
                        disabled={false}
                        value={'#000'}
                        autoFocus={false}
                        additionalStyles={{maxHeight:'34px',padding:'initial',marginLeft:'3px',paddingLeft:'6px',paddingRight:'6px',paddingTop:'1px',paddingBottom:'1px'}}
                    />
                */}
                <input 
                    type="color"
                    className={"form-control"}
                    onBlur={event => { onFormatTextColorButtonChange(editor,event) }}
                    onChange={event => { onFormatTextColorButtonChange(editor,event) }}
                    defaultValue={'#000'}
                    style={{width:'38px',marginLeft:'3px',paddingLeft:'6px',paddingRight:'6px',paddingTop:'1px',paddingBottom:'1px'}}
                />
                {/*</div>*/}
                {/*<div className="col">*/}
                    <Button
                        key={"emailText"}
                        buttonText={""}
                        additionalClasses={""}
                        onClicked={event => { onFormatTextEmailButtonClick(editor) }}
                        icon={mailIcon} 
                        iconSize={"20px"}
                        iconColor={"#000"}
                        additionalStyles={{marginLeft:'3px',paddingLeft:'6px',paddingRight:'6px',paddingTop:'1px',paddingBottom:'1px'}}
                    />
                {/*</div>*/}
                {/*<div className="col">*/}
                    <Button
                        key={"urlText"}
                        buttonText={""}
                        additionalClasses={""}
                        onClicked={event => { onFormatTextUrlButtonClick(editor) }}
                        icon={urlIcon} 
                        iconSize={"20px"}
                        iconColor={"#000"}
                        additionalStyles={{marginLeft:'3px',paddingLeft:'6px',paddingRight:'6px',paddingTop:'1px',paddingBottom:'1px'}}
                    />
                {/*</div>*/}
                {
                    (props.showPositioningButtons === undefined || props.showPositioningButtons === true) &&
                    <>
                        {/*<div className="col">*/}
                            <Button
                                key={"textLeftKey"}
                                buttonText={""}
                                additionalClasses={""}
                                onClicked={event => { onTextAlignmentButtonClick('flex-start') }}
                                icon={textLeftIcon} 
                                iconSize={"20px"}
                                iconColor={"#000"}
                                additionalStyles={{marginLeft:'3px',paddingLeft:'6px',paddingRight:'6px',paddingTop:'1px',paddingBottom:'1px'}}
                            />
                        {/*</div>*/}
                        {/*<div className="col">*/}
                            <Button
                                key={"textCenterKey"}
                                buttonText={""}
                                additionalClasses={""}
                                onClicked={event => { onTextAlignmentButtonClick('center') }}
                                icon={textCenterIcon} 
                                iconSize={"20px"}
                                iconColor={"#000"}
                                additionalStyles={{marginLeft:'3px',paddingLeft:'6px',paddingRight:'6px',paddingTop:'1px',paddingBottom:'1px'}}
                            />
                        {/*</div>*/}
                        {/*<div className="col">*/}
                            <Button
                                key={"textRightKey"}
                                buttonText={""}
                                additionalClasses={""}
                                onClicked={event => { onTextAlignmentButtonClick('flex-end') }}
                                icon={textRightIcon} 
                                iconSize={"20px"}
                                iconColor={"#000"}
                                additionalStyles={{marginLeft:'3px',paddingLeft:'6px',paddingRight:'6px',paddingTop:'1px',paddingBottom:'1px'}}
                            />
                        {/*</div>*/}
                    </>
                }
            </div>
            <div className="row">
                <TextEditor className="col">
                    <Slate
                        editor={editor}
                        value={textValue}
                        onChange={(newTextValue) => onTextEditChanged(newTextValue)}
                    >
                        <Editable 
                            readOnly={false}
                            renderElement={renderElement}
                            renderLeaf={renderLeaf}
                        />
                    </Slate>
                </TextEditor>
            </div>
            {
                    modalState !== MODAL_STATE_CLOSED &&
                    <div>
                        <OptionsModalBackground></OptionsModalBackground>
                        <OptionsModalDiv key={"editTextModal"}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col text-end">
                                        <br />
                                        <br />
                                        <OptionsModalDivCloseButton type="button" className="btn-close" aria-label="Close" onClick={closeModal}></OptionsModalDivCloseButton>
                                    </div>
                                </div>
                                {
                                    modalState === MODAL_STATE_OPEN_LINK && 
                                    <>
                                        <div className="row text-center mt-1">
                                            <div className="col">
                                                {
                                                    Language[props.language].editor.editLink
                                                }
                                            </div>
                                        </div>
                                        <div className="row mt-1 mb-1">
                                            <div className="col text-start">
                                                <TextInput 
                                                    key={"urlTextInput"}
                                                    type={"text"}
                                                    label={"Link Text"}
                                                    name={"linkTextInput"}
                                                    placeholder={"Link Text"}
                                                    onBlur={ changeLinkText }
                                                    onChange={ changeLinkText }
                                                    submitOnEnter={false}
                                                    disabled={false}
                                                    value={newLinkText}
                                                    autoFocus={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-1 mb-1">
                                            <div className="col text-start">
                                                <TextInput 
                                                    key={"urlTextInput"}
                                                    type={"text"}
                                                    label={"URL"}
                                                    name={"urlTextInput"}
                                                    placeholder={"URL"}
                                                    onBlur={ changeUrl }
                                                    onChange={ changeUrl }
                                                    submitOnEnter={false}
                                                    disabled={false}
                                                    value={newLinkUrl}
                                                    autoFocus={false}
                                                    additionalClasses={(isNewLinkUrlValid) ? undefined : 'is-invalid'}
                                                    labelColor={(isNewLinkUrlValid) ? undefined : "#dc3545"}
                                                />
                                                {
                                                    !isNewLinkUrlValid &&
                                                    <InvalidErrorDiv isUrlValid={isNewLinkUrlValid} className="invalid-feedback">
                                                        Please provide a valid URL.
                                                    </InvalidErrorDiv>
                                                }
                                            </div>
                                        </div>
                                        <div className="row mt-1 mb-4">
                                            <div className="col text-center">
                                                <Button
                                                    key={"saveLink"}
                                                    buttonText={Language[props.language].general.ok}
                                                    additionalClasses={""}
                                                    disabled={isNullOrUndefined(newLinkUrl) || !isNewLinkUrlValid}
                                                    onClicked={event => { onSubmitHyperlink(editor) }}
                                                />
                                            </div>
                                            <div className="col text-center">
                                                <Button
                                                    key={"removeLink"}
                                                    buttonText={Language[props.language].editor.removeLink}
                                                    additionalClasses={""}
                                                    onClicked={event => { removeLink(editor) }}
                                                    icon={deleteImageIcon} 
                                                    iconSize={"20px"}
                                                    iconColor={"#000"}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }
                                {
                                    modalState === MODAL_STATE_OPEN_EMAIL && 
                                    <>
                                        <div className="row text-center mt-1">
                                            <div className="col">
                                                {
                                                    Language[props.language].editor.editEmail
                                                }
                                            </div>
                                        </div>
                                        <div className="row mt-1 mb-1">
                                            <div className="col text-start">
                                                <TextInput 
                                                    key={"emailTextInput"}
                                                    type={"text"}
                                                    label={"Angezeigter Text"}
                                                    name={"emailTextInput"}
                                                    placeholder={"Angezeigter Text"}
                                                    onBlur={ changeEmailText }
                                                    onChange={ changeEmailText }
                                                    submitOnEnter={false}
                                                    disabled={false}
                                                    value={newEmailText}
                                                    autoFocus={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-1 mb-1">
                                            <div className="col text-start">
                                                <TextInput 
                                                    key={"urlTextInput"}
                                                    type={"text"}
                                                    label={"E-Mail Adresse"}
                                                    name={"urlTextInput"}
                                                    placeholder={"E-Mail Adresse"}
                                                    onBlur={ changeEmailAddress }
                                                    onChange={ changeEmailAddress }
                                                    submitOnEnter={false}
                                                    disabled={false}
                                                    value={newEmailAddress}
                                                    autoFocus={false}
                                                    additionalClasses={(isNewEmailAddressValid) ? undefined : 'is-invalid'}
                                                    labelColor={(isNewEmailAddressValid) ? undefined : "#dc3545"}
                                                />
                                                {
                                                    !isNewEmailAddressValid &&
                                                    <InvalidErrorDiv isUrlValid={isNewEmailAddressValid} className="invalid-feedback">
                                                        Please provide a valid email address.
                                                    </InvalidErrorDiv>
                                                }
                                            </div>
                                        </div>
                                        <div className="row mt-1 mb-4">
                                            <div className="col text-center">
                                                <Button
                                                    key={"saveEmail"}
                                                    buttonText={Language[props.language].general.ok}
                                                    additionalClasses={""}
                                                    disabled={isNullOrUndefined(newEmailAddress) || !isNewEmailAddressValid}
                                                    onClicked={event => { onSubmitEmail(editor) }}
                                                />
                                            </div>
                                            <div className="col text-center">
                                                <Button
                                                    key={"removeLink"}
                                                    buttonText={Language[props.language].editor.removeEmail}
                                                    additionalClasses={""}
                                                    onClicked={event => { removeEmail(editor) }}
                                                    icon={deleteImageIcon} 
                                                    iconSize={"20px"}
                                                    iconColor={"#000"}
                                                />
                                            </div>
                                        </div>
                                    </>   
                                }
                            </div>
                        </OptionsModalDiv>
                    </div>
                }
        </>
    )
}

export const StandardLeaf = props => {
    return (
        <StyledSpan
            styles={props.text}
            {...props.attributes}
        >
            {props.children}
        </StyledSpan>
    )
}

export const DefaultElement = props => {
    return <DefaultElementParagraph {...props.attributes}>{props.children}</DefaultElementParagraph>
}

export function UrlLink(props) {
    const [hrefLink,setHrefLink] = useState(props?.element?.url);
    useEffect(() => {
        setHrefLink('//' + props?.element?.url);
        if(props?.element?.url.substring(0,7) === 'http://' || props?.element?.url.substring(0,8) === 'https://' || props?.element?.url.substring(0,4) === 'tel:') {
            setHrefLink(props?.element?.url);
        }
    },[]);

    return (
        <Link 
            href={hrefLink} 
            target="_blank" 
            rel="noreferrer"
            {...props.attributes}
        >
            <InlineChromiumBugfix />
            {
                props?.children
            }
            <InlineChromiumBugfix />
        </Link>
    )
}

export function EmailAddress(props) {
    const [emailUntilAtSign,setEmailUntilAtSign] = useState("");
    const [emailFromAtSignTillEnd,setEmailFromAtSignTillEnd] = useState("");
    const [randomString,setRandomString] = useState((Math.random() + 1).toString(36).substring(2,9));

    function onEmailAddressClick() {
        document.location.href = "mailto:" + props?.element?.email;
    }

    useEffect(() => {
        let emailUntilAtSign = props?.element?.email?.substring(0,props?.element?.email.indexOf('@'))?.replaceAll('.','(dot)');
        let emailFromAtSignTillEnd = props?.element?.email?.substring(props?.element?.email.indexOf('@')+1,props?.element?.email.length)?.replaceAll('.','(dot)');
        setEmailFromAtSignTillEnd(emailFromAtSignTillEnd);
        setEmailUntilAtSign(emailUntilAtSign);
    },[]);

    return (
        <Link 
            key={"email" + props?.element?.email} 
            onClick={onEmailAddressClick}
            {...props.attributes}
        >
            {/*
            {
                emailUntilAtSign
            }
            <span>(at)</span>
            <HiddenSpan>
                {
                    randomString
                }
            </HiddenSpan>
            {
                emailFromAtSignTillEnd
            }
            */}
            <InlineChromiumBugfix />
            {
                props?.children
            }
            <InlineChromiumBugfix />
        </Link>
    )
}

export function Span(props) {
    return (
        <HiddenSpan 
            contentEditable={false}
            {...props.attributes}
        >
            {
                props?.children
            }
        </HiddenSpan>
    )
}

const InlineChromiumBugfix = () => (
    <FontSize0
      contentEditable={false}
    >
      ${String.fromCodePoint(160) /* Non-breaking space */}
    </FontSize0>
)

const ComponentWithErrorBoundary = (props) =>  {
    return (
        <ErrorBoundary
            location={"builder"}
            component={"textAreaWithEditingOptions.component.js"}
            fallbackUI={<FallbackUI language={props.language} component={"textAreaWithEditingOptions"} />}
        >
            <TextAreaWithEditingOptions
                key={"textAreaWithEditingOptionsComponent" + props.textType}
                {...props}
            />
       </ErrorBoundary>
   )
}
 
export default ComponentWithErrorBoundary;