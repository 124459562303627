import * as Actiontypes from "../constants/actiontypes";

export function updateToken(payload) {
    return { type: Actiontypes.UPDATE_TOKEN, payload }
};

export function updateCsrfToken(payload) {
    return { type: Actiontypes.UPDATE_CSRF_TOKEN, payload }
};

export function updateUser(payload) {
    return { type: Actiontypes.UPDATE_USER, payload }
};
 
export function updateNavigation(payload) {
    return { type: Actiontypes.UPDATE_NAVIGATION, payload }
};
 
export function updateLanguage(payload) {
    return { type: Actiontypes.UPDATE_LANGUAGE, payload }
};

export function updateIsSignedIn(payload) {
    return { type: Actiontypes.UPDATE_IS_SIGNED_IN, payload }
};

export function updateNavigationExecutionCallback(payload) {
    return { type: Actiontypes.UPDATE_NAVIGATION_EXECUTION_CALLBACK, payload }
};

export function updateWaitingSyncs(payload) {
    return { type: Actiontypes.UPDATE_WAITING_SYNCS, payload }
};

export function updateStatus(payload) {
    return { type: Actiontypes.UPDATE_STATUS, payload }
};

export function updateCurrentEvent(payload) {
    return { type: Actiontypes.UPDATE_CURRENT_EVENT, payload }
};

export function updateCurrentLandingPage(payload) {
    return { type: Actiontypes.UPDATE_CURRENT_LANDING_PAGE, payload }
};

export function updateCurrentLandingPageTemplate(payload) {
    return { type: Actiontypes.UPDATE_CURRENT_LANDING_PAGE_TEMPLATE, payload }
};

export function updateMessage(payload) {
    return { type: Actiontypes.UPDATE_MESSAGE, payload }
};

export function updateFonts(payload) {
    return { type: Actiontypes.UPDATE_FONTS, payload }
};

export function updateIsCookieAndPrivacyOptOutOpen(payload) {
    return { type: Actiontypes.UPDATE_IS_COOKIE_AND_PRIVACY_OPT_OUT_OPEN, payload }
};

export function updateRowTypes(payload) {
    return { type: Actiontypes.UPDATE_ROW_TYPES, payload }
};

export function updateColumnTypes(payload) {
    return { type: Actiontypes.UPDATE_COLUMN_TYPES, payload }
};

export function updateBusinessData(payload) {
    return { type: Actiontypes.UPDATE_BUSINESS_DATA, payload }
};

export function updateWizard(payload) {
    return { type: Actiontypes.UPDATE_WIZARD, payload }
};

/*
export const updateWaitingSyncs = (payload) => dispatch => {
    //return new Promise((resolve,reject) => {
        //let returnValue = { type: Actiontypes.UPDATE_WAITING_SYNCS, payload }
        //resolve(returnValue);
    //});
    var val = dispatch({type: Actiontypes.UPDATE_WAITING_SYNCS, payload});
    return new Promise((resolve,reject) => {
        resolve(val);
    });
};
*/
