import { isNullOrUndefined } from "./utils";

export const imageSizes = [1920,1600,1366,1024,768,640];

export function getImageSizePictureUrl(image, preferredSize) {
    let imageUrl = '';
    if(isNullOrUndefined(image.app_image_sizes) || image.app_image_sizes.length === 0) {
        imageUrl = `/builder/images/show/${image.identifier_token}`;
    }
    else {
        if(image?.app_image_sizes?.find((imgSize) => parseInt(imgSize.size) === preferredSize) !== undefined) {
            imageUrl = `/builder/images/show/${image?.app_image_sizes?.find((imgSize) => parseInt(imgSize.size) === preferredSize).identifier_token}`;
        }
        else {
            let nextBiggerSize = imageSizes.reduce((result, current) => { 
                if(current > preferredSize) { 
                    result = current; 
                } 
                return result; 
            });
            if(image?.app_image_sizes?.find((imgSize) => parseInt(imgSize.size) === nextBiggerSize) !== undefined) {
                imageUrl = `/builder/images/show/${image?.app_image_sizes?.find((imgSize) => parseInt(imgSize.size) === nextBiggerSize).identifier_token}`;
            }
            else {
                imageUrl = `/builder/images/show/${image.identifier_token}`;
            }
        }
    }
    return imageUrl;
}