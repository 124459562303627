import React from 'react';
import _ from 'lodash';
import LandingPageImage from '../../components/managementInterface/landingPageImage.component';
import Language from '../../constants/language';
import ErrorBoundary from '../../components/error/error.component';
import FallbackUI from '../../components/error/standardFallback.component';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentLandingPage } from '../../reducers/slices/currentLandingPage.slice';

function LandingPageImagesContainer(props) {

    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);
    const currentLandingPage = useSelector((state) => state.currentLandingPage.value);

    function onLandingPageChanged(landingPage) {
        dispatch(setCurrentLandingPage(landingPage));
    }

    return (
        <>
            <div className="row mt-2">
                <div className="col">
                    <div className="card border-light bg-dark">
                        <div className="card-body">
                            <div className="card-title">
                                { props.headerText ? props.headerText : Language[language].images.alreadyUploadedImages }
                            </div>
                            <div className="row">
                                {
                                    props.product === undefined &&
                                    currentLandingPage?.app_images.map((image) => {
                                        return (
                                            <LandingPageImage
                                                key={"landingPageImage" + image.id} 
                                                image={image}
                                                onChange={onLandingPageChanged}
                                                maxImageWidth={props.maxImageWidth}
                                            />
                                        )
                                    })
                                }
                                {
                                    props.product !== undefined &&
                                    props.product.app_images?.map((image) => {
                                        return (
                                            <LandingPageImage
                                                key={"landingPageImage" + image.id} 
                                                image={image}
                                                onChange={onLandingPageChanged}
                                                maxImageWidth={props.maxImageWidth}
                                                product={props.product}
                                            />
                                        )
                                    })
                                }
                                {
                                    (currentLandingPage?.app_images.length === 0 || (props.product !== undefined && props.product.app_images?.length === 0)) &&
                                    <div className="col">
                                        { Language[language].images.noImagesUploadedYet }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
	
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"landingPageImages.container.js"}
            fallbackUI={<FallbackUI language={language} component={"landingPageImagesContainer"} />}
        >
            <LandingPageImagesContainer
                key={"landingPageImagesContainer"}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;