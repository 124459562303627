import { forEach } from 'lodash';
import React, { Component } from 'react';
import styled, { keyframes }  from "styled-components";
import Language from '../../constants/language';
import * as MessageTypes from '../../constants/messagetypes';
import { format } from 'date-fns';
import parseISO from 'date-fns/parseISO'

const Bold = styled.div`
    font-weight:bold;
`

const MessageFrom = styled.div`
    font-style:italic;
    color:#666;
`

const MessageDiv = styled.div`
    box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px 0px, rgb(0 0 0 / 19%) 0px 6px 20px 0px;
`

class AppMessage extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <MessageDiv className="card" id={"appMessage" + this.props.message.id}>
                <div className="card-header" style={this.props.textStyle}>
                    { this.props.message.subject }
                </div>
                <div className="card-body">
                    
                    <div className="row">
                        <MessageFrom className="col">
                            Nachricht vom { format(new Date(parseISO(this.props.message.updated_at)), 'dd.MM.yyyy HH:ii') }:
                        </MessageFrom>
                    </div>
                    <div className="row">
                        <div className="col text-center">
                            { this.props.message.message }
                        </div>
                    </div>
                </div>
            </MessageDiv>
	    );
	}
}

export default AppMessage;