export const ProductTypes = {
    goods: {
        id: 1,
        isActive: true,
        hasColor: true,
        hasDate: false,
        generatesLinkAfterPurchase: false,
    },
    event: {
        id: 2,
        isActive: true,
        hasColor: false,
        hasDate: true,
        generatesLinkAfterPurchase: false,
    },
    digitalProduct: {
        id: 3,
        isActive: true,
        hasColor: false,
        hasDate: false,
        generatesLinkAfterPurchase: true,
    },
    food: {
        id: 4,
        isActive: true,
        hasColor: false,
        hasDate: true,
        generatesLinkAfterPurchase: false,
    }
};