import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: { 
    step: 1, 
    product: null 
  },
};

export const productSetupWizardSlice = createSlice({
  name: 'productSetupWizard',
  initialState,
  reducers: {
    setProductSetupWizard: (state, action) => {
      state.value = action.payload
    },
    increaseStep: (state, action) => {
      state.value.step++;
    },
    decreaseStep: (state, action) => {
      state.value.step--;
    },
    removeImageById: (state, action) => {
      state.value.product.app_images = state.value.product.app_images.filter(image => image.id !== action.payload);
    },
    resetState: (state, action) => {
      state.value = { 
        step: 1, 
        product: null 
      };
    }
  },
});

export const { setProductSetupWizard, increaseStep, decreaseStep, removeImageById, resetState } = productSetupWizardSlice.actions;

export default productSetupWizardSlice.reducer;