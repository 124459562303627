import React, { Component } from "react";
import ReactDOM from "react-dom";
import styled from 'styled-components';
import Button from '../button.component';
import Language from '../../constants/language';
import * as Data from '../../backend/data';
import * as Utils from '../../utils/utils';
import platform from 'platform';
var jwt = require('jsonwebtoken');

class PushNotificationButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isNotificationAPIAvailable: false,
            hasSubscription: false,
        };

        this.onButtonClick = this.onButtonClick.bind(this);
        this.configurePushSubscription = this.configurePushSubscription.bind(this);
        this.onSendSubscriptionSuccess = this.onSendSubscriptionSuccess.bind(this);
        this.getSubscription = this.getSubscription.bind(this);
        this.displayConfirmNotification = this.displayConfirmNotification.bind(this);
    }

    componentDidMount() {
        if('Notification' in window && 'serviceWorker' in navigator) {
            this.setState({
                isNotificationAPIAvailable: true
            },() => {
                this.props.onCheckNotificationAPIChanged(true);
            });
        }
        else {
            this.setState({
                isNotificationAPIAvailable: false
            },() => {
                this.props.onCheckNotificationAPIChanged(false);
            });
        }
        this.getSubscription()
        .then((subscription) => {
            if(subscription !== null) {
                let formData = [
                    {
                        name:'subscriptionEndpoint',
                        value: subscription.endpoint,
                    },
                    {
                        name:'guestId',
                        value: this.props.guestLandingPage.guest.id, 
                    },
                    {
                        name:'landingPageId',
                        value: this.props.guestLandingPage.landing_page.id,
                    },
                    {
                        name:'csrfToken',
                        value: this.props.csrfToken
                    }
                ];
                Data.transfer('/pushNotification/hasPushNotification','POST',formData,undefined,undefined,this.props.csrfToken)
                .then((data) => {
                    if(data.success) {
                        return data.hasPushSubscription;
                    }
                })
                .then((hasPushSubscription) => {
                    this.setState({
                        hasSubscription: hasPushSubscription
                    },() => {
                        this.onHasSubscriptionChanged();
                    });
                })
                .catch(() => {
                    this.setState({
                        hasSubscription: true
                    },() => {
                        this.onHasSubscriptionChanged();
                    });
                });
            }
        })
    }

    onHasSubscriptionChanged() {
        this.props.onHasSubscriptionChanged(this.state.hasSubscription);
    }

    onButtonClick() {
        Notification.requestPermission((result) => {
            console.log('User choice', result);
            if(result !== 'granted') {
                console.log('No notification permission granted!');
            }
            else {
                this.configurePushSubscription();
            }
        });
    }

    getSubscription() {
        return new Promise((resolve, reject) => {
            if(!('serviceWorker' in navigator)) {
                reject();
            }
            navigator.serviceWorker.ready
            .then((serviceWorkerRegistration) => {
                resolve(serviceWorkerRegistration.pushManager.getSubscription());
            })
            .catch(function(error) {
                reject(error);
            });
        });
    }

    configurePushSubscription() {
        if(!('serviceWorker' in navigator)) {
            return;
        }
    
        var registration;
        navigator.serviceWorker.ready
        .then((serviceWorkerRegistration) => {
            registration = serviceWorkerRegistration;
            return serviceWorkerRegistration.pushManager.getSubscription();
        })
        .then((subscription) => {
            if(subscription === null) {
                // Create new subscription
                var vapidPublicKey = 'BJNAa1V1iPP6fD44-YySW7qOhwP7dM_Qxt0r8URq-7iVWLIK8u-U3F9eJrx0LT7nj63BbP9GVzBwgXbLPDUp6tg';
                var convertedVapidPublicKey = Utils.urlBase64ToUint8Array(vapidPublicKey);
                return registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: convertedVapidPublicKey
                });
            }
            else {
                // TODO: Register new landing page
                return subscription;
            }
        })
        .then((newSubscription) => {
            // Send subscription to backend
            var guest = this.props.guestLandingPage.guest;
            var landingPage = this.props.guestLandingPage.landing_page;
            var subscriptionEncrypted = jwt.sign({ newSubscription }, process.env.MIX_JWT_PRIVATE_KEY, { algorithm: 'HS512'});
            var guestEncrypted = jwt.sign({ guest }, process.env.MIX_JWT_PRIVATE_KEY, { algorithm: 'HS512'});
            var landingPageEncrypted = jwt.sign({ landingPage }, process.env.MIX_JWT_PRIVATE_KEY, { algorithm: 'HS512'});
            var platformEncrypted = jwt.sign({ platform }, process.env.MIX_JWT_PRIVATE_KEY, { algorithm: 'HS512'});
            let formData = [
                {
                    name:'subscription',
                    value: subscriptionEncrypted,
                },
                {
                    name:'guest',
                    value: guestEncrypted, 
                },
                {
                    name:'landingPage',
                    value: landingPageEncrypted,
                },
                {
                    name:'platform',
                    value: platformEncrypted,
                },
                {
                    name:'csrfToken',
                    value: this.props.csrfToken
                }
            ];
            return Data.transfer('/subscribe/pushNotification','POST',formData,this.onSendSubscriptionSuccess,this.onSendSubscriptionFailure,this.props.csrfToken);
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    onSendSubscriptionSuccess(data) {
        console.log(data);
        this.setState({
            hasSubscription: true
        },() => {
            this.onHasSubscriptionChanged();
        });
        this.displayConfirmNotification();
        return data;
    }

    onSendSubscriptionFailure(error) {
        console.log(error);
        return error;
    }

    displayConfirmNotification() {
        if('serviceWorker' in navigator) {
            var options = {
                body: Language[this.props.landingPageLanguage].app.successfullySubscribedToNotificationService,
                icon: this.props.guestLandingPage.landing_page.landing_page_url_base_path + '/images/icon96x96.png',
                image: this.props.guestLandingPage.landing_page.app_icon.image_url,
                dir: 'ltr',
                lang: 'en-US', //BCP 47
                vibrate: [100, 50, 200],
                badge: this.props.guestLandingPage.landing_page.landing_page_url_base_path + '/images/icon96x96.png',
                tag: 'confirm-notification',
                renotify: true,
                // you cannot rely on actions, depends on device:
                /*
                action: [
                    { action: 'confirm', title: 'Okay', icon: '/src/images/icons/app-icon-96x96.png' },
                    { action: 'cancel', title: 'Cancel', icon: '/src/images/icons/app-icon-96x96.png' }
                ]
                */
            };
            navigator.serviceWorker.ready
            .then(function(serviceWorkerRegistration) {
                serviceWorkerRegistration.showNotification('Successfully subscribed!', options);
            });
        }
    }

    render() {
        return (
            <>
                {
                    this.state.isNotificationAPIAvailable && !this.state.hasSubscription && 
                    <Button
                        key={"enablePushNotificationButton"}
                        buttonText={Language[this.props.landingPageLanguage].app.enablePushNotifications}
                        additionalClasses={this.props.additionalButtonClasses}
                        additionalStyles={this.props.additionalButtonStyles}
                        onClicked={this.onButtonClick}
                        disabled={false}
                        icon={this.props.icon}
                        iconColor={this.props.iconColor}
                    />
                }
            </>
        );
    }
}

export default PushNotificationButton;