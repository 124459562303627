import React, { useState, useEffect } from 'react';
import TextInput from '../../../../../textInput.component';
import ErrorBoundary from '../../../../../error/error.component';
import FallbackUI from '../../../../../error/standardFallback.component';
import { FadeIn } from '../../../../../styled/fadeIn.component';
import { Icon } from '../../../../../styled/icon.component';
import Language from '../../../../../../constants/language';
import { InputError } from '../../../../../styled/inputError.component';
import { isNullOrUndefined } from '../../../../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { setProductSetupWizard } from '../../../../../../reducers/slices/productSetupWizard.slice';
import { useProductTypeSetupWizard } from '../../../../../../factories/productSetup.factory';

function ProductName(props) {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);
    const productSetupWizard = useSelector((state) => state.productSetupWizard.value);

    const [name, setName] = useState(isNullOrUndefined(productSetupWizard.product?.name) ? '' : productSetupWizard.product.name);
    const [additionalClasses, setAdditionalClasses] = useState('');
    const [error, setError] = useState(null);

    const { updateProduct } = useProductTypeSetupWizard();

    function checkProductName(event, action = 'submit') {
        let newProductName = event.target.value;
        let isValid = false;
        if(newProductName.trim().length > 0) {
            isValid = true;
        }
        if(isValid) {
            setAdditionalClasses('');
            setError(null);
        }
        else {
            setAdditionalClasses('is-invalid');
            setError(Language[language].shop.wizard.product.productNameIsInvalid);
        }
        let product = _.cloneDeep(productSetupWizard.product);
        product = updateProduct(product, { type: 'name', value: newProductName });
        let updatedWizard = { step: productSetupWizard.step, product: product};
        if(isValid && action === 'submit') {
            updatedWizard.step++;
        }
        dispatch(setProductSetupWizard(updatedWizard));
        setName(newProductName);
    }

    return (
        <FadeIn>
            <div className="row">
                <div className="col d-flex justify-content-center align-items-center">
                    { Language[language].shop.wizard.product.enterProductNameText }
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <TextInput
                        key={"name"}
                        type={"text"}
                        label={""}
                        value={name}
                        name={"name"}
                        placeholder={Language[language].shop.wizard.product.productName}
                        onChange={(event) => checkProductName(event, 'change')}
                        submitOnEnter={true}
                        submitEvent={(event) => checkProductName(event)}
                        required={true}
                        maxLength={255}
                        autoFocus={true}
                        additionalClasses={additionalClasses}
                    />
                    {
                        error !== null &&
                        <InputError>
                            {
                                error
                            }
                        </InputError>
                    }
                </div>
            </div>
        </FadeIn>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"productName.component.js"}
            fallbackUI={<FallbackUI language={language} component={"productName"} />}
        >
            <ProductName
                key={`productNameComponent`}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;