import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import Button from '../button.component';
import Language from '../../constants/language';
import PushNotificationButton from './pushNotificationButton.component';
import ErrorBoundary from '../error/error.component';
import FallbackUI from '../error/standardFallback.component';
import * as Data from '../../backend/data';
import platform from 'platform';

let installImage = require('../../assets/images/download.svg').default;
let pushSubscriptionImage = require('../../assets/images/message-square.svg').default;
let fadeOutChevron = require('../../assets/images/chevrons-left.svg').default;
let fadeInChevron = require('../../assets/images/chevrons-right.svg').default;
let plusIcon = require('../../assets/images/plus-circle.svg').default;
let addToHomeScreenIcon = require('../../assets/images/addToHomeScreen.png').default;

const showFixedDiv = keyframes`
    100% {
        width: 320px;
    }
`;

const hideFixedDiv = keyframes`
    100% {
        width: 50px;
    }
`;

const FixedDiv = styled.div`
    position:fixed;
    z-index:10;
    bottom:10%;
    right:0px;
    width:50px;
    height:135px;
    background-color:#021030;
    color:#fff;
    animation-duration: 1s;
    animation-name: ${props => (props.active ? showFixedDiv : hideFixedDiv)};
    animation-fill-mode: ${props => (props.active ? 'forwards' : 'reverse')};
    cursor:pointer;
    border-top-left-radius:5px;
    border-bottom-left-radius:5px;
`;

const showAnimatedButton = keyframes`
100% {
    opacity: 1;
}
`;

const hideAnimatedButton = keyframes`
100% {
    opacity: 0;
}
`;

const AnimatedButtonDiv = styled.div`
    opacity:0;
    animation-duration: 2s;
    animation-name: ${props => (props.active ? showAnimatedButton : hideAnimatedButton)};
    animation-fill-mode: forwards;
    display:${props => (props.active ? 'inline-block' : 'none')};
    padding-right:20px;
`

const Icon = styled.div`
    margin:0.5rem;
    width:${props => props.minSize ? props.minSize : '50px'};
    height:${props => props.minSize ? props.minSize : '50px'};
    color:#fff;
    background-color:${props => props.maskColor ? props.maskColor : '#fff'};
    -webkit-mask-image: url(${props => props.image});
    mask-image: url(${props => props.image});
    -webkit-mask-size: cover;
    mask-size:cover;
    background-size: cover;
    background-position: center;
` 

const Mask = styled.div`
    min-width:50px;
    background-color:${props => props.maskColor ? props.maskColor : '#fff'};
    -webkit-mask-image: url(${props => props.image});
    mask-image: url(${props => props.image});
    -webkit-mask-size: cover;
    mask-size:cover;
    background-size: cover;
    background-position: center;
    margin:0.5rem;
`

const FixedDivRow = styled.div` 
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    height:135px;
    padding-left:30px;
`
const FixedDivCol = styled.div`
    padding-left:0px;
`

const MarginTop1rem = styled.div`
    margin-top:1rem;
` 

const AddToHomescreenHint = styled.div`
    position: fixed;
    z-index: 10000;
    bottom: 0rem;
    left: 50%;
    width: 50%;
    margin-left: -25%;
    background-color: transparent;
    padding:0.5rem;

    @media (max-width: 576px) {
        left: 0rem;
        width:100%;
        margin-left:0rem;
    }
`

class AppFeatures extends Component {

    constructor(props) {
        super(props);
        this.state = {
            active: false,
            installPrompt: null,
            hasSubscription: false,
            isNotificationAPIAvailable: true,
            isIOS: (platform.os?.name == 'iOS'), /*/(iPad|iPhone|iPod)/g.test(navigator.userAgent),*/
            isInStandaloneMode: ('standalone' in window.navigator) && (window.navigator.standalone),
            isSafariBrowser: (platform.name == 'Safari'), /*window.safari !== undefined,*/
        }

        this.onInstallAppButtonClick = this.onInstallAppButtonClick.bind(this);
        this.onAllowInstallation = this.onAllowInstallation.bind(this);
    }

    componentDidMount() {
        window.addEventListener('beforeinstallprompt',(event) => {
            event.preventDefault();
            this.setState({
                installPrompt: event
            });
        });
    }

    componentDidUpdate(prevProps) {
    }

    onInstallAppButtonClick() {
        if(this.state.installPrompt !== null) {
            this.state.installPrompt.prompt();
            this.state.installPrompt.userChoice.then((choiceResult) => {
                console.log(choiceResult.outcome);
                if(choiceResult.outcome === 'dismissed') {
                    console.log('User cancelled installtion');
                }
                else {
                    console.log('User added to Home Screen');
                    this.onAllowInstallation();
                }
            });
            this.setState({
                installPrompt: null
            });
        }
    }

    onAllowInstallation() {
        let formData = [
            {
                name: 'landingPageId',
                value: this.props.guestLandingPage?.landing_page_id
            },
            {
                name:'browserName',
                value: platform.name
            },
            {
                name:'browserVersion',
                value: platform.version
            },
            {
                name:'osName',
                value: platform.os.family
            },
            {
                name:'osVersion',
                value: platform.os.version
            },
            {
                name:'ua',
                value: platform.ua
            },
        ];
        Data.transfer(window.location.origin + '/log/installation','POST',formData,undefined,undefined,this.props.csrfToken);
    }

    onAppFeaturesFixedDivClick(event) {
        this.setState({
            active: !this.state.active,
        })
    }

    onHasSubscriptionChanged(newValue) {
        this.setState({
            hasSubscription: newValue
        });
    }

    onHasNotificationAPIChanged(newValue) {
        this.setState({
            isNotificationAPIAvailable: newValue
        });
    }

    render() {
        let fadeImage = this.state.active ? fadeInChevron : fadeOutChevron;
        return (
            <>
            {
                (this.state.installPrompt !== null || (!this.state.hasSubscription && this.state.isNotificationAPIAvailable)) &&
                <FixedDiv active={this.state.active} onClick={this.onAppFeaturesFixedDivClick.bind(this)}>
                    <FixedDivRow className="row">
                        <div className="col">
                            <Icon image={fadeImage} minSize={"30px"} />
                        </div>
                        <div className="col">
                            {
                                this.state.installPrompt !== null &&
                                <div className="row">
                                    {
                                        !this.state.active && 
                                        <FixedDivCol className="col">
                                            <Icon image={installImage} minSize={"30px"} />
                                        </FixedDivCol>
                                    }
                                    <AnimatedButtonDiv active={this.state.active} className="col">
                                        <Button
                                            key={"installAppButton"}
                                            buttonText={Language[this.props.language].app.install}
                                            additionalClasses={""}
                                            /*additionalStyles={this.props.additionalButtonStyles}*/
                                            onClicked={this.onInstallAppButtonClick}
                                            disabled={false}
                                            icon={installImage}
                                            iconColor={this.props.buttonIconColor}
                                        />
                                    </AnimatedButtonDiv>
                                </div>
                            }
                            {
                                !this.state.hasSubscription && this.state.isNotificationAPIAvailable &&
                                <div className="row">
                                    {
                                        !this.state.active &&
                                        <FixedDivCol className="col">
                                            <Icon image={pushSubscriptionImage} minSize={"30px"} />
                                        </FixedDivCol>
                                    }
                                    <MarginTop1rem>
                                        <AnimatedButtonDiv active={this.state.active} className="col">
                                            <PushNotificationButton
                                                key={"landingPageEnablePushNotificationButton"}
                                                landingPageLanguage={this.props.language}
                                                additionalButtonClasses={""}
                                                {...this.props}
                                                additionalButtonStyles={undefined}
                                                icon={pushSubscriptionImage}
                                                iconColor={this.props.buttonIconColor}
                                                onHasSubscriptionChanged={this.onHasSubscriptionChanged.bind(this)}
                                                onCheckNotificationAPIChanged={this.onHasNotificationAPIChanged.bind(this)}
                                            /> 
                                        </AnimatedButtonDiv>
                                    </MarginTop1rem>
                                </div>
                            }
                        </div>
                    </FixedDivRow>
                </FixedDiv>
            }
            {
                this.state.isIOS && this.state.isSafariBrowser && !this.state.isInStandaloneMode && 
                <AddToHomescreenHint key={"addToHomescreenHintDiv"}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col text-center alert alert-secondary alert-dismissible fade show" role="alert">
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <img src={plusIcon} alt="plus" /> Install this WebApp on your iPhone: tap <img src={addToHomeScreenIcon} alt="add to homescreen" /> and then "Add to homescreen"
                            </div>
                        </div>
                    </div>
                </AddToHomescreenHint>
            }
            </>
        )
    }
}

const ComponentWithErrorBoundary = (props) =>  {
    return ( 
        <ErrorBoundary
            location={"app"}
            component={"appFeatures.component.js"}
            fallbackUI={<FallbackUI language={props.language} component={"appFeatures"} />}
        >
            <AppFeatures
                key={"appFeaturesComponent"}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary; // AppFeatures;