import React, { Component } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import Fonts from './fonts.component';

const PreviewFont = styled.option`
    font-family:${props => props.font.font_family};
    font-size:1.25rem;
`

const Select = styled.select` 
    height: calc(1.5em + 1.5rem + 2px) !important;
`

const Label = styled.label`
    padding: 0px; 
    position: absolute; 
    top: 8px; 
    left: 12px; 
    color: rgb(108, 115, 120); 
    font-size: 15px; 
    line-height: 20px; 
    font-weight: normal; 
    transition: all 0.25s ease-in-out 0s; 
    transform: translate(1px, -6px) scale(0.75); 
    transform-origin: left top;
`

const DivRelative = styled.div`
    position:relative;
`

class SelectboxFonts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            key: uuidv4()
        }
        this.selectBox = React.createRef();
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if(prevProps.value !== this.props.value) {
            this.setState({
                inputTextValue: this.props.value
            });
            this.selectBox.current.value = this.props.value;
        } 
    }

    onChangedHandler(event) {
        if(this.props.onChange) {
            event.preventDefault()
            this.props.onChange(event);
        }
    }

    render() {
        return (
            <DivRelative>
                {
                    this.props.options.map(option => {
                        let woffFile;
                        let woff2File;
                        let eotFile;
                        let ttfFile;
                        let svgFile;
                        if(option.is_default_web_font !== undefined && option.is_default_web_font === 0) {
                            woffFile = require('../assets/fonts/' + option.woff).default;
                            woff2File = require('../assets/fonts/' + option.woff2).default;
                            eotFile = require('../assets/fonts/' + option.eot).default;
                            ttfFile = require('../assets/fonts/' + option.ttf).default;
                            svgFile = require('../assets/fonts/' + option.svg).default;
                            return (
                                <Fonts key={"font" + option.id} font={option} fontWoffFile={woffFile} fontWoff2File={woff2File} fontEotFile={eotFile} fontTtfFile={ttfFile} fontSvgFile={svgFile}></Fonts>
                            )
                        }
                    })
                }
                <Select 
                    key={this.state.key}
                    className={"form-control " + ((this.props.additionalClasses) ? this.props.additionalClasses : "")}
                    defaultValue={ this.props.value }
                    disabled={(this.props.disabled) ? true : undefined }
                    required={(this.props.disabled) ? true : undefined }
                    onChange={this.onChangedHandler.bind(this)}
                    ref={this.selectBox}
                    name={(this.props.name) ? this.props.name : undefined }
                    autoFocus={(this.props.autoFocus) ? true : undefined }
                >
                    {
                        this.props.options.map(option => {
                            return (
                                    <PreviewFont font={option} key={"fontPreview" + option.id} value={option.id}>
                                        { option.name }
                                    </PreviewFont>
                            )
                        })
                    }
                </Select>
                {
                    this.props.label && this.props.name && 
                    <Label htmlFor={this.props.name}>{this.props.label}</Label>
                }
            </DivRelative>
	    );
	}
}

export default SelectboxFonts;