import React, { useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import Selectbox from '../../../../selectbox.component';
import ErrorBoundary from '../../../../error/error.component';
import FallbackUI from '../../../../error/standardFallback.component';
import TextInput from '../../../../textInput.component';
import { FadeIn } from '../../../../styled/fadeIn.component';
import Language from '../../../../../constants/language';
import { LEGAL_STATUS_TYPE_PRIVATE, LEGAL_STATUS_TYPE_COMPANY } from '../../../../../constants/legalStatusTypes';
import { isNullOrUndefined } from '../../../../../utils/utils';
import { useSelector } from 'react-redux';

function Address(props) {
    const language = useSelector((state) => state.language.value);

    const [companyName, setCompanyName] = useState(props.addressData?.companyName);
    const [firstName, setFirstName] = useState(props.addressData?.firstName);
    const [lastName, setLastName] = useState(props.addressData?.lastName);
    const [street, setStreet] = useState(props.addressData?.street);
    const [postalCode, setPostalCode] = useState(props.addressData?.postalCode);
    const [city, setCity] = useState(props.addressData?.city);
    const [country, setCountry] = useState(props.addressData?.country)

    //to be used with Typeahead select box:
    function filterCountries(option, props) {
        option.name.toLowerCase().indexOf(props.text.toLowerCase()) !== -1
    }

    function isDataComplete() {
        if(props.legalStatus === LEGAL_STATUS_TYPE_COMPANY) {
            if(isNullOrUndefined(companyName) || companyName.trim().length < 2) {
                return false;
            }
        }
        else if(props.legalStatus === LEGAL_STATUS_TYPE_PRIVATE) {
            if(isNullOrUndefined(firstName) || firstName.trim().length < 2) {
                return false;
            }
            if(isNullOrUndefined(lastName) || lastName.trim().length < 2) {
                return false;
            }
        }
        if(isNullOrUndefined(street) || street.trim().length < 3) {
            return false;
        }
        if(isNullOrUndefined(postalCode) || postalCode.trim().length < 4) {
            return false;
        }
        if(isNullOrUndefined(city) || city.trim().length < 4) {
            return false;
        }
        if(isNullOrUndefined(country) || country === 0) {
            return false;
        }
        return true;
    }

    function createAddressObject() {
        return {
            legalStatus: props.legalStatus,
            companyName: props.legalStatus === LEGAL_STATUS_TYPE_COMPANY ? companyName : undefined,
            firstName: props.legalStatus === LEGAL_STATUS_TYPE_PRIVATE ? firstName : undefined,
            lastName: props.legalStatus === LEGAL_STATUS_TYPE_PRIVATE ? lastName : undefined,
            street: street,
            postalCode: postalCode,
            city: city,
            country: country
        };
    }

    useEffect(() => {
        props.onReturnData(createAddressObject(), isDataComplete());
    },[companyName,firstName,lastName,street,postalCode,city,country]);

    return (
        <FadeIn>
            <div className="row">
                <div className="col">
                    {
                        Language[language].shop.addressDataText
                    }
                </div>
            </div>
            {
                props.legalStatus === LEGAL_STATUS_TYPE_COMPANY &&
                <div className="row mt-3">
                    <div className="col">
                        <TextInput
                            key={"companyName"}
                            type={"text"}
                            label={""}
                            value={companyName}
                            name={"companyName"}
                            placeholder={Language[language].shop.companyName}
                            onChange={(event) => setCompanyName(event.target.value)}
                            submitOnEnter={false}
                            required={true}
                            autoFocus={true}
                            maxLength={255}
                        />
                    </div>
                </div>
            }
            {
                props.legalStatus === LEGAL_STATUS_TYPE_PRIVATE &&
                <div className="row mt-3">
                    <div className="col">
                        <TextInput
                            key={"firstName"}
                            type={"text"}
                            label={""}
                            value={firstName}
                            name={"firstName"}
                            placeholder={Language[language].shop.firstName}
                            onChange={(event) => setFirstName(event.target.value)}
                            submitOnEnter={false}
                            required={true}
                            autoFocus={true}
                            maxLength={255}
                        />
                    </div>
                    <div className="col">
                        <TextInput
                            key={"lastName"}
                            type={"text"}
                            label={""}
                            value={lastName}
                            name={"lastName"}
                            placeholder={Language[language].shop.lastName}
                            onChange={(event) => setLastName(event.target.value)}
                            submitOnEnter={false}
                            required={true}
                            maxLength={255}
                        />
                    </div>
                </div>
            }
            <div className="row mt-2">
                <div className="col">
                    <TextInput
                        key={"street"}
                        type={"text"}
                        label={""}
                        value={street}
                        name={"street"}
                        placeholder={Language[language].shop.street}
                        onChange={(event) => setStreet(event.target.value)}
                        submitOnEnter={false}
                        required={true}
                        maxLength={255}
                    />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-4">
                    <TextInput
                        key={"postalCode"}
                        type={"text"}
                        label={""}
                        value={postalCode}
                        name={"postalCode"}
                        placeholder={Language[language].shop.postalCode}
                        onChange={(event) => setPostalCode(event.target.value)}
                        submitOnEnter={false}
                        required={true}
                        maxLength={191}
                    />
                </div>
                <div className="col">
                    <TextInput
                        key={"city"}
                        type={"text"}
                        label={""}
                        value={city}
                        name={"city"}
                        placeholder={Language[language].shop.city}
                        onChange={(event) => setCity(event.target.value)}
                        submitOnEnter={false}
                        required={true}
                        maxLength={255}
                    />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    {/*
                    <Typeahead
                        filterBy={filterCountries}
                        id="countries"
                        caseSensitive={false}
                        ignoreDiacritics={true}
                        options={_.orderBy(props.countries,['name'])}
                        placeholder={Language[props.language].contact.country}
                        onChange={(event) => setCountry(event.target.value)}
                        onBlur={(event) => setCountry(event.target.value)}
                        labelKey="name"
                    />
                    */}
                    <Selectbox
                        key={"country"}
                        name={"country"}
                        label={Language[language].contact.country}
                        value={country}
                        valueField={"id"}
                        displayField={"name"}
                        options={_.orderBy(props.countries,['name'])}
                        onChange={(event) => setCountry(event.target.value)}
                        onBlur={(event) => setCountry(event.target.value)}
                    />
                </div>
            </div>
        </FadeIn>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"address.component.js"}
            fallbackUI={<FallbackUI language={language} component={"address"} />}
        >
            <Address
                key={`addressComponent`}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;