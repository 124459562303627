import { createSlice } from '@reduxjs/toolkit';
import * as Status from '../../constants/status';

const initialState = {
  value: Status.STATUS_IDLE,
};

export const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    set: (state, action) => {
      state.value = action.payload
    },
  },
});

export const { set } = statusSlice.actions;

export default statusSlice.reducer;