import React, { useState, useEffect } from 'react';
import ErrorBoundary from '../../../../../error/error.component';
import FallbackUI from '../../../../../error/standardFallback.component';
import { FadeIn } from '../../../../../styled/fadeIn.component';
import { Icon } from '../../../../../styled/icon.component';
import Language from '../../../../../../constants/language';
import TextInput from '../../../../../textInput.component';
import Checkbox from '../../../../../checkbox.component';
import { InputError } from '../../../../../styled/inputError.component';
import { isNullOrUndefined } from '../../../../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { setProductSetupWizard } from '../../../../../../reducers/slices/productSetupWizard.slice';
import { useProductTypeSetupWizard } from '../../../../../../factories/productSetup.factory';

function QuantityAvailable(props) {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.value);
    const language = useSelector((state) => state.language.value);
    const currentLandingPage = useSelector((state) => state.currentLandingPage.value);
    const productSetupWizard = useSelector((state) => state.productSetupWizard.value);

    const [numberAvailableGoods, setNumberAvailableGoods] = useState(isNullOrUndefined(productSetupWizard.product?.number_available_goods) ? '' : productSetupWizard.product.number_available_goods);
    const [unlimitedNumberAvailable, setUnlimitedNumberAvailable] = useState(isNullOrUndefined(productSetupWizard.product?.unlimited_number_available) ? false : productSetupWizard.product.unlimited_number_available);
    const [additionalClasses, setAdditionalClasses] = useState('');
    const [error, setError] = useState(null);

    const { updateProduct } = useProductTypeSetupWizard();

    function checkQuantityAvailableValue(event, action = 'submit') {
        let newValue = event.target.value;
        if(isNullOrUndefined(newValue)) {
            newValue = numberAvailableGoods.toString();
        }
        let isValid = false;
        if(unlimitedNumberAvailable || (newValue.trim().length > 0 && parseInt(newValue) !== NaN && parseInt(newValue) >= 0)) {
            if(unlimitedNumberAvailable) {
                newValue = 0;
            }
            isValid = true;
        }
        if(isValid) {
            setAdditionalClasses('');
            setError(null);
        }
        else {
            setAdditionalClasses('is-invalid');
            setError(Language[language].shop.wizard.product.valueIsInvalid);
        }
        let product = _.cloneDeep(productSetupWizard.product);
        product = updateProduct(product, { type: 'number_available_goods', value: newValue });
        product = updateProduct(product, { type: 'unlimited_number_available', value: unlimitedNumberAvailable });
        let updatedWizard = { step: productSetupWizard.step, product: product};
        if(isValid && action === 'submit') {
            updatedWizard.step++;
        }
        dispatch(setProductSetupWizard(updatedWizard));
        setNumberAvailableGoods(newValue);
    }

    useEffect(() => {
        if(event?.target?.name === 'numberAvailableGoods' || event?.target?.dataset?.name === 'unlimitedNumberAvailable') {
            checkQuantityAvailableValue(event, 'change');
        }
    },[unlimitedNumberAvailable]);

    return (
        <FadeIn>
            <div className="row">
                <div className="col d-flex justify-content-center align-items-center">
                    { Language[language].shop.wizard.product.whatQuantityIsAvailable }
                </div>
            </div>

            <div className="row mt-2">
                <div className="col">
                    <Checkbox 
                        key={"unlimitedQuantity"}
                        label={Language[language].shop.wizard.product.unlimited}
                        onChange={(event) => setUnlimitedNumberAvailable(!unlimitedNumberAvailable)}
                        checked={unlimitedNumberAvailable}
                        name={"unlimitedNumberAvailable"}
                    />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <TextInput
                        key={"numberAvailableGoods"}
                        type={"number"}
                        step={"1"}
                        min={"0"}
                        label={""}
                        value={numberAvailableGoods}
                        name={"numberAvailableGoods"}
                        placeholder={Language[language].shop.wizard.product.numberPiecesQuantityOfProduct}
                        onChange={(event) => checkQuantityAvailableValue(event, 'change')}
                        submitOnEnter={true}
                        submitEvent={(event) => checkQuantityAvailableValue(event)}
                        required={true}
                        maxLength={255}
                        autoFocus={true}
                        additionalClasses={additionalClasses}
                        disabled={unlimitedNumberAvailable}
                    />
                    {
                        error !== null &&
                        <InputError>
                            {
                                error
                            }
                        </InputError>
                    }
                </div>
            </div>
        </FadeIn>
    );
}

const ComponentWithErrorBoundary = (props) => {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"quantityAvailable.component.js"}
            fallbackUI={<FallbackUI language={language} component={"quantityAvailable"} />}
        >
            <QuantityAvailable
                key={`quantityAvailableComponent`}
                {...props}
            />
       </ErrorBoundary>
   )
}
 
export default ComponentWithErrorBoundary;