import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: window.laravel.csrfToken,
};

export const csrfTokenSlice = createSlice({
  name: 'csrfToken',
  initialState,
  reducers: {
    setCsrfToken: (state, action) => {
      state.value = action.payload
    },
  },
});

export const { setCsrfToken } = csrfTokenSlice.actions;

export default csrfTokenSlice.reducer;