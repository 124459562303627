import React, { Component, lazy } from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from './app.container';
import LandingPage from './views/event/landingPage.view';
import LandingPageEditorView from './views/editor/landingPageEditor.view';
import { Provider } from 'react-redux';
import { store } from './store/index';
import appStore from './store/app';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min.js';

//import AppTest from './tests/appTest.container';

if('serviceWorker' in navigator) {
    navigator.serviceWorker
    .register('/serviceWorker.js')
    .then(function() {
        console.log("Service worker registered!");
    });
}


var defferedInstallPrompt;
window.addEventListener('beforeinstallprompt',function(event) {
    console.log('beforeinstallprompt fired');
    event.preventDefault();
    defferedInstallPrompt = event;
    return false;
});

if(document.getElementById('app')) {
    let target;
    let data;
    let action;
    let googleReCaptchaPublicKey;
    if(document.getElementById('target')) {
        target = document.getElementById('target').value;
    }
    if(document.getElementById('data')) {
        data = document.getElementById('data').value;
    }
    if(document.getElementById('action')) {
        action = document.getElementById('action').value;
    }
    if(document.getElementById('googleReCaptchaPublicKey')) {
        googleReCaptchaPublicKey = document.getElementById('googleReCaptchaPublicKey').value;
    }
    const container = document.getElementById('app');
    const root = createRoot(container);
    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <App
                    target={target} 
                    data={data} 
                    action={action} 
                    googleReCaptchaPublicKey={googleReCaptchaPublicKey}
                    installPrompt={defferedInstallPrompt}
                    />
            </Provider>
        </React.StrictMode>
    );
    /*
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <App 
                    target={target} 
                    data={data} 
                    action={action} 
                    googleReCaptchaPublicKey={googleReCaptchaPublicKey}
                    installPrompt={defferedInstallPrompt}
                    />
            </Provider>
        </React.StrictMode>,
        document.getElementById('app')
    );
    */
}

if(document.getElementById('landingPage')) {
    const container = document.getElementById('landingPage');
    const root = createRoot(container);
    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <LandingPage 
                    key={"landingPage"}
                    //guest={window.guestLandingPage != undefined ? window.guestLandingPage.guest : null}
                    guestLandingPage={window.guestLandingPage != undefined ? window.guestLandingPage : null}
                    language={window.language}
                    installPrompt={defferedInstallPrompt}
                />
            </Provider>
        </React.StrictMode>
    );
    /*
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={appStore}>
                <LandingPage 
                    key={"landingPage"}
                    //guest={window.guestLandingPage != undefined ? window.guestLandingPage.guest : null}
                    guestLandingPage={window.guestLandingPage != undefined ? window.guestLandingPage : null}
                    language={window.language}
                    installPrompt={defferedInstallPrompt}
                />
            </Provider>
        </React.StrictMode>,
        document.getElementById('landingPage')
    );
    */
}

if(document.getElementById('modifyLandingPage')) {
    const container = document.getElementById('modifyLandingPage');
    const root = createRoot(container);
    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <LandingPageEditorView 
                    key={"landingPageEditor"}
                    landingPage={window.landingPage != undefined ? window.landingPage : null}
                    landingPageTemplate={window.landingPageTemplate != undefined ? window.landingPageTemplate : null}
                    language={window.language}
                    previewGuest={window.previewGuest}
                />
            </Provider>
        </React.StrictMode>
    );
    /*
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <LandingPageEditorView 
                    key={"landingPageEditor"}
                    landingPage={window.landingPage != undefined ? window.landingPage : null}
                    landingPageTemplate={window.landingPageTemplate != undefined ? window.landingPageTemplate : null}
                    language={window.language}
                    previewGuest={window.previewGuest}
                />
            </Provider>
        </React.StrictMode>,
        document.getElementById('modifyLandingPage')
    );
    */
}

if(document.getElementById('acceptLegals')) {
    const container = document.getElementById('acceptLegals');
    const root = createRoot(container);
    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <App 
                    target={"AcceptLegalsView"}
                    installPrompt={defferedInstallPrompt}
                    language={window.language}
                />
            </Provider>
        </React.StrictMode>
    );
    /*
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <App 
                    target={"AcceptLegalsView"}
                    installPrompt={defferedInstallPrompt}
                    language={window.language}
                />
            </Provider>
        </React.StrictMode>,
        document.getElementById('acceptLegals')
    );
    */
}

/*
if('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations()
        .then(function(registrations) {
        for(var i = 0; i < registrations.length; i++) {
            registrations[i].unregister();
        }
    });
}
*/