//import { openDB } from 'idb';
import * as idb from './idb';
import { useEffect, useRef } from 'react';

export const saveLastView = (view) => {
    if(window.localStorage) {
        window.localStorage.setItem('lastView', view);
    }
}

export const reloadWaitingSyncs = () => {
    return new Promise((resolve,reject) => {
        idbReadAllData('sync-comments')
        .then((idbData) => {
            resolve(idbData.length);
        });
    });
}

var dbPromise = idb.open('event-store',4, function(db) {
    if(!db.objectStoreNames.contains('comments')) {
        db.createObjectStore('comments', {keyPath: 'id'});
    }
    if(!db.objectStoreNames.contains('sync-comments')) {
        db.createObjectStore('sync-comments', {keyPath: 'id'});
    }
    if(!db.objectStoreNames.contains('participation')) {
        db.createObjectStore('participation', {keyPath: 'id'});
    }
    if(!db.objectStoreNames.contains('sync-participation')) {
        db.createObjectStore('sync-participation', {keyPath: 'id'});
    }
});

// const dbPromise = openDB('todo-store', 2, {
//     upgrade(db) {
//         db.createObjectStore('todos');
//     },
// });

export async function idbWriteData(storeTable,data) {
    return dbPromise
    .then(function(db) {
        var tx = db.transaction(storeTable, 'readwrite');
        var store = tx.objectStore(storeTable);
        store.put(data);
        return tx.complete;
    });
}

export async function idbReadAllData(storeTable) {
    return dbPromise
        .then(function(db) {
            var tx = db.transaction(storeTable,'readonly');
            var store = tx.objectStore(storeTable);
            return store.getAll();
        });
}

export async function idbClearAllData(storeTable) {
    return dbPromise
        .then(function(db) {
            var tx = db.transaction(storeTable,'readwrite');
            var store = tx.objectStore(storeTable);
            store.clear();
            return tx.complete;
        });
}

export async function idbDeleteItemFromData(storeTable,id) {
    dbPromise
        .then(function(db) {
            var tx = db.transaction(storeTable,'readwrite');
            var store = tx.objectStore(storeTable);
            store.delete(id);
            return tx.complete;
        })
        .then(function() {
            console.log('Item deleted!');
        });
}

export function getUrlVars() {
    var vars = [],
      hash;
    var hashes = window.location.href
      .slice(window.location.href.indexOf("?") + 1)
      .split("&");
    for (var i = 0; i < hashes.length; i++) {
      hash = hashes[i].split("=");
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
    }
    return vars;
}

export function urlBase64ToUint8Array(base64String) {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');
  
    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);
  
    for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], {type: mimeString});
    return blob;
}

export function isNullOrUndefined(variable) {
    if(variable === undefined || variable === null) {
        return true;
    }
    return false;
}

export function isJsonString(str) {
    try {
        JSON.parse(str);
    } 
    catch (e) {
        return false;
    }
    return true;
}

export function usePrevious(value) {
    const ref = useRef();
    
    useEffect(() => {
        ref.current = value; //assign the value of ref to the argument
    },[value]); //this code will run when the value of 'value' changes
    
    return ref.current; //in the end, return the current ref value.
}
