import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const LogoText = styled.span`
    font-family:'latolight' !important;
    font-size: ${props => props.fontSizeInPx ? props.fontSizeInPx : '30'}px;
    color: ${props => props.fontColor ? props.fontColor : '#000'};
`

const AppText = styled.span`
    font-family:'Bauhaus 93' !important;
    font-size: ${props => props.fontSizeInPx ? props.fontSizeInPx : '30'}px;
    color: #ff0000;
    letter-spacing:1px;
`

function Logo(props) {
    return (
        <>
            <LogoText 
                fontSizeInPx={props.fontSizeInPx}
                fontColor={props.fontColor}
            >
                est
            </LogoText>
            <AppText 
                fontSizeInPx={props.fontSizeInPx}
            >
                app
            </AppText>
            <LogoText 
                fontSizeInPx={props.fontSizeInPx}
                fontColor={props.fontColor}
            >
                lish
            </LogoText>
        </>
    )
}

export default Logo;
