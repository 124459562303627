import React, { Component } from "react";
import styled from 'styled-components';
import Language from '../../../../constants/language';
import Button from "../../../../components/button.component";
import CalendarDay from '../../../../components/app/calendarDay.component';
import Comment from '../../../../components/app/comment.component';
import Salutation from '../../../../components/app/salutation.component';
import * as GuestTypes from '../../../../constants/guesttypes';
import * as Data from '../../../../backend/data';
import Participation from "../../../../components/app/participation.component";
import AppFeatures from "../../../../components/app/appFeatures.component";
import Status from "../../../../components/app/status.component";
import AppMessages from "../../../../containers/app/appMessages.container";
import BrowserIncompatabilityWarning from "../../../../components/app/browserIncompatibilityWarning.component";
import ErrorBoundary from '../../../../components/error/error.component';
import FallbackUI from '../../../../components/error/standardFallback.component';
import CookieAndPrivacyConsent from '../../../../components/cookieAndPrivacyConsent.component';
import { connect } from 'react-redux';
import { updateNavigation, updateLanguage, updateCsrfToken, updateToken } from '../../../../actions/index';
import Footer from '../../../../components/footer.component';
import ImprintView from '../../../../views/imprint.view'; 
import PrivacyView from '../../../../views/privacy.view';

const locationIcon = require('../../../../assets/images/pin_map_google_icon.svg');
const pdfIcon = require('../../../../assets/images/file-pdf.svg');

const StartImage = styled.div`
    display:flex;
    background-repeat:no-repeat;
    width:100vw;
    height:100vh;

    background-image:url(${props => (props.images !== undefined && props.images !== null && props.images.length > 0 && props.images.filter((image) => image.landing_page_template_image_id === 1 ).length > 0) ? "/event/images/show/" + props.images.filter((image) => image.landing_page_template_image_id === 1)[0].identifier_token : ''});
    background-position:top center;
    background-size:cover;

    @media (max-width: 768px) {
        background-image:url(${props => (props.images !== undefined && props.images !== null && props.images.length > 0 && props.images.filter((image) => image.landing_page_template_image_id === 2 ).length > 0) ? "/event/images/show/" + props.images.filter((image) => image.landing_page_template_image_id === 2)[0].identifier_token : ''});
        background-size:cover;
    }
`

const Background = styled.div`
    background-repeat:repeat;
    background-image:url(${props => (props.images !== undefined && props.images !== null && props.images.length > 0 && props.images.filter((image) => image.landing_page_template_image_id === 3 ).length > 0) ? "/event/images/show/" + props.images.filter((image) => image.landing_page_template_image_id === 3)[0].identifier_token : ''});
    background-size:cover;
    background-position:center;
    width:100%;
    height:100%;
    position:fixed;
    /*opacity:0.3;*/
    z-index:-2;
` 

const HeaderText = styled.div`
    font-family: 'Handlee', cursive;
    font-size:4rem;
    color:#021030;
    background-color:transparent;
` 

const Header = styled.div`
    position: absolute;
    top: 5%;
` 

const SubHeader = styled.div`

    font-family: 'Handlee', cursive;
    font-size:2.5rem;
    color:#021030;
`


const Text = styled.div`

    font-family: 'Handlee', cursive;
    font-size:2rem;
    color:#021030;
`

const Link = styled.a`
    color:#021030;
    font-family: 'Handlee', cursive;
    font-size:${props => (props.fontSize !== undefined) ? props.fontSize : '1rem'}

    &:hover {
        text-decoration:none;
        color:#021030;
    }
`

const Separator = styled.hr`
    width: 25%;
    border-color: #b7b7b7;
    margin-top:3rem;
    margin-bottom:3rem;
`

const MarginTop1rem = styled.div`
    margin-top:2rem;
`

const MarginTop2rem = styled.div`
    margin-top:2rem;
`

const Italic = styled.span`
    font-style: italic;
`

const Bold = styled.span`
    font-weight: bold;
`

const Underline = styled.span`
    text-decoration:underline;
`

const Icon = styled.img`
    height:${props => props.height};
`

const Modal = styled.div`
    position:absolute;
    top:3%;
    width: 90%;
    left:50%;
    margin-left:-45%;
    background-color:#fff;
    color:#000;
    border-radius:0.25rem;
    z-index:9999;
    padding: 1rem;
    height:90vh;
    overflow-y:auto;
`

const CloseButton = styled.button`
    position: absolute;
    z-index: 1;
    right: 20px;
`

const ContainerWithMobileView = styled.div`
    @media (max-width: 576px) {
        padding-left:0px;
        padding-right:0px;
    }
`

class InvitationStandardView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            landingPageLanguage: props.language,
            showNewContentAvailable: false,
            errorOccured: null
        };

        this.getLandingPageLanguage = this.getLandingPageLanguage.bind(this);
        this.setText = this.setText.bind(this);
    }

    componentDidMount() {
        this.setState({
            landingPageLanguage: this.getLandingPageLanguage()
        },() => {
            this.props.updateLanguage(this.state.landingPageLanguage);
        });

        if(window.location.hash === '#newMessage') {
            window.setTimeout(() => {
                document.getElementById("appMessagesEnd").scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
            },1500);
        }

        if('serviceWorker' in navigator) {
            //check new content available
            //see SO: https://stackoverflow.com/questions/37573482/to-check-if-serviceworker-is-in-waiting-state
            navigator.serviceWorker.ready
            .then((registration) => {
                if(registration.waiting && registration.active) {
                    this.setState({
                        showNewContentAvailable: true
                    });
                } 
                else {
                    // updatefound is also fired for the very first install. ¯\_(ツ)_/¯
                    registration.addEventListener('updatefound', () => {
                        registration?.installing?.addEventListener('statechange', () => {
                            if (event.target.state === 'installed') {
                                if (registration.active) {
                                    // If there's already an active SW, and skipWaiting() is not
                                    // called in the SW, then the user needs to close all their
                                    // tabs before they'll get updates.
                                    this.setState({
                                        showNewContentAvailable: true
                                    });
                                }
                            }
                        });
                    });
                }
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
    }

    getLandingPageLanguage() {
        let language = this.props.language;
        if(this.props.guestLandingPage != null) {
            if(this.props.guestLandingPage.landing_page.app_info.language_bcp47_code !== null) {
                if(this.props.guestLandingPage.landing_page.app_info.language_bcp47_code.indexOf('-') > -1) {
                    let languageSplitted = this.props.guestLandingPage.landing_page.app_info.language_bcp47_code.split('-');
                    language = languageSplitted[0];
                }
                else {
                    language = this.props.guestLandingPage.landing_page.app_info.language_bcp47_code;
                }
            }
        } 
        return language;
    }

    setText(type) {
        let returnText = (this.props.guestLandingPage.landing_page.app_texts !== undefined && this.props.guestLandingPage.landing_page.app_texts.length > 0 && this.props.guestLandingPage.landing_page.app_texts.filter((text) => text.type === type).length > 0 ? this.props.guestLandingPage.landing_page.app_texts.filter((text) => text.type === type)[0].text : '' );
        return {__html: returnText };
    }

    onDownloadPDFClick(event) {
        event.preventDefault();
        window.open("/events/" + this.props.guestLandingPage.landing_page.user_id + "/" + this.props.guestLandingPage.landing_page.url_id + "/Einladung.pdf");
    }

    onModalCloseButtonClick() {
        this.props.updateNavigation(null);
    }

    render() {
        let provideFeedback = (this.props.guestLandingPage.guest_type_id === GuestTypes.GUEST_TYPE_SINGLE_PERSON) ? Language[this.state.landingPageLanguage].message.provideFeedbackSinglePerson : Language[this.state.landingPageLanguage].message.provideFeedbackFamily;
        //let additionalButtonStyles = {border:'1px solid #d07979',backgroundColor:'#fde5e5',color:'#021030',height:'auto',fontFamily: 'Parisienne,cursiv',fontSize: '1.7rem'};
        let additionalButtonStyles = {backgroundColor:'#021030',color:'#fff',height:'auto',fontFamily: 'Handlee, cursive',fontSize: '1.7rem'};
        let additionalButtonStylesInvitationAsPdfButton = {backgroundColor:'#021030',color:'#fff',height:'auto',fontFamily: 'Handlee, cursive',fontSize: '1.1rem'};
        let buttonIconColor = '#021030';
        let additionalTextStyles = {fontFamily: 'Handlee, cursive'}

        let View;
        if(this.props.navigation === 'ImprintView') {
            View = <ImprintView key={"appImprintViewComponent"} location={"app"} language={this.state.landingPageLanguage} />;
        }
        if(this.props.navigation === 'PrivacyView') {
            View = <PrivacyView key={"appPrivacyViewComponent"} location={"app"} language={this.state.landingPageLanguage} />;
        }

        return (
            <>
                <Background key={"backgroundImageKey"} images={this.props.guestLandingPage.landing_page.app_images}></Background>
                <StartImage key={"startImageKey"} images={this.props.guestLandingPage.landing_page.app_images} />                
                <Header className="container-fluid">
                    <div className="row">
                        <MarginTop2rem className="col text-center">
                            <HeaderText key={"headerKey"}>
                                {
                                    this.props.guest != null &&
                                    this.props.guest.landing_page.event.name
                                }
                            </HeaderText>
                        </MarginTop2rem>
                    </div>
                </Header>
                <div className="container-fluid">
                    <div className="row">
                        <MarginTop2rem className="col text-center">
                            <SubHeader key={"subHeaderKey1"}>
                                {
                                    this.props.guestLandingPage != null &&
                                    this.props.guestLandingPage.landing_page.name
                                }
                            </SubHeader>
                        </MarginTop2rem>
                        <div className="col-12 text-center">
                            <SubHeader key={"subHeaderKey2"}>
                                {
                                    
                                    this.props.guestLandingPage != null &&
                                    <CalendarDay 
                                        key={"calendarDateDay"}
                                        date={this.props.guestLandingPage.landing_page.event.event_date} 
                                        language={this.state.landingPageLanguage} 
                                    />
                                    
                                }
                            </SubHeader>
                        </div>
                        <MarginTop2rem className="col-12 d-flex justify-content-center">
                            {
                                this.props.guestLandingPage != null &&
                                <Link href={"/events/" + this.props.guestLandingPage.landing_page.user_id + "/" + this.props.guestLandingPage.landing_page.url_id + "/calendar.ics"}>
                                    
                                    <CalendarDay 
                                        key={"calendarDateDay"}
                                        showAsCalendarIcon={true}
                                        date={this.props.guestLandingPage.landing_page.event.event_date} 
                                        language={this.state.landingPageLanguage} 
                                    />
                                    
                                </Link>
                            }
                        </MarginTop2rem>
                        <div className="col-12 text-center">
                            <Link fontSize={"1.25rem"} href={"/events/" + this.props.guestLandingPage.landing_page.user_id + "/" + this.props.guestLandingPage.landing_page.url_id + "/calendar.ics"} download>
                                { Language[this.state.landingPageLanguage].app.saveInCalendar }
                            </Link>
                        </div>
                    </div>
                    <MarginTop2rem className="row">
                        <div className="col-12 text-center">
                            <Text key={"subHeaderKey3"}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <Italic>
                                                "I found a love, to carry more than just my secrets
                                                <br />
                                                To carry love, to carry children of our own"
                                            </Italic>
                                        </div>
                                        <div className="col-12 text-end">
                                            <small>
                                                Ed Sheeran - "Perfect"
                                            </small>
                                        </div>
                                    </div>
                                    <MarginTop2rem className="row">
                                        <div className="col-12 text-center">
                                            <Bold>
                                                Feiert mit uns!
                                            </Bold>
                                        </div>
                                    </MarginTop2rem>
                                    <MarginTop2rem className="row">
                                        {/*
                                        <div className="col-12 text-center">
                                            <Salutation 
                                                language={this.state.landingPageLanguage} 
                                                guestLandingPage={this.props.guestLandingPage} 
                                            />
                                        </div>
                                        */}
                                        <div className="col-12 text-center">
                                            Wir haben geheiratet und möchten uns gerne im Kreise unserer Familie und Freunde noch ein Mal das Ja-Wort geben. Wir laden euch herzlich ein diesen besonderen Tag mit uns zu verbringen! 
                                            Unsere Freie Trauung findet am
                                        </div>
                                    </MarginTop2rem>
                                    <MarginTop2rem className="row">
                                        <div className="col-12 text-center">
                                            Freitag, dem 13. August 2021, um 15:00 Uhr
                                            am Neimeshof (im Stadtfeld 32, 47906 Kempen) statt.
                                        </div>
                                        <div className="col-12 text-center">
                                            <a href="https://www.google.com/maps/place/Neimeshof/@51.386979,6.420392,17z/data=!3m1!4b1!4m5!3m4!1s0x47b8a6fc2610fb2b:0x5f03474b6be935b2!8m2!3d51.3867618!4d6.4223667" target="_blank">
                                                <Icon height={"60px"} src={locationIcon.default} alt="Location Icon" className="img-fluid" />
                                            </a>
                                        </div>
                                    </MarginTop2rem>
                                    <MarginTop2rem className="row">
                                        <div className="col-12 text-center">
                                            Im Anschluss möchten wir mit euch ein rauschendes Familienfest feiern, gefolgt von gemeinsamen Abendessen & Party.
                                        </div>
                                    </MarginTop2rem>

                                    <MarginTop2rem className="row">
                                        <div className="col-12 text-center">
                                            Wir freuen uns auf Eure Zusage bis zum <Bold>16. Juli 2021</Bold>. Teilt uns dann auch bitte direkt euren Lieblingssong mit, der auf der Party nicht fehlen darf.
                                        </div>
                                    </MarginTop2rem>

                                    <MarginTop2rem className="row">
                                        <div className="col-12 text-center">
                                            <Bold>Details zur Feier</Bold>
                                        </div>
                                        <div className="col-12 text-center">
                                            <Underline>Ablauf</Underline>
                                        </div>
                                    </MarginTop2rem>
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            15.15 Uhr Freie Trauung
                                        </div>
                                        <div className="col-12 text-center">
                                            16:00 Uhr Sekt, Selters und Schnabulöses
                                        </div>
                                        <div className="col-12 text-center">
                                            ab 18.00 Uhr Buffet
                                        </div>
                                        <div className="col-12 text-center">
                                            danach Party...
                                        </div>
                                    </div>
                                    <MarginTop2rem className="row">
                                        <div className="col-12 text-center">
                                            <Underline>Besonderes</Underline>
                                        </div>
                                    </MarginTop2rem>
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            Teilt uns über das Kommentarfeld bitte mit, ob ihr z.B. einen Kinderhochstuhl zum Essen benötigt oder ob ihr vegetarisch bzw. vegan essen möchtet. Dann können wir das bei der Planung berücksichtigen.
                                        </div>
                                        <div className="col-12 text-center">
                                            Damit wir alle gemeinsam sicher und mit gutem Gefühl ausgelassen feiern können, möchten wir euch bitten, am Tag der Hochzeit einen Corona-Schnelltest zu machen. Eine Maskenpflicht für Gäste besteht aktuell nicht, das Servicepersonal wird an dem Tag Maske tragen. Kurz vor der Feier senden wir euch ggfs. noch mal ein Update dazu.
                                        </div>
                                    </div>

                                    <MarginTop2rem className="row">
                                        <div className="col-12 text-center">
                                            <Underline>Organisation</Underline>
                                        </div>
                                    </MarginTop2rem>
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            Ihr möchtet unseren besonderen Tag ein wenig mitgestalten? Dann wendet Euch gerne an unsere beiden Trauzeugen:
                                            <br />
                                            Nadine Koch (<Link fontSize={"1.75rem"} href="tel:+4915229290781" target="_blank">01522 9290781</Link>) und Christoph Pfeil-Schneider (<Link fontSize={"1.75rem"} href="tel:+491629054170" target="_blank">0162 9054170</Link>)
                                        </div>
                                    </div>
                                    <MarginTop2rem className="row">
                                        <div className="col-12 text-center">
                                            <Underline>Geschenke</Underline>
                                        </div>
                                    </MarginTop2rem>
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            Mit Euch feiern zu können ist für uns das größte Geschenk! Solltet Ihr uns dennoch etwas schenken wollen, freuen wir uns über eine kleine Unterstützung für die nächste Reise in unserem Wohnwagen.
                                        </div>
                                    </div>
                                    <MarginTop2rem className="row">
                                        <div className="col-12 text-center">
                                            Wir freuen uns auf euch! 
                                        </div>
                                    </MarginTop2rem>
                                    <MarginTop2rem className="row">
                                        <div className="col-12 text-center">
                                            Wintha und Dennis
                                        </div>
                                    </MarginTop2rem>

                                    <MarginTop2rem className="row">
                                        <div className="offset-3 col-6 text-center">
                                            <Button
                                                key={"downloadPDFInvitation"}
                                                buttonText={Language[this.state.landingPageLanguage].general.invitation}
                                                additionalClasses={""}
                                                additionalStyles={additionalButtonStylesInvitationAsPdfButton}
                                                onClicked={this.onDownloadPDFClick.bind(this)}
                                                disabled={false}
                                                icon={pdfIcon.default}
                                                iconColor={"#fff"}
                                                iconSize={"20px"}
                                            />
                                        </div>
                                    </MarginTop2rem>
                                </div>
                            </Text>
                        </div>
                    </MarginTop2rem>

                    <MarginTop2rem className="row">
                        <Participation 
                            key="participate"
                            guestLandingPage={this.props.guestLandingPage}
                            language={this.state.landingPageLanguage}
                            additionalButtonStyles={additionalButtonStyles} 
                            additionalTextStyles={additionalTextStyles}
                        />
                    </MarginTop2rem>
                    <MarginTop2rem className="row">
                        <div className="col-12 text-center" style={additionalTextStyles}>
                            { provideFeedback }
                        </div>
                    </MarginTop2rem>
                    <MarginTop2rem className="row">
                        <div className="col-12 text-center">
                            <div className="container">
                                <Comment 
                                    key={"leaveComment"}
                                    guestLandingPage={this.props.guestLandingPage}
                                    language={this.state.landingPageLanguage}
                                    additionalButtonStyles={additionalButtonStyles}
                                    additionalTextStyles={additionalTextStyles}
                                />
                            </div>
                        </div>
                    </MarginTop2rem>
                    <MarginTop2rem className="row">
                        <Separator 
                            key={"separatorAppMessages"}
                        />
                    </MarginTop2rem>
                    <MarginTop2rem className="row">
                        <div className="col text-center">
                            <div className="container-lg">
                                <div className="row">
                                    <div className="col text-center">
                                        <AppMessages    
                                            key={"appMessages"}
                                            messages={this.props.guestLandingPage.messages}
                                            textStyle={additionalButtonStyles}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </MarginTop2rem>
                    <div className="row">
                        <div className="col">
                            <br />
                            <br />
                            {/* 
                            <img src="http://localhost:8001/builder/images/show/1ndCzR1ntp889GinuytpKG8T1KQIC5EYbsbnByMF2fTCwAw9tb" className="img-fluid" alt="" />
                            */}
                        </div>
                    </div>
                </div>
                {/*</Background>*/}
                <AppFeatures
                    key={"appFeatuesComponentKey"}
                    language={this.state.landingPageLanguage}
                    additionalButtonStyles={additionalButtonStyles}
                    guestLandingPage={this.props.guestLandingPage}
                    buttonIconColor={buttonIconColor}
                />
                <Status 
                    key={"statusComponentKey"} 
                    language={this.state.landingPageLanguage}
                />
                <BrowserIncompatabilityWarning 
                    key={"browserIncompatibalityWarning"}
                    language={this.state.landingPageLanguage}
                />
                <CookieAndPrivacyConsent 
                    key={"cookieAndPrivacyConsentComponent"}
                    location={"app"}
                />
                <Footer
                    key={"footerComponent"}
                    location={"app"} 
                    {...this.props}
                />
                {
                    this.state.errorOccured !== null &&
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                {
                                    this.state.errorOccured.error
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center">
                                {
                                    this.state.errorOccured.info
                                }
                            </div>
                        </div>
                    </div>
                }
                {
                    (this.props.navigation === 'PrivacyView' || this.props.navigation === 'ImprintView') &&
                    <Modal>
                        <ContainerWithMobileView className="container-fluid">
                            <div className="row d-flex align-items-center text-center">
                                <div className="col">
                                    <CloseButton type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.onModalCloseButtonClick.bind(this)}>
                                        <span aria-hidden="true">&times;</span>
                                    </CloseButton>
                                    { View }
                                </div>
                            </div>
                        </ContainerWithMobileView>
                    </Modal>
                }
            </>
        );
    }
}


function mapStateToProps({ navigation, language, csrfToken, token }) {
    return {
        navigation,
        language,
        csrfToken,
        token,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateNavigation: navigation => dispatch(updateNavigation(navigation)),
        updateLanguage: language => dispatch(updateLanguage(language)),
        updateCsrfToken: csrfToken => dispatch(updateCsrfToken(csrfToken)),
        updateToken: token => dispatch(updateToken(token)),
    };
}


const ComponentWithErrorBoundary = (props) =>  {
    return ( 
        <ErrorBoundary
            location={"app"}
            component={"event/wedding/invitation.view.js"}
            fallbackUI={<FallbackUI language={props.language} component={"invitation"} />}
        >
            <InvitationStandardView
                key={"weddingInvitationViewComponent"}
                {...props}
            />
       </ErrorBoundary>
   )
}

 
const Form = connect(
    mapStateToProps,
    mapDispatchToProps
)(ComponentWithErrorBoundary);
 

export default Form; //ComponentWithErrorBoundary; //InvitationStandardView;