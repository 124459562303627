import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import LoadingSpinner from '../components/loadingSpinner.component';
import Language from '../constants/language';
import ErrorBoundary from '../components/error/error.component';
import FallbackUI from '../components/error/standardFallback.component';
import TextReplacement from '../components/app/textReplacement.component';
import * as Data from '../backend/data';
import { format } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import { useSelector, useDispatch } from 'react-redux';


const ContainerWithMobileView = styled.div`
    @media (max-width: 576px) {
        padding-left:0px;
        padding-right:0px;
    }
`;

const H1 = styled.h1`
    text-transform: capitalize;

    @media (max-width: 576px) {
        font-size:1.5rem;
    }
`;

const H3 = styled.h3`
    @media (max-width: 576px) {
        font-size:1.25rem;
    }
`

function PrivacyView(props) {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);
    const csrfToken = useSelector((state) => state.csrfToken.value);

    const [isLoading,setIsLoading] = useState(true);
    const [userAcceptedPrivacyStatementDateTime, setUserAcceptedPrivacyStatementDateTime] = useState(null);
    const [privacyStatement, setPrivacyStatement] = useState(null);

    function getCurrentLegalData() {
        setIsLoading(true);
        let formData = [
            {
                name:'csrfToken',
                value: csrfToken
            }
        ];
        Data.transfer(window.baseUrl + '/legals/privacyStatement/' + language,'POST',formData,undefined,undefined,csrfToken)
        .then((data) => {
            setIsLoading(false);
            if(data.success) {
                setPrivacyStatement(data.currentPrivacyStatement);
                if(data.acceptedDateTime) {
                    setUserAcceptedPrivacyStatementDateTime(data.acceptedDateTime);
                }
            }
        });
    }

    useEffect(() => {
        getCurrentLegalData();
    },[language]);

    return (
        <ContainerWithMobileView className="container-fluid">
            {
                isLoading === true &&
                <div className="row">
                    <div className="col text-center">
                        <LoadingSpinner key="loadingSpinnerLegals"></LoadingSpinner>
                    </div>
                </div>
            }
            {
                isLoading === false &&
                <>
                    <div className="row">
                        <div className="col">
                            <H1>
                                {  
                                    Language[language].legals.privacyAgreement 
                                }
                            </H1>
                        </div>
                    </div>
                    {
                        userAcceptedPrivacyStatementDateTime !== null &&
                        <div className="row mt-2">
                            <div className="col text-center">
                                {
                                    Language[language].legals.acceptedPrivacyAgreementOnPart1 + ' ' + format(new Date(parseISO(userAcceptedPrivacyStatementDateTime)), 'dd.MM.yyyy hh:mm') + ' ' + Language[props.language].legals.acceptedPrivacyAgreementOnPart2 + '.'
                                }
                            </div>
                        </div>
                    }
                    {
                        privacyStatement !== null && privacyStatement.privacy_statement_texts?.map((text) => {
                            return (
                                <div key={"privacyStatementText" + text.id} className={(text.is_header === 1) ? "row mt-3" : "row mt-1"}>
                                    <div>
                                        {
                                            text.is_header === 1 &&
                                            <h2>
                                                {
                                                    text.order_no + ' ' + text.text
                                                }
                                            </h2>
                                        }
                                        {
                                            text.is_sub_header === 1 &&
                                            <h3>
                                                {
                                                    text.order_no + ' ' + text.text
                                                }
                                            </h3>
                                        }
                                        {
                                            text.is_bold_text === 1 &&
                                            <span className="fw-bold">
                                                {
                                                    text.order_no + ' ' + text.text
                                                }
                                            </span>
                                        }
                                        {
                                            text.is_header === 0 && text.is_sub_header === 0 && text.is_bold_text === 0 &&
                                            <TextReplacement
                                                key={"text" + text.id}
                                                text={text.text}
                                                language={props.language}
                                            />
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </>
            }
        </ContainerWithMobileView>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"privacy.view.js"}
            fallbackUI={<FallbackUI language={language} component={"privacy"} />}
        >
            <PrivacyView
                key={"privacyViewComponent"}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;