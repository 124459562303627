import React from 'react';
import ProductName from '../components/productName.component';
import ProductDescription from '../components/productDescription.component';
import ProductNumber from '../components/productNumber.component';
import NetPrice from '../components/netPrice.component';
import ShippingCosts from '../components/shippingCosts.component';
import MaxNumberProductsInShippingPrice from '../components/maxNumberProductsInShippingPrice.component';
import ProductSalesTax from '../components/productSalesTax.component';
import QuantityAvailable from '../components/quantityAvailable.component';
import ProductImages from '../components/productImages.component';
import ProductSummary from '../components/productSummary.component';
import { updateProduct } from '../../../../../../factories/productSetup.factory';
import { isNullOrUndefined } from '../../../../../../utils/utils';
import { useSelector, useDispatch } from 'react-redux';
import { setProductSetupWizard } from '../../../../../../reducers/slices/productSetupWizard.slice';
import { isPriceValid } from '../../../../../../utils/price';
import _ from 'lodash';

export const useGoodsSetupWizardController = () => {
    const dispatch = useDispatch();
    //const productSetupWizard = useSelector((state) => state.productSetupWizard.value);

    const STEP_PRODUCT_NAME = 2;
    const STEP_PRODUCT_DESCRIPTION = 3;
    const STEP_PRODUCT_NUMBER = 4;
    const STEP_NET_PRICE = 5;
    const STEP_SHIPPING_COSTS = 6;
    const STEP_MAX_NUMBER_OF_PRODUCTS_IN_SHIPPING_PRICE = 7;
    const STEP_PRODUCT_SALES_TAX = 8;
    const STEP_QUANTITY_AVAILABLE = 9;
    const STEP_PRODUCT_IMAGES = 10;
    const STEP_PRODUCT_SUMMARY = 11;

    function getMaxStepGoodsController() {
        return STEP_PRODUCT_SUMMARY;
    }

    function getComponentGoodsController(productSetupWizard) {
        switch(productSetupWizard.step) {
            case STEP_PRODUCT_NAME:
                return (
                    <ProductName
                        key={"productNameComponent"}
                    />
                );
            case STEP_PRODUCT_DESCRIPTION:
                return (
                    <ProductDescription
                        key={"productNameComponent"}
                    />
                );
            case STEP_PRODUCT_NUMBER:
                return (
                    <ProductNumber
                        key={"productNumberComponent"}
                    />
                );
            case STEP_NET_PRICE:
                return (
                    <NetPrice
                        key={"netPriceComponent"}
                    />
                );
            case STEP_SHIPPING_COSTS:
                return (
                    <ShippingCosts
                        key={"shippingCostsComponent"}
                    />
                );
            case STEP_MAX_NUMBER_OF_PRODUCTS_IN_SHIPPING_PRICE:
                return (
                    <MaxNumberProductsInShippingPrice
                        key={"maxNumberProductsInShippingPriceComponent"}
                    />
                );
            case STEP_PRODUCT_SALES_TAX:
                return (
                    <ProductSalesTax
                        key={"productSalesTaxComponent"}
                    />
                );
            case STEP_QUANTITY_AVAILABLE:
                return (
                    <QuantityAvailable
                        key={"quantityAvailableComponent"}
                    />
                );
            case STEP_PRODUCT_IMAGES:
                return (
                    <ProductImages
                        key={"productImagesComponent"}
                    />
                );
            case STEP_PRODUCT_SUMMARY:
                return (
                    <ProductSummary
                        key={"productSummaryComponent"}
                    />
                );
        }
    }

    function isNextButtonDisabledGoodsController(productSetupWizard) {
        let returnValue = false;
        switch(productSetupWizard?.step) {
            case STEP_PRODUCT_NAME:
                if(isNullOrUndefined(productSetupWizard?.product?.name) || productSetupWizard?.product?.name.trim().length === 0) {
                    returnValue = true;
                }
                break;
            case STEP_PRODUCT_DESCRIPTION:
                if(isNullOrUndefined(productSetupWizard?.product?.description) || productSetupWizard?.product?.description.toString().trim().length === 0) {
                    returnValue = true;
                }
                break;
            case STEP_PRODUCT_NUMBER:
                if(isNullOrUndefined(productSetupWizard?.product?.product_no) || productSetupWizard?.product?.product_no.trim().length === 0) {
                    returnValue = true;
                }
                break;
            case STEP_NET_PRICE:
                if(isNullOrUndefined(productSetupWizard?.product?.price_net) || !isPriceValid(productSetupWizard?.product?.price_net)) {
                    returnValue = true;
                }
                break;
            case STEP_MAX_NUMBER_OF_PRODUCTS_IN_SHIPPING_PRICE:
                if(isNullOrUndefined(productSetupWizard?.product?.maximum_number_of_products_in_shipping_price) || productSetupWizard?.product?.maximum_number_of_products_in_shipping_price === '' || productSetupWizard?.product?.maximum_number_of_products_in_shipping_price < 1) {
                    returnValue = true;
                }
                break;
            case STEP_PRODUCT_SALES_TAX:
                if(productSetupWizard?.product?.is_default_sales_tax_of_country === false && (productSetupWizard?.product?.sales_tax_amount_in_percent < 1 || productSetupWizard?.product?.sales_tax_amount_in_percent > 100 || productSetupWizard?.product?.sales_tax_amount_in_percent === '')) {
                    returnValue = true;
                }
                break;
            case STEP_QUANTITY_AVAILABLE:
                if(productSetupWizard?.product?.unlimited_number_available === false && (productSetupWizard?.product?.number_available_goods < 1 || productSetupWizard?.product?.number_available_goods === '')) {
                    returnValue = true;
                }
                break;
        }
        return returnValue;
    }

    function needsNextStepToBeSkippedGoodsController(productSetupWizard, user) {
        let returnValue = false;
        switch(productSetupWizard?.step) {
            case STEP_MAX_NUMBER_OF_PRODUCTS_IN_SHIPPING_PRICE:
                if(
                    (
                        isNullOrUndefined(productSetupWizard?.product?.shipping_costs_domestic) &&
                        isNullOrUndefined(productSetupWizard?.product?.shipping_costs_european_foreign_countries) &&
                        isNullOrUndefined(productSetupWizard?.product?.shipping_costs_other_countries)
                    ) ||
                    (
                        parseFloat(productSetupWizard?.product?.shipping_costs_domestic) === 0 ||
                        parseFloat(productSetupWizard?.product?.shipping_costs_domestic) === NaN
                    ) &&
                    (
                        parseFloat(productSetupWizard?.product?.shipping_costs_european_foreign_countries) === 0 ||
                        parseFloat(productSetupWizard?.product?.shipping_costs_european_foreign_countries) === NaN
                    ) &&
                    (
                        parseFloat(productSetupWizard?.product?.shipping_costs_other_countries) === 0 ||
                        parseFloat(productSetupWizard?.product?.shipping_costs_other_countries) === NaN
                    )
                ) {
                    returnValue = true;
                }
                break;
            case STEP_PRODUCT_SALES_TAX:
                if(user.business_data.is_liable_to_pay_sales_tax === 0) {
                    returnValue = true;
                }
                break;
            default:
                returnValue = false;
                break;
        }
        return returnValue;
    }

    return {
        getComponentGoodsController,
        isNextButtonDisabledGoodsController,
        needsNextStepToBeSkippedGoodsController,
        getMaxStepGoodsController
    };
}
