import React, { Component, useState, useEffect } from 'react';
import Language from '../constants/language';
import ErrorBoundary from './error/error.component';
import FallbackUI from './error/standardFallback.component';
import styled, { keyframes } from 'styled-components';
import * as Data from '../backend/data';
import { useSelector, useDispatch } from 'react-redux';
import { setNavigation } from '../reducers/slices/navigation.slice';
import { setUser } from '../reducers/slices/user.slice';
import { setIsSignedIn } from '../reducers/slices/isSignedIn.slice';
import { setLanguage } from '../reducers/slices/language.slice';
import Logo from '../components/styled/logo.component';

import 'flag-icon-css/css/flag-icon.min.css';

const Flag = styled.div`
    display:  inline-block;
    max-width: 100%;
    width:30px !important;
    height: 20px;
    border-radius: 5px;
    background-size: cover;
    background-repeat: no-repeat;
    border:1px solid #eee;
    background-position: center;
    cursor:pointer;
`

const fadeInBackground = keyframes`
    0% {
        background-color: transparent;
    }
    100% {
        background-color: #fff;
    }
`;

const Nav = styled.nav`
    opacity: 0.88 !important;
    background-color:transparent;
    z-index:5001;
    padding-top:0;

    @media (max-width: 974px) {
        background-color:transparent;
        animation-name: ${props => props.isNavBarOpen ? fadeInBackground : 'none'};
        animation-duration: ${props => props.isNavBarOpen ? '2s' : 'unset'};
        animation-fill-mode: ${props => props.isNavBarOpen ? 'forwards' : 'none'};
    }
` 

const LogoImage = styled.img`
    padding-right:1rem;
` 

const NavBackground = styled.div`
    width:100vw;
    height:56px;
    position:fixed;
    z-index:5000;
    background-color:#fff;
    opacity:${props => props.opacityActive ? 0.4 : 1};
`

function NavBarComponent(props) {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);
    const csrfToken = useSelector((state) => state.csrfToken.value);
    const isSignedIn = useSelector((state) => state.isSignedIn.value);

    const languagesToShow = ['de','en'];
    const [isNavBarOpen, setIsNavbarOpen] = useState(false);

    function onLinkClick(event,target) {
        event.preventDefault();
        setIsNavbarOpen(false);
        if(props.hideMenuOptions) {
           window.location = window.location; 
        }
        else {
            dispatch(setNavigation(target));
        }
    }

    function openNavbarMenu() {
        setIsNavbarOpen(!isNavBarOpen);
    }

    function logout() {
        window.localStorage.clear();
        dispatch(setUser(null));
        dispatch(setIsSignedIn(false));
        dispatch(setNavigation('StartView'));
        fetch(window.baseUrl + '/builder/logout', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'X-CSRF-TOKEN': csrfToken
            },
        })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
        })
        .catch((err) => {
        });
    }

    function handleClickLanguage(language) {
        dispatch(setLanguage(language));
        if(isSignedIn) {
            let formData = [
                {
                    name:'language',
                    value: language
                },
                {
                    name:'csrfToken',
                    value: csrfToken
                }
            ];
            Data.transfer(window.baseUrl + '/builder/user/setLanguage','POST',formData,null,null,csrfToken);
        }
    }   

    return (
        <>
            <NavBackground opacityActive={props.opacityActive} />
            <Nav isNavBarOpen={isNavBarOpen} className="navbar navbar-expand-lg navbar-light fixed-top">
                <a className="navbar-brand cursorPointer" onClick={(e) => onLinkClick(e,null)}>
                    <Logo />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={openNavbarMenu}>
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className={"collapse navbar-collapse" + (isNavBarOpen ? ' show' : '')} id="navbarSupportedContent">
                    {
                        isSignedIn === false && !props.hideMenuOptions &&
                        <ul className="navbar-nav mr-auto mt-2">
                            <li className="nav-item">
                                <a className="nav-link cursorPointer" onClick={(e) => onLinkClick(e,'RegisterView')}>{Language[language].menu.register}</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link cursorPointer" onClick={(e) => onLinkClick(e,'SignInView')}>{Language[language].menu.signIn}</a>
                            </li>
                        </ul>
                    }
                    {
                        isSignedIn === true && !props.hideMenuOptions &&
                        <>
                            <ul className="navbar-nav ms-auto">
                            </ul>
                            <ul className="navbar-nav ms-sm-2">
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {Language[language].menu.account}
                                    </a> 
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <a className="nav-link cursorPointer" onClick={(e) => onLinkClick(e,'AccountView')}>{Language[language].menu.account}</a>
                                        <a className="nav-link cursorPointer" onClick={logout}>{Language[language].menu.logout}</a>
                                    </div>
                                </li>
                            </ul>
                        </>
                    }
                    {
                        props.hideMenuOptions &&
                        <>
                            <ul className="navbar-nav ms-auto">
                            </ul>
                        </>
                    }
                    <span className="navbar-text text-center fontSizeXSmall d-flex align-items-center">
                        {
                            languagesToShow.map((language) => {
                                return (
                                    <Flag key={"navbarComponentLanguageSelection" + language} className={Language[language].language.flag}  onClick={(e) => handleClickLanguage(language)} alt={Language[language].language.name}></Flag>
                                );
                            })
                        }
                    </span>
                </div>
            </Nav>
        </>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"navbar.component.js"}
            user={props.user}
            fallbackUI={<FallbackUI language={language} component={"navigation"} />}
        >
            <NavBarComponent
                key={"navBarComponent"}
                {...props}
            />
       </ErrorBoundary>
   )
}
 
export default ComponentWithErrorBoundary;