import { createSlice } from '@reduxjs/toolkit';
import * as Utils from '../../utils/utils';

const initialState = {
    value: Utils.reloadWaitingSyncs(),
};

export const waitingSyncsSlice = createSlice({
  name: 'waitingSyncs',
  initialState,
  reducers: {
    set: (state, action) => {
      state.value = action.payload
    },
  },
});

export const { set } = waitingSyncsSlice.actions;

export default waitingSyncsSlice.reducer;