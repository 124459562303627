import React, { Component } from 'react';
import Language from '../../constants/language';
import Button from '../button.component';
import TextInput from '../textInput.component';
import Selectbox from '../selectbox.component';
import Message from './message.component';
import * as Utils from '../../utils/utils';
import * as GuestTypes from '../../constants/guesttypes';
import * as Data from '../../backend/data';
import * as MessageTypes from '../../constants/messagetypes';
import styled from 'styled-components';
var jwt = require('jsonwebtoken');
let checked = require('../../assets/images/check.svg').default;

class Participation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            iDbTable: 'participation',
            iDbSyncTable: 'sync-participation',
            answer: null,
            noOfPersons: null,
            message: null,
        }
        this.onLoadCurrentParticipationSuccess = this.onLoadCurrentParticipationSuccess.bind(this);
        this.onLoadCurrentParticipationFailure = this.onLoadCurrentParticipationFailure.bind(this);
        this.onSaveSuccess = this.onSaveSuccess.bind(this);
        this.onRequestDataFailure = this.onRequestDataFailure.bind(this);
        this.readIDbTable = this.readIDbTable.bind(this);
    }

    componentDidMount() {
        let formData = [
            {
                name:'identifierToken',
                value: this.props.guestLandingPage.identifier_token
            }
        ];
        Data.transfer(window.location.origin + '/participation/get','POST',formData,this.onLoadCurrentParticipationSuccess,this.onLoadCurrentParticipationFailure,null);
    }

    componentDidUpdate(prevProps) {
    }

    onLoadCurrentParticipationSuccess(data) {
        if(data.success) {
            if(data.data.answer === 1) {
                data.data.answer = 'yes'
            }
            else if(data.data.answer === 0) {
                data.data.answer = 'no'
            }
            Utils.idbClearAllData(this.state.iDbTable);
            Utils.idbWriteData(this.state.iDbTable,data.data)
            .then(() => {
                this.readIDbTable();
            });
        }
    }

    onLoadCurrentParticipationFailure(error) {
        this.readIDbTable();
    }

    readIDbTable() {
        Utils.idbReadAllData(this.state.iDbTable)
        .then((data) => {
            if(data.length > 0) {
                this.setState({
                    answer: data[0].answer,
                    noOfPersons: data[0].noOfPersons
                });
            }
        })
    }

    onParticipateYesButtonClick(event) {
        event.preventDefault();
        let noOfPersons = this.state.noOfPersons;
        if(this.props.guestLandingPage.max_persons === 1) {
            noOfPersons = 1;
        }
        this.setState({
            answer: (this.state.answer === 'yes' ? null : 'yes'),
            noOfPersons: (this.state.answer === 'yes' ? null : noOfPersons),
        },() => {
            this.sendParticipationAnswer(this.state.answer, this.state.noOfPersons);
        });
    }

    onParticipateNoButtonClick(event) {
        event.preventDefault();
        this.setState({
            answer: (this.state.answer === 'no' ? null : 'no'),
            noOfPersons: null
        },() => {
            this.sendParticipationAnswer(this.state.answer, this.state.noOfPersons);
        });
    }

    sendParticipationAnswer(answer, noOfPersons) {
        let route = window.location.origin + '/participation/save';
        let guestLandingPage = this.props.guestLandingPage;
        let participationToken = jwt.sign({ guestLandingPage, answer, noOfPersons }, process.env.MIX_JWT_PRIVATE_KEY, { algorithm: 'HS512'});
        let formData = [
            {
                name:'data',
                value: participationToken
            }
        ];
        if('serviceWorker' in navigator && 'SyncManager' in window) {
            navigator.serviceWorker.ready
            .then((sw) => {
                Utils.idbClearAllData(this.state.iDbSyncTable);
                let objectToStore = new Object();
                objectToStore.id = Math.round(Math.random() * 10000000);
                objectToStore.data = participationToken;
                objectToStore.table = this.state.iDbTable;
                objectToStore.syncTable = this.state.iDbSyncTable;
                objectToStore.url = route;
                Utils.idbWriteData(this.state.iDbSyncTable,objectToStore)
                .then(() => {
                    sw.sync.register(this.state.iDbSyncTable);
                })
                .then(() => {
                    //this.onSaveForSyncSuccess();
                })
                .catch(function (err) {
                    console.log(err);
                });
            });
        }
        else {
            Data.transfer(route,'POST',formData,this.onSaveSuccess,this.onRequestDataFailure,this.props.csrfToken);
        }        
    }

    onSaveSuccess(data) {
        this.setState({
            message: {
                messageType: MessageTypes.MESSAGE_TYPE_SUCCESS,
                message: 'SUCCESS'
            }
        },() => {
            window.setTimeout(() => {
                this.setState({
                    message: null 
                });
            },5000);
        });
    }

    onRequestDataFailure(error) {
        this.setState({
            message: {
                messageType: MessageTypes.MESSAGE_TYPE_ERROR,
                message: error
            }
        },() => {
            window.setTimeout(() => {
                this.setState({
                    message: null 
                });
            },5000);
        });
    }

    onNoOfPersonsChanged(event) {
        this.setState({
            noOfPersons: (event.target.value !== "-1") ? event.target.value : null
        },() => {
            this.sendParticipationAnswer(this.state.answer, this.state.noOfPersons);
        });
    }

    onNoOfPersonsBlur(event) {
        if(event.target.value > this.props.guestLandingPage.max_persons) {
            this.setState({
                noOfPersons: this.props.guestLandingPage.max_persons
            },() => {
                this.sendParticipationAnswer(this.state.answer, this.state.noOfPersons);
            });
        }
        if(event.target.value < 1) {
            this.setState({
                noOfPersons: 1
            },() => {
                this.sendParticipationAnswer(this.state.answer, this.state.noOfPersons);
            });
        }
    }

    render() {
        let participate = (this.props.guestLandingPage.guest_type_id === GuestTypes.GUEST_TYPE_SINGLE_PERSON) ? Language[this.props.language].participation.participateSinglePerson : Language[this.props.language].participation.participateFamily;
        let noOfPersonsList = [];
        for(let a = 0; a <= this.props.guestLandingPage.max_persons; a++) {
            let displayText = a + " " + Language[this.props.language].participation.persons;
            let value = a;
            if(a === 0) {
                displayText = Language[this.props.language].general.pleaseSelect;
                value = -1;
            }
            if(a === 1) {
                displayText = a + " " + Language[this.props.language].participation.person;
            }
            noOfPersonsList.push({value: value, display: displayText});
        }
        return (
            <>
                <div className="container" style={this.props.additionalTextStyles}>
                    <div className="row">
                        <div className="col text-center">
                            {
                                participate
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="offset-lg-2"></div>
                        <div className="col">
                            <Button
                                key={"participateYes"}
                                buttonText={Language[this.props.language].participation.yes}
                                additionalClasses={""}
                                additionalStyles={this.props.additionalButtonStyles}
                                onClicked={this.onParticipateYesButtonClick.bind(this)}
                                disabled={false}
                                icon={checked}
                                showIconOnValue={'yes'}
                                iconValue={this.state.answer}
                            />
                        </div>
                        <div className="col">
                            <Button
                                key={"participateNo"}
                                buttonText={Language[this.props.language].participation.no}
                                additionalClasses={""}
                                additionalStyles={this.props.additionalButtonStyles}
                                onClicked={this.onParticipateNoButtonClick.bind(this)}
                                disabled={false}
                                icon={checked}
                                showIconOnValue={'no'}
                                iconValue={this.state.answer}
                            />
                        </div>
                        <div className="offset-lg-2"></div>
                    </div>
                    {
                        this.state.answer === 'yes' &&
                        <>
                            <div className="row">
                                <div className="col text-center">
                                    {
                                        Language[this.props.language].general.optional
                                    }
                                </div>
                            </div>
                            {
                                this.props.guestLandingPage.max_persons > 1 &&
                                <div className="row">
                                    <div className="offset-lg-4"></div>
                                    <div className="col text-center">
                                        <Selectbox
                                            key={"participationNoOfPersonsInput"}
                                            name={"noOfPersons"}
                                            value={this.state.noOfPersons}
                                            label={Language[this.props.language].participation.noOfPersons}
                                            valueField={"value"}
                                            displayField={"display"}
                                            options={noOfPersonsList}
                                            onChange={this.onNoOfPersonsChanged.bind(this)}
                                            onBlur={this.onNoOfPersonsBlur.bind(this)}
                                        />
                                    </div>
                                    <div className="offset-lg-4"></div>
                                </div>
                            }
                        </>
                    }
                </div>
                {
                    this.state.message !== null &&
                    <Message 
                        key={"messageCommentComponent"}
                        message={this.state.message}
                        language={this.props.language}
                    />
                }
            </>
        )
    }
}

export default Participation;