import React, { useState, useEffect } from 'react';
import TextInput from '../../../../../textInput.component';
import ErrorBoundary from '../../../../../error/error.component';
import FallbackUI from '../../../../../error/standardFallback.component';
import { FadeIn } from '../../../../../styled/fadeIn.component';
import { Icon } from '../../../../../styled/icon.component';
import Language from '../../../../../../constants/language';
import { InputError } from '../../../../../styled/inputError.component';
import { isNullOrUndefined } from '../../../../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { setProductSetupWizard } from '../../../../../../reducers/slices/productSetupWizard.slice';
import { useProductTypeSetupWizard } from '../../../../../../factories/productSetup.factory';
import ImageUpload from '../../../../imageUpload.component';
import LandingPageImagesContainer from '../../../../../../containers/managementInterface/landingPageImages.container';

function ProductImages(props) {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);
    const productSetupWizard = useSelector((state) => state.productSetupWizard.value);

    const { updateProduct, addProductImage } = useProductTypeSetupWizard();

    function onImageAdded(image) {
        let product = _.cloneDeep(productSetupWizard.product);
        product = addProductImage(product, image);
        let updatedWizard = { step: productSetupWizard.step, product: product};
        dispatch(setProductSetupWizard(updatedWizard));
    }

    return (
        <FadeIn>
            <div className="row">
                <div className="col d-flex justify-content-center align-items-center">
                    { Language[language].shop.wizard.product.optionToUploadProductImages }
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <ImageUpload 
                        key={"imageUpload"}
                        product={productSetupWizard.product}
                        onImageAdded={onImageAdded}
                    />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <LandingPageImagesContainer
                        product={productSetupWizard.product}
                        maxImageWidth={"200px"}
                        headerText={Language[language].shop.wizard.product.productImages}
                    />
                </div>
            </div>
        </FadeIn>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"productImages.component.js"}
            fallbackUI={<FallbackUI language={language} component={"productImages"} />}
        >
            <ProductImages
                key={`productImagesComponent`}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;