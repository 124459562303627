import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: null,
};

export const currentLandingPageTemplateSlice = createSlice({
  name: 'currentLandingPageTemplate',
  initialState,
  reducers: {
    setCurrentLandingPageTemplate: (state, action) => {
      state.value = action.payload
    },
  },
});

export const { setCurrentLandingPageTemplate } = currentLandingPageTemplateSlice.actions;

export default currentLandingPageTemplateSlice.reducer;