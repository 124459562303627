import React, { useState, useEffect } from 'react';
import ErrorBoundary from '../../../../error/error.component';
import FallbackUI from '../../../../error/standardFallback.component';
import { FadeIn } from '../../../../styled/fadeIn.component';
import Language from '../../../../../constants/language';
import { LEGAL_STATUS_TYPE_PRIVATE, LEGAL_STATUS_TYPE_COMPANY } from '../../../../../constants/legalStatusTypes';
import { useSelector } from 'react-redux';

function PrivateOrCompany(props) {
    const language = useSelector((state) => state.language.value);

    return (
        <FadeIn>
            <div className="row">
                <div className="col d-flex justify-content-center align-items-center">
                    {
                        Language[language].shop.privateOrCompanyText
                    }
                </div>
            </div>
            <div className="row mt-3">
                <div className="col d-flex justify-content-center align-items-center">
                    <div className="form-check">
                        <input 
                            className="form-check-input" 
                            type="radio" 
                            name="privateCompanyRadio" 
                            id="private" 
                            onClick={() => props.onReturnData(LEGAL_STATUS_TYPE_PRIVATE)}
                            defaultChecked={props.legalStatus === LEGAL_STATUS_TYPE_PRIVATE ? "checked" : undefined}
                        />
                        <label 
                            className="form-check-label" 
                            htmlFor="private"
                        >
                            {
                                Language[language].shop.private
                            }
                        </label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col d-flex justify-content-center align-items-center">
                    <div className="form-check">
                        <input 
                            className="form-check-input" 
                            type="radio" 
                            name="privateCompanyRadio" 
                            id="company"
                            onClick={() => props.onReturnData(LEGAL_STATUS_TYPE_COMPANY)} 
                            value={props.legalStatus === null ? '' : props.legalStatus}
                            defaultChecked={props.legalStatus === LEGAL_STATUS_TYPE_COMPANY ? "checked" : undefined}
                        />
                        <label 
                            className="form-check-label" 
                            htmlFor="company"
                        >
                            {
                                Language[language].shop.company
                            }
                        </label>
                    </div>
                </div>
            </div>
        </FadeIn>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"privateOrCompany.component.js"}
            fallbackUI={<FallbackUI language={language} component={"privateOrCompany"} />}
        >
            <PrivateOrCompany
                key={`privateOrCompanyComponent`}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;