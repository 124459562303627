import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ErrorBoundary from '../error/error.component';
import FallbackUI from '../error/standardFallback.component';
import { isNullOrUndefined } from '../../utils/utils';
import { v4 as uuidv4 } from 'uuid';
import Language from '../../constants/language';
import { getImageSizePictureUrl } from '../../utils/image';
import { useSelector } from 'react-redux';

const Image = styled.div`
    background-repeat:no-repeat;
    background-image:url(${props => !isNullOrUndefined(props.imageUrl640) && props.imageUrl640?.length > 0 ? props.imageUrl640 : '' });
    background-size:cover;
    background-position:center;
    width: ${props => !isNullOrUndefined(props.image) ? `${props.image.width_in_percent}%` : '200px'};
    height: ${props => !isNullOrUndefined(props.image) ? `${props.image.height_in_vh}vh` : '200px'};
    border-radius: ${props => !isNullOrUndefined(props.image) ? props.image.border_radius : '1' }%;
    opacity: ${props => !isNullOrUndefined(props.image) ? props.image.opacity : 1 };
    background-color: #fff;
    border: ${props => !isNullOrUndefined(props.image) || !props.isEditModeActive ? '0px' : '1px dashed #a0a0a0'};
    font-size: 1rem;
    align-items: center;
    display: flex;
    justify-content: center;

    &:hover {
        border: ${props => !props.isEditModeActive ? 'none' : '1px solid #000'};
    }

    @media (max-width: 768px) and (min-width: 641px) {
        background-image:url(${props => !isNullOrUndefined(props.imageUrl768) && props.imageUrl768?.length > 0 ? props.imageUrl768 : '' });
    }

    @media (max-width: 1024px) and (min-width: 769px) {
        background-image:url(${props => !isNullOrUndefined(props.imageUrl1024) && props.imageUrl1024?.length > 0 ? props.imageUrl1024 : '' });
    }

    @media (max-width: 1366px) and (min-width: 1025px) {
        background-image:url(${props => !isNullOrUndefined(props.imageUrl1366) && props.imageUrl1366?.length > 0 ? props.imageUrl1366 : '' });
    }

    @media (max-width: 1600px) and (min-width: 1367px) {
        background-image:url(${props => !isNullOrUndefined(props.imageUrl1600) && props.imageUrl1600?.length > 0 ? props.imageUrl1600 : '' });
    }
    
    @media (min-width: 1601px) {
        background-image:url(${props => !isNullOrUndefined(props.imageUrl1920) && props.imageUrl1920?.length > 0 ? props.imageUrl1920 : '' });
    }
`

function PageContentImage(props) {

    const language = useSelector((state) => state.language.value);

    const [imageUrl640, setImageUrl640] = useState(null);
    const [imageUrl768, setImageUrl768] = useState(null);
    const [imageUrl1024, setImageUrl1024] = useState(null);
    const [imageUrl1366, setImageUrl1366] = useState(null);
    const [imageUrl1600, setImageUrl1600] = useState(null);
    const [imageUrl1920, setImageUrl1920] = useState(null);

    useEffect(() => {
        if(!isNullOrUndefined(props.appImage)) {
            setImageUrl640(getImageSizePictureUrl(props.appImage, 640));
            setImageUrl768(getImageSizePictureUrl(props.appImage, 768));
            setImageUrl1024(getImageSizePictureUrl(props.appImage, 1024));
            setImageUrl1366(getImageSizePictureUrl(props.appImage, 1366));
            setImageUrl1600(getImageSizePictureUrl(props.appImage, 1600));
            setImageUrl1920(getImageSizePictureUrl(props.appImage, 1920));
        }
    },[props.appImage]);

    return (
        <div className="d-flex justify-content-center">
            <Image 
                key={isNullOrUndefined(props.appImage) ? `pageContentImage${uuidv4()}` : `pageContentImage${props.appImage?.id}`}
                imageUrl640={imageUrl640}
                imageUrl768={imageUrl768}
                imageUrl1024={imageUrl1024}
                imageUrl1366={imageUrl1366}
                imageUrl1600={imageUrl1600}
                imageUrl1920={imageUrl1920}
                image={props.appImage}
                isEditModeActive={props.isEditModeActive}
            >
                {
                    (isNullOrUndefined(props.appImage) || props.appImage.image_url.length === 0) && props.isEditModeActive &&
                    Language[language].editor.uploadImageHere
                }
            </Image>
        </div>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"pageContentImage.component.js"}
            fallbackUI={<FallbackUI language={language} component={"pageContentImage"} />}
        >
            <PageContentImage
                key={`pageContentImage${props.image?.id}`}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;