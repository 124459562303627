const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;

export function handleDecimalsOnPrice(priceValue) {
    /*
    const splitters = ['.',','];
    let returnValue = priceValue;
    splitters.forEach((splitter) => {
        let splittedPrice = priceValue.split(splitter);
        if(splittedPrice.length > 1 && splittedPrice[1].length > 2) {
            splittedPrice[1] = splittedPrice[1].toString().substring(0,2);
            returnValue = splittedPrice.join(splitter);
        }
    });
    return returnValue;
    */
    priceValue = priceValue.replaceAll(',','.');
    return priceValue.match(regex)[0];
}

export function isPriceValid(price) {
    //let regex = /^\d{0,8}([\.,\,]\d{1,2})?$/g;
    if(price === '') 
        return false;
    return regex.test(price);
}