import styled from 'styled-components';

export const EditOptionsBackground = styled.div`
    background-color: rgb(33, 37, 41);
    border: 1px solid #aaa;
    color: #eaeaea;
    border-radius: 0.25rem;
    width: ${props => props.width ? props.width : 'auto'};
    margin:0.25rem;
`;
