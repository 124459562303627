import React, { Component, useState, useEffect } from 'react';
import styled from 'styled-components';
import _, { isNull } from 'lodash';
import Selectbox from '../selectbox.component';
import Language from '../../constants/language';
import * as Data from '../../backend/data';
import ErrorBoundary from '../error/error.component';
import FallbackUI from '../error/standardFallback.component';
import { isNullOrUndefined } from '../../utils/utils';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentLandingPage } from '../../reducers/slices/currentLandingPage.slice';
import { getErrorMessage } from '../../utils/error';
import { IMAGE_TYPE_PRODUCT_IMAGE } from '../../constants/imageTypes';

const LabelForFile = styled.label`
    overflow:hidden;
`

function ImageUpload(props) {

    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);
    const csrfToken = useSelector((state) => state.csrfToken.value);
    const currentLandingPage = useSelector((state) => state.currentLandingPage.value);
    const currentLandingPageTemplate = useSelector((state) => state.currentLandingPageTemplate.value);

    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [file, setFile] = useState(null);
    const [landingPageTemplateImageId, setLandingPageTemplateImageId] = useState((isNullOrUndefined(props.landingPageImageTemplateIds) || props.landingPageImageTemplateIds.length > 1) ? null : props.landingPageImageTemplateIds[0]);

    function onUploadFileChanged(event) {
        //console.log(event.target.files[0]);
        setFile(event.target.files[0]);
    }

    function onBackgroundImageTypeChanged(event) {
        setLandingPageTemplateImageId(event.target.value);
    }

    function onBackgroundImageTypeBlur(event) {
        setLandingPageTemplateImageId(event.target.value);
    }

    function onUploadButtonClick(event) {
        event.preventDefault();
        setErrorMessage(null);
        setSuccessMessage(null);
        if(file !== null) {
            let formData = [
                {
                    name: 'csrfToken',
                    value: csrfToken
                },
                {
                    name: 'image',
                    value: file
                },
                {
                    name: 'landingPageId',
                    value: currentLandingPage.id
                }
            ];
            if(landingPageTemplateImageId !== null) {
                formData.push({
                    name: 'landingPageTemplateImageId',
                    value: landingPageTemplateImageId
                });
            }
            if(!isNullOrUndefined(props.columnId)) {
                formData.push({
                    name: 'columnId',
                    value: props.columnId
                });
            }
            if(!isNullOrUndefined(props.product)) {
                formData.push({
                    name: 'productUniqueKey',
                    value: props.product.product_unique_key
                });
                formData.push({
                    name: 'landingPageTemplateImageId',
                    value: IMAGE_TYPE_PRODUCT_IMAGE
                });
            }
            Data.transfer(window.baseUrl + '/builder/images/upload','POST',formData,null,null,csrfToken)
            .then((data) => {
                if(data.success && data.landingPage && data.image) {
                    dispatch(setCurrentLandingPage(data.landingPage));
                    setFile(null);
                    if(!isNullOrUndefined(props.onImageAdded)) {
                        props.onImageAdded(data.image);
                    }
                }
            })
            .catch((error) => {
                setErrorMessage(getErrorMessage(error));
                setSuccessMessage(null);
            })
        }
        else {
            setErrorMessage('Error - Please select a file to upload first');
            setSuccessMessage(null);
        }
    }

    return (
        <>
            <div className="col">
                <div className="card border-light bg-dark">
                    <div className="card-body">
                        <div className="card-title">
                            { Language[language].images.backgroundImageUpload }
                        </div>
                        <form action="/builder/images/upload" method="post" encType="multipart/form-data" key={uuidv4()}>
                            {
                                props.landingPageImageTemplateIds?.length > 1 &&
                                <div className="row mb-2">
                                    <div className="col">
                                        <Selectbox
                                            key={"uploadImageType"}
                                            name={"landingPageTemplateImageId"}
                                            //value={this.state.noOfPersons}
                                            label={Language[language].images.backgroundImageType}
                                            valueField={"id"}
                                            displayField={"name"}
                                            options={currentLandingPageTemplate.landing_page_template_images.filter(image => landingPageImageTemplateIds.indexOf(image.id) > -1)}
                                            onChange={onBackgroundImageTypeChanged}
                                            onBlur={onBackgroundImageTypeBlur}
                                        />
                                    </div>
                                </div>
                            }
                            <div className="row mb-2">
                                <div className="col">
                                    <div className="input-group mb-3">
                                        <div className="custom-file">
                                            <input 
                                                type="file" 
                                                className="form-control" 
                                                name="image" 
                                                id="image" 
                                                onChange={onUploadFileChanged} 
                                            />
                                            <LabelForFile 
                                                className="text-start custom-file-label" 
                                                htmlFor="inputGroupFile02" 
                                                aria-describedby="inputGroupFileAddon02">
                                                    { (file === null ? Language[language].general.selectFile : file.name) }
                                            </LabelForFile>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">
                                    <button type="submit" className="btn btn-light" onClick={onUploadButtonClick}>{ Language[language].general.upload }</button>
                                    <input type="hidden" name="_token" value={csrfToken} />
                                </div>
                            </div>
                            {
                                (errorMessage !== null || successMessage !== null) &&
                                <div className="row mb-2">
                                    <div className="col alert alert-danger">
                                        { errorMessage }
                                    </div>
                                </div>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"imageUpload.component.js"}
            fallbackUI={<FallbackUI language={language} component={"imageUpload"} />}
        >
            <ImageUpload
                key={"imageUploadComponent"}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;