import { forEach } from 'lodash';
import React, { Component } from 'react';
import styled, { keyframes }  from "styled-components";
import Language from '../../constants/language';
import * as Utils from '../../utils/utils';

const showStatusDiv = keyframes`
    100% {
        opacity: 1;
    }
`;

const hideStatusDiv = keyframes`
    100% {
        opacity: 0;
    }
`;

const StatusDiv = styled.div`
    position: fixed;
    z-index: 10000;
    bottom: 0rem;
    left: 50%;
    height: 2.5rem;
    width: 50%;
    margin-left: -25%;
    background-color: #e9e9e9;
    pointer-events: none;
    
    transition: opacity ${props => (props.waitingSyncs > 0 ? '0.5s' : '3.5s')};
    opacity: ${props => (props.waitingSyncs > 0 ? 1 : 0)};

    @media (max-width: 576px) {
        left: 0rem;
        width:100%;
        margin-left:0rem;
    }
`

const StatusBackgroundColor = styled.div`
    background-color: ${props => (props.waitingSyncs > 0 ? '#fffed3' : '#dafdd1')} !important;
    color: ${props => (props.waitingSyncs > 0 ?  '#31311d' : '#29422f')} !important;
`

class Status extends Component {

    constructor(props) {
        super(props);
        this.state = {
            waitingSyncs: null
        };

        this.interval;
        this.startReadWaitingSyncsInterval = this.startReadWaitingSyncsInterval.bind(this);
    }

    componentDidMount() {
        this.startReadWaitingSyncsInterval();
    }

    componentDidUpdate(prevProps, prevState) {
    }

    componentWillUnmount() {
        if(this.interval !== undefined) {
            window.clearInterval(this.interval);
        }
    }

    reloadWaitingSyncs() {
        return new Promise((resolve, reject) => {
            let tablesToRead = ['sync-comments','sync-participation'];
            let promises = [];
            tablesToRead.forEach(table => {
                promises.push(this.loadWaitingTasks(table));
            });
            Promise.all(promises)
            .then((values) => {
                //console.log(values);
                resolve(values.reduce(function(total, sum) { return total + sum; }, 0));
            });
        });
    }

    loadWaitingTasks(dbTable) {
        return new Promise((resolve,reject) => {
            Utils.idbReadAllData(dbTable)
            .then((idbData) => {
                resolve(idbData.length);
            })
            .catch((error) => {
                console.log(error);
            });
        });
    }

    startReadWaitingSyncsInterval() {
        this.interval = window.setInterval(() => {
            this.reloadWaitingSyncs()
            .then((waitingSyncs) => {
                if(this.state.waitingSyncs !== waitingSyncs) {
                    this.setState({
                        waitingSyncs: waitingSyncs
                    });
                }
            });
        },2000);
    }

    render() {
        return (
            <StatusDiv waitingSyncs={this.state.waitingSyncs} className="statusDiv">
                <div className="container">
                    <div className="row">
                        <StatusBackgroundColor waitingSyncs={this.state.waitingSyncs} className="col text-center alert alert-light">
                            {
                                this.state.waitingSyncs === 0 &&
                                Language[this.props.language].status.dataSuccessfullyTransmitted
                            }
                            {
                                this.state.waitingSyncs > 0 &&
                                Language[this.props.language].status.dataWaitingForSync
                            }
                        </StatusBackgroundColor>
                    </div>
                </div>
            </StatusDiv>
	    );
	}
}

export default Status;