import React from 'react';
import { isNullOrUndefined } from "../utils/utils";
import { useGoodsSetupWizardController } from '../components/managementInterface/shop/tabs/productSetupWizard/setups/goodsSetupWizard.controller';
import DigitalGoodsSetupWizardController from "../components/managementInterface/shop/tabs/productSetupWizard/setups/digitalGoodsSetupWizard.controller";
import { useSelector } from 'react-redux';
import { PRODUCT_STATUS_PAUSED } from '../constants/productStatus';

export const useProductTypeSetupWizard = () => {

    const user = useSelector((state) => state.user.value);

    const {
        getComponentGoodsController,
        isNextButtonDisabledGoodsController,
        needsNextStepToBeSkippedGoodsController,
        getMaxStepGoodsController
    } = useGoodsSetupWizardController();

    function getController(productTypeId) {
        if(isNaN(parseInt(productTypeId))) {
            return null;
        }
        switch(parseInt(productTypeId)) {
            case 1:
                return {
                    getComponent: getComponentGoodsController,
                    isNextButtonDisabled: isNextButtonDisabledGoodsController,
                    needsNextStepToBeSkipped: needsNextStepToBeSkippedGoodsController,
                    getMaxStep: getMaxStepGoodsController
                };
            case 3:
                //apply like useGoodsSetupWizardController
        }
    }
    
    function updateProduct(product, data) {
        if(isNullOrUndefined(product)) {
            product = createProduct();
        }
        product[data.type] = data.value;
        return product;
    }

    function addProductImage(product, image) {
        product.app_images.push(image);
        return product;
    }
    
    function createProduct() {
        return {
            id: parseInt(user.id + new Date().getTime().toString()),
            name: '',
            description: '',
            product_no: '',
            price_net: '',
            currency_id: user.business_data.country.currency.id,
            unlimited_number_available: false,
            number_available_goods: '',
            is_default_sales_tax_of_country: true,
            sales_tax_amount_in_percent: user.business_data.is_liable_to_pay_sales_tax === 1 ? user.business_data.country.sales_tax_amount_in_percent : 0,
            is_product_linked_to_date: false,
            product_date: null,
            product_status_id: PRODUCT_STATUS_PAUSED,
            maximum_number_of_products_in_shipping_price: 1,
            shipping_costs_domestic: '',
            shipping_costs_european_foreign_countries: '',
            shipping_costs_other_countries: '',
            product_unique_key: user.id + "-" + new Date().getTime().toString(),
            app_images: []
        }
    }

    return { 
        getController,
        updateProduct,
        addProductImage
    };
}
