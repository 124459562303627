import React, { Component } from 'react';
import styled from 'styled-components';
import SelectboxOption from './selectboxOption';
import { v4 as uuidv4 } from 'uuid';

const TextArea = styled.textarea`
    line-height:2.2;
    overflow-y:hidden;
`;

const Label = styled.label`
    padding: 0px; 
    position: absolute; 
    top: 8px; 
    left: 27px; 
    color: rgb(108, 115, 120); 
    font-size: 15px; 
    line-height: 20px; 
    font-weight: normal; 
    transition: all 0.25s ease-in-out 0s; 
    transform: translate(1px, -6px) scale(0.75); 
    transform-origin: left top;
`;

const DivRelative = styled.div`
    position:relative;
`

class Textarea extends Component {

    constructor(props) {
        super(props);
        this.state = {
            key: uuidv4(),
            inputTextValue: (this.props.value) ? this.props.value : ''
        }
        this.textarea = React.createRef();
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if(prevProps.value !== this.props.value) {
            this.setState({
                inputTextValue: this.props.value
            });
            this.textarea.current.value = this.props.value;
        } 
    }

    onChangedHandler(event) {
        if(this.props.onChange) {
            event.preventDefault()
            this.props.onChange(event);
        }
    }

    onBlurHandler(event) {
        if(this.props.onBlur) {
            event.preventDefault()
            this.props.onBlur(event);
        }
    }

    render() {
        return (
            <DivRelative>
                <TextArea 
                    id={this.props.name} 
                    rows={this.props.rows}
                    key={this.state.key}
                    defaultValue={ this.state.inputTextValue }
                    className={"form-control " + ((this.props.additionalClasses) ? this.props.additionalClasses : "")}
                    disabled={(this.props.disabled) ? true : undefined }
                    required={(this.props.required) ? true : undefined }
                    onChange={this.onChangedHandler.bind(this)}
                    onBlur={this.onBlurHandler.bind(this)}
                    onInput={() => {
                        const node = this.textarea.current;
                        node.style.height = ''; 
                        node.style.height = node.scrollHeight +'px'
                    }}
                    ref={this.textarea}
                    name={this.props.name}
                    autoFocus={(this.props.autoFocus) ? true : undefined }
                    style={((this.props.additionalTextStyles) ? this.props.additionalTextStyles : {})}
                ></TextArea>
                {
                    this.props.label && this.props.name && 
                    <Label htmlFor={this.props.name}>{this.props.label}</Label>
                }
            </DivRelative>
	    );
	}
}

export default Textarea;