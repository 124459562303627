import React, { useState, useEffect } from 'react';
import ErrorBoundary from '../../../../error/error.component';
import FallbackUI from '../../../../error/standardFallback.component';
import { FadeIn } from '../../../../styled/fadeIn.component';
import Language from '../../../../../constants/language';
import TextInput from '../../../../textInput.component';
import { checkVAT, countries } from 'jsvat';
import { InputError } from '../../../../styled/inputError.component';
import { useSelector } from 'react-redux';

function VatNo(props) {
    const language = useSelector((state) => state.language.value);
    
    const [vatNo, setVatNo] = useState(props.vatNo);
    const [additionalClasses, setAdditionalClasses] = useState('');
    const [error, setError] = useState(null);

    function validateVatNo() {
        return checkVAT(vatNo, countries);
    }

    function validateAndReturnData(event, action = 'submit') {
        let result = validateVatNo(event.target.value);
        let returnVatNo = vatNo;
        let moveToNextStep = false;
        if(result?.isValid) {
            setAdditionalClasses('is-valid');
            setError(null);
            if(action === 'submit') {
                moveToNextStep = true;
            }
        }
        else {
            setAdditionalClasses('is-invalid');
            setError(Language[language].shop.vatNoIsNotValid);
            returnVatNo = null;
        }
        props.onReturnData(returnVatNo, moveToNextStep);
        setVatNo(event.target.value);
    }

    return (
        <FadeIn>
            <div className="row">
                <div className="col d-flex justify-content-center align-items-center">
                    {
                        Language[language].shop.vatNoText
                    }
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <TextInput
                        key={"vatNo"}
                        type={"text"}
                        label={""}
                        value={vatNo}
                        name={"vatNo"}
                        placeholder={Language[language].shop.vatNo}
                        onChange={(event) => setVatNo(event.target.value)}
                        onBlur={(event) => validateAndReturnData(event, 'blur')}
                        submitOnEnter={true}
                        submitEvent={(event) => validateAndReturnData(event)}
                        required={true}
                        autoFocus={true}
                        additionalClasses={additionalClasses}
                    />
                    {
                        error !== null &&
                        <InputError>
                            {
                                error
                            }
                        </InputError>
                    }
                </div>
            </div>
        </FadeIn>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"vatNo.component.js"}
            fallbackUI={<FallbackUI language={language} component={"vatNo"} />}
        >
            <VatNo
                key={`vatNoComponent`}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;