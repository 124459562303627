import React from "react";
import styled, { keyframes } from 'styled-components';

const ldsRing = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const LdsRingDivInner = styled.div`
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 80px;
    height: 80px;
    margin: 8px;
    border: 8px solid #000;
    border-radius: 50%;
    animation: ${ldsRing} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${props => props.color ? `${props.color} transparent transparent transparent` : '#000 transparent transparent transparent'};
`;

const LdsRing = styled.div`
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;

    ${LdsRing} div:nth-child(1) {
        animation-delay: -0.45s;
    }
    ${LdsRing} div:nth-child(2) {
        animation-delay: -0.3s;
    }
    ${LdsRing} div:nth-child(3) {
        animation-delay: -0.15s;
    }
`;

export const LoadingSpinner = (props) => {
    return (
        <LdsRing>
            <LdsRingDivInner color={props.color}></LdsRingDivInner>
            <LdsRingDivInner color={props.color}></LdsRingDivInner>
            <LdsRingDivInner color={props.color}></LdsRingDivInner>
            <LdsRingDivInner color={props.color}></LdsRingDivInner>
        </LdsRing>
    );
}

export default LoadingSpinner;