import React from 'react';
import styled from 'styled-components';
import Language from '../../constants/language';
import { blendIn } from '../../components/app/appText.component';
import editIcon from '../../assets/images/edit.svg';

const HoverImageDiv = styled.div`
    display: inline-block;
    position: ${props => props.hoverDivPosition};
    right: ${props => props.right ? props.right : '2%'};
    top: ${props => props.top ? props.top : '50%'};
    font-size:1rem;
    font-weight:normal;
    width:200px;
    margin-left:-100px;
    height:fit-content;/*65px;*/
    margin-top:-32.5px;
    font-family:'latolight' !important;
    font-size: 20px;
    animation-name: ${props => props.isVisible ? blendIn : 'auto'};
    animation-fill-mode: ${props => props.isVisible ? 'forwards' : 'inherit'};
    animation-duration: ${props => props.isVisible ? '1s' : 'inherit'};
    background-color: rgba(255,255,255,0.4);
    border-radius:0.25rem;
    border: 1px solid #000;
    z-index:5;

    &:hover {
        cursor:pointer;
    }
`

const HoverText = styled.div`
`

function EditImageHover(props) {
    return (
        <div>
            <HoverImageDiv
                isVisible={props.isVisible}
                hoverDivPosition={props.hoverDivPosition}
                right={props.hoverDivRight}
                top={props.hoverDivTop}
            >
                <div className="row">
                    <div className="col text-center">
                        <img src={editIcon} alt="Edit image"></img>
                    </div>
                </div>
                <div className="row">
                    <HoverText className="col text-center">
                        {
                            props.language === 'en' ? `${Language[props.language].editor.edit} ${props.imageName}` : `${props.imageName} ${Language[props.language].editor.edit}`
                        }
                    </HoverText>
                </div>
            </HoverImageDiv>
        </div>
    )
}

export default EditImageHover;