import React, { useState, useEffect } from 'react';
import ErrorBoundary from '../../../../error/error.component';
import FallbackUI from '../../../../error/standardFallback.component';
import { FadeIn } from '../../../../styled/fadeIn.component';
import { Icon } from '../../../../styled/icon.component';
import Language from '../../../../../constants/language';
import { useSelector } from 'react-redux';

import infoIcon from '../../../../../assets/images/info-circle.svg';

function Introduction(props) {
    const language = useSelector((state) => state.language.value);

    return (
        <FadeIn>
            <div className="row">
                <div className="col d-flex justify-content-center align-items-center">
                    <Icon 
                        image={infoIcon} 
                        hoverBackgroundColor={'darkgray'}
                        minSize={"30px"}
                        maskColor={'#eaeaea'}
                        cursor={'pointer'}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col d-flex justify-content-center align-items-center">
                    {
                        Language[language].shop.wizard.business.introductionText
                    }
                </div>
            </div>
        </FadeIn>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"introduction.component.js"}
            fallbackUI={<FallbackUI language={language} component={"introduction"} />}
        >
            <Introduction
                key={`introductionComponent`}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;