import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
/*
import { connect } from 'react-redux';
import { updateCurrentLandingPage, updateMessage } from '../../actions/index';
*/
import ErrorBoundary from '../error/error.component';
import FallbackUI from '../error/standardFallback.component';
import Column from './column.component';
import { useSelector } from 'react-redux';
/*
import * as Data from '../../backend/data';
import * as MessageTypes from '../../constants/messagetypes';

import { Icon } from '../styled/icon.component';
import { EditOptionsBackground } from '../styled/editOptionsBackground.component';
import RowDelete from '../managementInterface/rowDelete.component';
import RowAdd from '../managementInterface/rowAdd.component';
import LoadingSpinner from '../../components/loadingSpinner.component';
import _ from 'lodash';

import addIcon from '../../assets/images/plus-square.svg';
import removeIcon from '../../assets/images/trash.svg';
import moveUpIcon from '../../assets/images/arrow-up-square.svg';
import moveDownIcon from '../../assets/images/arrow-down-square.svg';
*/
const RelDiv = styled.div`
    position:relative;

    &:hover {
        border:${props => props.isEditModeActive ? '1px dashed #000' : 'none'};
        border-radius:${props => props.isEditModeActive ? '0.25rem' : 'unset'};
    }
`
/*
const OptionsParentRow = styled.div`
    display:none;

    ${RelDiv}:hover & {
        display:block;
    }
`
*/

function Row(props) {
    /*
    const [isLoading, setIsLoading] = useState(false);
    const [isAddNewRowOptionActive, setIsAddNewRowOptionActive] = useState(false);
    const [isDeleteRowConfirmActive, setIsDeleteRowConfirmActive] = useState(false);

    function moveRow(direction) {
        setIsLoading(true);
        let newRowNo = props.row.row_no;
        if(direction === 'up') {
            newRowNo--;
        }
        else if(direction === 'down') {
            newRowNo++;
        }
        let formData = [
            {
                name: 'csrfToken',
                value: props.csrfToken
            },
            {
                name: 'landingPageId',
                value: props.currentLandingPage.id
            },
            {
                name: 'rowId',
                value: props.row?.id
            },
            {
                name: 'newRowNo',
                value: newRowNo
            }
        ];
        Data.transfer(window.baseUrl + '/builder/user/landingPage/rows/move','POST',formData,null,null,props.csrfToken)
        .then((data) => {
            if(data.success && data.landingPage) {
                props.updateCurrentLandingPage(data.landingPage);
                props.onRowAdded();
            }
        })
        .catch((error) => {
            props.updateMessage({
                message: error.errors?.join(' '),
                messageType: MessageTypes.MESSAGE_TYPE_ERROR
            });
            setIsLoading(false);
        });
    }
    */

    return (
        <RelDiv 
            isEditModeActive={props.isEditModeActive}
        >
            <div className="row m-0">
                {
                    _.orderBy(props.row?.columns,['column_no'])?.map((column, index) => {
                        return (
                            <Column
                                key={`column${column.id}`}
                                row={props.row}
                                column={column}
                                landingPage={props.landingPage}
                                isEditModeActive={props.isEditModeActive}
                            />
                        )
                    })
                }
            </div>
        </RelDiv>
    )
}
/*
function mapStateToProps({ user, navigation, language, csrfToken, currentEvent, currentLandingPage, currentLandingPageTemplate, message, rowTypes }) {
    return {
        user,
        navigation,
        language,
        csrfToken,
        currentEvent,
        currentLandingPage,
        currentLandingPageTemplate,
        message,
        rowTypes
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateCurrentLandingPage: currentLandingPage => dispatch(updateCurrentLandingPage(currentLandingPage)),
        updateMessage: message => dispatch(updateMessage(message)),
    };
}
*/

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"row.component.js"}
            fallbackUI={<FallbackUI language={language} component={"row"} />}
        >
            <Row
                key={`rowComponent${props.row.row_no}`}
                {...props}
            />
       </ErrorBoundary>
   )
}
 
/*
const Form = connect(
    mapStateToProps,
    mapDispatchToProps
)(ComponentWithErrorBoundary);
*/

export default ComponentWithErrorBoundary;