import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import Language from '../../../../constants/language';
//import EditText from '../../../../components/managementInterface/editText.component';
import AppText from '../../../../components/app/columnTypes/appText.component';
import Footer from '../../../../components/footer.component';
import { PageBackgroundImage } from "../../../../components/app/pageBackgroundImage.component";
import { isNullOrUndefined } from "../../../../utils/utils";
import AppImage from '../../../../components/app/appImage.component';
import Row from '../../../../components/app/row.component';
import _ from 'lodash';
import ErrorBoundary from '../../../../components/error/error.component';
import FallbackUI from '../../../../components/error/standardFallback.component';
import { useSelector, useDispatch } from 'react-redux';

const StartImage = styled.div`
    display:flex;
    flex-direction:column;
    background-repeat:no-repeat;
    width: 100%;
    height:100vh;
    position:relative;
    /*z-index:1;*/

    background-image:url(${props => (!isNullOrUndefined(props.images) && props.images.length > 0 && props.images.find((image) => image.landing_page_template_image_id === 1 ) !== undefined) ? "/builder/images/show/" + props.images.filter((image) => image.landing_page_template_image_id === 1)[0].identifier_token : ''});
    background-position:top center;
    background-size:cover;
    border:${props => props.isEditImageVisible ? '1px solid #000' : ((isNullOrUndefined(props.images) || props.images?.length === 0 || props.images?.find((image) => image.landing_page_template_image_id === 1 ) === undefined) ? '1px dashed #a0a0a0' : '0px')};
    border-radius: ${props => props.isEditImageVisible ? '0.25rem' : '0rem'};

    @media (max-width: 768px) {
        background-image:url(${props => (!isNullOrUndefined(props.images) && props.images.length > 0 && props.images.find((image) => image.landing_page_template_image_id === 2 ) !== undefined) ? "/builder/images/show/" + props.images.filter((image) => image.landing_page_template_image_id === 2)[0].identifier_token : ''});
        border:${props => props.isEditImageVisible ? '1px solid #000' : ((isNullOrUndefined(props.images) || props.images?.length === 0 || props.images?.find((image) => image.landing_page_template_image_id === 2 ) === undefined) ? '1px dashed #a0a0a0' : '0px')};
    }
`

const HeaderText = styled.div`
    background-color:transparent;
    margin-top:5rem;
    margin-right:1rem;

    @media (max-width: 768px) {
        font-size:3rem;
    }
` 

const Logo = styled.div`
    background-repeat:no-repeat;
    background-image:url(${props => !isNullOrUndefined(props.image) && props.image.app_image_sizes?.find((size) => size.size === "640") !== undefined ? "/builder/images/show/" + props.image.app_image_sizes?.find((size) => size.size === "640").identifier_token : '' });
    background-size:cover;
    background-position:center;
    width:200px;
    height:200px;
    border-radius: ${props => props.image !== null ? props.image.border_radius : '1' }%;
    opacity: ${props => props.image !== null ? props.image.opacity : 1 };
    background-color: #fff;
    border: ${props => props.image !== null ? '0px' : '1px dashed #a0a0a0'};
    font-size: 1rem;
    align-items: center;
    display: flex;
    justify-content: center;

    &:hover {
        border: 1px solid #000;
    }
`

const HeaderImageRightCol = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`

function StandardTemplate(props) {

    const language = useSelector((state) => state.language.value);
    const currentLandingPage = useSelector((state) => state.currentLandingPage.value);

    const [isEditMode, setIsEditMode] = useState(true);
    const [isStartImageEditVisible, setIsStartImageEditVisible] = useState(false);
    const [isBackgroundImageEditVisible, setIsBackgroundImageEditVisible] = useState(false);
    const [isLogoImageEditVisible, setIsLogoImageEditVisible] = useState(false);
    const [isLandingPageNameTextEditActive, setIsLandingPageNameTextEditActive] = useState(false);

    function getLogoComponent() {
        let logoImage = null;
        if(!isNullOrUndefined(currentLandingPage?.app_images) && currentLandingPage?.app_images.length > 0 && currentLandingPage?.app_images.find((image) => image.landing_page_template_image_id === 4 ) !== undefined) {
            logoImage = currentLandingPage?.app_images.find((image) => image.landing_page_template_image_id === 4);
        } 
        return (
            <Logo 
                className="text-center" 
                image={logoImage}
                images={currentLandingPage?.app_images}
                onMouseEnter={(e) => {
                    setIsStartImageEditVisible(false);
                    setIsLogoImageEditVisible(true);
                }}
                onMouseLeave={(e) => {
                    setIsStartImageEditVisible(true);
                    setIsLogoImageEditVisible(false);
                }}
            >
                {
                    (currentLandingPage?.app_images === undefined || currentLandingPage?.app_images === null || currentLandingPage?.app_images.length === 0 || currentLandingPage?.app_images.filter((image) => image.landing_page_template_image_id === 4 ).length === 0) &&
                    Language[language].editor.placeYourLogoHere
                }
            </Logo>
        )
    }

    function getBackgroundImage() {
        return (
            <PageBackgroundImage 
                key={"backgroundImageKey"} 
                images={currentLandingPage?.app_images}
                isEditImageVisible={isBackgroundImageEditVisible}
                onMouseEnter={(e) => setIsBackgroundImageEditVisible(true)}
                onMouseLeave={(e) => setIsBackgroundImageEditVisible(false)}
            >
            </PageBackgroundImage>
        )
    }

    function getHeroImage() {
        let landingPageAppText = currentLandingPage?.app_texts?.find((appText) => appText.landing_page_template_text_id === 2);
        return (
            <StartImage 
                key={"startImageKey"} 
                images={currentLandingPage?.app_images}
                onMouseEnter={(e) => setIsStartImageEditVisible(true)}
                onMouseLeave={(e) => setIsStartImageEditVisible(false)}
                isEditImageVisible={isStartImageEditVisible}
            >
                <div className="container-fluid">
                    <div className="row">
                        <HeaderImageRightCol className="col">
                            <HeaderText>
                                <AppImage
                                    imageComponent={getLogoComponent()}
                                    images={[currentLandingPage?.app_images?.find((image) => image.landing_page_template_image_id === 4)]}
                                    imageName={Language[language].images.logo}
                                    isEditMode={isEditMode}
                                    isHoverVisible={isLogoImageEditVisible}
                                    language={language}
                                    hoverDivPosition={'absolute'}
                                    landingPageImageTemplateIds={[4]}
                                    onEditAreaHover={(e) => {
                                        setIsStartImageEditVisible(false);
                                    }}
                                />
                            </HeaderText>
                        </HeaderImageRightCol>
                    </div>
                </div>
                <div
                    onMouseEnter={(e) => setIsStartImageEditVisible(false)}
                    onMouseLeave={(e) => setIsStartImageEditVisible(true)}
                >
                    <AppText 
                        key={`appText${landingPageAppText?.id}`}
                        appText={landingPageAppText}
                        language={language}
                        isEditMode={true}
                    />
                </div>
            </StartImage>
        )
    }

    return (
        <>
            <AppImage
                imageComponent={getBackgroundImage()}
                images={[currentLandingPage?.app_images?.find((image) => image.landing_page_template_image_id === 3)]}
                imageName={Language[language].images.textBackgroundImage}
                isEditMode={isEditMode}
                isHoverVisible={isBackgroundImageEditVisible}
                language={language}
                hoverDivPosition={'fixed'}
                landingPageImageTemplateIds={[3]}
            />
            <AppImage
                imageComponent={getHeroImage()}
                images={[currentLandingPage?.app_images?.find((image) => image.landing_page_template_image_id === 1),currentLandingPage?.app_images?.find((image) => image.landing_page_template_image_id === 2)]}
                imageName={Language[language].images.heroImage}
                isEditMode={isEditMode}
                isHoverVisible={isStartImageEditVisible}
                language={language}
                hoverDivPosition={'absolute'}
                hoverParentDivPosition={'unset'}
                landingPageImageTemplateIds={[1,2]}
                hoverDivTop={'90%'}
            />
            <div className="container-fluid p-0">
                {
                    _.orderBy(currentLandingPage?.rows,['row_no'])?.map((row, index) => {
                        return (
                            <Row 
                                key={`row${index}`} 
                                landingPage={currentLandingPage}
                                row={row}
                                isEditModeActive={true}
                            />
                        )
                    })
                }
                <div className="row">
                    <div className="col">
                        <br />
                        <br />
                    </div>
                </div>
            </div>
            <Footer
                key={"landingPageFooterComponent"}
                location={"app"}
                {...props}
            />
        </>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"standardPageTemplate.view.js"}
            fallbackUI={<FallbackUI language={language} component={"standardPageTemplate"} />}
        >
            <StandardTemplate
                key={"standardPageTemplateComponent"}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;