import React, { Component } from "react";
import Language from '../../constants/language';
import styled from 'styled-components';

const Bold = styled.span`
  font-weight:bold;
`

class FallbackUI extends React.Component {
    constructor(props) {
      super(props);
      this.state = {

      };
    }
  
    render() {
      return (
            <div className="container alert alert-danger">
                <div className="row">
                    <div className="col text-center">
                        {
                          Language[this.props.language].error.anErrorOccured + ' '
                        }
                        <Bold>{ Language[this.props.language].error.components[this.props.component] }</Bold>
                    </div>
                </div>
                <div className="row">
                    <div className="col text-center">
                        {
                          Language[this.props.language].error.ifErrorPersists
                        }
                    </div>
                </div>
            </div>
      )
    }
}


export default FallbackUI;
