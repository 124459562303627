import { createSlice } from '@reduxjs/toolkit';
import { isNullOrUndefined } from '../../utils/utils';
const initialState = {
  value: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.value = action.payload
    },
    setBusinessData: (state, action) => {
      if(!isNullOrUndefined(state.user)) {
        state.user.business_data = action.payload;
      }
    },
  },
});

export const { setUser, setBusinessData } = userSlice.actions;

export default userSlice.reducer;