import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from '../../button.component';
import TextInput from '../../textInput.component';
import TextAreaWithEditingOptions from '../../textAreaWithEditingOptions.component';
import SelectboxFonts from '../../selectBoxFonts.component';
import Language from '../../../constants/language';
import AppText from './appText.component';
import ErrorBoundary from '../../error/error.component';
import FallbackUI from '../../error/standardFallback.component';
import * as MessageTypes from '../../../constants/messagetypes';
import * as Data from '../../../backend/data';
import * as TextPositions from '../../../constants/textPositions';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentLandingPage } from '../../../reducers/slices/currentLandingPage.slice';
import { setMessage } from '../../../reducers/slices/message.slice';

import saveIcon from '../../../assets/images/check2-square.svg';
import cancelIcon from '../../../assets/images/x-square.svg';


const EditTextFieldDiv = styled.div`
    border:1px solid #eaeaea;
    background-color:#fff;
    padding:1rem;
    border-radius:0.25rem;
    /*font-family: 'Lato', sans-serif !important;*/
    font-family:'latolight' !important;
    font-size: 20px;
    margin:2rem;
    max-width: 90vw;
    overflow-x: hidden;
    z-index:10;
    position:relative;
`

const EditTextFieldBackground = styled.div`
    position:absolute;
    background-color:#fff;
    opacity:0.5;
    width:100%;
    height:100%;
    border-radius:0.5rem;
` 

const RowMargin = styled.div`
    margin:1rem;
`

function EditText(props) {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);
    const currentLandingPage = useSelector((state) => state.currentLandingPage.value);
    const csrfToken = useSelector((state) => state.csrfToken.value);
    const fonts = useSelector((state) => state.fonts.value);

    const [appText, setAppText] = useState(null);

    function onCloseClick(event) {
        props.onIsTextEditActiveChange(false);
    }

    function onSaveButtonClicked(event) {
        let formData = [
            {
                name: 'csrfToken',
                value: csrfToken
            },
            {
                name: 'landingPageId',
                value: currentLandingPage.id
            },
            {
                name: 'textType',
                value: props.textType
            },
            {
                name: 'textValue',
                value: (appText.text === "") ? null : (typeof appText.text === "string") ? appText.text : JSON.stringify(appText.text)
            },
            {
                name: 'plainText',
                value: appText.plain_text
            },
            {
                name: 'fontSize',
                value: appText.font_size
            },
            {
                name: 'fontId',
                value: appText.font_id
            },
            {
                name: 'color',
                value: appText.color
            },
            {
                name: 'justifyContent',
                value: appText.justify_content
            },
            {
                name: 'textId',
                value: appText.id
            }
        ];
        Data.transfer(window.baseUrl + '/builder/user/landingPage/texts/save','POST',formData,null,null,csrfToken)
        .then((data) => {
            if(data.success && data.landingPage) {
                onCloseClick(event);
                dispatch(setCurrentLandingPage(data.landingPage));
                dispatch(setMessage({
                    message: Language[language].editor.savedTextSuccessfully,
                    messageType: MessageTypes.MESSAGE_TYPE_SUCCESS
                }));
            }
        })
        .catch((error) => {
            dispatch(setMessage({
                message: error.errors?.join(' '),
                messageType: MessageTypes.MESSAGE_TYPE_ERROR
            }));
        });
    }

    function onCancelButtonClicked(event) {
        onCloseClick(event);
    }

    function onTextEdit(text = null, plainText = null, color = null,textPosition = null, fontSize = null, fontFamily = null) {
        return new Promise((resolve,reject) => {
            let appTextClone = _.cloneDeep(appText);
            if(appTextClone !== null) {
                if(text !== null) {
                    appTextClone.text = text;
                }
                if(plainText !== null) {
                    appTextClone.plain_text = plainText;
                }
                if(color !== null) {
                    appTextClone.color = color;
                }
                if(textPosition !== null) {
                    appTextClone.justify_content = textPosition;
                }
                if(fontSize !== null) {
                    appTextClone.font_size = fontSize;
                }
                if(fontFamily !== null) {
                    appTextClone.font_id = fontFamily;
                    appTextClone.font = fonts.find((font) => font.id === parseInt(fontFamily));
                }
                setAppText(appTextClone);
                resolve(appTextClone);
            }
            else {
                reject("editText.component.js - property \"appText\" not available")
            }
        });
    }

    function onTextEditChange(newValue, plainText) {
        onTextEdit(newValue, plainText, null, null, null, null);
    }

    function onFontSizeBlur(event) {
        if(event.target.value >= 0.5 && event.target.value <= 6) {
            onTextEdit(null, null, null, null, event.target.value, null);
        }
    }

    function onFontSizeChanged(event) {
        if(event.target.value >= 0.5 && event.target.value <= 6) {
            onTextEdit(null, null, null, null, event.target.value, null);
        }
    }

    function onTextFontFamilyChange(event) {
        if(fonts.find((font) => font.id === parseInt(event.target.value)) !== undefined) {
            onTextEdit(null, null, null, null, null, event.target.value);
        }
        else {
            throw("editText.component.js - value \"font family\" is not valid");
        }
    }

    function onTextAlignmentChange(newTextAlignmentValue) {
        if(TextPositions.getTextPositions(language).find((position) => position.value === newTextAlignmentValue) !== undefined) {
            onTextEdit(null, null, null, newTextAlignmentValue, null, null);
        }
        else {
            throw("editText.component.js - value \"textPosition\" is not valid");
        }
    }

    useEffect(() => {
        setAppText(props.appText);
    },[props.appText])

    return (
        props.isTextEditActive &&
        <>
            <EditTextFieldBackground />
            <EditTextFieldDiv>
                <div className="row">
                    <div className="col text-end">
                        <button type="button" className="btn-close" aria-label="Close" onClick={onCloseClick}></button>
                    </div>
                </div>
                <div className="row">
                    <div className="col text-start">
                        { Language[language].editor.preview }:
                    </div>
                </div>
                <RowMargin className="row">
                    <div className="col text-start">
                        <AppText 
                            key={"appText" + props.textType}
                            appText={appText}
                            language={language}
                            isEditMode={false}
                        />
                    </div>
                </RowMargin>
                {/*
                <RowMargin className="row">
                    <div className="col text-start alert alert-info">
                        {
                            props.appTextTemplate.help_text
                        }
                    </div>
                </RowMargin>
                */}
                <RowMargin className="row">
                    <div className="col">
                        <TextAreaWithEditingOptions
                            id={"textarea" + props.textType} 
                            value={appText?.text}
                            language={language}
                            onChange={onTextEditChange}
                            onTextAlignmentChange={onTextAlignmentChange}
                        />
                    </div>                   
                </RowMargin>
                <RowMargin className="row">
                    <div className="col">  
                        <SelectboxFonts
                            key={"editTextFontFamiliy" + props.textType}
                            name={"editTextFontFamiliy" + props.textType}
                            label={Language[language].editor.fontFamily}
                            value={appText?.font_id}
                            valueField={"id"}
                            displayField={"name"}
                            options={fonts}
                            onChange={onTextFontFamilyChange}
                            onBlur={onTextFontFamilyChange}
                        />
                    </div>
                </RowMargin>
                <RowMargin className="row">
                    <div className="col">
                        <TextInput
                            key={"textEditFontSize" + props.textType}
                            type={"number"}
                            label={""}
                            min={0.5}
                            max={6}
                            step={0.25}
                            name={props.textType}
                            placeholder={Language[language].editor.fontSize}
                            onBlur={onFontSizeBlur}
                            onChange={onFontSizeChanged}
                            submitOnEnter={false}
                            required={true}
                            disabled={false}
                            value={props.appText?.font_size}
                            autoFocus={true}
                        />
                    </div>
                </RowMargin>
                <RowMargin className="row">
                    <div className="col">
                        <Button
                            key={"saveButton" + props.textType}
                            buttonText={Language[language].general.save}
                            additionalClasses={""}
                            onClicked={onSaveButtonClicked}
                            icon={saveIcon} 
                            iconSize={"20px"}
                            iconColor={"#000"}
                        />
                    </div>
                    <div className="col">
                        <Button
                            key={"cancelButton" + props.textType}
                            buttonText={Language[language].general.cancel}
                            additionalClasses={""}
                            onClicked={onCancelButtonClicked}
                            icon={cancelIcon} 
                            iconSize={"20px"}
                            iconColor={"#000"}
                        />
                    </div>
                </RowMargin>
            </EditTextFieldDiv>
        </>
    );
}


const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"editText.component.js"}
            fallbackUI={<FallbackUI language={language} component={"editText"} />}
        >
            <EditText
                key={"editTextComponent" + props.textType}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;