import React, { Component } from "react";
import styled from 'styled-components';
import Language from '../../../../constants/language';
import CalendarDay from '../../../../components/app/calendarDay.component';
import Comment from '../../../../components/app/comment.component';
import * as GuestTypes from '../../../../constants/guesttypes';
import * as Data from '../../../../backend/data';
import Participation from "../../../../components/app/participation.component";
import AppFeatures from "../../../../components/app/appFeatures.component";
import Status from "../../../../components/app/status.component";
import AppMessages from "../../../../containers/app/appMessages.container";

const flowerImage = require('../../../../assets/images/background_hochzeit2.png').default;

const StartImage = styled.div`
    display:flex;
    background-repeat:no-repeat;
    width:100vw;
    height:100vh;

    background-image:url(${props => (props.images !== undefined && props.images !== null && props.images.length > 0 && props.images.filter((image) => image.type === 'horizontalAppImage' ).length > 0) ? props.images.filter((image) => image.type === 'horizontalAppImage')[0].image_url : ''});
    background-position:top center;
    background-size:cover;

    @media (max-width: 768px) {
        background-image:url(${props => (props.images !== undefined && props.images !== null && props.images.length > 0 && props.images.filter((image) => image.type === 'verticalAppImage' ).length > 0) ? props.images.filter((image) => image.type === 'verticalAppImage')[0].image_url : ''});
        background-size:cover;
    }
`

const Background = styled.div`
    /*
    padding-top:2rem;
    padding-bottom:2rem;
    */
    background-repeat:repeat;
    background-image:url(${props => (props.images !== undefined && props.images !== null && props.images.length > 0 && props.images.filter((image) => image.type === 'textBackgroundImage' ).length > 0) ? props.images.filter((image) => image.type === 'textBackgroundImage')[0].image_url : ''});
    background-size:cover;
    background-position:center;
    width:100%;
    height:100%;
    position:fixed;
    opacity:0.3;
    z-index:-2;
` 

const BackgroundFlower = styled.div`
    /*
    padding-top:2rem;
    padding-bottom:2rem;
    */
    background-repeat:repeat;
    background-image:url(${flowerImage});
    background-size:cover;
    background-position:center;
    width:100%;
    height:100%;
    position:fixed;
    z-index:-1;
` 

const HeaderText = styled.div`
    font-family: 'Parisienne', cursive;
    font-size:4rem;
    color:#021030;
    background-color:transparent;
    /*
    position:absolute;
    top:5%;
    left:20%; 
    */
` 

const Header = styled.div`
    position: absolute;
    top: 5%;
` 

const SubHeader = styled.div`

    font-family: 'Parisienne', cursive;
    font-size:2.5rem;
    color:#021030;
`


const Text = styled.div`

    font-family: 'Parisienne', cursive;
    font-size:2rem;
    color:#021030;
`

const Link = styled.a`
    color:#021030;

    &:hover {
        text-decoration:none;    
        color: lavenderblush;
    }
`

const Separator = styled.hr`
    width: 25%;
    border-color: #b7b7b7;
    margin-top:3rem;
    margin-bottom:3rem;
`

const MarginTop2rem = styled.div`
    margin-top:2rem;
`

class WeddingClassic extends Component {

    constructor(props) {
        super(props);
        this.state = {
            landingPageLanguage: props.language,
            showNewContentAvailable: false,
        };

        this.getLandingPageLanguage = this.getLandingPageLanguage.bind(this);
        this.setText = this.setText.bind(this);
    }

    componentDidMount() {
        this.setState({
            landingPageLanguage: this.getLandingPageLanguage()
        });

        if(window.location.hash === '#newMessage') {
            window.setTimeout(() => {
                document.getElementById("appMessagesEnd").scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
            },1500);
        }

        if('serviceWorker' in navigator) {
            //check new content available
            //see SO: https://stackoverflow.com/questions/37573482/to-check-if-serviceworker-is-in-waiting-state
            navigator.serviceWorker.ready
            .then((registration) => {
                if(registration.waiting && registration.active) {
                    this.setState({
                        showNewContentAvailable: true
                    });
                } 
                else {
                    // updatefound is also fired for the very first install. ¯\_(ツ)_/¯
                    registration.addEventListener('updatefound', () => {
                        registration?.installing?.addEventListener('statechange', () => {
                            if (event.target.state === 'installed') {
                                if (registration.active) {
                                    // If there's already an active SW, and skipWaiting() is not
                                    // called in the SW, then the user needs to close all their
                                    // tabs before they'll get updates.
                                    this.setState({
                                        showNewContentAvailable: true
                                    });
                                }
                            }
                        });
                    });
                }
            });
        }
    }

    getLandingPageLanguage() {
        let language = this.props.language;
        if(this.props.guestLandingPage != null) {
            if(this.props.guestLandingPage.landing_page.app_info.language_bcp47_code !== null) {
                if(this.props.guestLandingPage.landing_page.app_info.language_bcp47_code.indexOf('-') > -1) {
                    let languageSplitted = this.props.guestLandingPage.landing_page.app_info.language_bcp47_code.split('-');
                    language = languageSplitted[0];
                }
                else {
                    language = this.props.guestLandingPage.landing_page.app_info.language_bcp47_code;
                }
            }
        } 
        return language;
    }

    setText(type) {
        let returnText = (this.props.guestLandingPage.landing_page.app_texts !== undefined && this.props.guestLandingPage.landing_page.app_texts.length > 0 && this.props.guestLandingPage.landing_page.app_texts.filter((text) => text.type === type).length > 0 ? this.props.guestLandingPage.landing_page.app_texts.filter((text) => text.type === type)[0].text : '' );
        return {__html: returnText };
    }

    render() {
        let provideFeedback = (this.props.guestLandingPage.guest_type_id === GuestTypes.GUEST_TYPE_SINGLE_PERSON) ? Language[this.state.landingPageLanguage].message.provideFeedbackSinglePerson : Language[this.state.landingPageLanguage].message.provideFeedbackFamily;
        //let additionalButtonStyles = {border:'1px solid #d07979',backgroundColor:'#fde5e5',color:'#021030',height:'auto',fontFamily: 'Parisienne,cursiv',fontSize: '1.7rem'};
        let additionalButtonStyles = {backgroundColor:'#021030',color:'#fff',height:'auto',fontFamily: 'Parisienne,cursiv',fontSize: '1.7rem'};
        let buttonIconColor = '#021030';
        return (
            <>
                <Background key={"backgroundImageKey"} images={this.props.guestLandingPage.landing_page.app_images}></Background>
                <StartImage key={"startImageKey"} images={this.props.guestLandingPage.landing_page.app_images} />                
                <Header className="container-fluid">
                    <div className="row">
                        <MarginTop2rem className="col text-center">
                            <HeaderText key={"headerKey"}>
                                {
                                    this.props.guest != null &&
                                    this.props.guest.landing_page.event.name
                                }
                            </HeaderText>
                        </MarginTop2rem>
                    </div>
                </Header>
                <div className="container-fluid">
                    <div className="row">
                        <MarginTop2rem className="col text-center">
                            <SubHeader key={"subHeaderKey1"}>
                                {
                                    this.props.guestLandingPage != null &&
                                    this.props.guestLandingPage.landing_page.name
                                }
                            </SubHeader>
                        </MarginTop2rem>
                        <div className="col-12 text-center">
                            <SubHeader key={"subHeaderKey2"}>
                                {
                                    
                                    this.props.guestLandingPage != null &&
                                    <CalendarDay 
                                        key={"calendarDateDay"}
                                        date={this.props.guestLandingPage.landing_page.event.event_date} 
                                        language={this.state.landingPageLanguage} 
                                    />
                                    
                                }
                            </SubHeader>
                        </div>
                        <MarginTop2rem className="col-12 d-flex justify-content-center">
                            {
                                this.props.guestLandingPage != null &&
                                <Link href={"/events/" + this.props.guestLandingPage.landing_page.event.url_id + "/" + this.props.guestLandingPage.landing_page.url_id + "/calendar.ics"}>
                                    
                                    <CalendarDay 
                                        key={"calendarDateDay"}
                                        showAsCalendarIcon={true}
                                        date={this.props.guestLandingPage.landing_page.event.event_date} 
                                        language={this.state.landingPageLanguage} 
                                    />
                                    
                                </Link>
                            }
                        </MarginTop2rem>
                        <div className="col-12 text-center">
                            <Link href={"/events/" + this.props.guestLandingPage.landing_page.event.url_id + "/" + this.props.guestLandingPage.landing_page.url_id + "/calendar.ics"} download>
                                { Language[this.state.landingPageLanguage].app.saveInCalendar }
                            </Link>
                        </div>
                    </div>
                    <MarginTop2rem className="row">
                        <div className="col-12 text-center">
                            <Text key={"subHeaderKey3"}>
                                <div className="container">
                                    {/*
                                    <Salutation language={this.state.landingPageLanguage} guest={this.props.guest} />
                                    <br />
                                    */}
                                    <div dangerouslySetInnerHTML={this.setText('mainText')}></div>
                                </div>
                            </Text>
                        </div>
                    </MarginTop2rem>
                    <MarginTop2rem className="row">
                        <Participation 
                            key="participate"
                            guestLandingPage={this.props.guestLandingPage}
                            language={this.state.landingPageLanguage}
                            additionalButtonStyles={additionalButtonStyles} 
                        />
                    </MarginTop2rem>
                    <MarginTop2rem className="row">
                        <div className="col-12 text-center">
                            { provideFeedback }
                        </div>
                    </MarginTop2rem>
                    <MarginTop2rem className="row">
                        <div className="col-12 text-center">
                            <div className="container">
                                <Comment 
                                    key={"leaveComment"}
                                    guestLandingPage={this.props.guestLandingPage}
                                    language={this.state.landingPageLanguage}
                                    additionalButtonStyles={additionalButtonStyles}
                                />
                            </div>
                        </div>
                    </MarginTop2rem>
                    <MarginTop2rem className="row">
                        <Separator 
                            key={"separatorAppMessages"}
                        />
                    </MarginTop2rem>
                    <MarginTop2rem className="row">
                        <div className="col text-center">
                            <div className="container-lg">
                                <div className="row">
                                    <div className="col text-center">
                                        <AppMessages    
                                            key={"appMessages"}
                                            messages={this.props.guestLandingPage.messages}
                                            textStyle={additionalButtonStyles}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </MarginTop2rem>
                    <div className="row">
                        <div className="col">
                            <br />
                            <br />
                            {/* 
                            <img src="http://localhost:8001/builder/images/show/1ndCzR1ntp889GinuytpKG8T1KQIC5EYbsbnByMF2fTCwAw9tb" className="img-fluid" alt="" />
                            */}
                        </div>
                    </div>
                </div>
                {/*</Background>*/}
                <AppFeatures
                    key={"appFeatuesComponentKey"}
                    language={this.state.landingPageLanguage}
                    additionalButtonStyles={additionalButtonStyles}
                    guestLandingPage={this.props.guestLandingPage}
                    buttonIconColor={buttonIconColor}
                />
                <Status 
                    key={"statusComponentKey"} 
                    language={this.state.landingPageLanguage}
                />
            </>
        );
    }
}

export default WeddingClassic;