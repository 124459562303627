import React, { Component } from 'react';
import styled from 'styled-components';
import Textarea from '../textarea.component';
import Button from '../button.component';
import Language from '../../constants/language';
import Message from './message.component';
import * as Utils from '../../utils/utils';
import * as Data from '../../backend/data';
import * as MessageTypes from '../../constants/messagetypes';
var jwt = require('jsonwebtoken');

const MarginTop1rem = styled.div`
    margin-top:1rem;
`

class Comment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            iDbTable: 'comments',
            iDbSyncTable: 'sync-comments',
            commentText: (props.guestLandingPage.landing_page.comment !== undefined && props.guestLandingPage.landing_page.comment !== null) ? props.guestLandingPage.landing_page.comment.text : '',
            message: null,
        }
        this.onLoadCurrentCommentSuccess = this.onLoadCurrentCommentSuccess.bind(this);
        this.onLoadCurrentCommentFailure = this.onLoadCurrentCommentFailure.bind(this);
        this.onSaveSuccess = this.onSaveSuccess.bind(this);
        this.onRequestDataFailure = this.onRequestDataFailure.bind(this);
        this.readIDbTable = this.readIDbTable.bind(this);
    }

    componentDidCatch(error, info) {
    }

    componentDidMount() {
        let formData = [
            {
                name:'identifierToken',
                value: this.props.guestLandingPage.identifier_token
            }
        ];
        Data.transfer(window.location.origin + '/comment/get','POST',formData,this.onLoadCurrentCommentSuccess,this.onLoadCurrentCommentFailure,null);
        
    }

    componentDidUpdate(prevProps) {
    }

    onLoadCurrentCommentSuccess(data) {
        if(data.success) {
            Utils.idbClearAllData(this.state.iDbTable);
            Utils.idbWriteData(this.state.iDbTable,data.data)
            .then(() => {
                this.readIDbTable();
            });
        }
    }

    onLoadCurrentCommentFailure(error) {
        this.readIDbTable();
    }

    readIDbTable() {
        Utils.idbReadAllData(this.state.iDbTable)
        .then((data) => {
            if(data.length > 0) {
                this.setState({
                    commentText: data[0].text,
                });
            }
        })
    }

    onSaveCommentButtonClick(event) {
        event.preventDefault();
        let route = window.location.origin + '/comment/save';
        let guestLandingPage = this.props.guestLandingPage;
        let commentText = this.state.commentText;
        let commentToken = jwt.sign({ guestLandingPage, commentText }, process.env.MIX_JWT_PRIVATE_KEY, { algorithm: 'HS512'});
        let formData = [
            {
                name:'data',
                value: commentToken
            }
        ];
        if('serviceWorker' in navigator && 'SyncManager' in window) {
            navigator.serviceWorker.ready
            .then((sw) => {
                let objectToStore = new Object();
                objectToStore.id = Math.round(Math.random() * 10000000);
                objectToStore.data = commentToken;
                objectToStore.table = this.state.iDbTable;
                objectToStore.syncTable = this.state.iDbSyncTable;
                objectToStore.url = route;
                Utils.idbWriteData(this.state.iDbSyncTable,objectToStore)
                .then(() => {
                    sw.sync.register(this.state.iDbSyncTable);
                })
                .then(() => {
                    //this.onSaveForSyncSuccess();
                })
                .catch(function (err) {
                    console.log(err);
                });
            });
        }
        else {
            Data.transfer(route,'POST',formData,this.onSaveSuccess,this.onRequestDataFailure,this.props.csrfToken);
        }
    }

    onSaveSuccess(data) {
        this.setState({
            message: {
                messageType: MessageTypes.MESSAGE_TYPE_SUCCESS,
                message: 'SUCCESS'
            }
        },() => {
            window.setTimeout(() => {
                this.setState({
                    message: null 
                });
            },5000);
        });
    }

    onRequestDataFailure(error) {
        this.setState({
            message: {
                messageType: MessageTypes.MESSAGE_TYPE_ERROR,
                message: error
            }
        },() => {
            window.setTimeout(() => {
                this.setState({
                    message: null 
                });
            },5000);
        });
    }

    onCommentTextChanged(event) {
        this.setState({
            commentText: event.target.value
        });
    }

    render() {
        return (
            <div>
                <div>
                    <Textarea
                        id={"comment"} 
                        rows={4}
                        additionalClasses={""}
                        additionalTextStyles={this.props.additionalTextStyles}
                        name={"comment"}
                        value={this.state.commentText}
                        onChange={this.onCommentTextChanged.bind(this)}
                    />
                </div>
                <MarginTop1rem className="row">
                    <div className="col-3"></div>
                    <div className="col-6 text-center">
                        <Button
                            key={"saveCommentButton"}
                            buttonText={Language[this.props.language].general.submit}
                            additionalClasses={""}
                            additionalStyles={this.props.additionalButtonStyles}
                            onClicked={this.onSaveCommentButtonClick.bind(this)}
                            disabled={false}
                        />
                    </div>
                    <div className="col-3"></div>
                </MarginTop1rem>
                {
                    this.state.message !== null &&
                    <Message 
                        key={"messageCommentComponent"}
                        message={this.state.message}
                        language={this.props.language}
                    />
                }
            </div>
        )
    }
}

export default Comment;