import React, { useState, useEffect } from 'react';
import ErrorBoundary from '../../../../../error/error.component';
import FallbackUI from '../../../../../error/standardFallback.component';
import { FadeIn } from '../../../../../styled/fadeIn.component';
import { Icon } from '../../../../../styled/icon.component';
import Language from '../../../../../../constants/language';
import TextInput from '../../../../../textInput.component';
import Checkbox from '../../../../../checkbox.component';
import { InputError } from '../../../../../styled/inputError.component';
import { isNullOrUndefined } from '../../../../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { setProductSetupWizard } from '../../../../../../reducers/slices/productSetupWizard.slice';
import { useProductTypeSetupWizard } from '../../../../../../factories/productSetup.factory';

function ProductSalesTax(props) {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);
    const user = useSelector((state) => state.user.value);
    const productSetupWizard = useSelector((state) => state.productSetupWizard.value);

    const [productSalesTaxInPercent, setProductSalesTaxInPercent] = useState(isNullOrUndefined(productSetupWizard.product?.sales_tax_amount_in_percent) ? user.business_data.country.sales_tax_amount_in_percent : productSetupWizard.product.sales_tax_amount_in_percent);
    const [isDefaultSalesTaxOfCountry, setIsDefaultSalesTaxOfCountry] = useState(isNullOrUndefined(productSetupWizard.product?.is_default_sales_tax_of_country) ? 1 : productSetupWizard.product?.is_default_sales_tax_of_country);
    const [additionalClasses, setAdditionalClasses] = useState('');
    const [error, setError] = useState(null);

    const { updateProduct } = useProductTypeSetupWizard();

    function checkSalesTaxValue(event, action = 'submit') {
        let newValue = event.target.value;
        if(isNullOrUndefined(newValue) || newValue === "") {
            newValue = productSalesTaxInPercent.toString();
            if(isDefaultSalesTaxOfCountry) {
                newValue = user.business_data.country.sales_tax_amount_in_percent.toString();
            }
        }
        let isValid = false;
        if(isDefaultSalesTaxOfCountry || (newValue.trim().length > 0 && parseInt(newValue) !== NaN && parseInt(newValue) >= 0 && parseInt(newValue) < 100)) {
            isValid = true;
        }
        if(isValid) {
            setAdditionalClasses('');
            setError(null);
        }
        else {
            setAdditionalClasses('is-invalid');
            setError(Language[language].shop.wizard.product.valueIsInvalid);
        }
        let product = _.cloneDeep(productSetupWizard.product);
        product = updateProduct(product, { type: 'sales_tax_amount_in_percent', value: newValue });
        product = updateProduct(product, { type: 'is_default_sales_tax_of_country', value: isDefaultSalesTaxOfCountry });
        let updatedWizard = { step: productSetupWizard.step, product: product};
        if(isValid && action === 'submit') {
            updatedWizard.step++;
        }
        dispatch(setProductSetupWizard(updatedWizard));
        setProductSalesTaxInPercent(newValue);
    }

    useEffect(() => {
        if(event.target.name === 'productSalesTaxInPercent' || event.target.dataset?.name === 'isDefaultSalesTaxOfCountry') {
            checkSalesTaxValue(event, 'change');
        }
    },[isDefaultSalesTaxOfCountry]);

    return (
        <FadeIn>
            <div className="row">
                <div className="col d-flex justify-content-center align-items-center">
                    { Language[language].shop.wizard.product.whatIsTheSalesTaxOnThisProduct }
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <Checkbox 
                        key={"isDefaultSalesTaxOfCountry"}
                        label={`${Language[language].shop.wizard.product.isDefaultSalesTax} ${Language[language].general.of} ${user.business_data.country.name} (${user.business_data.country.sales_tax_amount_in_percent} %)`}
                        onChange={(event) => setIsDefaultSalesTaxOfCountry(!isDefaultSalesTaxOfCountry)}
                        checked={isDefaultSalesTaxOfCountry}
                        name="isDefaultSalesTaxOfCountry"
                    />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <TextInput
                        key={"productSalesTaxInPercent"}
                        type={"number"}
                        step={"1"}
                        min={"1"}
                        max={"99"}
                        label={""}
                        value={productSalesTaxInPercent}
                        name={"productSalesTaxInPercent"}
                        placeholder={Language[language].shop.wizard.product.salesTaxInPercent}
                        onChange={(event) => checkSalesTaxValue(event, 'change')}
                        submitOnEnter={true}
                        submitEvent={(event) => checkSalesTaxValue(event)}
                        required={true}
                        maxLength={255}
                        autoFocus={true}
                        additionalClasses={additionalClasses}
                        disabled={isDefaultSalesTaxOfCountry}
                    />
                    {
                        error !== null &&
                        <InputError>
                            {
                                error
                            }
                        </InputError>
                    }
                </div>
            </div>
        </FadeIn>
    );
}

const ComponentWithErrorBoundary = (props) => {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"productSalesTax.component.js"}
            fallbackUI={<FallbackUI language={language} component={"productSalesTax"} />}
        >
            <ProductSalesTax
                key={`productSalesTaxComponent`}
                {...props}
            />
       </ErrorBoundary>
   )
}
 
export default ComponentWithErrorBoundary;