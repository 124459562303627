import React, { useState, useEffect } from 'react';
import ErrorBoundary from '../../../../../error/error.component';
import FallbackUI from '../../../../../error/standardFallback.component';
import { FadeIn } from '../../../../../styled/fadeIn.component';
import { Icon } from '../../../../../styled/icon.component';
import Language from '../../../../../../constants/language';
import TextInput from '../../../../../textInput.component';
import { InputError } from '../../../../../styled/inputError.component';
import { isNullOrUndefined } from '../../../../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { handleDecimalsOnPrice, isPriceValid } from '../../../../../../utils/price';
import { setProductSetupWizard } from '../../../../../../reducers/slices/productSetupWizard.slice';
import { useProductTypeSetupWizard } from '../../../../../../factories/productSetup.factory';
import { v4 as uuidv4 } from 'uuid';

function NetPrice(props) {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);
    const productSetupWizard = useSelector((state) => state.productSetupWizard.value);
    const user = useSelector((state) => state.user.value);

    const [netPrice, setNetPrice] = useState(isNullOrUndefined(productSetupWizard.product?.price_net) ? '' : productSetupWizard.product.price_net);
    const [additionalClasses, setAdditionalClasses] = useState('');
    const [error, setError] = useState(null);

    const { updateProduct } = useProductTypeSetupWizard();
    /*
    let regex = /[^0-9$.,]/g;
    this.setState({
      value: value.replace(regex, "").replace(",", ".")
    });
    */

    function checkNetPrice(event, action = 'submit') {
        let newNetPrice = event.target.value;
        if(newNetPrice < 0 || newNetPrice === '--') {
            newNetPrice = '';
        }
        let validPrice = handleDecimalsOnPrice(newNetPrice);
        if(isPriceValid(validPrice)) {
            setAdditionalClasses('');
            setError(null);
        }
        else {
            setAdditionalClasses('is-invalid');
            setError(Language[language].shop.wizard.product.priceIsInvalid);
        }
        let product = _.cloneDeep(productSetupWizard.product);
        product = updateProduct(product, { type: 'price_net', value: validPrice });
        let updatedWizard = { step: productSetupWizard.step, product: product};
        if(isPriceValid(validPrice) && action === 'submit') {
            updatedWizard.step++;
        }
        dispatch(setProductSetupWizard(updatedWizard));
        setNetPrice(validPrice);
    }

    return (
        <FadeIn>
            <div className="row">
                <div className="col d-flex justify-content-center align-items-center">
                    { `${Language[language].shop.wizard.product.enterNetPriceText} (${Language[language].general.in} ${user.business_data.country.currency.name})` }
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <TextInput
                        key={`netPrice${uuidv4()}`}
                        type={"text"}
                        //step={"0.01"}
                        label={""}
                        value={netPrice}
                        name={"netPrice"}
                        placeholder={`${Language[language].shop.wizard.product.netPriceIn} ${user.business_data.country.currency.iso_4217_code}`}
                        onChange={(event) => checkNetPrice(event, 'change')}
                        submitOnEnter={true}
                        submitEvent={(event) => checkNetPrice(event)}
                        required={true}
                        autoFocus={true}
                        additionalClasses={additionalClasses}
                    />
                    {
                        error !== null &&
                        <InputError>
                            {
                                error
                            }
                        </InputError>
                    }
                </div>
            </div>
        </FadeIn>
    );
}

const ComponentWithErrorBoundary = (props) => {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"netPrice.component.js"}
            fallbackUI={<FallbackUI language={language} component={"netPrice"} />}
        >
            <NetPrice
                key={`netPriceComponent`}
                {...props}
            />
       </ErrorBoundary>
   )
}
 
export default ComponentWithErrorBoundary;