import styled from 'styled-components';

export const Icon = styled.div`
    display: ${props => props.display ? props.display : 'inherit'};
    margin: ${props => props.margin ? props.margin : '0.5rem'};
    min-width:${props => props.minSize ? props.minSize : '24px'};
    width:${props => props.minSize ? props.minSize : '24px'};
    height:${props => props.minSize ? props.minSize : '24px'};
    color:#fff;
    background-color:${props => props.maskColor ? props.maskColor : '#fff'};
    -webkit-mask-image: url(${props => props.image});
    mask-image: url(${props => props.image});
    -webkit-mask-size: cover;
    mask-size:cover;
    background-size: cover;
    background-position: center;
    cursor:${props => props.cursor ? props.cursor : 'auto'};

    &:hover {
        background-color:${props => props.hoverBackgroundColor ? props.hoverBackgroundColor : 'inherit'};
        border:${props => props.hoverBorder ? props.hoverBorder : 'inherit'};
    }
`;