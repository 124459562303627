import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
    0% {
        transform: scale(0);
        opacity: 0.3;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
`;

export const FadeIn = styled.div`
    animation: ${fadeIn};
    animation-duration: 0.3s;
`