import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const ProgressBarDiv = styled.div`
    width:${props => props.width}%;
    background-color:#e45d5d !important;
`

function ProgressBar(props) {
    const [currentPercentValue, setCurrentPercentValue] = useState(0);

    useEffect(() => {
        if(props.maxValue >= props.currentValue) {
            setCurrentPercentValue(Math.round(props.currentValue/props.maxValue * 100));
        }
    },[props.currentValue,props.maxValue]);

    return (
        <>
            <div className="progress">
                <ProgressBarDiv 
                    className="progress-bar progress-bar-striped bg-dark" 
                    role="progressbar" 
                    aria-label=""
                    aria-valuenow={currentPercentValue} 
                    aria-valuemin="0" 
                    aria-valuemax="100"
                    width={currentPercentValue}
                >
                    { `${currentPercentValue} %` }    
                </ProgressBarDiv>
            </div>
        </>
    );
}

export default ProgressBar;