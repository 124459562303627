import { HTTP_STATUS_CODE_OK, HTTP_STATUS_CODE_TOKEN_MISMATCH } from '../constants/httpStatusCodes';

export function transfer(url,method,formDataArray,callbackSuccessFunction,callbackErrorFunction,csrfToken) {
    return new Promise((resolve,reject) => {

        let formData = new FormData();
        formDataArray.forEach(parameter => {
            formData.append(parameter.name, parameter.value);
        });
        fetch(url, {
            method: method,
            headers: {
                'Accept': 'application/json',
                'X-CSRF-TOKEN': csrfToken
            },
            body: (method !== 'GET') ? formData : undefined
        })
        .then((res) => {
            if(res.status === HTTP_STATUS_CODE_TOKEN_MISMATCH) {
                location.reload();
            }
            if(res.status < HTTP_STATUS_CODE_OK || res.status > 299) {
                //throw(res.status + " " + res.statusText);
                //throw(res.json());
                return res.json();
            }
            else {
                return res.json();
            }
        })
        .then((data) => {
            if(data.success) {
                if(callbackSuccessFunction) {
                    callbackSuccessFunction(data);
                }
                else {
                    resolve(data);
                }
            }
            else {
                throw(data);
            }
        })
        .catch((err) => {
            if(callbackErrorFunction) {
                let message = '';
                if(err.message && err.message == 'Failed to fetch') {
                    message = err.message + ' - Please check your connection!'
                }
                else if((typeof err.message === "object" || typeof err.message === 'function') && (err.message !== null) ) {
                    for (const [key, value] of Object.entries(err.message)) {
                        if(Array.isArray(value)) {
                            message += value.join(' - ');
                        }
                        else {
                            message += value + ' - ';
                        }
                    }
                }
                else {
                    message = err;
                }
                callbackErrorFunction(message);
            }
            else {
                reject(err);
            }
        });

    });
}

export function trackError(error, stackTrace, component, location, guestLandingPageIdentifierToken = null, userId = null) {
    let formData = [
        {
            name:'location',
            value: location
        },
        {
            name:'component',
            value: component
        },
        {
            name:'error',
            value: error
        },
        {
            name:'stackTrace',
            value: stackTrace
        }
        /*,
        {
            name:'userId',
            value: (userId === undefined || userId === null) ? null : userId
        },
        {
            name:'guestLandingPageIdentifierToken',
            value: guestLandingPageIdentifierToken
        }
        */
    ];
    if (userId !== undefined && userId !== null) {
        formData.push({
            name:'userId',
            value: userId
        });
    }
    if (guestLandingPageIdentifierToken !== undefined && guestLandingPageIdentifierToken !== null) {
        formData.push({
            name:'guestLandingPageIdentifierToken',
            value: guestLandingPageIdentifierToken
        });
    }
    transfer(window.location.origin + '/builder/logs/error/save','POST',formData,null,null,null);
}