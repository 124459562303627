import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ErrorBoundary from '../../components/error/error.component';
import FallbackUI from '../../components/error/standardFallback.component';
import ProductSetup from '../../components/managementInterface/shop/tabs/productSetupWizard.component';
import ProductListRow from '../../components/managementInterface/shop/tabs/productListRow.component';
import Button from '../../components/button.component';
import Language from '../../constants/language';
import { resetState } from '../../reducers/slices/productSetupWizard.slice';
import { deleteProduct } from '../../reducers/slices/currentLandingPage.slice';
import { setMessage } from '../../reducers/slices/message.slice';
import { Icon } from '../../components/styled/icon.component';
import { isNullOrUndefined } from '../../utils/utils';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { ModalOverlayBackground, ModalContent, ModalCloseButton } from '../../components/styled/modal.component';
import * as MessageTypes from '../../constants/messagetypes';
import { getErrorMessage } from '../../utils/error';
import * as Data from '../../backend/data';

import infoIcon from '../../assets/images/info-circle.svg';

function ProductListContainer(props) {

    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);
    const user = useSelector((state) => state.user.value);
    const currentLandingPage = useSelector((state) => state.currentLandingPage.value);
    const csrfToken = useSelector((state) => state.csrfToken.value);

    const [isProductWizardActive,setIsProductWizardActive] = useState(false);
    const [currentProduct,setCurrentProduct] = useState(null);
    const [productSetupKey, setProductSetupKey] = useState(uuidv4());
    const [modalContent, setModalContent] = useState(null);
    const [deleteButtonsDisabled, setDeleteButtonsDisabled] = useState(false);
    const [productToDelete, setProductToDelete] = useState(null);

    function onAddNewProductClick() {
        dispatch(resetState());
        setCurrentProduct(null);
        setIsProductWizardActive(true);
    }

    function onEditProduct(product) {
        setCurrentProduct(product);
        setIsProductWizardActive(true);
    }

    function onProductDeleted(product) {
        setModalContent('deleteProduct');
        setProductToDelete(product);
    }

    function navigateBackToProductList() {
        dispatch(resetState());
        setIsProductWizardActive(false);
    }
    
    function deleteProductConfirm() {
        setDeleteButtonsDisabled(true);
        if(productToDelete !== null) {
            let formData = [
                {
                    name: 'landingPageId',
                    value: currentLandingPage.id
                },
                {
                    name: 'productId',
                    value: productToDelete.id
                }
            ];
            Data.transfer(window.baseUrl + '/builder/user/product/delete','POST',formData,null,null,csrfToken)
            .then((data) => {
                if(data.success) {
                    dispatch(deleteProduct(productToDelete.id));
                    closeModal();
                }
            })
            .catch((error) => {
                dispatch(setMessage({
                    message: getErrorMessage(error),
                    messageType: MessageTypes.MESSAGE_TYPE_ERROR
                }));
            });
        }
    }

    function closeModal() {
        setModalContent(null);
        setProductToDelete(null);
    }

    return (
        <>
            {
                isProductWizardActive &&
                <ProductSetup
                    key={`productSetup`}
                    product={currentProduct}
                    navigateBackToProductList={navigateBackToProductList}
                />
            }
            {
                !isProductWizardActive &&
                <div className="container-fluid">
                    <div className="row mt-2">
                        <div className="col">
                            <Button
                                key={"addNewProductButton"}
                                buttonText={Language[language].shop.wizard.product.addNewProduct}
                                additionalClasses={""}
                                onClicked={onAddNewProductClick}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col">
                            <div className="table-responsive">
                                <table className="table table-dark table-striped table-bordered caption-top">
                                    <caption>Products</caption>
                                    <thead>
                                        <tr>
                                            <th scope="col" colSpan="6"></th>
                                            <th scope="col" colSpan="3">Shipping costs</th>
                                            <th scope="col" colSpan="3"></th>
                                        </tr>
                                        <tr>
                                            <th scope="col" style={{whiteSpace:'nowrap'}}></th>
                                            <th scope="col" style={{whiteSpace:'nowrap'}}></th>
                                            <th scope="col" style={{whiteSpace:'nowrap'}}>Status</th>
                                            <th scope="col" style={{whiteSpace:'nowrap'}}>No</th>
                                            <th scope="col" style={{whiteSpace:'nowrap'}}>Name</th>
                                            <th scope="col" style={{whiteSpace:'nowrap'}}>Description</th>
                                            <th scope="col" style={{whiteSpace:'nowrap'}}>Price net</th>
                                            <th scope="col" style={{whiteSpace:'nowrap'}}>Domestic</th>
                                            <th scope="col" style={{whiteSpace:'nowrap'}}>Foreign countries</th>
                                            <th scope="col" style={{whiteSpace:'nowrap'}}>Other countries</th>
                                            <th scope="col" style={{whiteSpace:'nowrap'}}>Max no. prod. in ship. price</th>
                                            <th scope="col" style={{whiteSpace:'nowrap'}}>Sales tax</th>
                                            <th scope="col" style={{whiteSpace:'nowrap'}}>Available no of products</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                           currentLandingPage?.all_products?.length === 0 &&
                                           <tr>
                                                <td colSpan="12">
                                                    <div className="d-flex align-items-center">
                                                        <Icon
                                                            image={infoIcon} 
                                                            title="Info"
                                                            hoverBackgroundColor={'darkgray'}
                                                            cursor={'default'}
                                                            display={'inline-block'}
                                                        /> 
                                                        { ` ${Language[language].shop.noProductsCreatedYet}` }
                                                    </div>
                                                </td>
                                           </tr> 
                                        }
                                        {
                                            _.orderBy(currentLandingPage.all_products,'product_no').map((product) => {
                                                return (
                                                    <ProductListRow
                                                        key={`product${product.id}`}
                                                        product={product}
                                                        onEditProduct={onEditProduct}
                                                        onProductDeleted={onProductDeleted}
                                                    />
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                modalContent !== null && 
                <>
                    <ModalOverlayBackground />
                    <ModalContent
                        backgroundColor={'rgb(33,37,41)'}
                        color={'#fff'}
                    >
                        <div className="container-fluid">
                            <div className="row mt-2">
                                <div className="col text-end">
                                    <ModalCloseButton 
                                        type="button" 
                                        className="btn-close" 
                                        aria-label="Close" 
                                        onClick={closeModal} 
                                    />
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">
                                    <div className="row text-center">
                                        <div className="col">
                                            {
                                                Language[language].shop.deleteProductConfirm
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col d-flex justify-content-center">
                                            <Button
                                                key={"deleteLandingPageConfirmButton"}
                                                buttonText={Language[language].general.yes}
                                                additionalClasses={""}
                                                onClicked={deleteProductConfirm}
                                                disabled={deleteButtonsDisabled}
                                            />
                                        </div>
                                        <div className="col d-flex justify-content-center">
                                            <Button
                                                key={"deleteLandingPageDenyButton"}
                                                buttonText={Language[language].general.no}
                                                additionalClasses={""}
                                                onClicked={closeModal}
                                                disabled={deleteButtonsDisabled}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalContent>
                </>
            }            
        </>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"productList.container.js"}
            fallbackUI={<FallbackUI language={language} component={"productListContainer"} />}
        >
            <ProductListContainer
                key={`productListContainer`}
                {...props}
            />
       </ErrorBoundary>
   )
}
 
export default ComponentWithErrorBoundary;