import Language from './language';

export function getTextPositions(language) {
    return [
        {
            name: Language[language].editor.textPositions.left,
            value: 'flex-start'
        },
        {
            name: Language[language].editor.textPositions.center,
            value: 'center'
        },
        {
            name: Language[language].editor.textPositions.right,
            value: 'flex-end'
        }
    ];
}

export default getTextPositions;