import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TextArea from '../../../../../textarea.component';
import TextAreaWithEditingOptions from '../../../../../textAreaWithEditingOptions.component';
import ErrorBoundary from '../../../../../error/error.component';
import FallbackUI from '../../../../../error/standardFallback.component';
import { FadeIn } from '../../../../../styled/fadeIn.component';
import { Icon } from '../../../../../styled/icon.component';
import Language from '../../../../../../constants/language';
import { InputError } from '../../../../../styled/inputError.component';
import { isNullOrUndefined } from '../../../../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { setProductSetupWizard } from '../../../../../../reducers/slices/productSetupWizard.slice';
import { useProductTypeSetupWizard } from '../../../../../../factories/productSetup.factory';

const TextAreaDiv = styled.div`
    color:#000;
    text-align:left;
`

function ProductDescription(props) {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.value);
    const language = useSelector((state) => state.language.value);
    const productSetupWizard = useSelector((state) => state.productSetupWizard.value);

    const [description, setDescription] = useState(isNullOrUndefined(productSetupWizard.product?.description) ? '[{"type":"paragraph","children":[{"text":""}]}]' : productSetupWizard.product.description);
    const [additionalClasses, setAdditionalClasses] = useState('');
    const [error, setError] = useState(null);

    const { updateProduct } = useProductTypeSetupWizard();

    function checkValue(newValue, newPlainTextValue, action = 'submit') {
        //let newValue = event.target.value;
        let isValid = false;
        if(!isNullOrUndefined(newPlainTextValue) && newPlainTextValue.toString().trim().length > 0) {
            isValid = true;
        }
        if(isValid) {
            setAdditionalClasses('');
            setError(null);
        }
        else {
            setAdditionalClasses('is-invalid');
            setError(Language[language].shop.wizard.product.productDescriptionIsInvalid);
        }
        let product = _.cloneDeep(productSetupWizard.product);
        product = updateProduct(product, { type: 'description', value: JSON.stringify(newValue) });
        let updatedWizard = { step: productSetupWizard.step, product: product};
        if(isValid && action === 'submit') {
            updatedWizard.step++;
        }
        dispatch(setProductSetupWizard(updatedWizard));
        setDescription(newValue);
    }

    function onTextEditChange() {

    }

    return (
        <FadeIn>
            <div className="row">
                <div className="col d-flex justify-content-center align-items-center">
                    { Language[language].shop.wizard.product.enterProductDescriptionText }
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    {/*
                    <TextArea
                        key={"description"}
                        type={"text"}
                        label={""}
                        value={description}
                        name={"description"}
                        placeholder={Language[language].shop.wizard.product.productDescription}
                        onChange={(event) => checkValue(event, 'change')}
                        submitOnEnter={true}
                        submitEvent={(event) => checkValue(event)}
                        required={true}
                        maxLength={255}
                        autoFocus={true}
                        additionalClasses={additionalClasses}
                    />
                    */}
                    <TextAreaDiv>
                        <TextAreaWithEditingOptions
                            id={`productDescriptionTextArea`} 
                            value={description}
                            language={language}
                            showPositioningButtons={false}
                            onChange={(newValue, newPlainTextValue) => checkValue(newValue, newPlainTextValue, 'change')}
                            //onTextAlignmentChange={onTextAlignmentChange}
                        />
                    </TextAreaDiv>
                    {
                        error !== null &&
                        <InputError>
                            {
                                error
                            }
                        </InputError>
                    }
                </div>
            </div>
        </FadeIn>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"productDescription.component.js"}
            fallbackUI={<FallbackUI language={language} component={"productDescription"} />}
        >
            <ProductDescription
                key={`productDescriptionComponent`}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;