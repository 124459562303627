import React, { Component, lazy, useEffect, useState } from 'react';
import NavBarComponent from './components/navbar.component';
import Footer from './components/footer.component';
import ErrorBoundary from './components/error/error.component';
import FallbackUI from './components/error/standardFallback.component';
import CookieAndPrivacyConsent from './components/cookieAndPrivacyConsent.component';
import styled from 'styled-components';
import LoadingSpinner from './components/loadingSpinner.component';
import { useSelector, useDispatch } from 'react-redux'
import { setToken } from './reducers/slices/token.slice';
import { setNavigation } from './reducers/slices/navigation.slice';
import { setUser } from './reducers/slices/user.slice';
import { setIsSignedIn } from './reducers/slices/isSignedIn.slice';
import { setLanguage } from './reducers/slices/language.slice';

const Main = styled.div`
    /*padding-bottom:5vh;*/
    padding-top: 56px;
    height: 100%;
`

const Wrap = styled.div`
    min-height:100%;
`

const VIEWS = {
    NullView: React.lazy(() => import(/* webpackChunkName: "NullView" */'./views/notFound.view')),
    StartView: React.lazy(() => import(/* webpackChunkName: "StartView" */'./views/start.view')),
    SignInView: React.lazy(() => import(/* webpackChunkName: "SignInView" */'./views/signIn.view')),
    RegisterView: React.lazy(() => import(/* webpackChunkName: "RegisterView" */'./views/register.view')),
    DashboardView: React.lazy(() => import(/* webpackChunkName: "DashboardView" */'./views/dashboard.view')),
    ForgotPasswordView: React.lazy(() => import(/* webpackChunkName: "ForgotPasswordView" */'./views/forgotPassword.view')),
    SetNewPasswordView: React.lazy(() => import(/* webpackChunkName: "SetNewPasswordView" */'./views/setNewPassword.view')),
    ImprintView: React.lazy(() => import(/* webpackChunkName: "ImprintView" */'./views/imprint.view')),
    PrivacyView: React.lazy(() => import(/* webpackChunkName: "PrivacyView" */'./views/privacy.view')),
    AccountView: React.lazy(() => import(/* webpackChunkName: "AccountView" */'./views/account.view')),
    ChangePasswordView: React.lazy(() => import(/* webpackChunkName: "ChangePasswordView" */'./views/setNewPassword.view')),
    LandingPageEditorView: React.lazy(() => import(/* webpackChunkName: "LandingPageEditorView" */'./views/editor/landingPageEditor.view')),
    ContactView: React.lazy(() => import(/* webpackChunkName: "ContactView" */'./views/contact.view')),
    TermsAndConditionsView: React.lazy(() => import(/* webpackChunkName: "TermsAndConditions" */'./views/termsAndConditions.view')),
    AcceptLegalsView: React.lazy(() => import(/* webpackChunkName: "AcceptLegals" */'./views/acceptLegals.view')),
}


function App(props) {
    const dispatch = useDispatch();
    const isSignedIn = useSelector((state) => state.isSignedIn.value);
    const navigation = useSelector((state) => state.navigation.value);
    const csrfToken = useSelector((state) => state.csrfToken.value);
    const [windowLocationSearchValue, setWindowLocationSearchValue] = useState(null);
    const [authenticationChecked, setAuthenticationChecked] = useState(false);
    const [navBarOpacityActive, setNavBarOpacityActive] = useState(true);
    const [View, setView] = useState(null);

    useEffect(() => {
        if(window.location.search.length > 0) {
            setWindowLocationSearchValue(window.location.search.substring(1,window.location.search.length));
        }
        if(window.localStorage.getItem('token') && window.localStorage.getItem('token').length > 0) {
            dispatch(setToken(window.localStorage.getItem('token')));
        }
        if(props.target !== undefined) {
            dispatch(setNavigation(props.target));
        }
        if(windowLocationSearchValue !== null) {
            //TODO Lade Guest Landing Page
        }
        getSession();
    
        window.onscroll = (event) => onScroll(event);
    }, []);

    useEffect(() => {
        setView(importView());
        window.localStorage.setItem('lastView',navigation);
    }, [navigation, isSignedIn]);

    function onScroll(event) {
        let opacityActive = true;
        if (document.body.scrollTop > 60 || document.documentElement.scrollTop > 60) {
            opacityActive = false;
        }
        else {
            opacityActive = true;
        }
        if(navBarOpacityActive !== opacityActive) {
            setNavBarOpacityActive(opacityActive);
        }
    }

    function getSession() {
        fetch(window.baseUrl + '/builder/login/session', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'X-CSRF-TOKEN': csrfToken
            },
        })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            if(data.success) {
                dispatch(setUser(data.user));
                dispatch(setIsSignedIn(true));
                dispatch(setNavigation(window.localStorage.getItem('lastView') && window.localStorage.getItem('lastView') !== 'null' ? window.localStorage.getItem('lastView') : 'DashboardView'));
                dispatch(setLanguage(data.user.language));
            }
            else {
                console.log(Object.values(data[0]).map((error) => {
                        return error.join(' ')
                    }).join(' '),
                );
            }
            setAuthenticationChecked(true);
        })
        .catch((error) => {
        });
    }

    function importView() {
        if(VIEWS[navigation] === undefined) {
            if(isSignedIn) {
                return VIEWS['DashboardView'];
            }
            else {
                return VIEWS['StartView'];
            }
        }
        else {
            return VIEWS[navigation];
        }
    }

    return (
        <>
            {
                authenticationChecked && 
                <>                        
                    <Wrap>
                        <NavBarComponent
                            key={"navBarComponent"}
                            hideMenuOptions={(props.target && props.target === 'AcceptLegalsView') || (navigation && navigation === 'AcceptLegalsView') ? true : undefined}
                            opacityActive={navBarOpacityActive}
                        />
                        <Main className="text-center">
                            <React.Suspense fallback={"Loading..."}>
                                {
                                    /*view*/
                                    <View key={"view"} cssClasses={"marginTop1rem"} {...props} />
                                }
                            </React.Suspense>
                        </Main>
                    </Wrap>       
                    {
                        navigation !== 'LandingPageEditorView' &&
                        <Footer key={"footerComponent"} {...props} />   
                    }
                </>    
            }
            {
                !authenticationChecked &&
                <div className="container">
                    <div className="row">
                        <div className="col text-center d-flex justify-content-center">
                            <LoadingSpinner key="loadingSpinnerAuthenticationCheck" />  
                        </div>
                    </div>
                </div>
            } 
            <CookieAndPrivacyConsent 
                key={"cookieAndPrivacyConsentComponent"}
                location={"builder"}
            />
        </>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"app.component.js"}
            user={props.user}
            fallbackUI={<FallbackUI language={language} component={"app"} />}
        >
            <App
                key={"app"}
                {...props}
            />
       </ErrorBoundary>
   )
}
 
export default ComponentWithErrorBoundary;