import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: (window.localStorage.getItem('showOptOutWindow') === "false") ? false : true,
};

export const isCookieAndPrivacyOptOutOpenSlice = createSlice({
  name: 'isCookieAndPrivacyOptOutOpen',
  initialState,
  reducers: {
    setIsCookieAndPrivacyOptOutOpen: (state, action) => {
      state.value = action.payload
    },
  },
});

export const { setIsCookieAndPrivacyOptOutOpen } = isCookieAndPrivacyOptOutOpenSlice.actions;

export default isCookieAndPrivacyOptOutOpenSlice.reducer;