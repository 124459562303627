import React, { useState,useEffect } from 'react';
import styled from 'styled-components';
import ErrorBoundary from '../error/error.component';
import FallbackUI from '../error/standardFallback.component';
import { format } from 'date-fns';
import parseISO from 'date-fns/parseISO'
import { isNullOrUndefined } from '../../utils/utils';
import Language from '../../constants/language';
import Button from '../button.component';
import * as Data from '../../backend/data';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentLandingPage } from '../../reducers/slices/currentLandingPage.slice';
import { setCurrentEvent } from '../../reducers/slices/currentEvent.slice';
import { setMessage } from '../../reducers/slices/message.slice';
import { getErrorMessage } from '../../utils/error';
import { MESSAGE_TYPE_SUCCESS, MESSAGE_TYPE_ERROR } from '../../constants/messagetypes';

import deleteImageIcon from '../../assets/images/trash.svg';

const CardFooter = styled.div`
    padding:0;
`

const ModalBackground = styled.div`
    position: fixed;
    top:0px;
    left:0px;
    width: 100%;
    height: 100%;
    background-color:#0e0e0e;
    opacity:0.6;
    z-index:1000;
` 

const ModalContent = styled.div`
    position: fixed;
    top: 20%;
    left: 50%;
    margin-left: -25%;
    background-color: #fff;
    z-index: 1001;
    border: 1px solid rgba(0,0,0,0.125);
    border-radius: 0.25rem;
    width: 50%;
    max-height: 80vh;
    height:fit-content;
    overflow-y: auto;
`

const ModalCloseButton = styled.div`
    margin:0.5rem;
    position: absolute;
    right: 5px;
    top: 5px;
` 

function PushNotificationMessagesListItem(props) {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);
    const csrfToken = useSelector((state) => state.csrfToken.value);

    const [isModalVisible,setIsModalVisible] = useState(false);

    function onDeleteMessageButtonClick() {
        setIsModalVisible(true);
    }

    function deleteMessageConfirm() {
        const formData = [
            {
                name:'messageId',
                value: props.message.id
            }
        ];
        Data.transfer('/builder/user/landingPage/messages/delete','POST',formData,undefined,undefined,csrfToken)
        .then((data) => {
            if(data.success) {
                if(data.landingPage) {
                    dispatch(setCurrentLandingPage(data.landingPage));
                }
                if(data.event) {
                    dispatch(setCurrentEvent(data.event));
                }
            }
            setIsModalVisible(false);
        })
        .catch((error) => {
            dispatch(setMessage({
                message: getErrorMessage(error),
                messageType: MESSAGE_TYPE_ERROR
            }));
        });
    }

    function closeModal() {
        setIsModalVisible(false);
    }

    return (
        <>
            {
                isModalVisible &&
                <>
                    <ModalBackground />
                    <ModalContent className="bg-dark">
                        <div className="container-fluid">
                            <div className="row mt-2">
                                <div className="col text-end">
                                    <ModalCloseButton 
                                        type="button" 
                                        className="btn-close btn-close-white" 
                                        aria-label="Close" 
                                        onClick={closeModal} 
                                    />
                                </div>
                            </div>
                            <div className="row mt-2 text-center">
                                <div className="col">
                                    {
                                        Language[language].message.deleteMessageConfirm
                                    }
                                </div>
                            </div>
                            <div className="row mt-2 mb-2">
                                <div className="col text-start">
                                    <Button
                                        key={"deleteMessageConfirmButton" + props.message.id}
                                        buttonText={Language[language].general.yes}
                                        additionalClasses={""}
                                        onClicked={deleteMessageConfirm}
                                    />
                                </div>
                                <div className="col text-end">
                                    <Button
                                        key={"deleteMessageDenyButton" + props.message.id}
                                        buttonText={Language[language].general.no}
                                        additionalClasses={""}
                                        onClicked={closeModal}
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalContent>
                </>
            }
            <div className="card mt-3">
                <div className="card-header">
                    <div className="row">
                        <div className="col fw-bold">
                            {
                                props.message.subject
                            }
                        </div>
                    </div>
                </div>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                        {props.message.message}
                    </li>
                    {
                        props.guest.is_event_creator !== 1 && (isNullOrUndefined(props.guest.firstName.length) || isNullOrUndefined(props.guest.lastName)) &&
                        <li className="list-group-item">
                            <small>
                                {
                                    `${Language[language].message.to}: ${props.guest.firstName} ${props.guest.lastName}`
                                } 
                            </small>
                        </li>
                    }
                    <li className="list-group-item">
                        <small>
                            {
                                `${Language[language].message.sent}: ` + format(new Date(parseISO(props.message.updated_at)), 'dd.MM.yyyy hh:mm')
                            } 
                        </small>
                    </li>
                </ul>
                <CardFooter className="card-footer">
                    <Button
                        key={"deleteButton" + props.message.id}
                        buttonText={""}
                        additionalClasses={""}
                        onClicked={onDeleteMessageButtonClick}
                        icon={deleteImageIcon} 
                        iconColor={"#000"}
                    />
                </CardFooter>
            </div>            
        </>
    )
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"pushNotificationMessagesListItem.component.js"}
            fallbackUI={<FallbackUI language={language} component={"pushNotificationMessagesListItem"} />}
        >
            <PushNotificationMessagesListItem
                key={"pushNotificationMessagesListItemComponent"}
                {...props}
            />
       </ErrorBoundary>
   )
}
 
export default ComponentWithErrorBoundary;