import * as Actiontypes from "../constants/actiontypes";
import * as Status from "../constants/status";
import * as Utils from '../utils/utils';

const initialState = {
    token: null,
    csrfToken: window.laravel.csrfToken,
    language: window.language,
    navigation: null,
    status: Status.STATUS_IDLE,
    message: null,
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case Actiontypes.UPDATE_TOKEN:
            return Object.assign({}, state, {
                token: action.payload
            });
        case Actiontypes.UPDATE_CSRF_TOKEN:
            return Object.assign({}, state, {
                csrfToken: action.payload
            });
        case Actiontypes.UPDATE_NAVIGATION:
            Utils.saveLastView(action.payload);
            return Object.assign({}, state, {
                navigation: action.payload
            });
        case Actiontypes.UPDATE_LANGUAGE:
            return Object.assign({}, state, {
                language: action.payload
            });
        case Actiontypes.UPDATE_MESSAGE:
            return Object.assign({}, state, {
                message: action.payload
            });
        default:
            return state;
    }
}
 
export default rootReducer;