import { createSlice } from '@reduxjs/toolkit';
import * as ProductStatus from '../../constants/productStatus';

const initialState = {
  value: null,
};

export const currentLandingPageSlice = createSlice({
  name: 'currentLandingPage',
  initialState,
  reducers: {
    setCurrentLandingPage: (state, action) => {
      state.value = action.payload
    },
    addOrUpdateProduct: (state, action) => {
      let activeProducts = state.value.active_products;
      let allProducts = state.value.all_products;
      let productIndex = allProducts.findIndex((product) => product.product_unique_key === action.payload.product.product_unique_key);
      if(productIndex === -1) {
        allProducts.push(action.payload.product);
      }
      else {
        allProducts[productIndex] = action.payload.product;
      }
      if(action.payload.product.product_status_id === ProductStatus.PRODUCT_STATUS_ACTIVE) {
        productIndex = activeProducts.findIndex((product) => product.product_unique_key === action.payload.product.product_unique_key);
        if(productIndex === -1) {
          activeProducts.push(action.payload.product);
        }
        else {
          activeProducts[productIndex] = action.payload.product;
        }
      }
      state.value.active_products = activeProducts;
      state.value.all_products = allProducts;
    },
    deleteProduct: (state, action) => {
      state.value.active_products = state.value.active_products.filter(product => product.id !== action.payload);
      state.value.all_products = state.value.all_products.filter(product => product.id !== action.payload);
    }
  },
});

export const { setCurrentLandingPage, addOrUpdateProduct, deleteProduct } = currentLandingPageSlice.actions;

export default currentLandingPageSlice.reducer;