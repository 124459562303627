import React, { Component } from "react";
import * as Data from '../../backend/data';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    /*
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
    */
  
    componentDidCatch(error, stackTrace) {
      // You can also log the error to an error reporting service
      // TODO: Send errors to backend
      this.setState({ hasError: true  });
      console.log(error, stackTrace);
      Data.trackError(error, stackTrace.componentStack, this.props.component, this.props.location, this.props.guestLandingPage?.identifier_token, this.props.user?.id);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        //return <h1>Something went wrong.</h1>;
        return this.props.fallbackUI;
      }
  
      return this.props.children; 
    }
}


export default ErrorBoundary;
