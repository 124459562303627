import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: null,
};

export const executionCallbackSlice = createSlice({
  name: 'executionCallback',
  initialState,
  reducers: {
    set: (state, action) => {
      state.value = action.payload
    },
  },
});

export const { set } = executionCallbackSlice.actions;

export default executionCallbackSlice.reducer;