import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import Button from '../button.component';
import TextInput from '../textInput.component';
import Language from '../../constants/language';
import ErrorBoundary from '../error/error.component';
import FallbackUI from '../error/standardFallback.component';
import * as Data from '../../backend/data';
import { MESSAGE_TYPE_SUCCESS } from '../../constants/messagetypes';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentLandingPage } from '../../reducers/slices/currentLandingPage.slice';
import { setMessage } from '../../reducers/slices/message.slice';

import urlIcon from '../../assets/images/globe.svg';

const Header = styled.div`
    font-weight:bold;
`

const InvalidDomainErrorDiv = styled.div`
    display:${props => props.isUrlValid ? 'none' : 'block'};
`

function ManageSubdomain(props) {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);
    const csrfToken = useSelector((state) => state.csrfToken.value);
    const currentLandingPage = useSelector((state) => state.currentLandingPage.value);

    const [isModalOpen, setIsModalOpen] = useState(true);
    const [subdomain, setSubdomain] = useState(null);
    const [isUrlValid, setIsUrlValid] = useState(true);

    function onSubdomainSubmit(value) {
        onSubdomainChange(value, true);
    }

    function is_url(urlToTest) {
        const regexp =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        if (regexp.test(urlToTest)) {
            return true;
        }
        else {
            return false;
        }
    }

    function onSubdomainChange(value, submit = false) {
        setIsUrlValid((value && value.length > 6) ? is_url(value) : true);
        if(isUrlValid) {
            setSubdomain(value);
            if(submit) {
                let formData = [
                    {
                        name:'csrfToken',
                        value: csrfToken
                    },
                    {
                        name:'landingPageId',
                        value: currentLandingPage.id
                    },
                    {
                        name:'subdomain_url',
                        value: subdomain
                    },
                ];
                Data.transfer(window.baseUrl + '/builder/user/landingPage/subdomain/save','POST',formData,undefined,undefined,csrfToken)
                .then((data) => {
                    if(data.success && data.landingPage !== undefined) {
                        dispatch(setCurrentLandingPage(data.landingPage));
                        //props.updateCurrentLandingPage(data.landingPage);
                        /*
                        props.updateMessage({
                            message: Language[props.language].subdomain.subdomainSavedSuccessfully,
                            messageType: MESSAGE_TYPE_SUCCESS
                        });
                        */
                       dispatch(setMessage({
                            message: Language[language].subdomain.subdomainSavedSuccessfully,
                            messageType: MESSAGE_TYPE_SUCCESS
                        }));
                    }
                });
            }
        }
    }

    useEffect(() => {
        if(subdomain === null && currentLandingPage?.subdomain?.domain_url) {
            setSubdomain(currentLandingPage?.subdomain?.domain_url);
        }
    });

    return (
        <>            
            <div className="container">
                <div className="row">
                    <div className="col">
                        <Header>{Language[language].subdomain.manageSubdomain}</Header>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {Language[language].subdomain.helpSentence}
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <TextInput
                            key={"subdomainTextField"}
                            type={"text"}
                            label={""}
                            name={"subdomain"}
                            placeholder={Language[language].subdomain.subdomain}
                            onBlur={(e) => onSubdomainChange(e.target.value, false)}
                            onChange={(e) => onSubdomainChange(e.target.value, false)}
                            submitOnEnter={true}
                            submitEvent={(e) => onSubdomainSubmit(e.target.value)}
                            required={true}
                            disabled={false}
                            value={subdomain}
                            autoFocus={true}
                            additionalClasses={isUrlValid ? undefined : 'is-invalid'}
                            labelColor={isUrlValid ? undefined : "#dc3545"}
                        />
                        {
                            !isUrlValid &&
                            <InvalidDomainErrorDiv isUrlValid={isUrlValid} className="invalid-feedback">
                                Please provide a valid URL.
                            </InvalidDomainErrorDiv>
                        }
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <Button
                            key={"manageSubdomainSubmitButton"}
                            buttonText={Language[language].general.save}
                            additionalClasses={""}
                            onClicked={(e) => onSubdomainSubmit(subdomain)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"manageSubdomain.component.js"}
            fallbackUI={<FallbackUI language={language} component={"manageSubdomain"} />}
        >
            <ManageSubdomain
                key={"manageSubdomainComponent"}
                {...props}
            />
       </ErrorBoundary>
   )
}
 
export default ComponentWithErrorBoundary;