import React, { Component, useState, useEffect } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import Button from '../button.component';
import Selectbox from '../selectbox.component';
import Language from '../../constants/language';
import * as MessageTypes from '../../constants/messagetypes';
import * as ImageOptionsModalStates from '../../constants/imageOptionsModalStates';
import * as Data from '../../backend/data';
import { EditOptionsBackground } from '../styled/editOptionsBackground.component';
import { Icon } from '../styled/icon.component';
import { IMAGE_TYPE_PAGE_CONTENT } from '../../constants/imageTypes';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentLandingPage } from '../../reducers/slices/currentLandingPage.slice';
import * as MessageSlice from '../../reducers/slices/message.slice';
import { getErrorMessage } from '../../utils/error';
import ErrorBoundary from '../error/error.component';
import FallbackUI from '../error/standardFallback.component';
import { removeImageById } from '../../reducers/slices/productSetupWizard.slice';

import appIconSymbol from '../../assets/images/card-image.svg';
import deleteImageSymbol from '../../assets/images/trash.svg';
import editUsageSymbol from '../../assets/images/pencil-square.svg';
import { isNullOrUndefined } from '../../utils/utils';

const ImageDiv = styled.div`
    padding:1rem;
    border-radius:0.5rem;
    /*background-color:#dee2e6;*/
    /*background: linear-gradient(to top, #fff, #ecdeb4, #f9f9f9, #fff);*/
    margin:0.1rem;

    &:hover {
        /*transform: scale(1.5);*/
        z-index:1000;
    }
` 

const Image = styled.img`
    border: 1px solid #aeaeae;
    border-radius: 0.25rem;
    max-width: ${props => props.maxImageWidth ? props.maxImageWidth : 'initial'};
`
const PaddingLeft = styled.span`
    padding-left:0.5rem;
`

const Margin1Rem = styled.div`
    margin:1rem;
`

const OptionsModalBackground = styled.div`
    position: fixed;
    top:0px;
    left:0px;
    width: 100%;
    height: 100%;
    background-color:#6c757d;
    opacity:0.6;
    z-index:1000;
` 

const OptionsModalDivCloseButton = styled.div`
    margin:0.5rem;
    position:absolute;
    right:5px;
    top:5px;
` 

const OptionsModalDiv = styled.div`
    position:fixed;
    top:50%;
    left:50%;
    width:50%;
    margin-left:-25%;
    height:fit-content;
    margin-top:-100px;
    /*background-color:#fff;*/
    background-color: rgb(33, 37, 41);
    border: 1px solid #aaa;
    color: #eaeaea;
    z-index:1001;
    border-radius:0.25rem;
` 

const NoWrap = styled.div`
    white-space:nowrap;
`

function LandingPageImage(props) {

    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);
    const csrfToken = useSelector((state) => state.csrfToken.value);
    const currentLandingPage = useSelector((state) => state.currentLandingPage.value);
    const currentLandingPageTemplate = useSelector((state) => state.currentLandingPageTemplate.value);

    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [imageOptionsModalState, setImageOptionsModalState] = useState(ImageOptionsModalStates.CLOSED);
    const [showOptions, setShowOptions] = useState(false);
    const [imageTypeId, setImageTypeId] = useState(null);

    const [imageType, setImageType] = useState(getImageType());

    function getImageType() {
        let returnValue = {};
        if(props.image.landing_page_template_image !== undefined && props.image.landing_page_template_image !== null) {
            if(props.image.landing_page_template_image.icon !== null) {
                returnValue.icon = require('../../assets/images/' + props.image.landing_page_template_image.icon).default;
            }
            returnValue.text = props.image.landing_page_template_image.name;
        }
        return returnValue;
    } 

    function onMouseOver(event) {
        setShowOptions(true);
    }

    function onMouseLeave(event) {
        setShowOptions(false);
    }

    function onDeleteImageButtonClick(event) {
        setImageOptionsModalState(ImageOptionsModalStates.CONFIRM_DELETION);
    }

    function onSetAppIconButtonClick(event) {
        let formData = [
            {
                name: 'csrfToken',
                value: csrfToken
            },
            {
                name: 'imageId',
                value: props.image.id
            },
            {
                name: 'landingPageId',
                value: currentLandingPage.id
            }
        ];
        Data.transfer(window.baseUrl + '/builder/images/setAppIcon','POST',formData,null,null,csrfToken)
        .then((data) => {
            if(data.success && data.landingPage) {
                updateLandingPage(data.landingPage);
                setMessage(Language[language].images.appIconSetSuccessfully ,MessageTypes.MESSAGE_TYPE_SUCCESS);
                closeModal();
            }
            else {
                setMessage(Language[language].error.general ,MessageTypes.MESSAGE_TYPE_ERROR);
                closeModal();
            }
        })
        .catch((error) => {
            setError(error);
        });
    }

    function onEditUsageTypeButtonClick(event) {
        setImageOptionsModalState(ImageOptionsModalStates.SET_IMAGE_TYPE);
    }

    function updateLandingPage(landingPage) {
        dispatch(setCurrentLandingPage(landingPage));
    }

    function closeModal() {
        setImageOptionsModalState(ImageOptionsModalStates.CLOSED);
    }

    function setMessage(message, messageType) {
        dispatch(MessageSlice.setMessage({
            message: message,
            messageType: messageType
        }));
    }

    function onDeleteImageConfirmButtonClick(event) {
        let formData = [
            {
                name: 'csrfToken',
                value: csrfToken
            },
            {
                name: 'imageId',
                value: props.image.id
            },
            {
                name: 'landingPageId',
                value: currentLandingPage.id
            }
        ];
        Data.transfer(window.baseUrl + '/builder/images/delete','POST',formData,null,null,csrfToken)
        .then((data) => {
            if(data.success && data.landingPage) {
                updateLandingPage(data.landingPage);
                setMessage(Language[language].images.imageDeletedSuccessfully, MessageTypes.MESSAGE_TYPE_SUCCESS);
                setImageOptionsModalState(ImageOptionsModalStates.CLOSED);
                if(props.product) {
                    dispatch(removeImageById(props.image.id));
                }
            }
        })
        .catch((error) => {
            setError(error);
            setImageOptionsModalState(ImageOptionsModalStates.CLOSED);
        });
    }

    function setError(error) {
        setSuccessMessage(null);
        setErrorMessage(getErrorMessage(error));
    }
 
    function onDeleteImageDenyButtonClick(event) {
        closeModal();
    }

    function onImageTypeSelectionChanged(event) {
        setImageTypeId(event.target.value);
    }

    function onImageTypeSelectionBlur(event) {
        setImageTypeId(event.target.value);
    }

    function onImageTypeSelectionButtonClick(event) {
        event.preventDefault();
        setErrorMessage(null);
        setSuccessMessage(null);
        if(imageTypeId != null && currentLandingPageTemplate?.landing_page_template_images.find(({ id }) => id === parseInt(imageTypeId)) ) {
            let formData = [
                {
                    name: 'csrfToken',
                    value: csrfToken
                },
                {
                    name: 'imageId',
                    value: props.image.id
                },
                {
                    name: 'imageTypeId',
                    value: imageTypeId
                },
                {
                    name: 'landingPageId',
                    value: currentLandingPage.id
                }
            ];
            Data.transfer(window.baseUrl + '/builder/images/setType','POST',formData,null,null,csrfToken)
            .then((data) => {
                if(data.success && data.landingPage) {
                    updateLandingPage(data.landingPage);
                    setMessage(Language[language].images.imageTypeSetSuccessfully, MessageTypes.MESSAGE_TYPE_SUCCESS);
                    closeModal();
                }
            })
            .catch((error) => {
                setError(error);
            });
        }
        else {
            this.onRequestDataFailure("Error - Please select a file to upload first");
        }
    }

    return (
        <>
            <ImageDiv 
                className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 d-flex align-items-start" 
                onMouseOver={onMouseOver} 
                onMouseLeave={onMouseLeave}
            >
                <div className="card border-light bg-dark">
                    <Image 
                        src={`/builder/images/show/${props.image.identifier_token}`} 
                        className="img-fluid card-img-top" 
                        alt=""
                        maxImageWidth={props.maxImageWidth}
                    />
                    {
                        (imageType?.icon !== undefined || imageType?.text !== undefined) &&
                        <div className="card-body">
                            <h5 className="card-title">
                                {
                                    imageType?.icon !== undefined &&
                                    <div>
                                        <img 
                                            src={imageType.icon} 
                                            alt={props.image.landing_page_template_image.name} 
                                        />
                                        <PaddingLeft>
                                            { imageType.text }
                                        </PaddingLeft>
                                    </div>
                                }
                                {
                                    imageType?.icon === undefined && imageType?.text !== undefined &&
                                    <div>
                                        { imageType?.text }
                                    </div>
                                }
                                {
                                    props.image.is_app_icon === 1 &&
                                    <div>
                                        (
                                            <Icon 
                                                image={appIconSymbol} 
                                                margin={'2px'}
                                                display={'inline-block'}
                                                minSize={'20px'}
                                            />
                                            App Icon
                                        )
                                    </div>
                                }
                            </h5>
                        </div>
                    }
                    {
                        showOptions && 
                        <div className="row">
                            <div className="col">
                                <div className="row d-flex justify-content-center">
                                    <EditOptionsBackground>
                                        <div className="col d-flex justify-content-center">
                                            {
                                                isNullOrUndefined(props.product) &&
                                                <>
                                                    <Icon 
                                                        image={appIconSymbol} 
                                                        title="Set image as app icon"
                                                        hoverBackgroundColor={'darkgray'}
                                                        cursor={'pointer'}
                                                        onClick={onSetAppIconButtonClick}
                                                        />
                                                        <Icon 
                                                        image={editUsageSymbol} 
                                                        title="Set usage type for image"
                                                        hoverBackgroundColor={'darkgray'}
                                                        cursor={'pointer'}
                                                        onClick={onEditUsageTypeButtonClick}
                                                    />
                                                </>
                                            }
                                            <Icon 
                                                image={deleteImageSymbol} 
                                                title="Delete image"
                                                hoverBackgroundColor={'darkgray'}
                                                cursor={'pointer'}
                                                onClick={onDeleteImageButtonClick}
                                            />
                                        </div>
                                    </EditOptionsBackground>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </ImageDiv>
            {
                imageOptionsModalState !== ImageOptionsModalStates.CLOSED &&
                <div>
                    <OptionsModalBackground></OptionsModalBackground>
                    <OptionsModalDiv key={"imageConfirm" + props.image.id}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col text-end">
                                    <br />
                                    <br />
                                    <OptionsModalDivCloseButton 
                                        type="button" 
                                        className="btn-close btn-close-white" 
                                        aria-label="Close" 
                                        onClick={closeModal} 
                                    />
                                </div>
                            </div>
                            {
                                imageOptionsModalState === ImageOptionsModalStates.CONFIRM_DELETION &&
                                <>
                                    <div className="row text-center">
                                        <div className="col">
                                            {
                                                Language[language].images.deleteImageConfirm
                                            }
                                        </div>
                                    </div>
                                    <Margin1Rem className="row">
                                        <div className="col text-start">
                                            <Button
                                                key={"deleteImageConfirmButton" + props.image.id}
                                                buttonText={Language[language].general.yes}
                                                additionalClasses={""}
                                                onClicked={onDeleteImageConfirmButtonClick}
                                            />
                                        </div>
                                        <div className="col text-end">
                                            <Button
                                                key={"deleteImageDenyButton" + props.image.id}
                                                buttonText={Language[language].general.no}
                                                additionalClasses={""}
                                                onClicked={onDeleteImageDenyButtonClick}
                                            />
                                        </div>
                                    </Margin1Rem>
                                </>
                            } 
                            {
                                imageOptionsModalState === ImageOptionsModalStates.SET_IMAGE_TYPE &&
                                <>
                                    <div className="row text-center">
                                        <div className="col">
                                            {
                                                Language[language].images.setImageType
                                            }
                                        </div>
                                    </div>
                                    <Margin1Rem className="row text-center">
                                        <div className="col">
                                            <Selectbox
                                                key={"setImageType" + props.image.id}
                                                name={"setImageType" + props.image.id}
                                                label={Language[language].images.backgroundImageType}
                                                value={props.image.landing_page_template_image_id === null ? 0 : props.image.landing_page_template_image_id}
                                                valueField={"id"}
                                                displayField={"name"}
                                                options={_.orderBy(currentLandingPageTemplate?.landing_page_template_images.filter(imageType => imageType.id !== IMAGE_TYPE_PAGE_CONTENT),['name'])}
                                                onChange={onImageTypeSelectionChanged}
                                                onBlur={onImageTypeSelectionBlur}
                                            />
                                        </div>
                                    </Margin1Rem>
                                    <Margin1Rem className="row text-center">
                                        <div className="col">
                                            <Button
                                                key={"submitImageType" + props.image.id}
                                                buttonText={Language[language].general.save}
                                                additionalClasses={""}
                                                onClicked={onImageTypeSelectionButtonClick}
                                            />
                                        </div>
                                    </Margin1Rem>
                                </>
                            }
                        </div>
                    </OptionsModalDiv>
                </div>
            }
        </>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"landingPageImage.component.js"}
            fallbackUI={<FallbackUI language={language} component={"landingPageImage"} />}
        >
            <LandingPageImage
                key={"landingPageImageComponent"}
                {...props}
            />
       </ErrorBoundary>
   )
}
 
export default ComponentWithErrorBoundary;