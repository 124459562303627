import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ErrorBoundary from '../error/error.component';
import FallbackUI from '../error/standardFallback.component';
import Text from './columnTypes/appText.component';
import Image from './appImage.component';
import { Icon } from '../styled/icon.component';
import { EditOptionsBackground } from '../styled/editOptionsBackground.component';
import RowDelete from '../managementInterface/rowDelete.component';
import RowAdd from '../managementInterface/rowAdd.component';
import * as Data from '../../backend/data';
import * as MessageTypes from '../../constants/messagetypes';
import LoadingSpinner from '../../components/loadingSpinner.component';
import Language from '../../constants/language';
import PageContentImage from './pageContentImage.component';
import { IMAGE_TYPE_PAGE_CONTENT } from '../../constants/imageTypes';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentLandingPage } from '../../reducers/slices/currentLandingPage.slice';
import { setMessage } from '../../reducers/slices/message.slice';

import moveLeftIcon from '../../assets/images/arrow-left-square.svg';
import moveRightIcon from '../../assets/images/arrow-right-square.svg';
import addIcon from '../../assets/images/plus-square.svg';
import removeIcon from '../../assets/images/trash.svg';
import moveUpIcon from '../../assets/images/arrow-up-square.svg';
import moveDownIcon from '../../assets/images/arrow-down-square.svg';
import editIcon from '../../assets/images/edit.svg';
import closeIcon from '../../assets/images/x-circle.svg';

const ColumnDiv = styled.div`
`

const OptionsParentRow = styled.div`
    display:none;

    ${ColumnDiv}:hover & {
        display:block;
    }
`

function Column(props) {

    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);
    const csrfToken = useSelector((state) => state.csrfToken.value);

    const [isLoading, setIsLoading] = useState(false);
    const [isImageEditHoverVisible, setIsImageEditHoverVisible] = useState(false);
    const [isAddNewRowOptionActive, setIsAddNewRowOptionActive] = useState(false);
    const [isDeleteRowConfirmActive, setIsDeleteRowConfirmActive] = useState(false);
    const [isShowRowColumnOptionsActive, setIsShowRowColumnOptionsActive] = useState(false);

    function getColumnTypeComponent(columnType) {
        switch(columnType) {
            case 'text':
                return <Text 
                            key={`apisEditHoverVisiblepText${props.column.id}`}
                            appText={props.column.app_text}
                            language={language}
                            isEditMode={props.isEditModeActive}
                        />
                break;
            case 'image':
                let pageContentImage = <PageContentImage isEditModeActive={props.isEditModeActive} appImage={props.landingPage?.app_images?.find((image) => image.id === props.column.app_image_id)} />
                return <div
                            onMouseEnter={(e) => {
                                setIsImageEditHoverVisible(true);
                            }}
                            onMouseLeave={(e) => {
                                setIsImageEditHoverVisible(false);
                            }}
                        >
                            <Image
                                imageComponent={pageContentImage}
                                images={[props.landingPage?.app_images?.find((image) => image.id === props.column.app_image_id)]}
                                imageName={Language[language].images.image}
                                isEditMode={props.isEditModeActive}
                                isHoverVisible={isImageEditHoverVisible}
                                language={language}
                                hoverDivPosition={'absolute'}
                                hoverDivRight={'calc(50% - 100px)'}
                                hoverDivTop={'-40px'}
                                landingPageImageTemplateIds={[IMAGE_TYPE_PAGE_CONTENT]}
                                columnId={props.column.id}
                            >
                            </Image>
                        </div>
                    break;
        }
    }

    function moveColumn(direction) {
        setIsLoading(true);
        let newColumnNo = props.column?.column_no;
        if(direction === 'left') {
            newColumnNo--;
        }
        else if(direction === 'right') {
            newColumnNo++;
        }
        let formData = [
            {
                name: 'csrfToken',
                value: csrfToken
            },
            {
                name: 'landingPageId',
                value: props.landingPage.id
            },
            {
                name: 'rowId',
                value: props.row?.id
            },
            {
                name: 'columnId',
                value: props.column?.id
            },
            {
                name: 'newColumnNo',
                value: newColumnNo
            }
        ];
        Data.transfer(window.baseUrl + '/builder/user/landingPage/columns/move','POST',formData,null,null,csrfToken)
        .then((data) => {
            if(data.success && data.landingPage) {
                dispatch(setCurrentLandingPage(data.landingPage));
            }
        })
        .catch((error) => {
            dispatch(setMessage({
                message: error.errors?.join(' '),
                messageType: MessageTypes.MESSAGE_TYPE_ERROR
            }));
            setIsLoading(false);
        });
    }

    function moveRow(direction) {
        setIsLoading(true);
        let newRowNo = props.row.row_no;
        if(direction === 'up') {
            newRowNo--;
        }
        else if(direction === 'down') {
            newRowNo++;
        }
        let formData = [
            {
                name: 'csrfToken',
                value: csrfToken
            },
            {
                name: 'landingPageId',
                value: props.landingPage.id
            },
            {
                name: 'rowId',
                value: props.row?.id
            },
            {
                name: 'newRowNo',
                value: newRowNo
            }
        ];
        Data.transfer(window.baseUrl + '/builder/user/landingPage/rows/move','POST',formData,null,null,csrfToken)
        .then((data) => {
            if(data.success && data.landingPage) {
                dispatch(setCurrentLandingPage(data.landingPage));
                props.onRowAdded();
            }
        })
        .catch((error) => {
            dispatch(setMessage({
                message: error.errors?.join(' '),
                messageType: MessageTypes.MESSAGE_TYPE_ERROR
            }));
            setIsLoading(false);
        });
    }

    return (
            <ColumnDiv className="col-12 col-sm p-0">
                {
                    props.isEditModeActive &&
                    <>
                        {
                            isLoading &&
                            <div className="row">
                                <div className="col text-center">
                                    <LoadingSpinner 
                                        key={`loadingSpinnerRow${props.row?.id}`} 
                                        color={'#000'}
                                    />
                                </div>
                            </div>
                        }
                        {
                            !isLoading &&
                            <>
                                {
                                    !isShowRowColumnOptionsActive &&
                                    <OptionsParentRow className="row">
                                        <div className="col-12">
                                            <div className="row d-flex justify-content-center">
                                                <EditOptionsBackground width={"fit-content"}>
                                                    <div className="col d-flex justify-content-center">
                                                        <Icon 
                                                            image={editIcon} 
                                                            title="Show row & column options"
                                                            hoverBackgroundColor={'darkgray'}
                                                            cursor={'pointer'}
                                                            onClick={() => {
                                                                setIsShowRowColumnOptionsActive(!isShowRowColumnOptionsActive);
                                                            }}
                                                        />
                                                    </div>
                                                </EditOptionsBackground>
                                            </div>
                                        </div>
                                    </OptionsParentRow>
                                }
                                {
                                    isShowRowColumnOptionsActive &&
                                    <OptionsParentRow className="row d-flex justify-content-center align-items-center">
                                        <div className="col-12">
                                            <EditOptionsBackground>
                                                <div className="row">
                                                    <div className="col d-flex justify-content-end">
                                                        <Icon 
                                                            image={closeIcon} 
                                                            title="Close"
                                                            hoverBackgroundColor={'darkgray'}
                                                            cursor={'pointer'}
                                                            onClick={() => {
                                                                setIsShowRowColumnOptionsActive(!isShowRowColumnOptionsActive);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="row d-flex justify-content-center">
                                                            <div>
                                                                <div className="col d-flex justify-content-center">
                                                                    {
                                                                        `${Language[language].editor.rowOptions}:`
                                                                    }
                                                                </div>
                                                                <div className="col d-flex justify-content-center">
                                                                    <Icon 
                                                                        image={addIcon} 
                                                                        title="Add new row"
                                                                        hoverBackgroundColor={'darkgray'}
                                                                        cursor={'pointer'}
                                                                        onClick={() => {
                                                                            setIsAddNewRowOptionActive(!isAddNewRowOptionActive);
                                                                            setIsDeleteRowConfirmActive(false);
                                                                        }}
                                                                    />
                                                                    {
                                                                        props.row.row_no !== 1 &&
                                                                        <Icon 
                                                                            image={removeIcon} 
                                                                            title="Delete row with content"
                                                                            hoverBackgroundColor={'darkgray'}
                                                                            cursor={'pointer'}
                                                                            onClick={() => {
                                                                                setIsDeleteRowConfirmActive(!isDeleteRowConfirmActive);
                                                                                setIsAddNewRowOptionActive(false);
                                                                            }}
                                                                        />
                                                                    }
                                                                    {
                                                                        props.row.row_no > 1 &&
                                                                        <Icon 
                                                                            image={moveUpIcon} 
                                                                            title="Move row up"
                                                                            hoverBackgroundColor={'darkgray'}
                                                                            cursor={'pointer'}
                                                                            onClick={(e) => moveRow('up')}
                                                                        />
                                                                    }
                                                                    {
                                                                        props.row.row_no < props.landingPage.rows.length &&
                                                                        <Icon 
                                                                            image={moveDownIcon} 
                                                                            title="Move row down"
                                                                            hoverBackgroundColor={'darkgray'}
                                                                            cursor={'pointer'}
                                                                            onClick={(e) => moveRow('down')}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            isAddNewRowOptionActive &&
                                                            <RowAdd
                                                                key={`rowAdd${props.row?.id}`}
                                                                row={props.row}
                                                                onRowAdded={(e) => setIsAddNewRowOptionActive(false)}
                                                            />
                                                        }
                                                        {
                                                            isDeleteRowConfirmActive &&
                                                            <RowDelete
                                                                key={`rowDelete${props.row?.id}`}
                                                                row={props.row}
                                                                onRowDeleteCancel={(e) => setIsDeleteRowConfirmActive(false)}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    props.row.columns.length > 1 &&
                                                    <div className="row">
                                                        <div>
                                                            <div className="row d-flex justify-content-center">
                                                                <div>
                                                                    <div className="col d-flex justify-content-center">
                                                                        {
                                                                            `${Language[language].editor.columnOptions}:`
                                                                        }
                                                                    </div>
                                                                    <div className="col d-flex justify-content-center">
                                                                        {
                                                                            props.column?.column_no > 1 && props.row?.columns.length > 1 &&
                                                                            <Icon 
                                                                                image={moveLeftIcon} 
                                                                                title="Move column left"
                                                                                hoverBackgroundColor={'darkgray'}
                                                                                cursor={'pointer'}
                                                                                onClick={(e) => moveColumn('left')}
                                                                            />
                                                                        }
                                                                        {
                                                                            props.column?.column_no < props.row?.columns.length && props.row?.columns.length > 1 &&
                                                                            <Icon 
                                                                                image={moveRightIcon} 
                                                                                title="Move column right"
                                                                                hoverBackgroundColor={'darkgray'}
                                                                                cursor={'pointer'}
                                                                                onClick={(e) => moveColumn('right')}
                                                                            />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </EditOptionsBackground>
                                        </div>
                                    </OptionsParentRow>
                                }
                            </>
                        }
                    </>
                }
                {
                    getColumnTypeComponent(props.column.column_type.type)
                }
            </ColumnDiv>
    )
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"column.component.js"}
            fallbackUI={<FallbackUI language={language} component={"column"} />}
        >
            <Column
                key={`columnComponent${props.column.column_no}`}
                {...props}
            />
       </ErrorBoundary>
   )
}


export default ComponentWithErrorBoundary;