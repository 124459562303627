import { forEach } from 'lodash';
import React, { Component } from 'react';
import styled, { keyframes }  from "styled-components";
import Language from '../../constants/language';
import * as MessageTypes from '../../constants/messagetypes';

const showStatusDiv = keyframes`
    100% {
        opacity: 1;
    }
`;

const hideStatusDiv = keyframes`
    100% {
        opacity: 0;
    }
`;

const StatusDiv = styled.div`
    position: fixed;
    z-index: 10000;
    bottom: 0rem;
    left: 50%;
    height: 2.5rem;
    width: 50%;
    margin-left: -25%;
    background-color: #e9e9e9;
    pointer-events: none;
    
    transition: opacity ${props => (props.message?.length > 0 ? '0.5s' : '3.5s')};
    opacity: ${props => (props.message?.length > 0 ? 1 : 0)};

    @media (max-width: 576px) {
        left: 0rem;
        width:100%;
        margin-left:0rem;
    }
`

const StatusBackgroundColor = styled.div`
    background-color: ${props => (props.message?.length > 0 ? '#dafdd1' : '#fffed3')} !important;
    color: ${props => (props.message?.length > 0 ?  '#29422f' : '#31311d')} !important;
`

class Message extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <StatusDiv message={this.props.message?.message} className="statusDiv">
                <div className="container">
                    <div className="row">
                        <StatusBackgroundColor message={this.props.message?.message} className="col text-center alert alert-light">
                            {
                                this.props.message?.messageType === MessageTypes.MESSAGE_TYPE_SUCCESS &&
                                Language[this.props.language].status.dataSuccessfullyTransmitted
                            }
                            {
                                this.props.message?.messageType === MessageTypes.MESSAGE_TYPE_ERROR &&
                                this.props.message?.message
                            }
                        </StatusBackgroundColor>
                    </div>
                </div>
            </StatusDiv>
	    );
	}
}

export default Message;