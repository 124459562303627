import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Language from '../../constants/language';
import Button from '../button.component';
import ErrorBoundary from '../error/error.component';
import FallbackUI from '../error/standardFallback.component';
import { isNullOrUndefined } from '../../utils/utils';
import EditImageHover from "../managementInterface/editImageHover.component";
import ImageUpload from '../managementInterface/imageUpload.component';
import * as MessageTypes from '../../constants/messagetypes';
import * as Data from '../../backend/data';
import { getErrorMessage } from '../../utils/error';
import _ from 'lodash';
import { getImageSizePictureUrl } from '../../utils/image';
import { IMAGE_TYPE_PAGE_CONTENT } from '../../constants/imageTypes';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentLandingPage } from '../../reducers/slices/currentLandingPage.slice';
import { setCurrentEvent } from '../../reducers/slices/currentEvent.slice';
import { setMessage } from '../../reducers/slices/message.slice';

import saveIcon from '../../assets/images/check2-square.svg';
import cancelIcon from '../../assets/images/x-square.svg';
import imagesIcon from '../../assets/images/images.svg';

const LogoImageHoverDiv = styled.div`
    position:${props => props.hoverParentDivPosition ? props.hoverParentDivPosition : 'relative'};
    cursor:pointer;
`

const OptionsModalBackground = styled.div`
    position: fixed;
    top:0px;
    left:0px;
    width: 100%;
    height: 100%;
    background-color:#6c757d;
    opacity:0.6;
    z-index:1002;
` 

const OptionsModalDivCloseButton = styled.div`
    margin:0.5rem;
    position:absolute;
    right:5px;
    top:5px;
` 

const OptionsModalDiv = styled.div`
    position:absolute;
    top:20%;
    left:50%;
    width:50%;
    margin-left:-25%;
    height:fit-content;
    margin-top:-100px;
    /*background-color:#fff;*/
    background-color: rgb(33, 37, 41);
    border: 1px solid #aaa;
    color: #eaeaea;
    z-index:1003;
    border-radius:0.25rem;
` 

const PreviewImage = styled.div`
    background-repeat:no-repeat;
    background-image:url(${props => !isNullOrUndefined(props.imageUrl) ? props.imageUrl : '' });
    background-size:cover;
    background-position:center;
    width: ${props => !isNullOrUndefined(props.image) && !isNullOrUndefined(props.image?.width_in_percent) ? `${props.image?.width_in_percent}%` : '200px'};
    height: ${props => !isNullOrUndefined(props.image) && !isNullOrUndefined(props.image?.height_in_vh) ? `${props.image?.height_in_vh}vh` : '200px'};
    border-radius: ${props => !isNullOrUndefined(props.image) ? props.image.border_radius : '1' }%;
    opacity: ${props => !isNullOrUndefined(props.image) ? props.image.opacity : 1 };
    background-color: #fff;
    border: 0px;
    font-size: 1rem;
    align-items: center;
    display: flex;
    justify-content: center;
    max-height:350px;
`

function AppImage(props) {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);
    const csrfToken = useSelector((state) => state.csrfToken.value);

    const [isImageEditActive, setIsImageEditActive] = useState(false);
    const [isMouseOverEditArea, setIsMouseOverEditArea] = useState(false);
    const [isHoverActive, setIsHoverActive] = useState(false);
    const [opacityValue, setOpacityValue] = useState(0); 
    const [borderRadiusValue, setBorderRadiusValue] = useState(0);
    const [widthValue, setWidthValue] = useState(0);
    const [heightValue, setHeightValue] = useState(0);
    const [images, setImages] = useState(props.images?.filter(image => image?.id !== undefined));

    function onEditClick(event) {
        setIsImageEditActive(true);
        setIsHoverActive(false);
        setIsMouseOverEditArea(false);
        /*
        window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
        });
        */
    }

    function onEditAreaHover() {
        if(!isMouseOverEditArea) {
            setIsHoverActive(true);
            setIsMouseOverEditArea(true);
            if(props.onEditAreaHover) {
                props.onEditAreaHover();
            }
        }
    }

    function closeModal() {
        setIsImageEditActive(false);
    }

    function onOpacityChange(event) {
        setOpacityValue(event.target.value);
        let updatedImages = _.cloneDeep(images);
        updatedImages?.forEach((image) => {
            image.opacity = event.target.value;
        });
        setImages(updatedImages);
    }

    function onSaveButtonClicked() {
        if((opacityValue >= 0 && opacityValue <= 1) && (borderRadiusValue >= 0 && borderRadiusValue <= 50)) {
            const formData = [
                {
                    name: 'opacity',
                    value: opacityValue
                },
                {
                    name: 'borderRadius',
                    value: borderRadiusValue
                },
                {
                    name: 'imageIds',
                    value: images?.map((image) => image.id)
                }
            ];
            if(props.landingPageImageTemplateIds.indexOf(IMAGE_TYPE_PAGE_CONTENT) > -1) {
                formData.push(
                    {
                        name: 'width',
                        value: widthValue
                    },
                    {
                        name: 'height',
                        value: heightValue
                    }
                );
            }
            Data.transfer('/builder/images/setValues','POST',formData,undefined,undefined,csrfToken)
            .then((data) => {
                if(data.success) { 
                    dispatch(setMessage({
                        message: Language[language].editor.savedSettingsSuccessfully,
                        messageType: MessageTypes.MESSAGE_TYPE_SUCCESS
                    }));
                    if(data.landingPage) {
                        dispatch(setCurrentLandingPage(data.landingPage));
                    }
                    if(data.event) {
                        dispatch(setCurrentEvent(data.event));
                    }
                }
                closeModal();
            })
            .catch((error) => {
                dispatch(setMessage({
                    message: getErrorMessage(error),
                    messageType: MessageTypes.MESSAGE_TYPE_ERROR
                }));
            });
        }
    }

    function onBorderRadiusChange(event) {
        setBorderRadiusValue(event.target.value);
        let updatedImages = _.cloneDeep(images);
        updatedImages?.forEach((image) => {
            image.border_radius = event.target.value;
        });
        setImages(updatedImages);
    }

    function onWidthChange(event) {
        setWidthValue(event.target.value);
        let updatedImages = _.cloneDeep(images);
        updatedImages?.forEach((image) => {
            image.width_in_percent = event.target.value;
        });
        setImages(updatedImages);
    }

    function onHeightChange(event) {
        setHeightValue(event.target.value);
        let updatedImages = _.cloneDeep(images);
        updatedImages?.forEach((image) => {
            image.height_in_vh = event.target.value;
        });
        setImages(updatedImages);
    }

    useEffect(() => {
        if(isHoverActive !== props.isHoverVisible && !isMouseOverEditArea) {
            setIsHoverActive(props.isHoverVisible);
        }
    },[props.isHoverVisible]);
    
    useEffect(() => {
        setImages(props.images?.filter(image => image?.id !== undefined));
    },[props.images]);

    useEffect(() => {
        if(images?.length > 0) {
            setOpacityValue(images[0].opacity);
            setBorderRadiusValue(images[0].border_radius);
            setWidthValue(isNullOrUndefined(images[0].width_in_percent) ? 100 : images[0].width_in_percent);
            setHeightValue(isNullOrUndefined(images[0].height_in_vh) ? 100 : images[0].height_in_vh);
        }
    },[images]);

    return (
        <>
            {
                props.imageComponent
            }
            {
                props.isEditMode && isHoverActive &&
                <LogoImageHoverDiv
                    hoverParentDivPosition={props.hoverParentDivPosition}
                    onMouseEnter={onEditAreaHover}
                    onMouseLeave={() => { if(isMouseOverEditArea) { setIsMouseOverEditArea(false); } }}
                    onClick={onEditClick}
                >
                    <EditImageHover 
                        isVisible={isHoverActive}
                        language={language}
                        imageName={props.imageName}
                        hoverDivPosition={props.hoverDivPosition}
                        hoverDivRight={props.hoverDivRight}
                        hoverDivTop={props.hoverDivTop}
                    />
                </LogoImageHoverDiv>
            }
            {
                props.isEditMode && isImageEditActive &&
                <div>
                    <OptionsModalBackground></OptionsModalBackground>
                    <OptionsModalDiv>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col text-end">
                                    <br />
                                    <br />
                                    <OptionsModalDivCloseButton 
                                        type="button" 
                                        className="btn-close btn-close-white" 
                                        aria-label="Close" 
                                        onClick={closeModal}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    {Language[language].editor.preview}
                                </div>
                            </div>
                            <div className="row">
                                {
                                    images.length > 0 &&
                                    images?.map((image) => {
                                        return (
                                            <div className="col d-flex justify-content-center" key={`previewImage${image?.id}`}>
                                                <PreviewImage
                                                    imageUrl={getImageSizePictureUrl(image,640)}
                                                    image={image}
                                                >
                                                </PreviewImage>
                                            </div>  
                                        )
                                    })
                                }
                                {
                                    props.images.length > 0 && props.images[0] === undefined &&
                                    <div className="col text-center">
                                        {Language[language].images.noImageUploadedYet}
                                    </div>
                                }
                            </div>
                            <div className="row mt-1 mb-1">
                                <div className="col mb-2">
                                    <div className="accordion accordion-flush" id={`options${props.imageName.replace(" ", "")}`}>
                                        <div className="accordion-item bg-dark text-white">
                                            <h2 className="accordion-header" id={`heading${props.imageName.replace(" ", "")}`}>
                                                <Button
                                                    key={`buttonUpload${props.imageName}`}
                                                    buttonText={Language[language].images.backgroundImageUpload}
                                                    icon={imagesIcon} 
                                                    iconSize={"20px"}
                                                    iconColor={"#fff"}
                                                    additionalClasses={"accordion-button" + ((images.length > 0) ? " collapsed" : "")}
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#collapse${props.imageName.replace(" ", "")}`}
                                                    aria-expanded={((images.length > 0) ? "true" : "false")}
                                                    aria-controls={`collapse${props.imageName.replace(" ", "")}`}
                                                    additionalStyles={{backgroundColor:'inherit',color:'inherit'}}
                                                />
                                            </h2>
                                            <div 
                                                id={`collapse${props.imageName.replace(" ", "")}`} 
                                                className={"accordion-collapse collapse" + ((images.length > 0) ? "" : " show")} 
                                                aria-labelledby={`heading${props.imageName.replace(" ", "")}`} 
                                                data-bs-parent={`#options${props.imageName.replace(" ", "")}`}
                                            >
                                                <div className="accordion-body">
                                                    <ImageUpload 
                                                        key={"imageUpload"}
                                                        landingPageImageTemplateIds={props.landingPageImageTemplateIds}
                                                        columnId={isNullOrUndefined(props.columnId) ? undefined : props.columnId}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                images.length > 0 &&
                                <>
                                    <div className="row mt-2">
                                        <div className="col">
                                            <label htmlFor={"opacityRange" + props.imageName} className="form-label">
                                                {
                                                    `${Language[language].images.opacity} ${Math.round(opacityValue * 100)}%`
                                                }
                                            </label>
                                            <input 
                                                type="range" 
                                                className="form-range" 
                                                min="0" 
                                                max="1" 
                                                step="0.01" 
                                                value={opacityValue} 
                                                id={`opacityRange${props.imageName}`} 
                                                onChange={onOpacityChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col">
                                            <label htmlFor={"borderRadiusRange" + props.imageName} className="form-label">
                                                {
                                                    `${Language[language].images.borderRadius} ${Math.round(borderRadiusValue)}%`
                                                }
                                            </label>
                                            <input 
                                                type="range" 
                                                className="form-range" 
                                                min="0" 
                                                max="50" 
                                                step="1" 
                                                value={borderRadiusValue} 
                                                id={`borderRadiusRange${props.imageName}`} 
                                                onChange={onBorderRadiusChange} 
                                            />
                                        </div>
                                    </div>
                                    {
                                        props.landingPageImageTemplateIds.indexOf(IMAGE_TYPE_PAGE_CONTENT) > -1 &&
                                        <>
                                            <div className="row mt-2">
                                                <div className="col">
                                                    <label htmlFor={`widthRange${props.imageName}`} className="form-label">
                                                        {
                                                            `${Language[language].images.width} ${Math.round(widthValue)}%`
                                                        }
                                                    </label>
                                                    <input 
                                                        type="range" 
                                                        className="form-range" 
                                                        min="1" 
                                                        max="100" 
                                                        step="1" 
                                                        value={widthValue} 
                                                        id={`widthRange${props.imageName}`} 
                                                        onChange={onWidthChange} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col">
                                                    <label htmlFor={`heightRange${props.imageName}`} className="form-label">
                                                        {
                                                            `${Language[language].images.height} ${Math.round(heightValue)}%`
                                                        }
                                                    </label>
                                                    <input 
                                                        type="range" 
                                                        className="form-range" 
                                                        min="1" 
                                                        max="100" 
                                                        step="1" 
                                                        value={heightValue} 
                                                        id={`heightRange${props.imageName}`} 
                                                        onChange={onHeightChange} 
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className="row mt-2 mb-2">
                                        <div className="col">
                                            <Button
                                                key={`saveButton${props.imageName}`}
                                                buttonText={Language[language].general.save}
                                                additionalClasses={""}
                                                onClicked={onSaveButtonClicked}
                                                icon={saveIcon} 
                                                iconSize={"20px"}
                                                iconColor={"#000"}
                                            />
                                        </div>
                                        <div className="col">
                                            <Button
                                                key={`cancelButton${props.imageName}`}
                                                buttonText={Language[language].general.cancel}
                                                additionalClasses={""}
                                                onClicked={closeModal}
                                                icon={cancelIcon} 
                                                iconSize={"20px"}
                                                iconColor={"#000"}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </OptionsModalDiv>
                </div>
            }
        </>
    )
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={props.isEditMode ? "builder" : "app"}
            component={"appImage.component.js"}
            fallbackUI={<FallbackUI language={language} component={"appImage"} />}
        >
            <AppImage
                key={"appImageComponent"}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;
