import React, { Component } from "react";
import styled from 'styled-components';
import Language from '../../../../../constants/language';
import CalendarDay from '../../../../../components/app/calendarDay.component';
import Comment from '../../../../../components/app/comment.component';
import * as GuestTypes from '../../../../../constants/guesttypes';
import * as Data from '../../../../../backend/data';
import Participation from "../../../../../components/app/participation.component";
import EditText from '../../../../../components/managementInterface/editText.component';
import EditDate from '../../../../../components/managementInterface/editDate.component';
import { connect } from 'react-redux';
import { updateNavigation, updateLanguage, updateToken } from '../../../../../actions/index';
import Footer from '../../../../../components/footer.component';

const flowerImage = require('../../../../../assets/images/background_hochzeit2.png').default;

const StartImage = styled.div`
    display:flex;
    background-repeat:no-repeat;
    width:100vw;
    height:100vh;

    background-image:url(${props => (props.images !== undefined && props.images !== null && props.images.length > 0 && props.images.filter((image) => image.landing_page_template_image_id === 1 ).length > 0) ? "/builder/images/show/" + props.images.filter((image) => image.landing_page_template_image_id === 1)[0].identifier_token : ''});
    background-position:top center;
    background-size:cover;

    @media (max-width: 768px) {
        background-image:url(${props => (props.images !== undefined && props.images !== null && props.images.length > 0 && props.images.filter((image) => image.landing_page_template_image_id === 2 ).length > 0) ? "/builder/images/show/" + props.images.filter((image) => image.landing_page_template_image_id === 2)[0].identifier_token : ''});
        background-size:cover;
    }
`

const Background = styled.div`
    /*
    padding-top:2rem;
    padding-bottom:2rem;
    */
    background-repeat:repeat;
    background-image:url(${props => (props.images !== undefined && props.images !== null && props.images.length > 0 && props.images.filter((image) => image.landing_page_template_image_id === 3 ).length > 0) ? "/builder/images/show/" + props.images.filter((image) => image.landing_page_template_image_id === 3)[0].identifier_token : ''});
    background-size:cover;
    background-position:center;
    width:100%;
    height:100%;
    position:fixed;
    opacity:0.3;
    z-index:-2;
` 

const BackgroundFlower = styled.div`
    /*
    padding-top:2rem;
    padding-bottom:2rem;
    */
    background-repeat:repeat;
    background-image:url(${flowerImage});
    background-size:cover;
    background-position:center;
    width:100%;
    height:100%;
    position:fixed;
    z-index:-1;
` 

const HeaderText = styled.div`
    font-family: 'Parisienne', cursive;
    font-size:4rem;
    color:#021030;
    background-color:transparent;
    /*
    position:absolute;
    top:5%;
    left:20%; 
    */
` 

const Header = styled.div`
    position: absolute;
    top: 5%;
` 

const SubHeader = styled.div`

    font-family: 'Parisienne', cursive;
    font-size:2.5rem;
    color:#021030;
`


const Text = styled.div`

    font-family: 'Parisienne', cursive;
    font-size:2rem;
    color:#021030;
`

const Link = styled.a`
    color:#021030;

    &:hover {
        text-decoration:none;    
        color: lavenderblush;
    }
`

const Separator = styled.hr`
    width: 100px;
    border-color: #021030;
    margin-top:3rem;
    margin-bottom:3rem;
`

const MarginTop2rem = styled.div`
    margin-top:2rem;
`

class StandardTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        let provideFeedback = (this.props.guestLandingPage?.guest_type_id === GuestTypes.GUEST_TYPE_SINGLE_PERSON) ? Language[this.props.language].message.provideFeedbackSinglePerson : Language[this.props.language].message.provideFeedbackFamily;
        let additionalButtonStyles = {backgroundColor:'#021030',color:'#fde5e5',height:'auto',fontFamily: 'Parisienne,cursiv',fontSize: '1.7rem'};
        let buttonIconColor = '#021030';
        return (
            <>
                <Background key={"backgroundImageKey"} images={this.props.currentLandingPage?.app_images} />
                <StartImage key={"startImageKey"} images={this.props.currentLandingPage?.app_images} />                
                <Header className="container-fluid">
                    <div className="row">
                        <MarginTop2rem className="col text-center">
                            <HeaderText key={"headerKey"}>
                                <EditText
                                    key={"eventNameEditTextField"}
                                    textType={"eventName"}
                                />
                            </HeaderText>
                        </MarginTop2rem>
                    </div>
                </Header>
                <div className="container-fluid">
                    <div className="row">
                        <MarginTop2rem className="col text-center">
                            <SubHeader key={"subHeaderKey1"}>
                                <EditText
                                    key={"landingPageNameEditTextField"}
                                    textType={"landingPageName"}
                                />
                            </SubHeader>
                        </MarginTop2rem>
                        <div className="col-12 text-center">
                            <SubHeader key={"subHeaderKey2"}>
                                {
                                    <EditDate
                                        key={"eventDateEdit"}
                                        showAsCalendarIcon={false}
                                    />
                                }
                            </SubHeader>
                        </div>
                        <MarginTop2rem className="col-12 d-flex justify-content-center">
                            {
                                <EditDate
                                    key={"eventDateEditIcon"}
                                    showAsCalendarIcon={true}
                                />
                            }
                        </MarginTop2rem>
                        <div className="col-12 text-center">
                            <Link href="#" download>
                                { Language[this.props.language].app.saveInCalendar }
                            </Link>
                        </div>
                    </div>
                    <MarginTop2rem className="row">
                        <div className="col-12 text-center">
                            <Text key={"subHeaderKey3"}>
                                <div className="container">
                                    <EditText
                                        key={"mainTextEditTextField"}
                                        textType={"mainText"}
                                    />
                                </div>
                            </Text>
                        </div>
                    </MarginTop2rem>
                    <MarginTop2rem className="row">
                        {
                            this.props.guestLandingPage != null &&
                            <Participation 
                                key="participate"
                                guestLandingPage={this.props.guestLandingPage}
                                language={this.props.language}
                                additionalButtonStyles={additionalButtonStyles} 
                        />
                        }
                    </MarginTop2rem>
                    <MarginTop2rem className="row">
                        <div className="col-12 text-center">
                            { provideFeedback }
                        </div>
                    </MarginTop2rem>
                    <MarginTop2rem className="row">
                        <div className="col-12 text-center">
                            <div className="container">
                            {
                                this.props.guestLandingPage != null &&
                                <Comment 
                                    key={"leaveComment"}
                                    guestLandingPage={this.props.guestLandingPage}
                                    language={this.props.language}
                                    additionalButtonStyles={additionalButtonStyles}
                                />
                            }
                            </div>
                        </div>
                    </MarginTop2rem>
                    <div className="row">
                        <div className="col">
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
                <Footer
                    key={"landingPageFooterComponent"}
                    {...this.props}
                />
            </>
        );
    }
}

function mapStateToProps({ user, navigation, language, isSignedIn, csrfToken, token, currentEvent, currentLandingPage, currentLandingPageTemplate }) {
    return {
        user,
        navigation,
        language,
        isSignedIn,
        csrfToken,
        token,
        currentEvent, 
        currentLandingPage, 
        currentLandingPageTemplate
    };
}

function mapDispatchToProps(dispatch) {
    return {

        updateNavigation: navigation => dispatch(updateNavigation(navigation)),
        updateLanguage: language => dispatch(updateLanguage(language)),
        updateToken: token => dispatch(updateToken(token)),
    };
}
 
const Form = connect(
    mapStateToProps,
    mapDispatchToProps
)(StandardTemplate);
 
export default Form;