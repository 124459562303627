import React, { Component } from "react";
import styled  from 'styled-components';


const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right:5px;
`

const Icon = styled.svg`
  fill: none;
  stroke: #656b6f;
  stroke-width: 2px;
  position:relative;
  top:-3px;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  display: inline-block;
  width: ${props => (props.widthHeight + 'px;')}
  height: ${props => (props.widthHeight + 'px;') }
  background: white; 
  transition: all 150ms;
  border: 1px solid #ced4da;
  font-weight:bold;
  border-radius:5px;

  ${HiddenCheckbox}:focus + & {
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')}
  }
`

const SpanMarginLeft5px = styled.span`
  margin-left:5px;
`
const LineTrough = styled.span`
  text-decoration: line-through;
`

const Label = styled.label`
  margin-bottom:0px;
`


class Checkbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: this.props.checked
    };
    this.change = this.change.bind(this);
    this.doNothing = this.doNothing.bind(this);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.checked !== this.props.checked) {
      this.setState({
        checked: this.props.checked
      });
    }
  }

  change(event) {
    event.preventDefault();
    this.setState({ 
        checked: !this.state.checked 
    }, () => {
      this.props.onChange((this.state.checked ? true : false),event);
    });
  }

  doNothing() {
  }

  setValue(val) {
    this.setState({ checked: val });
  }

  render() {
    return (
      <div className={"h-100 " + this.props.additionalClasses}>
        <Label tabIndex={this.props.tabindex} className="checkbox">
          <CheckboxContainer>
            <HiddenCheckbox 
              onChange={this.doNothing} 
              checked={this.state.checked} 
              {...this.props} 
            />
            <StyledCheckbox 
              onClick={this.change} 
              checked={this.state.checked} 
              widthHeight={(this.props.widthHeight) ? this.props.widthHeight : 25}
              data-name={this.props.name}
            >
              <Icon viewBox="0 0 24 24">
                <polyline points="20 6 9 17 4 12" />
              </Icon>
            </StyledCheckbox>
            <SpanMarginLeft5px 
              onClick={this.change}
              data-name={this.props.name}
            >
              {
                this.props.strikeTroughLabelOnChecked && (this.state.checked === 1 || this.state.checked === true) &&
                <LineTrough>
                  {this.props.label}
                </LineTrough>
              }
              {
                (!this.props.strikeTroughLabelOnChecked || this.state.checked === 0 || this.state.checked === false) &&
                this.props.label
              }
            </SpanMarginLeft5px>
          </CheckboxContainer>
        </Label>
      </div>
    );
  }
}

export default Checkbox;
