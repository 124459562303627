export const Language = {
    en: {
        language: {
            'name': 'English',
            'flag':'flag-icon-gb',
        },
        general: {
            ok: "Ok",
            save: "Save",
            submit: "Submit",
            cancel: "Cancel",
            upload: "Upload",
            selectFile: "Select file",
            pleaseSelect: "Please select",  
            showMore: "More...",
            optional: "Optional",
            yes: "Yes",
            no: "No",
            download: 'Download',
            invitation: 'Invitation',
            asPdf: 'as PDF file',
            phone: 'Phone',
            email: 'Email',
            more: 'More',
            less: 'Less',
            options: 'Options',
            back: 'Back',
            next: 'Next',
            in: 'in',
            of: 'of',
        },
        error: {
            general: "Something went wrong. Please try again or contact the support.",
            anErrorOccured: "We're sorry. Unfortunately, an error has occurred in one component: ",
            ifErrorPersists: "If this error occurs frequently, please contact our support for further assistance.",
            components: {
                app: 'App',
                navigation: 'Navigation',
                start: 'Start',
                signIn: 'Sign In',
                register: 'Register',
                setNewPassword: 'Set New Password',
                privacy: 'Privacy',
                notFound: 'Not found',
                imprint: 'Imprint',
                forgotPassword: 'Forgot Password',
                dashboard: 'Dashboard',
                account: 'Account',
                landingPageView: 'Landing Page View',
                invitation: 'Invitation',
                catering: 'Catering',
                contact: 'Contact',
                termsAndConditions: 'Terms & Conditions',
                footer: 'Footer',
                cookieAndPrivacyConsent: 'Cookie & Privacy Consent',
                browserIncompatabilityWarning: 'Browser Incompatability Warning',
                appText: 'App Text',
                editText: 'Edit Text',
                imageUpload: 'Image Upload',
                appFeatures: 'App Features',
                contactDetails: 'Contact Details',
                manageSubdomain: 'Manage Subdomain',
                internalLink: 'Internal Link',
                textReplacement: 'Text Replacement',
                sendPushNotificationMessage: 'Send Push Message',
                pagePushNotificationMessagesListContainer: 'Page Push Notification Messages List Container',
                pushNotificationMessagesListItem: 'Push Notification Messages List Item',
            }
        },
        start: {
            startNowForFree: 'Start now for free',
        },
        hints: {
            browserIncompatabilityWarning: 'For optimal viewing and functionality of this app, we recommend Google Chrome, Microsoft Edge or Apple Safari browsers. If something does not work as expected, we would like to ask you to change the browser to one of the mentioned.',
        },
        message: {
            all: 'All',
            title: "Title",
            message: "Message",
            sendNewPushMessage: "Send new push message",
            provideFeedbackSinglePerson: "You want to leave us a message?",
            provideFeedbackFamily: "You want to leave us a message?",
            sentMessages: 'Sent messages',
            sent: 'Sent',
            to: 'To',
            newMessage: 'New message',
            deleteMessageConfirm: 'Please confirm that you want to delete the message irrevocably',
            noMessagesSentYet: 'No messages sent so far',
        },
        participation: {
            participateSinglePerson: "Acceptance / Cancellation",
            participateFamily: "Acceptance / Cancellation",
            commitments: 'Commitments',
            cancellations: 'Cancellations',
            waitingOnResponse: 'No response',
            yes: "Yes",
            no: "No",
            noOfPersons: "No. of persons",
            person: "Person",
            persons: "Persons",
        },
        app: {
            enablePushNotifications: 'Enable notifications',
            install: 'Install App',
            saveInCalendar: 'Save date in my calendar',
            successfullySubscribedToNotificationService: 'You successfully subscribed to our notification service!',
        },
        menu: {
            register: "Create account",
            signIn: "Sign in",
            signOut: "Sign out",
            dashboard: "Dashboard",
            logout: "Logout",
            account: "Account",
            forgotPassword: "Forgot password",
            language: "Language",
            newEntry: "New entry",
        },
        account: {
            email: "Email",
            emailAddress: "Email address",
            password: "Password",
            firstname: "First name",
            surname: "Surname",
            passwordAssignedSuccessfully: 'The password was assigned successfully',
            passwordConfirm: "Repeat Password",
            passwordsDoNotMatch: "The selected passwords do not match! Please check the passwords again.",
            passwordsAreMatching: "The selected passwords match and comply with the password policy!",
            passwordDoesNotComplyToGuidelines: "The password does not comply with the password guidelines (at least 6 characters, upper and lower case and at least 1 number).",
            passwordHasBeenSent: "A link to reset your password was sent to your email address.",
            resetPassword: "Reset password",
            pleaseSetANewPasswordNow: "Please set a new password now",
            accountSuccessfullyUpdated: "The changes have been saved successfully.",
            furtherOptions: "Further options",
            changePassword: "Change password",
        },
        register: {
            accountCreated: "Account confirmed",
            registerForFree: "Create a free account for free here easily",
            checkYourInputs: "Please check your email address!",
            registrationSuccess: "The registration was successful. We have sent an email to the email address provided to verify the email address. Please check your email inbox.",
            emailConfirmed: "Your email has been confirmed. Please set a new password for your account.",
            agreeToPrivacyPolicyAndTermsOfService: "Signing up for a account means you agree to the Privacy Policy and Terms of Service.",
            agreeToTermsAndConditionsPart1: "I have read and agree to the",
            agreeToTermsAndConditionsPart2: "",
            termsAndConditions: "terms and conditions",
            agreeToPrivacyPolicy: "I accept the",
            privacyPolicy: "privacy agreement",
            pleaseAcceptTermsAndConditions: 'Please accept the terms and conditions',
            pleaseAcceptPrivacyPolicy: 'Please accept the privacy agreement',
            pleaseAcceptTermsAndConditionsAndPrivacyPolicy: 'Please accept the terms and conditions & the privacy agreement',
        },
        login: {
            backToEmailAddress: "Back to enter email address",
            pleaseEnterEmailAddress: "Please enter your email address.",
            pleaseEnterYourPassword: "Please enter your password now to complete the login process.",
            stayLoggedIn: "stay signed in",
            forgotPassword: "forgot password?",
            emailIsEmpty: "Please enter your email address for the login process.",
        },
        footer: {
            imprint: "Imprint",
            privacy: "Privacy",
            contact: "Contact",
            termsAndConditions: "Terms & Conditions",
        },
        time: {
            today: 'Today',
            tomorrow: 'Tomorrow',
            oclock: '',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            sunday: 'Sunday',
            week: 'Week',
            months: [
                {
                    name: 'January',
                    shortName: 'JAN',
                    number: 1,
                },
                {
                    name: 'February',
                    shortName: 'FEB',
                    number: 2,
                },
                {
                    name: 'March',
                    shortName: 'MAR',
                    number: 3,
                },
                {
                    name: 'April',
                    shortName: 'APR',
                    number: 4,
                },
                {
                    name: 'May',
                    shortName: 'MAY',
                    number: 5,
                },
                {
                    name: 'June',
                    shortName: 'JUN',
                    number: 6,
                },
                {
                    name: 'July',
                    shortName: 'JUL',
                    number: 7,
                },
                {
                    name: 'August',
                    shortName: 'AUG',
                    number: 8,
                },
                {
                    name: 'September',
                    shortName: 'SEP',
                    number: 9,
                },
                {
                    name: 'October',
                    shortName: 'OCT',
                    number: 10,
                },
                {
                    name: 'November',
                    shortName: 'NOV',
                    number: 11,
                },
                {
                    name: 'December',
                    shortName: 'DEC',
                    number: 12,
                },
            ]
        },
        texts: {
            salutation: {
                dearMale: 'Dear',
                dearFemale: 'Dear',
                dearDiverse: 'Dear',
                dearFamily: 'Dear family',
            },
            events: {
                event: "Event",
                eventName: 'Event name',
                eventDate: 'Event Datum',
                myEvents: 'My Events',
                addEvent: 'Add',
                createEvent: 'Create new event',
                editEvent: 'Modify event',
                landingPage: 'Landing Page',
                guest: 'Guest',
                eventInfos: 'Event Information',
                eventLandingPageInfo: 'You have the option to create multiple landing pages for different guests for one event. ',
                eventCreateLandingPageText: 'Currently you have not created any landing page for this event. Create a new landing page by clicking on the plus icon.',
            }
        },
        status: {
            dataWaitingForSync: 'Data is waiting for sync...',
            dataSuccessfullyTransmitted: 'Data successfully transmitted',
        },
        editor: {
            editText: 'Edit text',
            editImage: 'Edit image',
            editDate: 'Edit date',
            savedSettingsSuccessfully: 'The settings have been saved successfully',
            savedTextSuccessfully: 'The text has been saved successfully',
            savedDateSuccessfully: 'The date has been saved successfully',
            warning: "Warning!",
            noAppIconSet: "No app icon selected yet!",
            needToSetAppIconForAppFunctionality: "You need to set an app icon for app functionality!",
            uploadAPictureAndSetAsAppIcon: "Solution: Upload a picture and set it as app icon!",
            placeYourLogoHere: 'Place your logo here',
            preview: 'Preview',
            qrCode: 'QR-Code',
            shop: 'Shop',
            pushNotifications: 'Push Notifications',
            textColor: 'Text color',
            textPosition: 'Text Position',
            textPositions: {
                left: 'Left',
                center: 'Center',
                right: 'Right',
            },
            fontSize: 'Font size',
            fontFamily: 'Font family',
            backToMenu: 'Back to menu',
            pleaseSelectATemplate: 'Please select a template',
            addLink: 'Add link',
            editLink: 'Edit link',
            editEmail: 'Edit email',
            removeLink: 'Remove link',
            removeEmail: 'Remove email',
            edit: 'edit',
            createNewRow: 'Create new row',
            deleteRow: 'Delete row with content',
            uploadImageHere: 'Upload an image here',
            rowOptions: 'Row options',
            columnOptions: 'Column options',
            whatDoYouWantToCreate: 'What do you want to create',
            whichTemplateDoYouWantToUse: 'Which template do you want to use',
            theLandingPageIsNowCreated: 'The landing page is now being created',
            youWillBeRedirectedToTheEditorPage: 'You will automatically be redirected to the page editor',
            pleaseBePatientThanks: 'Please be patient for a moment. Thank you very much!',
        },
        images: {
            image: 'Image',
            manageImages: 'Manage images',
            backgroundImageUpload: 'Upload background image',
            backgroundImageType: 'Display position',
            alreadyUploadedImages: 'Uploaded images',
            deleteImageConfirm: 'Do you really want to delete this image?',
            setImageType: 'Please select a new display position for the image:',
            noImagesUploadedYet: 'No images have been uploaded yet',
            imageTypeSetSuccessfully: 'The image display position has been saved!',
            imageDeletedSuccessfully: 'The image has been deleted!',
            appIconSetSuccessfully: 'The image has been set as app icon!',
            uploadAnImageThenSetPosition: 'First upload an image here and then select the display position.',
            manageImagesIntroText: 'Here you have the option to upload new or delete images, to change the display position of images and set an image as the app icon of your app.',
            uploadAStartPageImage: 'Upload a start page image',
            noAppIconImageUploadedYet: 'No app icon image set',
            heroImage: 'Hero Image',
            textBackgroundImage: 'Text background image',
            logo: 'Logo',
            noImageUploadedYet: 'No image has been selected yet',
            opacity: 'Opacity',
            borderRadius: 'Border radius',
            width: 'Width',
            height: 'Height',
        },
        headers: {
            myPages: 'My pages',
            pages: 'Pages',
            events: 'Events',
        },
        pageTypes: {
            1: 'Page',
            2: 'Event',
        },
        legals: {
            changedSentence1Part1: 'We have changed our',
            changedSentence1Part2: '',
            termsAndConditions: 'terms and conditions',
            termsAndConditionsHeader: 'Terms & Conditions',
            and: 'and',
            privacyAgreement: 'privacy agreement',
            privacyAgreementHeader: 'Privacy Agreement',
            changedSentence2Part1: 'Please read them carefully and accept the new',
            amendmentsToThe: 'Amendments to the',
            pleaseConfirmSentencePart1: 'Please confirm the ',
            pleaseConfirmSentencePart2: 'by activating the checkbox',
            acceptedPrivacyAgreementOnPart1: 'You have accepted the privacy agreement on ',
            acceptedPrivacyAgreementOnPart2: '',
        },
        imprint: {
            informationAccordingToTMG5: 'Information according to § 5 TMG',
            salesTaxIdentificationNumberAccordingTo27aSalesTaxAct: 'Sales tax identification number according to §27a Sales Tax Act',
            pictureCredits: 'Picture credits',
            disclaimer: 'Disclaimer',
            contentOfTheOnlineOffer: 'Content of the online offer',
            contentOfTheOnlineOfferText1: 'The contents of our pages were created with the greatest care. However, we cannot guarantee the correctness, completeness and up-to-dateness of the contents. As a service provider, we are responsible for our own content on these pages in accordance with general laws pursuant to Section 7 (1) of the German Telemedia Act (TMG). However, according to §§ 8 to 10 TMG, we are not obliged as a service provider to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity. Obligations to remove or block the use of information in accordance with general laws remain unaffected by this. However, liability in this regard is only possible from the point in time at which a concrete infringement of the law becomes known. If we become aware of any such infringements, we will remove the relevant content immediately.',
            contentOfTheOnlineOfferText2: 'Liability claims against the author relating to material or non-material damage caused by the use or non-use of the information provided or by the use of incorrect or incomplete information are excluded, unless the author can be proven to have acted with intent or gross negligence.',
            contentOfTheOnlineOfferText3: 'All offers are subject to change and non-binding. The author expressly reserves the right to change, supplement or delete parts of the pages or the entire offer without separate announcement or to discontinue publication temporarily or permanently.',
            referencesAndLinks: 'References and links',
            referencesAndLinksText1: 'In the case of direct or indirect references to external websites ("hyperlinks") that lie outside the author\'s area of responsibility, a liability obligation would only come into force if the author had knowledge of the content and it would be technically possible and reasonable for him to prevent use in the case of illegal content. The author hereby expressly declares that at the time the links were created, no illegal content was discernible on the linked pages. The author has no influence on the current and future design, content or authorship of the linked pages. For this reason, he hereby expressly distances himself from all contents of all linked pages that were changed after the link was created. This statement applies to all links and references set within the author\'s own Internet offer as well as to external entries in guest books, discussion forums, link directories, mailing lists and all other forms of databases set up by the author to which external write access is possible. Liability for illegal, incorrect or incomplete content and in particular for damage arising from the use or non-use of such information lies solely with the provider of the page to which reference is made, and not with the person who merely refers to the respective publication via links.',
            copyrightAndTrademarkLaw: 'Copyright and trademark law',
            copyrightAndTrademarkLawText1: 'The author endeavours to observe the copyrights of the images, graphics, sound documents, video sequences and texts used in all publications, to use images, graphics, sound documents, video sequences and texts created by himself or to use licence-free graphics, sound documents, video sequences and texts. All brand names and trademarks mentioned on the website and possibly protected by third parties are subject without restriction to the provisions of the applicable trademark law and the ownership rights of the respective registered owners. Should you nevertheless become aware of a copyright infringement, please notify us. If we become aware of any copyright infringements, we will remove the relevant content immediately.',
            copyrightAndTrademarkLawText2: 'The mere mention of a trademark does not imply that it is not protected by the rights of third parties!',
            copyrightAndTrademarkLawText3: 'The copyright for published objects created by the author himself remains solely with the author of the pages. Any duplication or use of objects such as diagrams, sounds or texts in other electronic or printed publications is not permitted without the author\'s agreement.',
            legalValidityOfThisDisclaimer: 'Legal validity of this disclaimer',
            legalValidityOfThisDisclaimerText: 'This disclaimer is to be regarded as part of the internet publication which you were referred from. If sections or individual terms of this statement are not legal or correct, the content or validity of the other parts remain uninfluenced by this fact.',
            siteIsOperatedAndProvidedBy: 'The site is operated and provided by',
            bannedContentSentence: 'The above-mentioned operator assumes no responsibility for the contents of the page provided. The contents of this page were created by.... If the content is sexual, racist, homophobic, pornographic, glorifies violence or incites hatred, please contact our support. We will take the necessary steps.',
            hereinafter: 'hereinafter',
            operator: 'Operator',
            startPageBackgroundImage: 'Start Page Background Image',
            photoFrom: 'Photo from ',
            by: 'by',
        },
        privacy: {
            dataPrivacyInformation: 'Data privacy information',
            tracking: 'Tracking',
            weUseCookiesNote: 'We use cookies to enable you to make the best possible use of our website. Cookies necessary for operating the website are set. You can also allow cookies for statistics purposes. You can change privacy settings or directly allow all cookies.',
            changePrivacySettings: 'Change privacy settings',
            acceptAllCookies: 'Yes, I accept all cookies',
            cookieAndTrackingExplanation: 'We use the Google Analytics analysis tool on our website. The tool uses cookies. These cookies allow us to count visits. These text files (cookies) are stored on your computer and enable us to analyse the use of our website. Your IP address is an anonymous identifier for us; we have no technical means of identifying you as a registered user. You remain anonymous as a user. If you agree to the analysis of your data, please activate this cookie.',
            acceptChanges: 'Save changes',
            onlyEssential: 'Only essential cookies',
            acceptAll: 'Accept all',
            saveSelection: 'Accept selection',
            linkToPrivacyStatement: 'Click here for the privacy policy',
            allowTracking: 'Allow Tracking',
            essentialCookies: 'Essential cookies',
            essentialCookiesExplanation: 'The site requires essential (technical) cookies for error-free use. You cannot opt out of these cookies as they are required for the operation of the site. The essential cookies are not used to track your visit to our website.',
            changeTrackingOption: 'Change tracking settings',
            changeCookieUsageOption: 'Customize cookie usage',
        },
        landingPage: {
            deleteLandingPageConfirm: 'Do you really want to delete this page irrevocably?',
        },
        subdomain: {
            subdomain: 'Subdomain',
            manageSubdomain: 'Manage own domain',
            helpSentence: 'If you have your own domain, you can run your site under your domain. All you have to do is set up a subdomain. You can find more information here: Link',
            subdomainSavedSuccessfully: 'The subdomain has been successfully saved.',
        },
        contact: {
            companyName: 'Company name',
            street: 'Street',
            zipCode: 'ZIP code',
            city: 'City',
            country: 'Country',
            phone: 'Phone',
            fax: 'Fax',
            mobile: 'Mobile',
            email: 'Email',
            companyNamePlaceholder: '[Company name]',
            streetPlaceholder: '[Street]',
            zipCodePlaceholder: '[ZIP code]',
            cityPlaceholder: '[City]',
            countryPlaceholder: '[Country]',
            phonePlaceholder: '[Phone]',
            faxPlaceholder: '[Fax]',
            mobilePlaceholder: '[Mobile]',
            emailPlaceholder: '[Email]',
        },
        shop: {
            iAmLiableToPaySalesTax: 'I am liable to pay sales tax',
            payPalEmailAddressExplanationText: 'The payment is handled by the payment service provider PayPal. The sales will be credited to your PayPal account. For this automatic processing we need the email address of your PayPal account to credit the amounts to your PayPal account.',
            payPalEmailAddressIsNotValid: 'The email address is invalid.',
            paypalEmailAddress: 'PayPal email address',
            vatNo: 'VAT no',
            vatNoAbbrevation: 'VAT no',
            vatNoText: 'You have indicated that you are subject to VAT. Please enter your VAT number below.',
            vatNoIsNotValid: 'The VAT number provided is invalid.',
            private: 'Private',
            company: 'Company',
            companyName: 'Company name',
            addressDataText: 'Please enter your details as a seller.',
            firstName: 'First name',
            lastName: 'Last name',
            street: 'Street',
            postalCode: 'Postal code',
            city: 'City',
            privateOrCompanyText: 'Is the store run privately or as a company?',
            selectCountry: '- Select country -',
            summary: 'Summary',
            legalStatus: 'Legal status',
            noProductsCreatedYet: 'No products have been created yet.',
            deleteProductConfirm: 'Do you want to delete the product irrevocably?',
            wizard: {
                business: {
                    headerText: 'Business Setup Wizard',
                    introductionText: 'You are in the Business Account setup for the first time. The wizard will help you to set up the account in a few steps.',
                },
                product: {
                    headerText: 'Product setup',
                    productType: 'Product type',
                    selectProductTypeText: 'Please select a product type',
                    enterProductNameText: 'What should be the name of your product?',
                    enterProductDescriptionText: 'Please enter a description of your product here (e.g. special features, dimensions, weight, etc.).',
                    enterProductNumberText: 'What is your product number?',
                    enterNetPriceText: 'What is the net price of your product?',
                    enterShippingCosts: 'What are the shipping costs of your product? We distinguish between the costs for shipping in the domestic market, in other European countries and other countries.',
                    enterMaxNumberOfProductsInOneShippingPackage: 'What is the maximum number of products that can be shipped in one package for the selected shipping prices?',
                    whatIsTheSalesTaxOnThisProduct: 'What is the sales tax (in percent) on this product?',
                    whatQuantityIsAvailable: 'What number of pieces / quantity of the product is available?',
                    optionToUploadProductImages: 'You have the possibility to upload pictures of your product here (optional). ',
                    productName: 'Product name',
                    status: 'Status',
                    productDescription: 'Product description',
                    productNumber: 'Product number',
                    netPrice: 'Net price',
                    netPriceIn: 'Net price in',
                    unlimited: 'unlimited',
                    isDefaultSalesTax: 'Standard sales tax',
                    productImages: 'Images of product',
                    numberPiecesQuantityOfProduct: 'Number of pieces / quantity of the product',
                    shippingCostsDomestic: 'Shipping costs domestic',
                    shippingCostsEuropeanForeignCountries: 'Shipping costs european foreign countries',
                    shippingCostsOtherCountries: 'Shipping costs other countries',
                    maxNumberOfProductsInOneShippingPackage: 'Maximum number of products in one shipping package',
                    salesTaxInPercent: 'Sales tax in percent',
                    salesTax: 'Sales tax',
                    optional: 'Optional',
                    productNameIsInvalid: 'The chosen name is too short. The name must contain at least 1 character.',
                    productDescriptionIsInvalid: 'The chosen product description is too short. The description must contain at least 1 character.',
                    productNumberIsInvalid: 'The chosen number is too short. The number must contain at least 1 character.',
                    priceIsInvalid: 'The selected price is invalid.',
                    valueIsInvalid: 'The selected value is invalid.',
                    productNumberAlreadyAssigned: 'This product number is already assigned to another product.',
                    addNewProduct: 'Create new product',
                    backToProductList: 'Back to product list',
                    productStatusActive: 'Active',
                    productStatusPaused: 'Paused',
                },
            },
        },
    },
    de: {
        language: {
            'name': 'Deutsch',
            'flag':'flag-icon-de',
        },
        general: {
            ok: "Ok",
            save: "Speichern",
            submit: "Absenden",
            cancel: "Abbrechen",
            upload: "Hochladen",
            selectFile: "Datei auswählen",
            pleaseSelect: "Bitte auswählen",  
            showMore: "Mehr...",
            optional: "Optional",
            yes: "Ja",
            no: "Nein",
            download: 'Herunterladen',
            invitation: 'Einladung',
            asPdf: 'als PDF Datei',
            phone: 'Telefon',
            email: 'E-Mail',
            more: 'Mehr',
            less: 'Weniger',
            options: 'Optionen',
            back: 'Zurück',
            next: 'Weiter',
            in: 'in',
            of: 'von',
        },
        error: {
            general: "Das hat leider nicht geklappt. Versuche es erneut oder wende dich an den Support.",
            anErrorOccured: "Es tut uns leid. Leider ist in einer Komponente ein Fehler aufgetreten: ",
            ifErrorPersists: "Falls dieser Fehler mehrfach auftreten sollte, informiere bitte unseren Support für weitere Hilfe.",
            components: {
                app: 'App',
                navigation: 'Navigation',
                start: 'Start',
                signIn: 'Anmelden',
                register: 'Registrierung',
                setNewPassword: 'Neues Passwort setzen',
                privacy: 'Datenschutz',
                notFound: 'Not found',
                imprint: 'Impressum',
                forgotPassword: 'Passwort vergessen',
                dashboard: 'Dashboard',
                account: 'Account',
                landingPageView: 'Landing Page View',
                invitation: 'Einladung',
                catering: 'Catering',
                contact: 'Kontakt',
                termsAndConditions: 'AGB',
                footer: 'Footer',
                cookieAndPrivacyConsent: 'Cookie & Privacy Consent',
                browserIncompatabilityWarning: 'Browser Inkompatibilitäts Warnung',
                appText: 'App Text',
                editText: 'Edit Text',
                imageUpload: 'Image Upload',
                appFeatures: 'App Features',
                contactDetails: 'Kontakt Details',
                manageSubdomain: 'Eigene Domain verwalten',
                internalLink: 'Interner Link',
                textReplacement: 'Text Replacement',
                sendPushNotificationMessage: 'Send Push Message',
                pagePushNotificationMessagesListContainer: 'Page Push Notification Messages List Container',
                pushNotificationMessagesListItem: 'Push Notification Messages List Item',
            }
        },
        start: {
            startNowForFree: 'Jetzt kostenlos starten',
        },
        hints: {
            browserIncompatabilityWarning: 'Für die optimale Darstellung und Funktionalität dieser App empfehlen wir die Browser Google Chrome, Microsoft Edge oder Apple Safari. Sollte etwas nicht wie erwartet funktionieren, möchten wir Sie bitten den Browser auf einen der genannten zu wechseln.',
        },
        message: {
            all: 'An alle',
            title: "Titel",
            message: "Nachricht",
            sendNewPushMessage: "Neue Push-Benachrichtigung senden",
            provideFeedbackSinglePerson: "Möchtest du uns eine Nachricht da lassen?",
            provideFeedbackFamily: "Ihr möchtet uns eine Nachricht da lassen?",
            sentMessages: 'Gesendete Nachrichten',
            sent: 'Gesendet am',
            to: 'Empfänger',
            newMessage: 'Neue Nachricht',
            deleteMessageConfirm: 'Bitte bestätige, dass du die Nachricht unwideruflich löschen möchtest',
            noMessagesSentYet: 'Bislang keine gesendeten Nachrichten',
        },
        participation: {
            participateSinglePerson: "Zu- / Absage",
            participateFamily: "Zu- / Absage",
            commitments: 'Zusagen',
            cancellations: 'Absagen',
            waitingOnResponse: 'Keine Antwort',
            yes: "Ja",
            no: "Nein",
            noOfPersons: "Anzahl Personen",
            person: "Person",
            persons: "Personen",
        },
        app: {
            enablePushNotifications: 'Aktivere Benachrichtigungen',
            install: 'Installieren als App',
            saveInCalendar: 'Termin in meinem Kalender speichern',
            successfullySubscribedToNotificationService: 'Du hast dich erfolgreich für unseren Benachrichtigungsdienst angemeldet!',
        },
        menu: {
            register: "Account erstellen",
            signIn: "Einloggen",
            signOut: "Ausloggen",
            dashboard: "Dashboard",
            logout: "Ausloggen",
            account: "Account",
            forgotPassword: "Passwort vergessen",
            language: "Sprache",
            newEntry: "Neuer Eintrag",
        },
        account: {
            email: "E-Mail",
            emailAddress: "Email Addresse",
            password: "Password",
            firstname: "First name",
            surname: "Surname",
            passwordAssignedSuccessfully: 'Das Passwort wurde erfolgreich gespeichert',
            passwordConfirm: "Password Wiederholung",
            passwordsDoNotMatch: "Die gewählten Passwörter stimmen nicht überein! Bitte überprüfe die Passwörter noch einmal.",
            passwordsAreMatching: "Die gewählten Passwörter stimmen überein und erfüllen die Passwort-Richtlinie!",
            passwordDoesNotComplyToGuidelines: "Das Passwort entspricht nicht den Passwort-Richtlinien (mind. 6 Zeichen, Groß- und Kleinschreibung und mind. 1 Zahl).",
            passwordHasBeenSent: "Ein Link zum Zurücksetzen deines Passworts wurde an deine E-Mail Adresse gesendet.",
            resetPassword: "Passwort zurücksetzen",
            pleaseSetANewPasswordNow: "Bitte lege nun ein neues Passwort fest",
            accountSuccessfullyUpdated: "Die Änderungen wurden erfolgreich gespeichert.",
            furtherOptions: "Weitere Optionen",
            changePassword: "Passwort ändern",
        },
        register: {
            accountCreated: "Account bestätigt",
            registerForFree: "Erstelle dir einen kostenlosen Account",
            checkYourInputs: "Bitte überprüfe die Eingabe der E-Mail Adresse!",
            registrationSuccess: "Die Registrierung war erfolgreich. Wir haben zur Verifizierung der E-Mail Adresse eine E-Mail an die angegebene E-Mail Adresse gesendet. Bitte prüfe deinen E-Mail Eingang.",
            emailConfirmed: "Deine E-Mail Bestätigung war erfolgreich. Bitte setzte nun ein Passwort für deinen Account",
            agreeToPrivacyPolicyAndTermsOfService: "Wenn Sie sich für ein Konto anmelden, erklären Sie sich mit der Datenschutzrichtlinie und den Nutzungsbedingungen einverstanden.",
            agreeToTermsAndConditionsPart1: "Ich habe die",
            agreeToTermsAndConditionsPart2: "gelesen und bin damit einverstanden",
            termsAndConditions: "Geschäftsbedingungen",
            agreeToPrivacyPolicy: "Ich akzeptiere die",
            privacyPolicy: "Datenschutzvereinbarung",
            pleaseAcceptTermsAndConditions: 'Bitte akzeptiere die Geschäftsbedingungen',
            pleaseAcceptPrivacyPolicy: 'Bitte akzeptiere die Datenschutzvereinbarung',
            pleaseAcceptTermsAndConditionsAndPrivacyPolicy: 'Bitte akzeptiere die Geschäftsbedingungen & die Datenschutzvereinbarung',
        },
        login: {
            backToEmailAddress: "Zurück zur Eingabe E-Mail Adresse",
            pleaseEnterEmailAddress: "Bitte gib deine Email Adresse ein",
            pleaseEnterYourPassword: "Bitte gib nun dein Passwort ein, um den Login vollständig abzuschließen.",
            stayLoggedIn: "Angemeldet bleiben",
            forgotPassword: "Passwort vergessen?",
            emailIsEmpty: "Bitte gib deine E-Mail Adresse für den Login Vorgang ein.",
        },
        footer: {
            imprint: "Impressum",
            privacy: "Datenschutz",
            contact: "Kontakt",
            termsAndConditions: "AGB",
        },
        time: {
            today: 'Heute',
            tomorrow: 'Morgen',
            oclock: 'Uhr',
            monday: 'Montag',
            tuesday: 'Dienstag',
            wednesday: 'Mittwoch',
            thursday: 'Donnerstag',
            friday: 'Freitag',
            saturday: 'Samstag',
            sunday: 'Sonntag',
            week: 'KW',
            months: [
                {
                    name: 'Januar',
                    shortName: 'JAN',
                    number: 1,
                },
                {
                    name: 'Februar',
                    shortName: 'FEB',
                    number: 2,
                },
                {
                    name: 'März',
                    shortName: 'MAR',
                    number: 3,
                },
                {
                    name: 'April',
                    shortName: 'APR',
                    number: 4,
                },
                {
                    name: 'Mai',
                    shortName: 'MAI',
                    number: 5,
                },
                {
                    name: 'Juni',
                    shortName: 'JUN',
                    number: 6,
                },
                {
                    name: 'Juli',
                    shortName: 'JUL',
                    number: 7,
                },
                {
                    name: 'August',
                    shortName: 'AUG',
                    number: 8,
                },
                {
                    name: 'September',
                    shortName: 'SEP',
                    number: 9,
                },
                {
                    'name': 'Oktober',
                    'shortName': 'OKT',
                    'number': 10,
                },
                {
                    name: 'November',
                    shortName: 'NOV',
                    number: 11,
                },
                {
                    name: 'Dezember',
                    shortName: 'DEZ',
                    number: 12,
                },
            ]
        },
        texts: {
            salutation: {
                dearMale: 'Lieber',
                dearFemale: 'Liebe',
                dearDiverse: 'Liebe(r)',
                dearFamily: 'Liebe Familie',
            },
            events: {
                event: "Event",
                eventName: 'Event Name',
                eventDate: 'Event Datum',
                myEvents: 'Meine Events',
                addEvent: 'Hinzufügen',
                createEvent: 'Neues Event erstellen',
                editEvent: 'Event bearbeiten',
                landingPage: 'Landing Page',
                guest: 'Gast',
                eventInfos: 'Event Informationen',
                eventLandingPageInfo: 'Du hast die Möglichkeit für ein Event mehrere Landing Pages für verschiedene Gäste zu erstellen. ',
                eventCreateLandingPageText: 'Aktuell hast du für dieses Event noch keine Landing Page erstellt. Erstelle eine neue Landing Page in dem du auf das Plus-Zeichen klickst.',
            }
        },
        status: {
            dataWaitingForSync: 'Die Daten werden übermittelt...',
            dataSuccessfullyTransmitted: 'Die Daten wurden erfolgreich übermittelt',
        },
        editor: {
            editText: 'Text anpassen',
            editImage: 'Bild anpassen',
            editDate: 'Datum anpassen',
            savedSettingsSuccessfully: 'Die Einstellungen wurden erfolgreich gespeichert',
            savedTextSuccessfully: 'Der Text wurde erfolgreich gespeichert',
            savedDateSuccessfully: 'Das Datum wurde erfolgreich gespeichert',
            warning: "Achtung!",
            noAppIconSet: "Es wurde noch kein App Icon festgelegt!",
            needToSetAppIconForAppFunctionality: "Für die volle App Funktionalität ist es erforderlich ein App Icon auszuwählen.",
            uploadAPictureAndSetAsAppIcon: "Hilfe: Lade ein Bild hoch und markiere es als App Icon!",
            placeYourLogoHere: 'Platziere hier dein Logo',
            preview: 'Vorschau',
            qrCode: 'QR-Code',
            shop: 'Shop',
            pushNotifications: 'Push Notifications',
            textColor: 'Text Farbe',
            textPosition: 'Text Position',
            textPositions: {
                left: 'Links',
                center: 'Zentriert',
                right: 'Rechts',
            },
            fontSize: 'Schriftgröße',
            fontFamily: 'Schriftart',
            backToMenu: 'Zurück zum Menü',
            pleaseSelectATemplate: 'Bitte wähle eine Vorlage aus',
            addLink: 'Link hinzufügen',
            editLink: 'Link bearbeiten',
            editEmail: 'E-Mail bearbeiten',
            removeLink: 'Link entfernen',
            removeEmail: 'E-Mail entfernen',
            edit: 'anpassen',
            createNewRow: 'Neue Reihe hinzufügen',
            deleteRow: 'Reihe mit Inhalt löschen',
            uploadImageHere: 'Bild hochladen',
            rowOptions: 'Reihen Optionen',
            columnOptions: 'Spalten Optionen',
            whatDoYouWantToCreate: 'Was möchtest du erstellen',
            whichTemplateDoYouWantToUse: 'Welches Template möchtest du nutzen',
            theLandingPageIsNowCreated: 'Die Landing Page wird nun erstellt',
            youWillBeRedirectedToTheEditorPage: 'Du wirst automatisch zum Seiten-Editor weitergeleitet',
            pleaseBePatientThanks: 'Bitte habe einen kurzen Moment Geduld. Vielen Dank!',

        },
        images: {
            image: 'Bild',
            manageImages: 'Bilder verwalten',
            backgroundImageUpload: 'Neues Bild hochladen',
            backgroundImageType: 'Anzeige Position',
            alreadyUploadedImages: 'Hochgeladene Bilder',
            deleteImageConfirm: 'Möchtest du wirklich dieses Bild löschen?',
            setImageType: 'Bitte wähle eine neue Anzeigeposition für das Bild aus:',
            noImagesUploadedYet: 'Es wurden noch keine Bilder hochgeladen',
            imageTypeSetSuccessfully: 'Die Bild-Anzeigeposition wurde gespeichert!',
            imageDeletedSuccessfully: 'Das Bild wurde gelöscht!',
            appIconSetSuccessfully: 'Das Bild wurde als App-Icon festgelegt!',
            uploadAnImageThenSetPosition: 'Lade zunächst ein Bild hoch und wähle anschließend die Anzeigeposition.',
            manageImagesIntroText: 'Hier hast du die Möglichkeit neue Bilder hochzuladen oder zu löschen, die Anzeigeposition von Bildern zu verändern und ein Bild als App Icon deiner App festzulegen.',
            uploadAStartPageImage: 'Lade ein Startseitenbild hoch',
            noAppIconImageUploadedYet: 'Kein Bild als App Icon festgelegt',
            heroImage: 'Hero Image',
            textBackgroundImage: 'Text Hintergrundbild',
            logo: 'Logo',
            noImageUploadedYet: 'Bislang wurde kein Bild festgelegt',
            opacity: 'Opazität',
            borderRadius: 'Kantenradius',
            width: 'Breite',
            height: 'Höhe',
        },
        headers: {
            myPages: 'Meine Seiten',
            pages: 'Seiten',
            events: 'Veranstaltungen',
        },
        pageTypes: {
            1: 'Seite',
            2: 'Veranstaltung',
        },
        legals: {
            changedSentence1Part1: 'Wir haben unsere',
            changedSentence1Part2: 'geändert',
            termsAndConditions: 'Allgmeinen Geschäftsbedingungen',
            termsAndConditionsHeader: 'Allgemeine Geschäftsbedingungen',
            and: 'und',
            privacyAgreement: 'Datenschutzvereinbarung',
            privacyAgreementHeader: 'Datenschutzvereinbarung',
            changedSentence2Part1: 'Bitte lies dir diese aufmerksam durch und akzeptiere die neuen',
            amendmentsToThe: 'Änderungen der',
            pleaseConfirmSentencePart1: 'Bitte bestätige die ',
            pleaseConfirmSentencePart2: 'durch das Setzen des Hakens',
            acceptedPrivacyAgreementOnPart1: 'Du hast die Datenschutzvereinbarung am',
            acceptedPrivacyAgreementOnPart2: 'akzeptiert.'
        },
        imprint: {
            informationAccordingToTMG5: 'Angaben gemäß § 5 TMG',
            salesTaxIdentificationNumberAccordingTo27aSalesTaxAct: 'Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz',
            pictureCredits: 'Bildnachweise',
            disclaimer: 'Haftungsausschluss',
            contentOfTheOnlineOffer: 'Inhalt des Onlineangebotes',
            contentOfTheOnlineOfferText1: 'Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.',
            contentOfTheOnlineOfferText2: 'Haftungsansprüche gegen den Autor, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens des Autors kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt.',
            contentOfTheOnlineOfferText3: 'Alle Angebote sind freibleibend und unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.',
            referencesAndLinks: 'Verweise und Links',
            referencesAndLinksText1: 'Bei direkten oder indirekten Verweisen auf fremde Webseiten ("Hyperlinks"), die außerhalb des Verantwortungsbereiches des Autors liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall in Kraft treten, in dem der Autor von den Inhalten Kenntnis hat und es ihm technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern. Der Autor erklärt hiermit ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die aktuelle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft der verlinkten/verknüpften Seiten hat der Autor keinerlei Einfluss. Deshalb distanziert er sich hiermit ausdrücklich von allen Inhalten aller verlinkten /verknüpften Seiten, die nach der Linksetzung verändert wurden. Diese Feststellung gilt für alle innerhalb des eigenen Internetangebotes gesetzten Links und Verweise sowie für Fremdeinträge in vom Autor eingerichteten Gästebüchern, Diskussionsforen, Linkverzeichnissen, Mailinglisten und in allen anderen Formen von Datenbanken, auf deren Inhalt externe Schreibzugriffe möglich sind. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist.',
            copyrightAndTrademarkLaw: 'Urheber- und Kennzeichenrecht',
            copyrightAndTrademarkLawText1: 'Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Bilder, Grafiken, Tondokumente, Videosequenzen und Texte zu beachten, von ihm selbst erstellte Bilder, Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte zurückzugreifen. Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Sollten Sie dennoch auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um eine Benachrichtigung. Bei Kenntnis von Urheberrechtsverletzungen werden wir die betreffenden Inhalte umgehend entfernen.',
            copyrightAndTrademarkLawText2: 'Allein aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt sind!',
            copyrightAndTrademarkLawText3: 'Das Copyright für veröffentlichte, vom Autor selbst erstellte Objekte bleibt allein beim Autor der Seiten. Eine Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung des Autors nicht gestattet.',
            legalValidityOfThisDisclaimer: 'Rechtswirksamkeit dieses Haftungsausschlusses',
            legalValidityOfThisDisclaimerText: 'Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.',
            siteIsOperatedAndProvidedBy: 'Die Seite wird betrieben und bereitgestellt von',
            bannedContentSentence: 'Für die Inhalte der bereitgestellten Seite übernimmt der Betreiber keine Verantwortung. Die Inhalte dieser Seite wurden von... erstellt. Sollten die Inhalte sexuelle, rassistische, homophobe, pornographische, gewaltverherrlichende oder volksverhetzende Inhalte enthalten, wenden Sie sich bitte an unseren Support. Wir werden die notwendigen Schritte einleiten.',
            hereinafter: 'nachfolgend',
            operator: 'Betreiber',
            startPageBackgroundImage: 'Start-Seite Hintergrundfoto',
            photoFrom: 'Foto von ',
            by: 'von',
        },
        privacy: {
            dataPrivacyInformation: 'Datenschutzhinweis',
            tracking: 'Tracking',
            weUseCookiesNote: 'Wir verwenden Cookies, um Ihnen die optimale Nutzung unserer Webseite zu ermöglichen. Es werden für den Betrieb der Seite notwendige Cookies gesetzt. Darüber hinaus können Sie Cookies für Statistikzwecke zulassen. Sie können die Datenschutzeinstellungen anpassen oder allen Cookies direkt zustimmen.',
            changePrivacySettings: 'Datenschutzeinstellungen anpassen',
            acceptAllCookies: 'Ja, ich bin mit allen Cookies einverstanden',
            cookieAndTrackingExplanation: 'Wir nutzen auf unserer Internetseite das Analyse Tool Google Analytics. Das Tool verwendet Cookies. Mit diesen Cookies können wir Besuche zählen. Diese Textdateien (Cookies) werden auf Ihrem Computer gespeichert und machen es uns möglich, die Nutzung seiner Webseite zu analysieren. Ihre IP-Adresse ist für uns eine anonyme Kennung; wir haben keine technische Möglichkeit, Sie damit als angemeldeten Nutzer zu identifizieren. Sie bleiben als Nutzer anonym. Wenn Sie mit der Auswertung Ihrer Daten einverstanden sind, dann aktivieren Sie bitte diesen Cookie.',
            acceptChanges: 'Änderungen speichern',
            acceptAll: 'Alle akzeptieren',
            saveSelection: 'Auswahl akzeptieren',
            onlyEssential: 'Nur essenzielle zulassen',
            linkToPrivacyStatement: 'Zur Datenschutzerklärung',
            allowTracking: 'Tracking erlauben',
            essentialCookies: 'Essenzielle Cookies',
            essentialCookiesExplanation: 'Die Seite benötigt für die fehlerfreie Nutzung essenzielle (technische) Cookies. Diese Cookies können Sie nicht abwählen, da diese Cookies für den Betrieb der Seite benötigt werden. Die essenziellen Cookies werden nicht dafür verwendet, Ihren Besuch auf unserer Webseite zu tracken.',
            changeTrackingOption: 'Tracking anpassen',
            changeCookieUsageOption: 'Cookie-Nutzung anpassen',
        },
        landingPage: {
            deleteLandingPageConfirm: 'Möchtest du wirklich diese Seite unwiderruflich löschen?',
        },
        subdomain: {
            subdomain: 'Subdomain',
            manageSubdomain: 'Eigene Domain verwalten',
            helpSentence: 'Wenn du einen eigene Domain hast, kannst du deine Seite unter deiner Domain laufen lassen. Dazu musst du nur eine Subdomain einrichten. Mehr Infos dazu gibt es hier: Link',
            subdomainSavedSuccessfully: 'Die Subdomain wurde erfolgreich gespeichert',
        },
        contact: {
            companyName: 'Firmenname',
            street: 'Strasse',
            zipCode: 'PLZ',
            city: 'Ort',
            country: 'Land',
            phone: 'Telefon',
            fax: 'Fax',
            mobile: 'Mobil',
            email: 'E-Mail',
            companyNamePlaceholder: '[Firmenname]',
            streetPlaceholder: '[Strasse]',
            zipCodePlaceholder: '[PLZ]',
            cityPlaceholder: '[Ort]',
            countryPlaceholder: '[Land]',
            phonePlaceholder: '[Telefon]',
            faxPlaceholder: '[Fax]',
            mobilePlaceholder: '[Mobil]',
            emailPlaceholder: '[E-Mail]',
        },
        shop: {
            iAmLiableToPaySalesTax: 'Ich bin umsatzsteuerpflichtig',
            payPalEmailAddressExplanationText: 'Das Payment wird über den Zahlungsdienstanbieter PayPal abgewickelt. Die Verkäufe werden deinem PayPal Konto gutgeschrieben. Für diese automatische Abwicklung benötigen wir die E-Mail Adresse deines PayPal Accounts, um die Beträge deinem PayPal Account gutzuschreiben.',
            payPalEmailAddressIsNotValid: 'Die angegebene E-Mail Adresse ist ungültig.',
            paypalEmailAddress: 'PayPal E-Mail Adresse',
            vatNo: 'Umsatzsteueridentnummer',
            vatNoAbbrevation: 'Ust.Id.Nr.',
            vatNoText: 'Du hast angegeben, dass du umsatzsteuerpflichtig bist. Trage nun bitte unten nun deine Umsatzsteueridentnummer ein.',
            vatNoIsNotValid: 'Die angegebene Umsatzsteueridentnummer ist ungültig.',
            private: 'Privat',
            company: 'Firma',
            companyName: 'Firma',
            addressDataText: 'Bitte gib deine Daten als Verkäufer an.',
            firstName: 'Vorname',
            lastName: 'Nachname',
            street: 'Strasse',
            postalCode: 'PLZ',
            city: 'Ort',
            privateOrCompanyText: 'Wird der Shop privat oder als Firma betrieben?',
            selectCountry: '- Land auswählen -',
            summary: 'Zusammenfassung',
            legalStatus: 'Rechtsform',
            noProductsCreatedYet: 'Es wurden bislang keine Produkte angelegt.',
            deleteProductConfirm: 'Möchtest du das Produkt unwiderruflich löschen?',
            wizard: {
                business: {
                    headerText: 'Business Account Einrichtung',
                    introductionText: 'Du bist zum ersten Mal in der Einrichtung des Business Accounts. Der Wizard hilft dir in wenigen Schritten das Setup vorzunehmen.',
                },
                product: {
                    headerText: 'Produkt-Setup',
                    productType: 'Produkt-Typ',
                    selectProductTypeText: 'Bitte wähle einen Produkt-Typ aus',
                    enterProductNameText: 'Wie soll der Name deines Produkts lauten?',
                    enterProductDescriptionText: 'Bitte gib hier eine Beschreibung deines Produkts ein (z.B. besondere Eigenschaften, Maße, Gewicht etc.).',
                    enterProductNumberText: 'Wie ist deine Produktnummer?',
                    enterNetPriceText: 'Was ist der Nettopreis deines Produkts?',
                    enterShippingCosts: 'Wie hoch sind die Versandkosten deines Produkts? Unterschieden wird hier zwischen den Kosten für den Versand im Inland, im europäischem Ausland und anderen Ländern.',
                    enterMaxNumberOfProductsInOneShippingPackage: 'Wie hoch ist die maximale Anzahl an Produkten, die für die gewählten Versandkostenpreise in einer Verpackung versendet werden können?',
                    whatIsTheSalesTaxOnThisProduct: 'Wie hoch ist die Umsatzsteuer (in Prozent) bei diesem Produkt?',
                    whatQuantityIsAvailable: 'Welche Stückzahl / Menge des Produkts ist verfügbar?',
                    optionToUploadProductImages: 'Du hast hier die Möglichkeit Bilder deines Produkts hochzuladen (optional).',
                    productName: 'Produktname',
                    status: 'Status',
                    productDescription: 'Produktbeschreibung',
                    productNumber: 'Produktnummer',
                    netPrice: 'Nettopreis',
                    netPriceIn: 'Nettopreis in',
                    unlimited: 'unbegrenzt',
                    isDefaultSalesTax: 'Standard Umsatzsteuer',
                    productImages: 'Produktbilder',
                    numberPiecesQuantityOfProduct: 'Stückzahl / Menge des Produkts',
                    shippingCostsDomestic: 'Versandkosten Inland',
                    shippingCostsEuropeanForeignCountries: 'Versandkosten Europäisches Ausland',
                    shippingCostsOtherCountries: 'Versandkosten Andere Länder',
                    maxNumberOfProductsInOneShippingPackage: 'Maximale Anzahl von Produkten in einem Versandpaket',
                    salesTaxInPercent: 'Umsatzsteuer in Prozent',
                    salesTax: 'Umsatzsteuer',
                    optional: 'Optional',
                    productNameIsInvalid: 'Der gewählte Name ist zu kurz. Der Name muss mindestens 1 Zeichen enthalten.',
                    productDescriptionIsInvalid: 'Die gewählte Beschreibung ist zu kurz. Die Beschreibung muss mindestens 1 Zeichen enthalten.',
                    productNumberIsInvalid: 'Die gewählte Nummer ist zu kurz. Die Nummer muss mindestens 1 Zeichen enthalten.',
                    priceIsInvalid: 'Der gewählte Preis ist ungültig.',
                    valueIsInvalid: 'Der gewählte Wert ist ungültig.',
                    productNumberAlreadyAssigned: 'Diese Produktnummer ist bereits einem anderen Produkt zugeordnet.',
                    addNewProduct: 'Neues Produkt erstellen',
                    backToProductList: 'Zurück zur Produktliste',
                    productStatusActive: 'Aktiv',
                    productStatusPaused: 'Pausiert',
                },
            }
        },
    }
}

export default Language;