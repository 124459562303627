/*
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers/index';

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
*/

import { configureStore } from '@reduxjs/toolkit';
import columnTypesSlice from '../reducers/slices/columnTypes.slice';
import csrfTokenSlice from '../reducers/slices/csrfToken.slice';
import currentEventSlice from '../reducers/slices/currentEvent.slice';
import currentLandingPageSlice from '../reducers/slices/currentLandingPage.slice';
import currentLandingPageTemplateSlice from '../reducers/slices/currentLandingPageTemplate.slice';
import isCookieAndPrivacyOptOutOpenSlice from '../reducers/slices/isCookieAndPrivacyOptOutOpen.slice';
import isSignedInSlice from '../reducers/slices/isSignedIn.slice';
import executionCallbackSlice from '../reducers/slices/executionCallback.slice';
import fontsSlice from '../reducers/slices/fonts.slice';
import languageSlice from '../reducers/slices/language.slice';
import messageSlice from '../reducers/slices/message.slice';
import navigationSlice from '../reducers/slices/navigation.slice';
import productSetupWizardSlice from '../reducers/slices/productSetupWizard.slice';
import rowTypesSlice from '../reducers/slices/rowTypes.slice';
import statusSlice from '../reducers/slices/status.slice';
import tokenSlice from '../reducers/slices/token.slice';
import userSlice from '../reducers/slices/user.slice';
import waitingSyncsSlice from '../reducers/slices/waitingSyncs.slice';
import productTypesSlice from '../reducers/slices/productTypes.slice';
import countriesSlice from '../reducers/slices/countries.slice';

export const store = configureStore({
    reducer: {
        token: tokenSlice,
        csrfToken: csrfTokenSlice,
        language: languageSlice,
        user: userSlice,
        navigation: navigationSlice,
        isSignedIn: isSignedInSlice,
        executionCallback: executionCallbackSlice,
        //waitingSyncs: waitingSyncsSlice,
        status: statusSlice,
        currentEvent: currentEventSlice,
        currentLandingPage: currentLandingPageSlice,
        currentLandingPageTemplate: currentLandingPageTemplateSlice,
        message: messageSlice,
        fonts: fontsSlice,
        isCookieAndPrivacyOptOutOpen: isCookieAndPrivacyOptOutOpenSlice,
        rowTypes: rowTypesSlice,
        columnTypes: columnTypesSlice,
        productSetupWizard: productSetupWizardSlice,
        productTypes: productTypesSlice,
        countries: countriesSlice,
    },
});