import React, { useState, useEffect } from 'react';
import ErrorBoundary from '../../../../../error/error.component';
import FallbackUI from '../../../../../error/standardFallback.component';
import { FadeIn } from '../../../../../styled/fadeIn.component';
import { Icon } from '../../../../../styled/icon.component';
import Language from '../../../../../../constants/language';
import TextInput from '../../../../../textInput.component';
import { InputError } from '../../../../../styled/inputError.component';
import { isNullOrUndefined } from '../../../../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { setProductSetupWizard } from '../../../../../../reducers/slices/productSetupWizard.slice';
import { useProductTypeSetupWizard } from '../../../../../../factories/productSetup.factory';

function ProductNumber(props) {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.value);
    const language = useSelector((state) => state.language.value);
    const currentLandingPage = useSelector((state) => state.currentLandingPage.value);
    const productSetupWizard = useSelector((state) => state.productSetupWizard.value);

    const [productNumber, setProductNumber] = useState(isNullOrUndefined(productSetupWizard.product?.product_no) ? '' : productSetupWizard.product.product_no);
    const [additionalClasses, setAdditionalClasses] = useState('');
    const [error, setError] = useState(null);

    const { updateProduct } = useProductTypeSetupWizard();

    function checkProductNumber(event, action = 'submit') {
        let newProductNumber = event.target.value;
        let isValid = false;
        let isProductNumberAlreadyAssigned = false;
        if(newProductNumber.trim().length > 0) {
            if(currentLandingPage.all_products?.find((product) => product.product_no === newProductNumber && product.id !== productSetupWizard.product.id) === undefined) {
                isValid = true;
            }
            else {
                isProductNumberAlreadyAssigned = true;
            }
        }
        if(isValid) {
            setAdditionalClasses('');
            setError(null);
        }
        else {
            setAdditionalClasses('is-invalid');
            if(isProductNumberAlreadyAssigned) {
                setError(Language[language].shop.wizard.product.productNumberAlreadyAssigned);
            }
            else {
                setError(Language[language].shop.wizard.product.productNumberIsInvalid);
            }
        }
        let product = _.cloneDeep(productSetupWizard.product);
        product = updateProduct(product, { type: 'product_no', value: newProductNumber });
        let updatedWizard = { step: productSetupWizard.step, product: product};
        if(isValid && action === 'submit') {
            updatedWizard.step++;
        }
        dispatch(setProductSetupWizard(updatedWizard));
        setProductNumber(newProductNumber);
    }

    return (
        <FadeIn>
            <div className="row">
                <div className="col d-flex justify-content-center align-items-center">
                    { Language[language].shop.wizard.product.enterProductNumberText }
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <TextInput
                        key={"productNumber"}
                        type={"text"}
                        label={""}
                        value={productNumber}
                        name={"productNumber"}
                        placeholder={Language[language].shop.wizard.product.productNumber}
                        onChange={(event) => checkProductNumber(event, 'change')}
                        submitOnEnter={true}
                        submitEvent={(event) => checkProductNumber(event)}
                        required={true}
                        maxLength={255}
                        autoFocus={true}
                        additionalClasses={additionalClasses}
                    />
                    {
                        error !== null &&
                        <InputError>
                            {
                                error
                            }
                        </InputError>
                    }
                </div>
            </div>
        </FadeIn>
    );
}

const ComponentWithErrorBoundary = (props) => {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"productNumber.component.js"}
            fallbackUI={<FallbackUI language={language} component={"productNumber"} />}
        >
            <ProductNumber
                key={`productNumberComponent`}
                {...props}
            />
       </ErrorBoundary>
   )
}
 
export default ComponentWithErrorBoundary;