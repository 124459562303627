import React from 'react';
import styled from 'styled-components';
import Language from '../constants/language';
import ErrorBoundary from '../components/error/error.component';
import FallbackUI from '../components/error/standardFallback.component';
import { useSelector } from 'react-redux';
import { Link } from '../components/styled/link.component';

const MarginTop = styled.div`
    margin-top:${props => props.marginTop ? props.marginTop : 'auto'};
`

const H1 = styled.h1`
    @media (max-width: 576px) {
        font-size:1.5rem;
    }
`;

const H3 = styled.h1`
    @media (max-width: 576px) {
        font-size:1.25rem;
    }
`;

const ContainerWithMobileView = styled.div`
    @media (max-width: 576px) {
        padding-left:0px;
        padding-right:0px;
    }
`;

const ImprintView = (props) => {
    const language = useSelector((state) => state.language.value);

    return (
        <ContainerWithMobileView className="container-fluid">
            <div className="row">
                <div className="col">
                    <H1>{ Language[language].footer.imprint }</H1>
                </div>
                <div>
                    <H3>{ Language[language].imprint.informationAccordingToTMG5 }</H3>
                    <div>
                        { Language[language].imprint.siteIsOperatedAndProvidedBy }
                    </div>
                    <MarginTop marginTop={'1em'}>
                        Dennis Neetix Einzelunternehmung
                        <br />
                        Joseph-Veith Str. 20
                        <br />
                        DE-41334 Nettetal
                    </MarginTop>
                    <div>
                        { Language[language].imprint.salesTaxIdentificationNumberAccordingTo27aSalesTaxAct }: DE316989099
                    </div>
                    <div>
                        { Language[language].general.phone }: 
                        <br />
                        { Language[language].general.email }: 
                    </div> 
                    <div>({ Language[language].imprint.hereinafter + ' "' + Language[language].imprint.operator + '"' })</div>

                    {
                        props.location === 'app' &&
                        <MarginTop marginTop={'1em'}>
                            { Language[language].imprint.bannedContentSentence }
                        </MarginTop>
                    }

                    <MarginTop marginTop={'2em'}>
                        <H3>
                            { Language[language].imprint.pictureCredits }
                        </H3>
                        <div>
                            { 
                                `${Language[language].imprint.startPageBackgroundImage}: ` + 
                                //${Language[language].imprint.photoFrom} Tofros.com ${Language[language].imprint.by} Pexels 
                                `Photo by `
                            }
                            <Link 
                                href="https://unsplash.com/@epicantus?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                            >
                                Daria Nepriakhina 🇺🇦
                            </Link> 
                            {
                                ` on ` 
                            }
                            <Link 
                                href="https://unsplash.com/s/photos/app?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                            >
                                Unsplash
                            </Link>
                        </div>
                    </MarginTop>

                    <MarginTop marginTop={'2em'}>
                        <H3>
                            { Language[language].imprint.disclaimer }
                        </H3>
                        <MarginTop marginTop={'1em'}>
                                1. { Language[language].imprint.contentOfTheOnlineOffer }
                        </MarginTop>
                        <MarginTop marginTop={'0.5em'}>
                            { Language[language].imprint.contentOfTheOnlineOfferText1 }
                            <br />
                            { Language[language].imprint.contentOfTheOnlineOfferText2 }
                            <br />
                            { Language[language].imprint.contentOfTheOnlineOfferText3 }
                        </MarginTop>
                        <MarginTop marginTop={'1em'}>
                            2. { Language[language].imprint.referencesAndLinks }
                        </MarginTop>
                        <MarginTop marginTop={'0.5em'}>
                            { Language[language].imprint.referencesAndLinksText1 }
                        </MarginTop>
                        <MarginTop marginTop={'1em'}>
                            3. { Language[language].imprint.copyrightAndTrademarkLaw }
                        </MarginTop>
                        <MarginTop marginTop={'0.5em'}>
                            { Language[language].imprint.copyrightAndTrademarkLawText1 }
                            <br />
                            { Language[language].imprint.copyrightAndTrademarkLawText2 }
                            <br />
                            { Language[language].imprint.copyrightAndTrademarkLawText3 }
                        </MarginTop>
                        <MarginTop marginTop={'1em'}>
                            { Language[language].imprint.legalValidityOfThisDisclaimer }
                        </MarginTop>
                        <MarginTop marginTop={'0.5em'}>
                            { Language[language].imprint.legalValidityOfThisDisclaimerText }
                        </MarginTop>
                    </MarginTop>
                </div>
            </div>
        </ContainerWithMobileView>
    );
}


const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"imprint.view.js"}
            fallbackUI={<FallbackUI language={language} component={"imprint"} />}
        >
            <ImprintView
                key={"imprintViewComponent"}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;