import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ErrorBoundary from '../../components/error/error.component';
import FallbackUI from '../../components/error/standardFallback.component';
import BusinessSetup from '../../components/managementInterface/shop/tabs/businessSetupWizard.component';
import ProductSetup from '../../components/managementInterface/shop/tabs/productSetupWizard.component';
import ProductListContainer from './productList.container';
import { isNullOrUndefined } from '../../utils/utils';
import { useSelector, useDispatch } from 'react-redux';

const Tab = styled.li`
    cursor:${props => props.isTabDisabled ? 'default' : 'pointer'};
`

const TabLink = styled.a`
    color: ${props => props.isActiveTab ? '#495057' : props.isLinkDisabled ? '#aaa' : '#fff'} !important;
    background-color: ${props => props.isActiveTab ? '#fff' : 'transparent'} !important;
    border-color: ${props => props.isActiveTab ? '#dee2e6 #dee2e6 #fff' : 'transparent !important'};
`

function ProductsManagementContainer(props) {

    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.value);
    const user = useSelector((state) => state.user.value);

    const [activeTab,setActiveTab] = useState('businessAndPaymentSetup');
    const [isProductsTabDisabled, setIsProductsTabDisabled] = useState(isNullOrUndefined(user?.business_data) ? true : false);

    /*
    useEffect(() => {
        console.log('update isProductsTabDisabled...', props.user);
        setIsProductsTabDisabled(isNullOrUndefined(props.user?.business_data) ? true : false);
    },[props.user, props.user?.business_data]);
    */

    return (
        <div className="h-100">
            <ul className="nav nav-tabs">
                <Tab 
                    className="nav-item" 
                    onClick={() => setActiveTab('businessAndPaymentSetup')}
                >
                    <TabLink 
                        className="nav-link"
                        isActiveTab={activeTab === 'businessAndPaymentSetup'}
                    >
                        Business & Payment setup
                    </TabLink>
                </Tab>
                <Tab 
                    className="nav-item" 
                    isTabDisabled={isProductsTabDisabled}
                    onClick={() => {
                        if(!isProductsTabDisabled) {
                            setActiveTab('manageProducts')
                        }
                    }}
                >
                    <TabLink 
                        className={`nav-link ${isProductsTabDisabled ? 'disabled' : ''}`} 
                        isActiveTab={activeTab === 'manageProducts'}
                        isLinkDisabled={isProductsTabDisabled}
                    >
                        Manage products
                    </TabLink>
                </Tab>
            </ul>
            {
                activeTab === 'businessAndPaymentSetup' &&
                <BusinessSetup
                    key={"businessSetup"}
                    afterSavingBusinessData={() => setIsProductsTabDisabled(false)}
                />
            }
            {
                activeTab === 'manageProducts' &&
                <ProductListContainer
                    key={`productListContainer`}
                />
                /*
                <ProductSetup
                    key={"productSetup"}
                />
                */
            }
        </div>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"productsManagement.container.js"}
            fallbackUI={<FallbackUI language={language} component={"productsManagement"} />}
        >
            <ProductsManagementContainer
                key={`productsManagementContainer`}
                {...props}
            />
       </ErrorBoundary>
   )
}
 
export default ComponentWithErrorBoundary;