import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ErrorBoundary from '../error/error.component';
import FallbackUI from '../error/standardFallback.component';
import AppText from './appText.component';
import { isNullOrUndefined } from '../../utils/utils';

const StartImage = styled.div`
    display:flex;
    flex-direction:column;
    background-repeat:no-repeat;
    width:100vw;
    height:100vh;
`

const HeaderImageRightCol = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`

const Logo = styled.div`
    background-repeat:no-repeat;
    background-image:url(${props => !isNullOrUndefined(props.logoImage) ? ("/event/images/show/" + props.logoImage.identifier_token) : ''});
    background-size:cover;
    background-position:center;
    width:200px;
    height:200px;
    border-radius: ${props => !isNullOrUndefined(props.images) && !isNullOrUndefined(props.logoImage) ? props.images.find((image) => image.id === props.logoImage.app_image_id)?.border_radius : '1' }%;
    opacity: ${props => !isNullOrUndefined(props.images) && !isNullOrUndefined(props.logoImage) ? props.images.find((image) => image.id === props.logoImage.app_image_id)?.opacity : 1 };
    position: relative;

    @media (max-height: 400px) {
        width:100px;
        height:100px;
    }
`

const LogoDiv = styled.div`
    background-color:transparent;
    margin-top:5rem;
    margin-right:1rem;

    @media (max-height: 400px) {
        margin-top:1rem;
    }
` 

const StartPageImage = styled.img`
    position:absolute;
    width:100vw;
    height:100vh;
    object-fit: cover;
`

function LandingPageStartImage(props) {
    const [image640,setImage640] = useState(null);
    const [image768,setImage768] = useState(null);
    const [image1024,setImage1024] = useState(null);
    const [image1366,setImage1366] = useState(null);
    const [image1600,setImage1600] = useState(null);
    const [image1920,setImage1920] = useState(null);
    const [logoImage640, setLogoImage640] = useState(null);

    function setImages() {
        let images = props.guestLandingPage?.landing_page?.app_images?.find((image) => image.landing_page_template_image_id === 1)?.app_image_sizes;
        if(window.innerWidth < 768) {
            let portraitImages = props.guestLandingPage?.landing_page?.app_images?.find((image) => image.landing_page_template_image_id === 2)?.app_image_sizes;
            if(!isNullOrUndefined(portraitImages)) {
                images = portraitImages;
            }
        }
        setImage640(images?.find((image) => image.size === '640'));
        setImage768(images?.find((image) => image.size === '768'));
        setImage1024(images?.find((image) => image.size === '1024'));
        setImage1366(images?.find((image) => image.size === '1366'));
        setImage1600(images?.find((image) => image.size === '1600'));
        setImage1920(images?.find((image) => image.size === '1920'));
        
        let logoImages = props.guestLandingPage?.landing_page?.app_images?.find((image) => image.landing_page_template_image_id === 4 )?.app_image_sizes;
        setLogoImage640(logoImages?.find((image) => image.size === '640'));
    }

    useEffect(() => {
        setImages();
        const updateWindowDimensions = () => {
            setImages();
        };
    
        window.addEventListener("resize", updateWindowDimensions);
        return () => window.removeEventListener("resize", updateWindowDimensions) 
      }, []);

    return (
        <StartImage key={"startImageKey"}>
            
            {
                !isNullOrUndefined(image640) &&
                <picture>
                    <source srcSet={"/event/images/show/" + image768?.identifier_token} media="(max-width: 768px) and (min-width: 641px)" />
                    <source srcSet={"/event/images/show/" + image1024?.identifier_token} media="(max-width: 1024px) and (min-width: 769px)" />
                    <source srcSet={"/event/images/show/" + image1366?.identifier_token} media="(max-width: 1366px) and (min-width: 1025px)" />
                    <source srcSet={"/event/images/show/" + image1600?.identifier_token} media="(max-width: 1600px) and (min-width: 1367px)" />
                    <source srcSet={"/event/images/show/" + image1920?.identifier_token} media="(min-width: 1601px)" />
                    <StartPageImage src={"/event/images/show/" + image640?.identifier_token} alt="Image" />
                </picture>
            }
            
            <div className="container-fluid">
                <div className="row">
                    <HeaderImageRightCol className="col">
                        <LogoDiv>
                            <Logo images={props.guestLandingPage.landing_page.app_images} logoImage={logoImage640} />
                        </LogoDiv>
                    </HeaderImageRightCol>
                </div>
            <AppText
                key={"appTextLandingPageName"}
                appText={props.guestLandingPage.landing_page.app_texts?.find((text) => text.landing_page_template_text_id === 2)}
                language={props.language}
                isEditMode={false}
            />
            </div>
        </StartImage>
    )
}

const ComponentWithErrorBoundary = (props) =>  {
    return ( 
        <ErrorBoundary
            location={"app"}
            component={"landingPageStartImage.component.js"}
            fallbackUI={<FallbackUI language={props.language} component={"landingPageStartImage"} />}
        >
            <LandingPageStartImage
                key={"landingPageStartImage"}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;