export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const UPDATE_CSRF_TOKEN = "UPDATE_CSRF_TOKEN";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_NAVIGATION = "UPDATE_NAVIGATION";
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";
export const UPDATE_IS_SIGNED_IN = "UPDATE_IS_SIGNED_IN";
export const UPDATE_NAVIGATION_EXECUTION_CALLBACK = "UPDATE_NAVIGATION_EXECUTION_CALLBACK";
export const UPDATE_WAITING_SYNCS = "UPDATE_WAITING_SYNCS";
export const UPDATE_STATUS = "UPDATE_STATUS";
export const UPDATE_CURRENT_EVENT = "UPDATE_CURRENT_EVENT";
export const UPDATE_CURRENT_LANDING_PAGE = "UPDATE_CURRENT_LANDING_PAGE";
export const UPDATE_CURRENT_LANDING_PAGE_TEMPLATE = "UPDATE_CURRENT_LANDING_PAGE_TEMPLATE";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
export const UPDATE_FONTS = "UPDATE_FONTS";
export const UPDATE_IS_COOKIE_AND_PRIVACY_OPT_OUT_OPEN = "UPDATE_IS_COOKIE_AND_PRIVACY_OPT_OUT_OPEN";
export const UPDATE_ROW_TYPES = "UPDATE_ROW_TYPES";
export const UPDATE_COLUMN_TYPES = "UPDATE_COLUMN_TYPES";
export const UPDATE_BUSINESS_DATA = "UPDATE_BUSINESS_DATA";
export const UPDATE_WIZARD = "UPDATE_WIZARD";