import React, { useState, useEffect } from 'react';
import ErrorBoundary from '../../../../error/error.component';
import FallbackUI from '../../../../error/standardFallback.component';
import { FadeIn } from '../../../../styled/fadeIn.component';
import Language from '../../../../../constants/language';
import TextInput from '../../../../textInput.component';
import { isEmailAddressValid } from "../../../../../utils/email";
import { InputError } from '../../../../styled/inputError.component';
import { useSelector } from 'react-redux';

import paypalLogo from '../../../../../assets/images/de-pp-logo-200px.png';

function PayPalEmailAddress(props) {
    const language = useSelector((state) => state.language.value);
    
    const [emailAddress, setEmailAddress] = useState(props.paypalEmailAddress);
    const [additionalClasses, setAdditionalClasses] = useState('');
    const [error, setError] = useState(null);

    function checkEmailAddress(event, action = 'submit') {
        if(event.target.value.indexOf('@') > -1 && event.target.value.indexOf('.') > -1) {
            let isEmailValid = isEmailAddressValid(event.target.value);
            let moveToNextStep = false;
            if(isEmailValid) {
                setAdditionalClasses('is-valid');
                setError(null);
                if(action === 'submit') {
                    moveToNextStep = true;
                }
            }
            else {
                setAdditionalClasses('is-invalid');
                setError(Language[language].shop.payPalEmailAddressIsNotValid);
            }
            props.onReturnData(event.target.value, isEmailValid, moveToNextStep);
            setEmailAddress(event.target.value);
        }
    }

    return (
        <FadeIn>
            <div className="row mt-5">
                <div className="col">
                    <img src={paypalLogo} className="img-fluid" />
                </div>
            </div>  
            <div className="row mt-2">
                <div className="col">
                    {
                        Language[language].shop.payPalEmailAddressExplanationText
                    }
                </div>
            </div>  
            <div className="row mt-2">
                <div className="col">
                    <TextInput
                        key={"paypalEmailAddress"}
                        type={"email"}
                        value={emailAddress}
                        label={""}
                        name={"paypalEmailAddress"}
                        placeholder={Language[language].account.emailAddress}
                        onChange={(event) => checkEmailAddress(event, 'change')}
                        submitOnEnter={true}
                        submitEvent={(event) => checkEmailAddress(event)}
                        required={true}
                        autoFocus={true}
                        additionalClasses={additionalClasses}
                    />
                    {
                        error !== null &&
                        <InputError>
                            {
                                error
                            }
                        </InputError>
                    }
                </div>
            </div>
        </FadeIn>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"payPalEmailAddress.component.js"}
            fallbackUI={<FallbackUI language={language} component={"payPalEmailAddress"} />}
        >
            <PayPalEmailAddress
                key={`paypalEmailAddressComponent`}
                {...props}
            />
       </ErrorBoundary>
   )
}
 
export default ComponentWithErrorBoundary;