import { property } from 'lodash';
import React from 'react';

const SelectboxOption = ({option,valueField,displayField,disabled}) =>
    <option disabled={disabled !== undefined && disabled ? "disabled": undefined} value={disabled !== undefined && disabled ? -999 : option[valueField]}>
        {
            displayField !== undefined &&
            option[displayField]
        }
    </option>

export default SelectboxOption;