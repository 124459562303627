import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { QRCodeSVG } from 'qrcode.react';
import ErrorBoundary from '../error/error.component';
import FallbackUI from '../error/standardFallback.component';
import { useSelector, useDispatch } from 'react-redux';

function QrCode(props) {
    const language = useSelector((state) => state.language.value);

    return (
        <div className="container">
            <div className="row mt-2">
                <div className="col text-center">
                    <QRCodeSVG 
                        value="https://reactjs.org/" 
                        renderas="svg"
                        size="256"
                    />
                </div>
            </div>
        </div>
    );
}

const ComponentWithErrorBoundary = (props) =>  {
    const language = useSelector((state) => state.language.value);
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"qrCode.component.js"}
            fallbackUI={<FallbackUI language={language} component={"qrCode"} />}
        >
            <QrCode
                key={`qrCodeComponent`}
                {...props}
            />
       </ErrorBoundary>
   )
}
 
export default ComponentWithErrorBoundary;