import React, { Component } from 'react';
import styled  from "styled-components";
import Language from '../../constants/language';
import ErrorBoundary from '../error/error.component';
import FallbackUI from '../error/standardFallback.component';
import platform from 'platform';

const infoIcon = require('../../assets/images/info.svg');

const WarningModal = styled.div`
    position:absolute;
    top:3%;
    width: 80%;
    left:50%;
    margin-left:-40%;
    background-color:#fff;
    color:#000;
    border-radius:0.25rem;
    z-index:9999;
    padding: 1rem;
`

const CloseButton = styled.button`
    color: #868585;
`


class BrowserIncompatabilityWarning extends Component {

    constructor(props) {
        super(props);
        this.state = {
            foundIncompatibleBrowser: false,
            isModalClosed: true
        };
        this.checkCurrentBrowser = this.checkCurrentBrowser.bind(this);
    }

    componentDidMount() {
        this.checkCurrentBrowser();
    }

    componentDidUpdate(prevProps, prevState) {
    }

    componentWillUnmount() {
    }

    checkCurrentBrowser() {
        // see this stackoverflow post:
        // https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser?rq=1
        // console.log(platform);
        if(platform?.name !== 'Safari' && platform?.name !== 'Chrome' && platform?.name !== 'Chrome Mobile' && platform?.name !== 'Microsoft Edge') {
            this.setState({
                isModalClosed: false
            });
        }
    }

    onCloseButtonClick() {
        this.setState({
            isModalClosed: true
        });
    }

    render() {
        return (
            <>
            {
                !this.state.isModalClosed &&
                <WarningModal>
                    <div className="container">
                        <div className="row">
                            <div className="col text-end">
                                <CloseButton type="button" className="btn-close" aria-label="Close" onClick={this.onCloseButtonClick.bind(this)}></CloseButton>
                            </div>
                        </div>
                        <div className="row d-flex align-items-center text-center">
                            <div>
                                <img src={infoIcon.default} alt="Info" className="img-fluid" />
                            </div>
                            <div className="col">
                                {/*
                                <CloseButton type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.onCloseButtonClick.bind(this)}>
                                    <span aria-hidden="true">&times;</span>
                                </CloseButton>
                                */}
                                { Language[this.props.language].hints.browserIncompatabilityWarning }
                            </div>                            
                        </div>
                    </div>
                </WarningModal>
            }
            </>
	    );
	}
}

const ComponentWithErrorBoundary = (props) =>  {
    return ( 
        <ErrorBoundary
            location={"builder"}
            component={"browserIncompatabilityWarning.component.js"}
            fallbackUI={<FallbackUI language={props.language} component={"browserIncompatabilityWarning"} />}
        >
            <BrowserIncompatabilityWarning
                key={"browserIncompatabilityWarningComponent"}
                {...props}
            />
       </ErrorBoundary>
   )
}

export default ComponentWithErrorBoundary;