import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import { connect } from 'react-redux';
import { updateNavigation, updateUser, updateLanguage, updateIsSignedIn, updateCurrentLandingPage, updateMessage } from '../../actions/index';
import _ from 'lodash';
import CalendarDay from '../app/calendarDay.component';
import Button from '../button.component';
import TextInput from '../textInput.component';
import TextArea from '../textarea.component';
import Selectbox from '../selectbox.component';
import Language from '../../constants/language';
import * as MessageTypes from '../../constants/messagetypes';
import * as Data from '../../backend/data';
import * as Date from '../../utils/date';
import { format } from 'date-fns';
import parseISO from 'date-fns/parseISO'

const editIcon = require('../../assets/images/edit.svg').default;
const saveIcon = require('../../assets/images/check2-square.svg').default;
const cancelIcon = require('../../assets/images/x-square.svg').default;

const blendOut = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
`;

const blendIn = keyframes`
    0% {
        opacity: 0;
        width:100px;
    }
    100% {
        opacity: 1;
        width:200px;
    }
`;

const slideIn = keyframes`
    0% {
        width:100px;
    }
    100% {
        width:200px;
    }
`

const ShowTextFieldDiv = styled.div`
`
const Text = styled.div`
    ${ShowTextFieldDiv}:hover & {
        animation-name: ${blendOut};
        animation-fill-mode: forwards;
        animation-duration: 1s;
    }
`

const HoverDiv = styled.div`
    display: none;
    position:absolute;
    left:50%;
    top:50%;
    font-size:1rem;
    font-weight:normal;
    width:200px;
    margin-left:-100px;
    height:65px;
    margin-top:-32.5px;
    /*font-family: 'Lato', sans-serif !important;*/
    font-family:'latolight' !important;
    font-size: 20px;
    border: 1px solid #000;
    border-radius:0.25rem;

    &:hover {
        cursor:pointer;
    }

    ${ShowTextFieldDiv}:hover & {
        display: inline-block;

        animation-name: ${blendIn};
        animation-duration: 1s;
    }
`

const HoverBackground = styled.div`
    display: none;
    position:absolute;
    left:50%;
    top:50%;
    font-size:1rem;
    font-weight:normal;
    width:200px;
    margin-left:-100px;
    height:65px;
    margin-top:-32.5px;
    border-radius:0.25rem;
    background-color:#fff;
    opacity: 0.4;

    &:hover {
        opacity: 0.6;
    }

    ${HoverDiv}:hover & {
        opacity: 0.6;
    }

    ${ShowTextFieldDiv}:hover & {
        display: inline-block;
        animation-name: ${slideIn};
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }
`

const HoverText = styled.div`
`

const EditTextFieldDiv = styled.div`
    border:1px solid #eaeaea;
    background-color:transparent;
    padding:1rem;
    border-radius:0.25rem;
    /*font-family: 'Lato', sans-serif !important;*/
    font-family:'latolight' !important;
    font-size: 20px;
    margin:2rem;
`

const ModalCloseButton = styled.div`
    margin:0.5rem;
    position: relative;
    top: -20px;
    left: 10px;
` 

const Header = styled.div`
    font-size:1rem;
    font-weight:normal;
`

const EditHintIcon = styled.img`
    position:relative;
    width:15px;
    top:-90px;
    left:100px;

    ${ShowTextFieldDiv}:hover & {
        visibility:hidden;
    }
`

const EditHintText = styled.img`
    position:relative;
    width:15px;
    top:-10px;
    left:10px;

    ${ShowTextFieldDiv}:hover & {
        visibility:hidden;
    }
`

const RowMargin = styled.div`
    margin:1rem;
`

class EditDate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dateValue: null,
            isDateEditActive: false
        }

        this.getDateFromProps = this.getDateFromProps.bind(this);
        this.onSaveButtonClicked = this.onSaveButtonClicked.bind(this);
        this.onSaveDateSuccess = this.onSaveDateSuccess.bind(this);
        this.onRequestDataFailure = this.onRequestDataFailure.bind(this);
    }

    componentDidMount() {
        
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if((this.props.currentLandingPage !== null && prevProps.currentLandingPage === null) || (this.props.currentLandingPageTemplate !== null && prevProps.currentLandingPageTemplate === null)) {
            this.setState({
                dateValue: this.getDateFromProps()
            });
        }
    }

    componentDidCatch() {
    }
    
    //#region Events
    getDateFromProps() {
        return this.props.currentLandingPage?.event?.event_date;
    }

    onHandleDateEditClick(event) {
        this.setState({
            isDateEditActive: true
        });
    }

    onDateEditBlur(event) {
        this.setState({
            dateValue: event.target.value,
        });
    }

    onDateEditEnterKeyPress(event) {
        this.setState({
            dateValue: event.target.value,
        },() =>  {
            this.onSaveButtonClicked();
        });
    }

    onCloseClick(event) {
        this.setState({
            isDateEditActive: false
        });
    }

    onSaveButtonClicked(event) {
        let formData = [
            {
                name: 'csrfToken',
                value: this.props.csrfToken
            },
            {
                name: 'landingPageId',
                value: this.props.currentLandingPage.id
            },
            {
                name: 'dateValue',
                value: this.state.dateValue
            }
        ];
        Data.transfer(window.baseUrl + '/builder/user/landingPage/date/save','POST',formData,this.onSaveDateSuccess,this.onRequestDataFailure,this.props.csrfToken);
    }

    onCancelButtonClicked(event) {
        this.onCloseClick(event);
    }

    onSaveDateSuccess(data) {
        if(data.success && data.landingPage) {
            this.onCloseClick();
            this.props.updateCurrentLandingPage(data.landingPage);
            this.props.updateMessage({
                message: Language[this.props.language].editor.savedDateSuccessfully,
                messageType: MessageTypes.MESSAGE_TYPE_SUCCESS
            });
        }
    }

    onRequestDataFailure(error) {
        this.props.updateMessage({
            message: error,
            messageType: MessageTypes.MESSAGE_TYPE_ERROR
        });
    }
    //#endregion Events

	render() {
        return (
 			<>
                {
                    !this.state.isDateEditActive &&
                    <ShowTextFieldDiv onClick={this.onHandleDateEditClick.bind(this)} id={this.props.textType} title={Language[this.props.language].editor.editDate}>
                        {
                            <Text>
                                <CalendarDay 
                                    key={"calendarDateDay"}
                                    showAsCalendarIcon={this.props.showAsCalendarIcon}
                                    date={this.props.currentLandingPage?.event?.event_date} 
                                    language={this.props.language} 
                                />
                                {
                                    this.props.showAsCalendarIcon &&
                                    <EditHintIcon src={editIcon} alt="Edit date"></EditHintIcon>
                                }
                                {
                                    !this.props.showAsCalendarIcon &&
                                    <EditHintText src={editIcon} alt="Edit date"></EditHintText>
                                }
                            </Text>
                        }
                        <HoverBackground />
                        <HoverDiv>
                            <div className="row">
                                <div className="col text-center">
                                    <img src={editIcon} alt="Edit date"></img>
                                </div>
                            </div>
                            <div className="row">
                                <HoverText className="col text-center">
                                    {Language[this.props.language].editor.editDate}
                                </HoverText>
                            </div>
                        </HoverDiv>
                    </ShowTextFieldDiv>
                }
                {
                    this.state.isDateEditActive &&
                    <>
                        <EditTextFieldDiv>
                            <div className="row">
                                <div className="col text-end">
                                    <button type="button" className="btn-close" aria-label="Close" onClick={this.onCloseClick.bind(this)}></button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <TextInput
                                        key={"dateEdit"}
                                        type={"datetime-local"}
                                        label={""}
                                        name={Language[this.props.language].editor.editDate}
                                        placeholder={Language[this.props.language].editor.editDate}
                                        onBlur={this.onDateEditBlur.bind(this)}
                                        submitOnEnter={true}
                                        submitEvent={this.onDateEditEnterKeyPress.bind(this)}
                                        required={true}
                                        disabled={false}
                                        value={Date.formatValueForDatetimeLocalInputField(this.state.dateValue)}
                                        autoFocus={true}
                                    />
                                </div>                   
                            </div>
                            <RowMargin className="row">
                                <div className="col">
                                    <Button
                                        key={"saveButton" + this.props.textType}
                                        buttonText={Language[this.props.language].general.save}
                                        additionalClasses={""}
                                        onClicked={this.onSaveButtonClicked}
                                        icon={saveIcon} 
                                        iconSize={"20px"}
                                        iconColor={"#000"}
                                    />
                                </div>
                                <div className="col">
                                    <Button
                                        key={"cancelButton" + this.props.textType}
                                        buttonText={Language[this.props.language].general.cancel}
                                        additionalClasses={""}
                                        onClicked={this.onCancelButtonClicked.bind(this)}
                                        icon={cancelIcon} 
                                        iconSize={"20px"}
                                        iconColor={"#000"}
                                    />
                                </div>
                            </RowMargin>
                        </EditTextFieldDiv>
                    </>
                }
            </>
 		);
	}
}

function mapStateToProps({ user, navigation, language, isSignedIn, csrfToken, currentEvent, currentLandingPage, currentLandingPageTemplate, message }) {
    return {
        user,
        navigation,
        language,
        isSignedIn,
        csrfToken,
        currentEvent,
        currentLandingPage,
        currentLandingPageTemplate,
        message
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateUser: user => dispatch(updateUser(user)),
        updateNavigation: navigation => dispatch(updateNavigation(navigation)),
        updateLanguage: language => dispatch(updateLanguage(language)),
        updateIsSignedIn: isSignedIn => dispatch(updateIsSignedIn(isSignedIn)),
        updateCurrentLandingPage: currentLandingPage => dispatch(updateCurrentLandingPage(currentLandingPage)),
        updateMessage: message => dispatch(updateMessage(message)),
    };
}
 
const Form = connect(
    mapStateToProps,
    mapDispatchToProps
)(EditDate);
 
export default Form;